import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import AdminApi from '../../api/AdminApi';
import Loader from '../../components/Loader';
import Switch from '../../components/Switch';
import SVGMultiply from '../../svg/SVGMultiply';

interface IEmailPreferences {
  HasOrders: boolean;
  OrganisationName: string;
  OrganisationTag: string;
  OrganisationBlocked: boolean;
  SeatyBlocked: boolean;
  OrganisationMarketingBlocked: boolean;
  OrganisationSurveyBlocked: boolean;
  Email: string;
}

interface IProps {}

const EmailUserPreferencesPage: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [emailPreferences, setEmailPreferences] = useState<IEmailPreferences[]>([]);

  const getData = async () => {
    setLoading(true);
    AdminApi.request('GET', `/api/EmailUserPreferences`)
      .then((result: IEmailPreferences[]) => {
        setEmailPreferences(result);

        setLoading(false);
      })
      .catch((message) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Seaty - Email Preferences</title>
        <meta name="description" content={`Seaty.co.uk.`} />
      </Helmet>

      <article>
        {loading ? (
          <Loader inline>Loading...</Loader>
        ) : (
          <div>
            <div className="toolbar">
              <div className="title">Email Preferences</div>
              <div className="buttons">
                {
                  <button
                    onClick={() => {
                      setLoading(true);
                      AdminApi.request('POST', `/api/EmailUserUnsubscribeFromAll`, {})
                        .then(() => {
                          getData();
                        })
                        .catch((message) => {
                          alert('There was an error in saving new state. Please refresh the page.');
                        });
                    }}
                  >
                    <SVGMultiply />
                    Unsubscribe from all
                  </button>
                }
              </div>
            </div>

            {emailPreferences.length == 0 ? (
              <div className="info">
                We cannot find any event organisers for this email address where you have placed an order or signed up
                for email subscriptions.
              </div>
            ) : (
              <div className="info">
                Below is a list of event organisers where you have placed an order or signed up for email services.
                <br />
                You can disable any of these email services by turning off the switches below.
              </div>
            )}
            {emailPreferences.map((data) => (
              <>
                <h2>{data.OrganisationName}</h2>

                <div style={{ margin: '0', marginBottom: '40px' }}>
                  {data.HasOrders && (
                    <div style={{ margin: '10px 0' }}>
                      <label>Event Update / Change Emails</label>
                      <Switch
                        disabled={loading}
                        trueLabel="Yes, allow updates / changes emails"
                        falseLabel="No, block updates / changes emails"
                        onChange={(value) => {
                          setLoading(true);
                          AdminApi.request('POST', `/api/EmailUserPreferences`, {
                            organisationTag: data.OrganisationTag,
                            category: 1,
                            blocked: !value,
                          })
                            .then(() => {
                              getData();
                            })
                            .catch((message) => {
                              alert('There was an error in saving new state. Please refresh the page.');
                            });
                        }}
                        checked={!data.OrganisationBlocked}
                      />
                    </div>
                  )}
                  <div style={{ margin: '10px 0' }}>
                    <label>Marketing Emails</label>
                    <Switch
                      disabled={loading}
                      trueLabel="Yes, allow news / marketing emails"
                      falseLabel="No, block news / marketing emails"
                      onChange={(value) => {
                        setLoading(true);
                        AdminApi.request('POST', `/api/EmailUserPreferences`, {
                          organisationTag: data.OrganisationTag,
                          category: 2,
                          blocked: !value,
                        })
                          .then(() => {
                            getData();
                          })
                          .catch((message) => {
                            alert('There was an error in saving new state. Please refresh the page.');
                          });
                      }}
                      checked={!data.OrganisationMarketingBlocked}
                    />
                  </div>
                  <div style={{ margin: '5px 0' }}>
                    <label>Survey Emails</label>
                    <Switch
                      disabled={loading}
                      trueLabel="Yes, allow survey emails"
                      falseLabel="No, block survey emails"
                      onChange={(value) => {
                        setLoading(true);
                        AdminApi.request('POST', `/api/EmailUserPreferences`, {
                          organisationTag: data.OrganisationTag,
                          category: 3,
                          blocked: !value,
                        })
                          .then(() => {
                            getData();
                          })
                          .catch((message) => {
                            alert('There was an error in saving new state. Please refresh the page.');
                          });
                      }}
                      checked={!data.OrganisationSurveyBlocked}
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </article>
    </>
  );
};

export default EmailUserPreferencesPage;

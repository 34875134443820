import { FunctionComponent } from 'react';
import EventHelper from '../helpers/EventHelper';
import ReactQuill from 'react-quill';

export interface IProps {
  onChange?(value: string): void;
  autoFocus?: boolean;
  id?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
}

const RichTextBox: FunctionComponent<IProps> = (props) => {
  const value = props.value ?? '';

  const charsUsed = EventHelper.getRichTextCharLength(value);
  const charsRemain = props.maxLength != null ? props.maxLength - charsUsed : 0;

  return (
    <>
      {props.label && (
        <label className="label">
          {props.label} ({charsUsed}/{props.maxLength})
        </label>
      )}

      {charsRemain < 0 && (
        <div className="info bad">
          You have exceeded the maximum character limit by {Math.abs(charsRemain)} characters.
        </div>
      )}

      <ReactQuill
        id={props.id}
        theme="snow"
        value={value}
        placeholder={props.placeholder}
        onChange={(v, e, b) => {
          if (b != 'user') return;
          props.onChange(v);
        }}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        }}
      />
    </>
  );
};

export default RichTextBox;

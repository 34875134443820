import linq from 'linq';
import { FunctionComponent, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import DateHelper from '../../../helpers/DateHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrder } from '../../../interfaces/IOrder';
import { IPurchase } from '../../../interfaces/IPurchase';
import Block from '../../Block';
import BlockHeader from '../../BlockHeader';
import BlockInfo, { InfoType } from '../../BlockInfo';
import BlockTextBox from '../../BlockTextBox';
import Button from '../../Button';
import BlockCheckBox from '../../BlockCheckBox';
import Loader from '../../Loader';
import SpacerTable from '../../SpacerTable';
import NumberHelper from '../../../helpers/NumberHelper';
import StringHelper from '../../../helpers/StringHelper';

export interface IProps {
  event: IEvent;
  order: IOrder;
  onBackToOrderClick: () => void;
}

const CancelSection: FunctionComponent<IProps> = (props) => {
  const { event, order, onBackToOrderClick } = props;

  const [enterCustomAmount, setEnterCustomAmount] = useState(false);
  const [customPaymentAmount, setCustomPaymentAmount] = useState('0.00');
  const [useCustomAmount, setUseCustomAmount] = useState(false);

  const tickets = linq
    .from(order.Seats)
    .orderBy((s) => s.Group)
    .thenBy((s) => s.Name)
    .toArray();

  const purchase: IPurchase = order.Purchase;

  const [addRefund, setAddRefund] = useState(false);
  const [refundHandlingFee, setRefundHandlingFee] = useState(false);
  const [cancellingReason, setCancellingReason] = useState('');
  const [busyMessage, setBusyMessage] = useState(null);
  const [reloadMessage, setReloadMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTickets, setSelectedTickets] = useState([
    ...linq
      .from(tickets)
      .where((t) => !t.Cancelled)
      .toArray(),
  ]);

  const totalSelectedTicketsFaceValue = linq.from(selectedTickets).sum((s: any) => s.PriceAsInt);

  var allTicketsSelected =
    selectedTickets.length ===
    linq
      .from(tickets)
      .where((t) => !t.Cancelled)
      .toArray().length;

  const cancelSelectedTickets = (order) => {
    setBusyMessage('Cancelling tickets...');
    setErrorMessage(null);

    var eventDateId = order.EventDateId;
    var reason = cancellingReason;

    var cancellationSeats = linq
      .from(order.Seats)
      .where(function (s: any) {
        return linq
          .from(selectedTickets)
          .where((t) => !t.Cancelled)
          .toArray()
          .includes(s);
      })
      .select(function (seat: any) {
        return {
          group: seat.Group,
          name: seat.Name,
          seatCategoryId: seat.SeatCategoryId,
          ticketCategoryId: seat.TicketCategoryId,
          orderId: order.Id,
        };
      })
      .toArray();

    var cancellation = {
      currency: event.CurrencySymbol,
      orderId: order.Id,
      eventDateId: eventDateId,
      reason: reason,
      seats: cancellationSeats,
      addRefund: addRefund,
      refundHandlingFee: refundHandlingFee,
      refundAmount: amountBeingRefunded,
    };

    AdminApi.request('DELETE', `/api/Order`, cancellation)
      .then(() => {
        setBusyMessage(null);
        setReloadMessage('The tickets have been cancelled.');

        AdminApi.request('POST', '/api/EventDateHandshake', { eventDateId: eventDateId });
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  if (reloadMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Info}>{reloadMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={props.onBackToOrderClick} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  const ticketsFaceValue =
    order &&
    order.Seats &&
    order.Seats.length > 0 &&
    linq
      .from(order.Seats)
      .where((s) => !s.Merchandise)
      .sum((s) => s.PriceAsInt);

  const merchandiseFaceValue =
    order &&
    order.Seats &&
    order.Seats.length > 0 &&
    linq
      .from(order.Seats)
      .where((s) => s.Merchandise)
      .sum((s) => s.PriceAsInt);

  const isPurchase = order.Purchase != null;

  const cancellableTickets = linq
    .from(tickets)
    .where((t) => !t.Cancelled)
    .toArray();

  const selectedTicketsTotal =
    selectedTickets && selectedTickets.length > 0
      ? linq.from(selectedTickets).sum((s: any) => s.PriceAsInt) / 100.0
      : 0;

  const amountAlreadyRefunded =
    purchase && purchase.Refunds && purchase.Refunds.length > 0
      ? linq.from(purchase.Refunds).sum((r) => r.Amount) -
        (purchase.HandlingFeeRefundId ? purchase.PurchaseCosts.HandlingFee : 0)
      : 0;

  const amountAvailableToRefund = purchase ? purchase.PurchaseCosts.TotalCostWithoutFees - amountAlreadyRefunded : 0;

  const amountBeingRefunded = purchase
    ? useCustomAmount
      ? parseFloat(customPaymentAmount) * 100.0
      : selectedTicketsTotal * 100.0 > amountAvailableToRefund || cancellableTickets.length == 0
        ? amountAvailableToRefund
        : selectedTicketsTotal * 100.0
    : 0;

  const totalOriginallyPaid = purchase ? purchase.PurchaseCosts.TotalCostWithFees : 0;

  const seatyFees = purchase ? purchase.PurchaseCosts.SeatyServiceFee : 0;

  const ersFees = purchase ? purchase.PurchaseCosts.EnhancedRefundServiceFee : 0;

  const handlingFee = purchase ? purchase.PurchaseCosts.HandlingFee : 0;

  return (
    <>
      <SpacerTable>
        <h1>Order #{props.order.Id}</h1>
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Attendee</BlockHeader>
            {order.AttendeeName}
            <br />
            {order.Email}
          </Block>
          <Block>
            <BlockHeader>Event Date</BlockHeader>
            {DateHelper.asDateAtTimeAmPm((order.EventDate as any).DateAsString)}
          </Block>
          {purchase && (
            <>
              <Block>
                <table>
                  {totalOriginallyPaid && totalOriginallyPaid > 0 ? (
                    <tr>
                      <td>Amount Paid</td>
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(event.CurrencySymbol, totalOriginallyPaid)}
                      </td>
                    </tr>
                  ) : null}
                  {merchandiseFaceValue && merchandiseFaceValue > 0 ? (
                    <tr>
                      <td>Merchandise</td>
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(event.CurrencySymbol, merchandiseFaceValue)}
                      </td>
                    </tr>
                  ) : null}
                  {handlingFee && handlingFee > 0 ? (
                    <tr>
                      <td>Handling Fee</td>
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(event.CurrencySymbol, handlingFee)}
                      </td>
                    </tr>
                  ) : null}

                  {order.Discounts != null &&
                    order.Discounts.length > 0 &&
                    order.Discounts.map((discount) => {
                      var discountName = 'Discount';

                      if (discount.Name != null && discount.Name.length > 0) {
                        if (discountName.toLowerCase().indexOf('discount') !== -1) {
                          discountName = discount.Name;
                        } else {
                          discountName = discount.Name + ' Discount';
                        }
                      }

                      return (
                        <tr key={'Discount_' + discount.Name + '_' + discount.Id}>
                          <td>{discountName}</td>
                          <td style={{ textAlign: 'right' }} id={'discountAmount'}>
                            -{CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}
                          </td>
                        </tr>
                      );
                    })}

                  {purchase.HandlingFeeRefundId && (
                    <tr>
                      <td>Handling Fee Refund</td>
                      <td style={{ textAlign: 'right' }} id="handlingFeeRefundAmount">
                        -{CurrencyHelper.formatCurrency(event.CurrencySymbol, handlingFee)}
                      </td>
                    </tr>
                  )}
                  {!purchase.AbsorbFees && (
                    <tr>
                      <td>Non-Refundable Fees</td>
                      <td style={{ textAlign: 'right' }} id="nonRefundableFeesAmount">
                        -{CurrencyHelper.formatCurrency(event.CurrencySymbol, seatyFees)}
                      </td>
                    </tr>
                  )}
                  {ersFees && ersFees > 0 ? (
                    <tr>
                      <td>Enhanced Refund Service</td>
                      <td style={{ textAlign: 'right' }} id="ersFeesAmount">
                        -{CurrencyHelper.formatCurrency(event.CurrencySymbol, ersFees)}
                      </td>
                    </tr>
                  ) : null}
                  {amountAlreadyRefunded > 0 && (
                    <tr>
                      <td>Refunded Amount</td>
                      <td style={{ textAlign: 'right' }} id="refundedAmount">
                        -{CurrencyHelper.formatCurrency(event.CurrencySymbol, amountAlreadyRefunded)}
                      </td>
                    </tr>
                  )}
                  <tr style={{ fontWeight: 'bold' }}>
                    <td>Available to Refund</td>
                    <td style={{ textAlign: 'right' }} id="availableToRefundAmount">
                      {CurrencyHelper.formatCurrency(
                        event.CurrencySymbol,
                        amountAvailableToRefund + (purchase.HandlingFeeRefundId ? 0 : handlingFee),
                      )}
                    </td>
                  </tr>
                </table>
              </Block>

              {purchase.AbsorbFees && (
                <BlockInfo>
                  You absorbed a Seaty service fee of {CurrencyHelper.formatCurrency(event.CurrencySymbol, seatyFees)}{' '}
                  in this order. This fee is non-refundable.
                </BlockInfo>
              )}
            </>
          )}
        </tbody>
      </table>

      {cancellableTickets.length > 0 && (
        <>
          <div className="spacer" />

          <SpacerTable>
            <h1>Tickets to cancel</h1>
          </SpacerTable>

          <div className="spacer" />

          <SpacerTable>
            <Button
              onClick={() => {
                const value = selectedTickets.length != cancellableTickets.length;

                cancellableTickets.forEach((ticket) => {
                  if (value && !selectedTickets.includes(ticket)) {
                    selectedTickets.push(ticket);
                  } else if (!value && selectedTickets.includes(ticket)) {
                    const index = selectedTickets.indexOf(ticket);
                    if (index > -1) {
                      selectedTickets.splice(index, 1);
                    }
                  }

                  setSelectedTickets([...selectedTickets]);
                });
              }}
              text={
                selectedTickets.length != cancellableTickets.length ? `Select all tickets` : 'De-select all tickets'
              }
            />
          </SpacerTable>

          <div className="spacer" />

          <table className="blocks tickets">
            <tbody>
              {cancellableTickets.map((ticket) => {
                var editOrderAdminNotesInput = null;

                const showCheckbox = !ticket.Cancelled;

                const content = (
                  <>
                    <BlockHeader>
                      <td style={{ color: ticket.SeatCategoryColour }}>
                        <span className="ticket-font">{ticket.Group + ticket.Name}</span> {ticket.SeatCategoryName}
                      </td>
                      <td className="right">
                        <span style={{ color: ticket.CategoryColour }}>
                          {ticket.CategoryName} {ticket.PriceAsString}
                        </span>
                      </td>
                    </BlockHeader>

                    {!showCheckbox &&
                      ticket.QuestionAnswers &&
                      ticket.QuestionAnswers.map((qa) => (
                        <div className="question" style={{ marginTop: '6px' }}>
                          <BlockHeader>{qa.QuestionName}</BlockHeader>
                          {qa.Text}
                        </div>
                      ))}

                    {ticket.AdminNotes && ticket.AdminNotes.length > 0 && (
                      <span className="userNotes" style={{ display: 'block', maxWidth: '100%' }}>
                        {ticket.AdminNotes}
                      </span>
                    )}
                    {ticket.Cancelled && (
                      <div style={{ display: 'block' }}>Ticket cancelled on {ticket.CancelledDate}</div>
                    )}

                    {editOrderAdminNotesInput}
                  </>
                );

                if (showCheckbox) {
                  return (
                    <BlockCheckBox
                      id={`ticketCheckbox${ticket.Group}_${ticket.Name}`}
                      key={ticket.Group + ticket.Name}
                      checked={selectedTickets.includes(ticket)}
                      onBoxClick={() => {
                        if (selectedTickets.includes(ticket)) {
                          const index = selectedTickets.indexOf(ticket);
                          if (index > -1) {
                            selectedTickets.splice(index, 1);
                          }
                        } else {
                          selectedTickets.push(ticket);
                        }

                        setSelectedTickets([...selectedTickets]);
                      }}
                    >
                      {content}
                    </BlockCheckBox>
                  );
                } else {
                  return <Block key={ticket.Group + ticket.Name}>{content}</Block>;
                }
              })}
            </tbody>
          </table>
        </>
      )}

      {purchase && (
        <>
          <div className="spacer" />
          <SpacerTable>
            <h1>Refund</h1>
          </SpacerTable>
          <div className="spacer" />
          <table className="blocks tickets">
            <tbody>
              {enterCustomAmount ? (
                <>
                  <Block>
                    <BlockHeader>Amount to refund</BlockHeader>
                    <BlockTextBox
                      id="amountToRefund"
                      name={'customPaymentAmount'}
                      type="number"
                      style={{ minWdith: '150px', width: 'calc(50% - 5px)' }}
                      placeholder={'Enter an amount'}
                      value={customPaymentAmount}
                      autoFocus={true}
                      symbol={event.CurrencySymbol}
                      onChange={(e) => {
                        setCustomPaymentAmount(e);
                      }}
                    />

                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', paddingRight: '5px' }}>
                            <Button
                              onClick={() => {
                                setEnterCustomAmount(false);
                                setUseCustomAmount(true);
                              }}
                              disabled={
                                customPaymentAmount.length === 0 ||
                                !NumberHelper.isNumeric(customPaymentAmount) ||
                                parseFloat(customPaymentAmount) < 0 ||
                                parseFloat(customPaymentAmount) * 100.0 > amountAvailableToRefund
                              }
                              className=""
                              style={{ marginTop: '10px' }}
                              text={'Confirm'}
                            ></Button>
                          </td>
                          <td style={{ width: '50%', paddingLeft: '5px' }}>
                            <button
                              onClick={() => {
                                setUseCustomAmount(false);
                                setEnterCustomAmount(false);
                              }}
                              className="button bad"
                              style={{ marginTop: '10px' }}
                            >
                              {useCustomAmount ? 'Clear' : 'Cancel'}{' '}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Block>

                  {!NumberHelper.isNumeric(customPaymentAmount) ? (
                    <BlockInfo type={InfoType.Warning}>Value must be a number.</BlockInfo>
                  ) : parseFloat(customPaymentAmount) * 100.0 > amountAvailableToRefund ? (
                    <BlockInfo type={InfoType.Warning}>
                      Value must be less than or equal to{' '}
                      {CurrencyHelper.formatCurrency(event.CurrencySymbol, amountAvailableToRefund)}.
                    </BlockInfo>
                  ) : parseFloat(customPaymentAmount) < 0 ? (
                    <BlockInfo type={InfoType.Warning}>Value must be positive.</BlockInfo>
                  ) : null}
                </>
              ) : (
                <Block>
                  <BlockHeader>Amount to refund</BlockHeader>
                  <div id="amountToRefund">
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, amountBeingRefunded)}
                  </div>

                  <button
                    onClick={() => {
                      setEnterCustomAmount(true);
                      if (!useCustomAmount) {
                        setCustomPaymentAmount((amountBeingRefunded / 100.0).toFixed(2));
                      }
                    }}
                    className="button"
                    style={{ marginTop: '10px' }}
                  >
                    {useCustomAmount ? 'Edit custom amount' : 'Enter a custom amount'}
                  </button>
                </Block>
              )}

              {purchase.PurchaseCosts &&
                purchase.PurchaseCosts.HandlingFee != null &&
                purchase.PurchaseCosts.HandlingFee > 0 &&
                (purchase.HandlingFeeRefundId ? (
                  <>
                    <Block>
                      Handling fee of{' '}
                      {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.HandlingFee)} has
                      already been refunded.
                    </Block>
                  </>
                ) : (
                  <BlockCheckBox
                    onBoxClick={() => setRefundHandlingFee(!refundHandlingFee)}
                    checked={refundHandlingFee}
                  >
                    Refund handling fee of{' '}
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.HandlingFee)}
                  </BlockCheckBox>
                ))}

              {/* {purchase.PurchaseCosts && !purchase.AbsorbFees && purchase.PurchaseCosts.TotalFees > 0 && (
                <>
                  <Block>
                    <BlockHeader>Card fee paid by attendee</BlockHeader>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.TotalFees)}
                  </Block>
                  <BlockInfo type={InfoType.Info}>
                    Seaty card processing fees are only returned to the attendee when all tickets in an order are
                    cancelled.{' '}
                  </BlockInfo>
                </>
              )} */}
              {/* 
              {purchase.PurchaseCosts && purchase.AbsorbFees && purchase.PurchaseCosts.TotalFees > 0 && (
                <>
                  <Block>
                    <BlockHeader>Card fee paid by your organisation</BlockHeader>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.TotalFees)}
                  </Block>
                  <BlockInfo type={InfoType.Info}>
                    Seaty card processing fees are only returned to your organisation when all tickets in an order are
                    cancelled.{' '}
                  </BlockInfo>
                </>
              )} */}

              {/* {!enterCustomAmount && (
                <Block>
                  <BlockHeader>Total refund to attendee</BlockHeader>
                  {CurrencyHelper.formatCurrency(
                    event.CurrencySymbol,
                    linq.from(selectedTickets).sum((s: any) => s.PriceAsInt) +
                      (purchase.AbsorbFees ? 0 : allTicketsSelected ? purchase.PurchaseCosts.TotalFees : 0) +
                      (refundHandlingFee ? purchase.PurchaseCosts.HandlingFee : 0),
                  )}
                </Block>
              )} */}
            </tbody>
          </table>
        </>
      )}

      <div className="spacer" />
      <SpacerTable>
        <h1>Cancellation</h1>
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks">
        <tbody>
          {!isPurchase && selectedTicketsTotal > 0 && (
            <>
              <BlockCheckBox checked={addRefund} onBoxClick={() => setAddRefund(!addRefund)}>
                {'Add a balance refund of ' + event.CurrencySymbol + selectedTicketsTotal.toFixed(2)}
              </BlockCheckBox>
              {addRefund && (
                <BlockInfo>
                  Heads up! 💡 Adding this refund to the attendee's Seaty balance won't actually send them any money
                  (this wasn't an online card purchase). Use this option when you've already refunded them outside Seaty
                  and just want to keep their balance accurate. ✅
                </BlockInfo>
              )}
            </>
          )}

          <Block>
            <BlockHeader>Why are these tickets being cancelled?</BlockHeader>
            <BlockTextBox
              name={'cancellingReason'}
              placeholder={'Enter a reason'}
              value={cancellingReason}
              onChange={(e) => {
                setCancellingReason(e);
              }}
              rows={3}
            />
          </Block>

          {cancellingReason.length <= 4 && (
            <BlockInfo type={InfoType.Warning}>
              You must enter a reason why this order is being cancelled in order to continue.
            </BlockInfo>
          )}
        </tbody>
      </table>

      <div className="spacer" />
      <table className="blocks">
        <tbody>
          <BlockInfo>
            You are about to cancel{' '}
            <strong>{`${selectedTickets.length}
            ${StringHelper.AddSWhenMany(selectedTickets.length, 'ticket')}`}</strong>{' '}
            in order #{props.order.Id}
            {purchase &&
              (amountBeingRefunded + (refundHandlingFee ? handlingFee : 0) > 0 ? (
                <>
                  {' '}
                  and send a refund from the #{event.Organisation.OrganisationTag} account of{' '}
                  <strong>
                    {CurrencyHelper.formatCurrency(
                      event.CurrencySymbol,
                      amountBeingRefunded + (refundHandlingFee ? handlingFee : 0),
                    )}
                  </strong>{' '}
                  to the card used to pay
                </>
              ) : (
                <> and not issue a refund</>
              ))}
            .
          </BlockInfo>
          {errorMessage && <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button
          className="bad"
          disabled={
            cancellingReason.length <= 4 ||
            enterCustomAmount ||
            (amountBeingRefunded + (refundHandlingFee ? handlingFee : 0) == 0 && selectedTickets.length == 0)
          }
          onClick={() => cancelSelectedTickets(order)}
          text={`Confirm and submit`}
        />
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Button onClick={onBackToOrderClick} text={'Back to order'} />
      </SpacerTable>
    </>
  );
};

export default CancelSection;

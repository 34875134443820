import linq from 'linq';
import React from 'react';
import { Link } from 'react-router-dom';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import StringHelper from '../../../helpers/StringHelper';
import UserHelper from '../../../helpers/UserHelper';
import { IEvent } from '../../../interfaces/IEvent';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';
import BlockInfo from '../../../components/BlockInfo';

export interface IProps {
  event: IEvent;
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
  loadUserOrderOverview: () => void;
}

const DashboardSection: React.FC<IProps> = (props) => {
  const { event, overview, setSection, loadUserOrderOverview } = props;
  const { Orders: orders, Referrals: referrals, Requests: requests } = overview;

  const payments = overview.Payments.filter((p) => !p.IsRefund);
  const refunds = overview.Payments.filter((p) => p.IsRefund);

  const showMembers = event.MembershipsEnabled && overview.MemberType;
  const showPayments = payments.length > 0;
  const showRefunds = refunds.length > 0;

  return (
    <>
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Attendee</BlockHeader>
            {overview.User.Name} (<a href={`mailto:${UserHelper.currentUser.Email}`}>{UserHelper.currentUser.Email}</a>)
          </Block>
          <Block className="route" onClick={() => setSection(Section.Orders)}>
            <BlockHeader>{StringHelper.AddSWhenMany(orders.length, 'Order')}</BlockHeader>
            {overview.TotalTickets + ' ' + StringHelper.AddSWhenMany(overview.TotalTickets, 'ticket')}
            {orders.length == 1 ? null : ' in ' + orders.length + ' orders'} for {overview.TotalOrdersPriceAsString}
          </Block>

          {referrals.length > 0 && (
            <Block className="route" onClick={() => setSection(Section.Referrals)}>
              <BlockHeader>Referrals</BlockHeader>
              {referrals.length == 0 ? (
                <span>None made</span>
              ) : (
                <span>{referrals.length + ' totalling ' + overview.TotalReferralsPriceAsString}</span>
              )}
            </Block>
          )}

          {requests.length > 0 && (
            <Block className="route" onClick={() => setSection(Section.Requests)}>
              <BlockHeader>Requests</BlockHeader>
              {requests.length == 0 ? (
                <span>None made</span>
              ) : (
                <span>{requests.length + ' requests awaiting approval'}</span>
              )}
            </Block>
          )}

          {overview.TotalOwedAsInt === 0 ? (
            <Block>
              <BlockHeader>Balance</BlockHeader>
              {overview.Payments.length > 0 ? 'Paid in full' : overview.TotalOwedAsString}
            </Block>
          ) : overview.TotalOwedAsInt < 0 ? (
            <Block>
              <BlockHeader>Balance overpaid</BlockHeader>
              {overview.TotalOwedAsString.replace('-', '')}
            </Block>
          ) : (
            <>
              <Block>
                <BlockHeader>Balance due</BlockHeader>

                <div>
                  <strong style={{ color: 'red' }}>({overview.TotalOwedAsString})</strong>
                </div>
              </Block>
              <Block className={'route'} onClick={() => setSection(Section.Balance)}>
                <BlockHeader>Pay balance online</BlockHeader>
              </Block>
              {!event.AbsorbFee && (
                <BlockInfo>Please note that paying your balance online will involve additional fees.</BlockInfo>
              )}
            </>
          )}

          {(showRefunds || showPayments || showMembers) && (
            <>
              {showPayments && (
                <Block className="route" onClick={() => setSection(Section.Payments)}>
                  <BlockHeader>Payments</BlockHeader>
                  {payments.length +
                    ' totalling ' +
                    event.CurrencySymbol +
                    (linq.from(payments).sum((p) => p.AmountPaidAsInt) / 100.0).toFixed(2)}
                </Block>
              )}

              {showRefunds && (
                <Block className="route" onClick={() => setSection(Section.Refunds)}>
                  <BlockHeader>Refunds</BlockHeader>
                  {refunds.length +
                    ' totalling ' +
                    event.CurrencySymbol +
                    (linq.from(refunds).sum((r) => r.AmountPaidAsInt) / 100.0).toFixed(2)}
                </Block>
              )}

              {showMembers && (
                <Block>
                  <BlockHeader>Membership</BlockHeader>
                  {!overview.IsMember ? (
                    `Not a member of ${overview.Event.Organisation.Name}`
                  ) : (
                    <div>
                      {overview.MemberType.Name}{' '}
                      <span style={{ opacity: '0.5' }}>{overview.MemberType.Quota} Quota</span>
                    </div>
                  )}
                </Block>
              )}
            </>
          )}
        </tbody>
      </table>

      <div className="spacer-x2" />
      <SpacerTable>
        <a
          className="button confirm"
          href={'/Order/AllTickets?email=' + UserHelper.currentUser.Email + '&event=' + event.EventTag}
        >
          View all tickets
        </a>
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Link to={`/${event.EventTag}`} className="button">
          Go to event
        </Link>
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Button onClick={() => loadUserOrderOverview()} text={'Refresh'} />
      </SpacerTable>
    </>
  );
};

export default DashboardSection;

import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import loaderState, { Loader, LoaderHelper } from '../atoms/loaderState';
import EnvironmentVariables from '../EnvironmentVariables';
import UserHelper from '../helpers/UserHelper';
import { IOrganisation } from '../interfaces/IOrganisation';
import SVGEditEvent from '../svg/SVGEditEvent';
import SVGExcel from '../svg/SVGExcel';
import SVGOrders from '../svg/SVGOrders';
import SVGSummary from '../svg/SVGSummary';
import { AdminSection } from '../views/Admin/AdminSections';
import SVGOrganisation from '../svg/SVGOrganisation';
import SVGTour from '../svg/SVGTour';
import SVGMemberType from '../svg/SVGMemberType';
import SVGCompare from '../svg/SVGCompare';
import SVGAttendees from '../svg/SVGAttendees';
import SideMenu from './SideMenu';
import SVGSendMail from '../svg/SVGSendMail';

export interface IAdminMenuOrganisationProps {
  organisation: IOrganisation;
  onCloseClick: () => void;
}

const AdminMenuOrganisation: FunctionComponent<IAdminMenuOrganisationProps> = ({ organisation, onCloseClick }) => {
  const [loaders, setLoaders] = useRecoilState(loaderState);

  if (!organisation || !organisation.Id) {
    return null;
  }

  return (
    <>
      <div className="admin-menu">
        <h1>
          Organisation <strong>#{organisation.OrganisationTag.toUpperCase()}</strong>
        </h1>
        <div className="buttons">
          <div className="admin-menu-background"></div>

          <Link
            onClick={onCloseClick}
            className="organisation-tag"
            to={'/Organisation/' + organisation.OrganisationTag}
          >
            <SVGOrganisation />
            <label>Landing</label>
          </Link>
          <Link
            onClick={onCloseClick}
            className="edit"
            to={'/Organisation/' + organisation.OrganisationTag + '/Summary'}
          >
            <SVGSummary />
            <label>Summary</label>
          </Link>
          <Link
            onClick={onCloseClick}
            className="edit"
            to={'/Organisation/' + organisation.OrganisationTag + '/Events'}
          >
            <SVGEditEvent />
            <label>Events</label>
          </Link>
          <Link
            onClick={onCloseClick}
            className="edit"
            to={'/Organisation/' + organisation.OrganisationTag + '/Attendees'}
          >
            <SVGAttendees />
            <label>Attendees</label>
          </Link>
          <Link
            onClick={onCloseClick}
            className="compare"
            to={'/Organisation/' + organisation.OrganisationTag + '/Compare'}
          >
            <SVGCompare />
            <label>Compare Events</label>
          </Link>
          <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + organisation.OrganisationTag + '/Tours'}>
            <SVGTour />
            <label>Tours</label>
          </Link>
          <Link
            onClick={onCloseClick}
            className="orders"
            to={'/Organisation/' + organisation.OrganisationTag + '/' + AdminSection.Statements}
          >
            <SVGOrders />
            <label>{AdminSection.Statements}</label>
          </Link>
          {organisation.MembershipsEnabled && (
            <Link
              onClick={onCloseClick}
              className="orders"
              to={'/Organisation/' + organisation.OrganisationTag + '/' + AdminSection.Members}
            >
              <SVGMemberType />
              <label>{AdminSection.Members}</label>
            </Link>
          )}
          <Link onClick={onCloseClick} className="blank" to={'/Organisation/' + organisation.OrganisationTag + '/Mail'}>
            <SVGSendMail />
            <label>Mail</label>
          </Link>

          <Link onClick={onCloseClick} className="edit" to={'/Organisation/' + organisation.OrganisationTag + '/Edit'}>
            <SVGEditEvent />
            <label>Edit</label>
          </Link>

          <button
            className="excel"
            onClick={() => {
              LoaderHelper.add(setLoaders, loaders, Loader.ExcelExport, 'Exporting data...');

              var xhr = new XMLHttpRequest();
              xhr.open(
                'POST',
                `${EnvironmentVariables.ADMIN_API_URL}/api/OrganisationSpreadsheet?organisationId=${organisation.Id}`,
                true,
              );
              xhr.responseType = 'arraybuffer';
              xhr.onload = function () {
                if (this.status === 200) {
                  var filename = '';
                  var disposition = xhr.getResponseHeader('Content-Disposition');
                  if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                  }
                  var type = xhr.getResponseHeader('Content-Type');

                  var blob;
                  if (typeof File === 'function') {
                    try {
                      blob = new File([this.response], filename, { type: type });
                    } catch (e) {
                      /* Edge */
                    }
                  }
                  if (typeof blob === 'undefined') {
                    blob = new Blob([this.response], { type: type });
                  }

                  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    (window.navigator as any).msSaveBlob(blob, filename);
                  } else {
                    var URL = window.URL || (window as any).webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                      // use HTML5 a[download] attribute to specify filename
                      var a = document.createElement('a');
                      // safari doesn't support this yet
                      if (typeof a.download === 'undefined') {
                        (window as any).location = downloadUrl;
                      } else {
                        a.href = downloadUrl;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                      }
                    } else {
                      (window as any).location = downloadUrl;
                    }

                    setTimeout(function () {
                      URL.revokeObjectURL(downloadUrl);
                    }, 100); // cleanup
                  }
                }
                LoaderHelper.remove(setLoaders, loaders, Loader.ExcelExport);
              };
              xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
              xhr.setRequestHeader('Authorization', `Bearer ${UserHelper.currentUser.Token}`);
              xhr.send();
            }}
          >
            <SVGExcel />
            <label>Export data</label>
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminMenuOrganisation;

import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../api/AdminApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import Button from '../components/Button';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import { ISeatingPlanSeat } from '../interfaces/ISeatingPlanSeat';
import { ITicketAvailability } from '../services/TicketService';
import linq from 'linq';
import NumberHelper from '../helpers/NumberHelper';
import BlockCheckBox from '../components/BlockCheckBox';
import DateHelper from '../helpers/DateHelper';

export interface IProps {
  event?: IEvent;
  eventDate?: IEventDate;
  onClose?: () => void;
  seats: Array<ISeatingPlanSeat>;
}

const AdminLockSeatsModal = ((props) => {
  const { event, eventDate, seats } = props;

  useEffect(() => {
    GAHelper.modal(`Lock Seats`);
  }, []);

  const [error, setError] = useState(null);
  const [locking, setLocking] = useState(false);
  const [lockComplete, setLockComplete] = useState<string>(null);
  const [results, setResults] = useState<ITicketAvailability[]>([]);
  const [eventDateIds, setEventDateIds] = useState(eventDate ? [eventDate.Id] : []);

  const lockSeats = () => {
    setError(null);
    setLocking(true);
    setResults([]);

    AdminApi.request('POST', '/api/LockByAdmin', {
      eventId: event.Id,
      eventDateIds: eventDateIds.length == 0 ? event.Dates.map((ed) => ed.Id) : eventDateIds,
      seats: seats,
    })
      .then((availabilities: ITicketAvailability[]) => {
        setLocking(false);
        setResults(availabilities);

        setLockComplete('Ticket lock has been completed.');
      })
      .catch((message) => {
        setError(message);
        setLocking(false);
      });
  };

  const getSeatComponent = (group, from, to) => {
    const value = `${group}${from == to ? from : `${from}-${to}`}`;
    return (
      <span style={{ marginLeft: '10px' }} key={value}>
        {group}
        {from == to ? from : `${from}-${to}`}
      </span>
    );
  };

  const components = [];

  const groupedBySeatCategory = linq
    .from(props.seats)
    .groupBy((s) => s.SeatCategoryId)
    .toArray();

  groupedBySeatCategory.forEach((sc) => {
    const groupedByGroup = sc.groupBy((s) => s.Group).toArray();
    const groupComponents = [];
    const seatCategoryName = sc.first().SeatCategory.Name;
    const seatCategoryColour = sc.first().SeatCategory.Colour;

    groupedByGroup.forEach((scg) => {
      const orderedSeats = scg
        .orderBy((seat: ISeatingPlanSeat) => seat.Group)
        .thenBy((seat: ISeatingPlanSeat) => (NumberHelper.isNumeric(seat.Name) ? parseInt(seat.Name) : seat.Name))
        .toArray();

      let lastSeatNumber = null;

      orderedSeats.forEach((s, index) => {
        if (lastSeatNumber == null) {
          lastSeatNumber = s.Name;
        }

        // If last seat
        if (index == orderedSeats.length - 1) {
          groupComponents.push(getSeatComponent(s.Group, lastSeatNumber, s.Name));
          lastSeatNumber = null;
        } else {
          const nextSeat = orderedSeats[index + 1];

          if (NumberHelper.isNumeric(s.Name) && NumberHelper.isNumeric(nextSeat.Name)) {
            const thisSeatNumber = parseInt(s.Name);
            const nextSeatNumber = parseInt(nextSeat.Name);

            if (nextSeatNumber - thisSeatNumber > 1) {
              groupComponents.push(getSeatComponent(s.Group, lastSeatNumber, s.Name));
              lastSeatNumber = null;
            }
          } else {
            groupComponents.push(getSeatComponent(s.Group, s.Name, s.Name));
            lastSeatNumber = null;
          }
        }
      });
    });

    components.push(
      <Block key={seatCategoryName}>
        <BlockHeader>
          <td style={{ color: seatCategoryColour }}>{seatCategoryName}</td>
          <td
            className="right"
            style={{
              color: seatCategoryColour,
            }}
          >
            {groupComponents}
          </td>
        </BlockHeader>
      </Block>,
    );
  });

  const seatComponents = linq
    .from(props.seats)
    .orderBy((seat: ISeatingPlanSeat) => (seat.SeatCategory ? seat.SeatCategory.Name : 'Z'))
    .thenBy((seat: ISeatingPlanSeat) => seat.Group)
    .thenBy((seat: ISeatingPlanSeat) => (NumberHelper.isNumeric(seat.Name) ? parseInt(seat.Name) : seat.Name))
    .toArray()
    .map((seat: ISeatingPlanSeat) => {
      return (
        <Block key={'ticket_' + seat.Group + '_' + seat.Name + '_' + seat.SeatCategory.Id}>
          <BlockHeader>
            <td className="ticket-font" style={{ color: seat.SeatCategory.Colour }}>
              {seat.Group}
              {seat.Name}
            </td>
            <td
              className="right"
              style={{
                color: seat.SeatCategory ? seat.SeatCategory.Colour : null,
              }}
            >
              {seat.SeatCategory && seat.SeatCategory.Name}
            </td>
          </BlockHeader>
        </Block>
      );
    });

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>

            {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
              <div>
                {event.Venue.Name}
                {event.Venue.Postcode && event.Venue.Postcode.length > 0 ? ', ' + event.Venue.Postcode : ''}
              </div>
            )}
          </SpacerTable>

          <div className="spacer" />

          <SpacerTable>
            <h1>Lock Tickets</h1>
          </SpacerTable>
          <div className="spacer" />

          <table className="blocks">
            <tbody>
              {lockComplete ? (
                <>
                  {results.map((availability, index) => (
                    <BlockInfo key={index} type={availability.AllAvailable ? InfoType.Success : InfoType.Warning}>
                      <div>
                        <strong>{moment(availability.DateAsString).format('ddd Do MMM YYYY HH:mm a')}</strong>
                      </div>
                      {availability.AllAvailable
                        ? 'All requested tickets locked.'
                        : `No tickets locked. ${availability.ErrorMessage}`}
                    </BlockInfo>
                  ))}
                </>
              ) : (
                <>
                  <BlockInfo type={InfoType.Info}>
                    Ticket locks are a way for an admin to suspend the sale of individual seats. You can lock seats for
                    a specific event date or lock them over every date in the event. If any tickets in an event date you
                    are locking have already been ordered, the lock will fail for all tickets for that event date.
                  </BlockInfo>

                  <Block>
                    <BlockHeader>These seats will be locked</BlockHeader>
                  </Block>

                  {components}

                  {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                </>
              )}
            </tbody>
          </table>

          <div className="spacer-x2" />

          {!lockComplete && (
            <>
              <SpacerTable>
                <h1>Lock on Event Dates</h1>
                Choose the event dates that you wish to lock the selected tickets for.
              </SpacerTable>
              <div className="spacer" />
              <table className="blocks">
                <tbody>
                  <BlockCheckBox
                    onBoxClick={() => {
                      if (eventDateIds.length > 0) {
                        setEventDateIds([]);
                      } else {
                        setEventDateIds(event.Dates.map((d) => d.Id));
                      }
                    }}
                    checked={eventDateIds.length == 0}
                  >
                    All event dates
                  </BlockCheckBox>

                  {event.Dates.map((d) => (
                    <BlockCheckBox
                      key={d.Id}
                      onBoxClick={() => {
                        if (eventDateIds.includes(d.Id)) {
                          setEventDateIds(eventDateIds.filter((id) => id !== d.Id));
                        } else {
                          setEventDateIds([...eventDateIds, d.Id]);
                        }
                      }}
                      checked={eventDateIds.includes(d.Id)}
                    >
                      {DateHelper.asDateAtTimeAmPm(d.DateAsString)}
                    </BlockCheckBox>
                  ))}
                </tbody>
              </table>

              <div className="spacer-x2" />
            </>
          )}

          {lockComplete ? (
            <SpacerTable>
              <Button className="large" onClick={props.onClose} text={`Close`} />
            </SpacerTable>
          ) : (
            <SpacerTable>
              <Button
                disabled={locking}
                className="confirm large"
                onClick={() => lockSeats()}
                text={
                  eventDateIds.length == 0 ? 'Lock for all event dates' : `Lock for ${eventDateIds.length} event dates`
                }
              />
            </SpacerTable>
          )}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export { AdminLockSeatsModal };

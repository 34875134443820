import moment from 'moment';
import { FunctionComponent, useState } from 'react';
import NumberHelper from '../../helpers/NumberHelper';
import { IEventDate } from '../../interfaces/IEventDate';

import { ITicketCategory } from '../../interfaces/ITicketCategory';
import SVGMinus from '../../svg/SVGMinus';
import SVGPlus from '../../svg/SVGPlus';
import GeneralAdmissionDropdown from './GeneralAdmissionDropdown';
import SVGTrash from '../../svg/SVGTrash';
interface IProps {
  soldOut: boolean;
  ticket: ITicketCategory;
  quantitySelectedChanged: (amount: number) => void;
  value: number;
  eventDate: IEventDate;
  maxQuantity: number;
}

const MerchandiseProduct: FunctionComponent<IProps> = (props) => {
  var ticket = props.ticket;

  var dropdownItems = [];
  for (var i = 0; i <= props.maxQuantity; i++) {
    dropdownItems.push({
      value: i,
      description: i,
    });
  }

  return (
    <div className="general-ticket">
      <div className="general-ticket-quantity">
        {props.soldOut ? (
          <div className="quantity">
            <span style={{ display: 'block' }}>Sold out</span>
          </div>
        ) : (
          <>
            {props.value > 0 ? (
              <GeneralAdmissionDropdown
                selectedItemChanged={(dropdown) => props.quantitySelectedChanged(dropdown.value)}
                selectedValue={props.value}
                items={dropdownItems}
              />
            ) : (
              <div className="toolbar" style={{ margin: 0, background: 'white', border: 0, padding: 0 }}>
                <div className="buttons justify-left " style={{ padding: 0, margin: 0 }}>
                  <button
                    style={{ margin: 0 }}
                    disabled={props.value == props.maxQuantity}
                    className=""
                    onClick={() => props.quantitySelectedChanged(props.value + 1)}
                  >
                    <SVGPlus /> Add
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="general-ticket-details">
        <div className="name">{ticket.Name}</div>

        <div className="price">
          <span className="class" style={{ background: ticket.Colour }}></span>
          <span>{ticket.PriceAsString}</span>
        </div>

        {ticket.Description && ticket.Description.length > 0 && <div className="description">{ticket.Description}</div>}
        {ticket.Hide && <div className="description">* Only event administrators can book this ticket</div>}
      </div>
    </div>
  );
};

export default MerchandiseProduct;

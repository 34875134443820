import { FunctionComponent, useEffect, useState } from 'react';

import { IEvent } from '../../../../interfaces/IEvent';
import { IAdminEmail } from '../../../../interfaces/IAdminMail';
import AdminMailHistoryRow from './AdminMailHistoryRow';
import Loader from '../../../../components/Loader';
import SVGSendMail from '../../../../svg/SVGSendMail';
import SVGMultiply from '../../../../svg/SVGMultiply';
import AdminApi from '../../../../api/AdminApi';
import AdminMailComposer from './AdminMailComposer';
import linq from 'linq';
import moment from 'moment';
import { MailSendToDescriptions } from './MailSendTo';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import AdminMailViewer from './AdminMailViewer';

export interface IProps {
  event?: IEvent;
  organisation?: IOrganisation;
}

const AdminMail: FunctionComponent<IProps> = (props) => {
  const { event } = props;
  const organisation = props.event ? props.event.Organisation : props.organisation;

  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [adminMails, setAdminMails] = useState([]);
  const [compose, setCompose] = useState(false);
  const [editingMail, setEditingMail] = useState<IAdminEmail>();
  const [viewingMail, setViewingMail] = useState<IAdminEmail>();

  const getEmails = async () => {
    setLoading(true);
    AdminApi.request('POST', '/api/Mailshots', {
      eventId: event?.Id,
      organisationId: organisation.Id,
    })
      .then((result) => {
        setLoading(false);
        setAdminMails(result);
      })
      .catch((message) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmails();
  }, []);

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  const rows = [];

  linq
    .from(adminMails)
    .orderBy((adminEmail: IAdminEmail) => !adminEmail.IsDraft)
    .thenByDescending((adminEmail: IAdminEmail) => moment(adminEmail.DateSent).unix())
    .thenBy((adminEmail: IAdminEmail) => adminEmail.Subject)
    .toArray()
    .forEach((adminEmail: IAdminEmail, index) => {
      var include = true;
      if (filter != null && filter.length > 0) {
        include = false;

        if (
          adminEmail.Subject.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
          adminEmail.SentByEmail.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
          MailSendToDescriptions[adminEmail.SendTo].toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
          moment(adminEmail.DateSent).format('dddd Do MMMM YYYY').toLowerCase().indexOf(filter.toLowerCase()) !== -1
        ) {
          include = true;
        }
      }

      if (include) {
        var rowId = 'row_' + adminEmail.DateSent;
        rowId += '_' + index;

        rows.push(
          <AdminMailHistoryRow
            onViewClick={() => setViewingMail(adminEmail)}
            onEditClick={() => setEditingMail(adminEmail)}
            key={rowId}
            adminEmail={adminEmail}
          />,
        );
      }
    });

  if (loading) return <Loader inline>Loading...</Loader>;

  if (viewingMail) {
    return <AdminMailViewer mailId={viewingMail.Id} onBack={() => setViewingMail(null)} />;
  }

  if (compose || editingMail)
    return (
      <AdminMailComposer
        backMessage="Back to mail history"
        onBack={() => {
          setCompose(false);
          setEditingMail(null);
          getEmails();
        }}
        event={props.event}
        organisation={props.organisation}
        editingMail={editingMail}
      />
    );

  return (
    <>
      <div className="orderoverview" style={{ paddingBottom: '100px' }}>
        <div className="row">
          <div className="col-sm-8">
            <div className="admin-buttons" style={{ margin: 0, padding: 0 }}>
              <button
                className="admin-button icon-inline"
                onClick={() => {
                  setCompose(true);
                }}
              >
                <SVGSendMail />
                Compose new email
              </button>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="input donotprint">
              <input
                id="searchinput"
                onChange={handleSearchChanged}
                type="text"
                placeholder="Search..."
                value={query}
                onKeyPress={onFilterKeyPress.bind(this)}
                style={{ margin: '0' }}
              />

              <button onClick={clearFilterClicked}>
                <SVGMultiply />
              </button>
            </div>
          </div>
        </div>

        {rows.length == 0 ? (
          <div className="info">No emails found.</div>
        ) : (
          <div className="container-outer" style={{ minHeight: '300px' }}>
            <div className="container-inner" style={{ backgroundColor: 'white' }}>
              <table className="table grid-table">
                <thead>
                  <tr>
                    <th className="grid-header"></th>
                    <th className="grid-header">By</th>
                    <th className="grid-header">To</th>
                    <th style={{ width: '40%' }} className="grid-header">
                      Subject
                    </th>
                    <th className="grid-header">Date Sent</th>
                  </tr>
                </thead>

                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminMail;

import React, { useCallback } from 'react';

import Loader from './Loader';
import StringHelper from '../helpers/StringHelper';
import SpacerTable from './SpacerTable';

export interface IProps {
  onClick?(): void;
  text: string;
  description?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  icon?: any;
  spacer?: boolean;
}

const Button: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  loading,
  onClick,
  style,
  className,
  text,
  children,
  description,
  icon,
  spacer: spacerTable,
}) => {
  const execute = useCallback(() => {
    if (disabled || loading) {
      return;
    }
    onClick?.();
  }, [disabled, loading, onClick]);

  const button = (
    <button
      name={StringHelper.toCamelCase(text) + 'Button'}
      style={style}
      tabIndex={0}
      className={`button${icon ? ' button--hasIcon' : ''}${disabled ? ' disabled' : ''}${loading ? ' loading' : ''}${className ? ' ' + className : ''}`}
      onClick={onClick ? execute : null}
    >
      {loading ? (
        <Loader inline />
      ) : (
        <>
          {icon && icon}
          {text && text}
          {description && <div className="button__description">{description}</div>}
          {children && children}
        </>
      )}
    </button>
  );

  if (spacerTable) return <SpacerTable>{button}</SpacerTable>;
  return button;
};

export default Button;

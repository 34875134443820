import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import NumberHelper from '../../../helpers/NumberHelper';
import SeatingPlanHelper from '../../../helpers/SeatingPlanHelper';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';
import UserHelper from '../../../helpers/UserHelper';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

export enum AdmissionTypes {
  PrintedAndMobile = 1,
  PrintedOnly = 2,
  Exchange = 3,
  Custom = 4,
}

const TicketSetupSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [handlingFeeText, setHandlingFeeText] = useState(
    event.HandlingFee ? (event.HandlingFee / 100.0).toFixed(2) : '',
  );
  const [handlingFeePercentageText, setHandlingFeePercentageText] = useState(
    event.HandlingFeePercentage ? event.HandlingFeePercentage.toString() : '',
  );

  var feeInfo =
    'Online payments incur a ' +
    (window as any).feeAmount +
    '% (minimum ' +
    event.CurrencySymbol +
    '0.50) fee. You can either absorb the fee and include it in the price of a ticket or have pass it on and have it added onto the ticket cost for attendees that choose to pay by card.';
  var feeExampleAmount = 10.0;

  let handlingFee = 0;

  if (event.HandlingFee != null && event.HandlingFee > 0) {
    handlingFee += event.HandlingFee != null ? event.HandlingFee / 100.0 : 0;
  }
  if (event.HandlingFeePercentage != null && event.HandlingFeePercentage > 0) {
    handlingFee +=
      event.HandlingFeePercentage != null ? (20.0 + handlingFee) * (event.HandlingFeePercentage / 100.0) : 0;
  }

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>
      <div className="row">
        <div className="col-sm-8">
          <div className="fields">
            <h2>Seated tickets</h2>
            <div className="section">
              <InfoLabel
                text="Use seated tickets?"
                tooltip="Turning on this option will allow you to create a seating plan lower down on this page. It will be posible to create an order from any available seat within your seating plan."
              />

              <Switch
                trueLabel="Yes, use a seating plan"
                falseLabel="No seating plan"
                onChange={(value) => {
                  if (value) {
                    if (!event.SeatingPlans || event.SeatingPlans.length == 0) {
                      event.SeatingPlans = [SeatingPlanHelper.getDefaultSeatingPlan()];
                    }
                  }

                  const newEvent = { ...event, UseSeatingPlan: value };

                  if (!value) newEvent.SeatingPlanSectionsEnabled = false;

                  onEventUpdated(newEvent);
                }}
                checked={event.UseSeatingPlan}
              ></Switch>

              {!event.UseSeatingPlan ? null : (
                <div>
                  <InfoLabel
                    text="Prevent single seats?"
                    tooltip="If you turn on this option, users will be prevented from leaveing a single seat by itself on a row when ordering tickets. Administrators will still be able to book tickets and leave single seats."
                  />

                  <Switch
                    trueLabel="Yes, stop single seats"
                    falseLabel="No, allow single seats"
                    onChange={(value) => onEventUpdated({ ...event, PreventSingleSeatsEnabled: value })}
                    checked={event.PreventSingleSeatsEnabled}
                  ></Switch>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            Enable <strong>Seated Tickets</strong> 🪑 (with seating plan), <strong>General Admission Tickets</strong> 🎟️
            (with quantities, no plan), or both for your event. Enabling either option reveals new features in the
            editor menu!
          </div>
        </div>

        {event.UseSeatingPlan && (
          <>
            <div className="col-sm-8">
              <div className="fields">
                <div className="section">
                  <InfoLabel
                    text="Do you want to enable seating plan sections?"
                    tooltip=" With Seating Plan Sections, you gain the power to create distinct parts within your seating plan, offering a more organised and customised experience for your attendees. Each section
                can have its own set of seats, categories, and prices. However, we strongly recommend keeping this option disabled if you prefer simplicity. Placing all seats in a single plan ensures
                a smoother checkout process for your attendees. 🏟️ If you have a spacious venue with a multitude of seats that can't fit comfortably in a single plan, Seating Plan Sections become your
                best friend! 🚀"
                  />

                  <Switch
                    trueLabel="Yes, enable sections"
                    falseLabel="No, disable sections"
                    onChange={(value) => {
                      event.SeatingPlans.forEach((sp) => {
                        if (!sp.Name || sp.Name.length == 0) {
                          sp.Name = 'Main';
                        }
                      });
                      onEventUpdated({ ...event, SeatingPlanSectionsEnabled: value });
                    }}
                    checked={event.SeatingPlanSectionsEnabled}
                  ></Switch>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="info">
                We recommend keeping the <strong>Seating Plan Sections</strong> option disabled and using a single
                seating plan if you prefer simplicity. Placing all seats in a single plan ensures a smoother checkout
                process for your attendees.
              </div>
            </div>
          </>
        )}

        <div className="col-sm-8">
          <div className="fields">
            <h2>General admission tickets</h2>
            <div className="section">
              <InfoLabel
                text="Use general admission tickets?"
                tooltip="General admission tickets are not associated with any seating plan. They are free standing tickets designed for entry to your event. They can have their own quantity or share a quantity."
              />

              <Switch
                trueLabel="Yes, I want general admission tickets"
                falseLabel="No general admission tickets"
                onChange={(value) => onEventUpdated({ ...event, UseUnallocatedTickets: value })}
                checked={event.UseUnallocatedTickets}
              ></Switch>
            </div>
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Barcodes</h2>
            <div className="section">
              <InfoLabel
                text="Do you want barcodes on tickets?"
                tooltip="When you enable barcodes on your tickets you can use the Seaty phone application to scan tickets and check that they are valid."
              />

              <Switch
                trueLabel="Yes, show barcodes"
                falseLabel="No, disable barcodes"
                onChange={(value) => onEventUpdated({ ...event, ShowBarcodes: value })}
                checked={event.ShowBarcodes}
              ></Switch>
            </div>
            <div className="section">
              <InfoLabel
                text="Do you want barcodes on emails?"
                tooltip="We will place a QR code for each ticket in the order email sent to a user after they place an order."
              />

              <Switch
                trueLabel="Yes, include barcodes on email"
                falseLabel="No, do not include"
                onChange={(value) => onEventUpdated({ ...event, IncludeQRCodeInEmail: value })}
                checked={event.IncludeQRCodeInEmail}
              ></Switch>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            Barcodes make event entry a breeze with our Seaty iPhone app 📱! Each ticket gets a unique code for
            authenticity ✅. Enabling barcodes in emails helps customers easily access scannable tickets 🎫.
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Ticket requests</h2>
            <div className="section">
              <label className="label">Can people request tickets?</label>

              <Switch
                trueLabel="Yes, let users request tickets"
                falseLabel="No ticket requests"
                onChange={(value) => onEventUpdated({ ...event, RequestingEnabled: value })}
                checked={event.RequestingEnabled}
              ></Switch>

              {event.Organisation.MemberOnlyRequests ? null : (
                <div>
                  <div
                    style={{
                      display: event.RequestingEnabled == true ? 'block' : 'none',
                    }}
                  >
                    <InfoLabel
                      text="Are requests password protected?"
                      tooltip="If you add a password for ticket requests, only users with this password, or members of the organisation attached to this event, will be able to ask you for tickets via a request. This helps if you don't want random users asking for tickets. "
                    />

                    <Switch
                      trueLabel="Yes, password protect requests"
                      falseLabel="No anyone can make a request"
                      onChange={(value) => onEventUpdated({ ...event, RequestingPasswordEnabled: value })}
                      checked={event.RequestingPasswordEnabled}
                    ></Switch>
                  </div>
                  <div
                    style={{
                      display:
                        event.RequestingPasswordEnabled == true && event.RequestingEnabled == true ? 'block' : 'none',
                    }}
                  >
                    <InfoInput
                      labelText="Requesting password"
                      maxLength={100}
                      onChange={(value) => onEventUpdated({ ...event, RequestingPassword: value })}
                      value={event.RequestingPassword}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="info">
            When <strong>Ticket Requesting</strong> is enabled, logged in Seaty users can request tickets 🎟️ for your
            event. Admins of the event can view requests from the Seaty iPhone App, or on the website. Remember, money
            collection 💵 for requests is managed outside of Seaty.
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Ticket collection details</h2>
            <div className="section">
              <InfoLabel
                text="Offer collect tickets at box office?"
                tooltip="When you enable this option, we will ask your attendees if they would like to collect their tickets at the box office at the time of ordering. It is then your responsibility as event organisers to ensure those tickets are available for collection on the correct night for your attendees."
              />

              <Switch
                trueLabel="Yes, offer collect at box office"
                falseLabel="No, we do not offer this service"
                onChange={(value) => onEventUpdated({ ...event, CollectAtBoxOffice: value })}
                checked={event.CollectAtBoxOffice}
              ></Switch>
            </div>
          </div>

          <div className="fields">
            <h2>Admission options</h2>
            <div className="section">
              <InfoLabel
                text="Printed, or shown on a mobile device"
                tooltip="Choose this option if you will allow people to bring a physical printed Seaty ticket, but are also happy for people to display their ticket on their phone or mobile device."
              />

              <Switch
                trueLabel="Yes, allow physical and mobile tickets"
                falseLabel="No"
                onChange={(value) => onEventUpdated({ ...event, AdmissionType: AdmissionTypes.PrintedAndMobile })}
                checked={event.AdmissionType === AdmissionTypes.PrintedAndMobile}
              ></Switch>
            </div>

            <div className="section">
              <InfoLabel
                text="Printed tickets only"
                tooltip="Choose this option if you will only accept physical Seaty tickets that have been printed and will not allow people to show their tickets on a mobile device."
              />

              <Switch
                trueLabel="Yes, allow printed tickets only"
                falseLabel="No"
                onChange={(value) => onEventUpdated({ ...event, AdmissionType: AdmissionTypes.PrintedOnly })}
                checked={event.AdmissionType === AdmissionTypes.PrintedOnly}
              ></Switch>
            </div>

            <div className="section">
              <InfoLabel
                text="Exchanged at box office"
                tooltip="Choose this option if Seaty tickets must be exchanged at the venues box office before the event starts"
              />

              <Switch
                trueLabel="Yes, tickets must be exchanged"
                falseLabel="No"
                onChange={(value) => onEventUpdated({ ...event, AdmissionType: AdmissionTypes.Exchange })}
                checked={event.AdmissionType === AdmissionTypes.Exchange}
              ></Switch>
            </div>

            <div className="section">
              <InfoLabel
                text="Custom admission type"
                tooltip="Choose this option if you would like to add your own admission instructions to each ticket"
              />

              <Switch
                trueLabel="Yes, use a custom admission type"
                falseLabel="No"
                onChange={(value) => onEventUpdated({ ...event, AdmissionType: AdmissionTypes.Custom })}
                checked={event.AdmissionType === AdmissionTypes.Custom}
              ></Switch>
            </div>

            {event.AdmissionType === AdmissionTypes.Custom && (
              <div>
                <InfoInput
                  labelText="Custom admission type wording"
                  maxLength={300}
                  onChange={(value) => onEventUpdated({ ...event, CustomAdmissionType: value })}
                  value={event.CustomAdmissionType}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-sm-4"></div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Tickets off-sale timing</h2>
            <div className="section">
              <InfoLabel
                text="How many minutes before or after the event date and time begins should tickets remain on sale?"
                tooltip="This value will effect how long before or after each event date tickets will remain on sale. If you set the value to 0 tickets will go off sale as soon as the event starts. A negative number will mean ticket sales end before the events start and a positive will mean ticket sales carry on once the event has started for the alloted minutes."
              />

              <InfoInput
                style={{ maxWidth: '250px' }}
                labelText="Minutes"
                onChange={(e) => {
                  if (!NumberHelper.isNumeric(e) || e.indexOf('.') > -1) {
                    return;
                  }
                  event.ExtraSaleMinutes = parseInt(e);
                  onEventUpdated({ ...event });
                }}
                value={event.ExtraSaleMinutes.toString()}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-8">
          <div className="fields">
            <h2>Online payment options</h2>
            <div className="section">
              <div>
                <InfoLabel text="How will the fee be charged?" tooltip={feeInfo} />

                {/* <Radio
                  selectedId={event.AbsorbFee ? 1 : 0}
                  onValueChanged={(option) => {
                    onEventUpdated({ ...event, AbsorbFee: option.Id == 1 ? true : false });
                  }}
                  options={[
                    { Text: 'Absorb fee in cost of ticket', Id: 1 },
                    { Text: 'Pass fee to attendee', Id: 0 },
                  ]}
                /> */}

                <Switch
                  id="feeSwitch"
                  trueLabel="Absorb fee in cost of ticket"
                  falseLabel="Pass fee to attendee"
                  onChange={(value) => {
                    if (value) {
                      onEventUpdated({ ...event, AbsorbFee: value });
                    } else {
                      onEventUpdated({ ...event, AbsorbFee: value });
                    }
                  }}
                  checked={event.AbsorbFee}
                />

                {(UserHelper.currentUser.Id == 1 ||
                  event.Organisation.Id == 332 ||
                  event.Organisation.Id == 280 ||
                  event.Organisation.StripeConnectEnabled) && (
                  <>
                    <InfoLabel
                      text="Do you charge a handling fee per order?"
                      tooltip="You can add a fixed price to a whole order as a handling fee charged by you, the organisation. This will only apply to payments that are made through our online card payments, not those made directly by an admin."
                    />
                    <InfoInput
                      style={{ maxWidth: '250px' }}
                      labelText="Handling Fee"
                      onChange={(value: string) => {
                        setHandlingFeeText(value);
                        if (NumberHelper.isNumeric(value)) {
                          var parsedValue = parseFloat(value) * 100.0;
                          onEventUpdated({ ...event, HandlingFee: parseInt(parsedValue.toString()) });
                        } else {
                        }
                      }}
                      symbol={event.CurrencySymbol}
                      value={handlingFeeText}
                    />
                    <InfoInput
                      style={{ maxWidth: '250px' }}
                      labelText="Handling Fee Percentage"
                      onChange={(value: string) => {
                        setHandlingFeePercentageText(value);
                        if (NumberHelper.isNumeric(value)) {
                          onEventUpdated({ ...event, HandlingFeePercentage: parseFloat(value) });
                        } else {
                        }
                      }}
                      symbol={'%'}
                      value={handlingFeePercentageText}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          {handlingFeeText && handlingFeeText.length > 0 && !NumberHelper.isNumeric(handlingFeeText) ? (
            <>
              <div className="info">
                <strong>Invalid handling fee</strong>
                <br />
                You have entered an invalid handling fee.
              </div>
            </>
          ) : handlingFeePercentageText &&
            handlingFeePercentageText.length > 0 &&
            !NumberHelper.isNumeric(handlingFeePercentageText) ? (
            <>
              <div className="info">
                <strong>Invalid handling fee percentage</strong>
                <br />
                You have entered an invalid handling fee percentage.
              </div>
            </>
          ) : (
            <>
              <div className="info">
                <strong>Fee example</strong>
                <p>
                  Using the setup you have chosen, this is a typical example of how fees would be managed for your event
                  when an attendee pays by credit or debit card.
                </p>
                <p
                  style={{
                    display: event.AbsorbFee ? 'block' : 'none',
                  }}
                >
                  An order for two {event.CurrencySymbol}
                  {feeExampleAmount.toFixed(2)} tickets would cost an attendee {event.CurrencySymbol}
                  {(feeExampleAmount * 2 + handlingFee).toFixed(2)}.
                  <br />
                  <br />
                  Seaty takes a {event.Organisation.CardFeePercentage}% of the total amount fee of{' '}
                  {event.CurrencySymbol}
                  {((feeExampleAmount * 2 + handlingFee) * (event.Organisation.CardFeePercentage / 100.0)).toFixed(
                    2,
                  )}{' '}
                  and you would be left with {event.CurrencySymbol}
                  {(
                    feeExampleAmount * 2 +
                    handlingFee -
                    (feeExampleAmount * 2 + handlingFee) * (event.Organisation.CardFeePercentage / 100.0)
                  ).toFixed(2)}
                  .
                  {event.Organisation.StripeConnectEnabled && (
                    <>
                      <br />
                      <br />
                      When using Stripe connect there will be fees on the amount processed charged to you directly by
                      Stripe.
                    </>
                  )}
                </p>
                <p
                  style={{
                    display: !event.AbsorbFee ? 'block' : 'none',
                  }}
                >
                  An order for two {event.CurrencySymbol}
                  {feeExampleAmount.toFixed(2)} tickets would cost an attendee {event.CurrencySymbol}
                  {(
                    (feeExampleAmount * 2 + handlingFee) * (event.Organisation.CardFeePercentage / 100.0) +
                    handlingFee +
                    feeExampleAmount * 2
                  ).toFixed(2)}
                  .
                  <br />
                  <br />
                  The attendee would pay {event.CurrencySymbol}
                  {(
                    (feeExampleAmount * 2 + handlingFee) * (event.Organisation.CardFeePercentage / 100.0) +
                    handlingFee
                  ).toFixed(2)}{' '}
                  in total fees and you would be left with {event.CurrencySymbol}
                  {(feeExampleAmount * 2 + handlingFee).toFixed(2)}.
                  {event.Organisation.StripeConnectEnabled && (
                    <>
                      <br />
                      <br />
                      When using Stripe connect there will be fees on the amount processed charged to you directly by
                      Stripe.
                    </>
                  )}
                </p>
                <p>
                  You can get more information on fees <Link to="/Home/Fees">here</Link>.
                </p>
              </div>
            </>
          )}
        </div>
        {/* 
        <div className="col-sm-8">
          <div className="fields">
            <h2>Referrals</h2>
            <div className="section">
              <InfoLabel
                text="Can attendees leave referrals?"
                tooltip="If you allow referrals, attendees to your event will be able to give credit for their ticket order to other Seaty users at the time of ordering."
              />

              <Switch
                trueLabel="Yes, allow referrals"
                falseLabel="No, disable referrals"
                onChange={(value) => onEventUpdated({ ...event, ReferralsEnabled: value })}
                checked={event.ReferralsEnabled}
              ></Switch>
            </div>
          </div>
        </div>        
        <div className="col-sm-4"></div> */}
      </div>
    </>
  );
};

export default TicketSetupSection;

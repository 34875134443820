import moment from 'moment';
import React from 'react';

import StringHelper from '../../helpers/StringHelper';
import SVGHelper from '../../helpers/SVGHelper';

interface IProps {
  data: any;
  isLast: boolean;
  onClick: () => void;
}

const MyTickets_Row: React.FC<IProps> = (props) => {
  var rowData = props.data;

  var referralCount = rowData.Referrals - rowData.ReferralsMade;
  rowData.ReferralOrder = referralCount;
  var ticketPlusReferralCount = rowData.Tickets + referralCount;

  var quotaPercentage = 0;
  if (rowData.Quota != null && rowData.Quota > 0) {
    quotaPercentage = (ticketPlusReferralCount / rowData.Quota) * 100;
    if (quotaPercentage > 100.0) {
      quotaPercentage = 100;
    }
  }

  var from = moment.utc(rowData.FirstEventDate);
  var to = moment.utc(rowData.LastEventDate);
  var fromString = from.format('ddd Do MMM YY');
  var toString = to.format('ddd Do MMM YY');
  var dateString = fromString == toString ? fromString : fromString + ' to ' + toString;

  var balance = <span></span>;

  if (rowData.OwedAsInt == 0) {
    balance = (
      <span>
        <img className="balanceImage tick" src={SVGHelper.get('Ok')} />
      </span>
    );
  } else if (rowData.OwedAsInt < 0) {
    balance = (
      <span className="success">
        <img className="balanceImage over" src={SVGHelper.get('Overpaid')} />
        {rowData.Owed.replace('-', '')}
      </span>
    );
  } else {
    balance = (
      <span className="danger">
        ({rowData.Owed})<img className="balanceImage" src={SVGHelper.get('Owed')} />
      </span>
    );
  }

  return (
    <tr className="route" style={{ display: 'table-row' }} onClick={props.onClick}>
      <td style={{ minWidth: '300px' }}>
        <div className="name-field">{rowData.EventName}</div>
        <div style={{ opacity: '0.7' }}>{dateString}</div>
      </td>

      <td className="quota-cell" data-name="Member" style={{ textAlign: 'right' }}>
        {!rowData.IsMember || !rowData.MembershipsEnabled ? null : (
          <div className="quota">
            <div>{rowData.MemberType}</div>

            {rowData.Quota != null && rowData.Quota > 0 ? (
              ticketPlusReferralCount >= rowData.Quota ? (
                <div className={'quota-info'}>{rowData.Quota} Quota Complete</div>
              ) : (
                <div className={'quota-info'}>
                  Quota {ticketPlusReferralCount} / {rowData.Quota}
                </div>
              )
            ) : null}

            {rowData.Quota != null && rowData.Quota > 0 ? (
              <div
                className={
                  'quota-bar donotprint' +
                  (quotaPercentage >= 100 ? ' complete' : quotaPercentage >= 50 ? ' almost' : '')
                }
              >
                <div className="progress" style={{ width: quotaPercentage + '%' }}></div>
              </div>
            ) : null}
          </div>
        )}
      </td>

      <td data-name="User" style={{ textAlign: 'right', fontSize: '12px' }}>
        {`${rowData.Tickets} ${StringHelper.AddSWhenMany(rowData.Tickets, 'ticket')}`}
        {rowData.ReferralOrder != null && rowData.ReferralOrder != 0 ? (
          <div>{rowData.ReferralOrder > 0 ? '+' + rowData.ReferralOrder : rowData.ReferralOrder} referrals</div>
        ) : null}
      </td>

      <td data-name="PriceAsInt" style={{ textAlign: 'right' }}>
        {rowData.Price}
      </td>

      <td data-name="OwedAsInt" style={{ textAlign: 'right' }}>
        {balance}
      </td>
    </tr>
  );
};

export default MyTickets_Row;

import linq from 'linq';
import React from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Select from '../../../components/Select';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import DiscountHelper from '../../../helpers/DiscountHelper';
import { IDiscount } from '../../../interfaces/IDiscount';
import { IEvent } from '../../../interfaces/IEvent';
import { IOption } from '../../../interfaces/IOption';
import { DiscountModes } from '../../../enums/DiscountModes';

export interface IProps {
  event: IEvent;
  isAdmin: boolean;
  setActiveDiscount: (discount) => void;
  validDiscounts: IDiscount[];
  activeDiscount: IDiscount;
  enterDiscountCodeSelected: boolean;
}

const DiscountSection: React.FC<IProps> = ({
  enterDiscountCodeSelected,
  event,
  isAdmin,
  setActiveDiscount,
  validDiscounts,
  activeDiscount,
}) => {
  const getDiscountOptions = () => {
    const discounts = validDiscounts == null ? [] : validDiscounts;

    if (isAdmin) {
      let discountOptions: Array<IOption> = [
        {
          Id: 0,
          Text: 'No Discount',
        },
      ];

      discounts.forEach((discount) => {
        if (discount.ApplicationMode != DiscountModes.SingleUseDiscountCodes || discount.Activated) {
          discountOptions.push(discountToOption(discount));
        }
      });

      if (discounts.filter((d) => d.ApplicationMode == DiscountModes.SingleUseDiscountCodes).length > 0) {
        discountOptions.push({ Id: -1, Text: 'Enter a Discount Code' });
      }

      return discountOptions;
    } else if (discounts.length > 0) {
      return [discountToOption(DiscountHelper.getBestAutomaticDiscount(discounts))];
    } else {
      return null;
    }
  };

  const discountToOption = (discount: IDiscount) => {
    return {
      Id: discount.Id,
      Text:
        discount.Name +
        (discount.ApplicationMode == DiscountModes.Automatically
          ? ' (Automatic)'
          : discount.ApplicationMode == DiscountModes.DiscountCode
            ? ` (${discount.Code.toUpperCase()})`
            : ` (${discount.Codes[0].Code.toUpperCase()})`),
      TextRight: '- ' + CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount * 100.0),
    };
  };

  const discountOptions = getDiscountOptions();

  if (!isAdmin || (isAdmin && discountOptions.length === 1)) return null;

  return (
    <>
      {discountOptions && discountOptions.length > 0 ? (
        <Block key="renderBoxOfficeSection_discount">
          <BlockHeader>Discount</BlockHeader>
          <Select
            key="renderBoxOfficeSection_discount_select"
            onSelectedValueChange={(o) => {
              if (o.Id == -1) {
                setActiveDiscount(o);
              } else if (o.Id == 0) {
                setActiveDiscount(null);
              } else {
                setActiveDiscount(
                  linq.from(validDiscounts).firstOrDefault((d) => {
                    return d.Id == o.Id;
                  }),
                );
              }
            }}
            selectedId={enterDiscountCodeSelected ? -1 : activeDiscount == null ? 0 : activeDiscount.Id}
            options={discountOptions}
          />
        </Block>
      ) : null}
    </>
  );
};

export default DiscountSection;

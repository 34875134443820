import linq from 'linq';
import moment from 'moment';
import React, { useState } from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import DateHelper from '../../../helpers/DateHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrder } from '../../../interfaces/IOrder';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  event: IEvent;
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
  setSelectedOrder: (order: IOrder) => void;
}

const OrdersSection: React.FC<IProps> = (props) => {
  const { event, overview, setSection, setSelectedOrder } = props;
  const [showOrdersForDateIds, setShowOrdersForDateIds] = useState<number[]>([]);

  const orders = overview.Orders;

  return (
    <>
      <SpacerTable>
        <h1>{StringHelper.AddSWhenMany(orders.length, 'Order')}</h1>
        {overview.TotalTickets + ' ' + StringHelper.AddSWhenMany(overview.TotalTickets, 'ticket')}
        {orders.length == 1 ? null : ' in ' + orders.length + ' orders'} for {overview.TotalOrdersPriceAsString}
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          {overview.Dates.length > 0 && overview.Dates.length != showOrdersForDateIds.length && (
            <Block
              className={`flourish`}
              onClick={() => {
                setShowOrdersForDateIds(overview.Dates.map((d) => d.Id));
              }}
            >
              <BlockHeader>Expand all dates</BlockHeader>
            </Block>
          )}
          {overview.Dates.length > 0 && overview.Dates.length == showOrdersForDateIds.length && (
            <Block
              className={`flourish expanded`}
              onClick={() => {
                setShowOrdersForDateIds([]);
              }}
            >
              <BlockHeader>Collapse all dates</BlockHeader>
            </Block>
          )}

          {linq
            .from(overview.Dates)
            .orderBy((d) => moment(d.Date).utc())
            .select((d) => {
              const dateTicketCount = linq.from(d.Orders).sum((o: any) => o.Seats.filter((s) => !s.Cancelled).length);
              return (
                <>
                  <Block
                    key={DateHelper.asDateAtTimeAmPm(d.Date)}
                    className={`flourish${showOrdersForDateIds.indexOf(d.Id) != -1 ? ' expanded' : ''}`}
                    onClick={() => {
                      if (showOrdersForDateIds.indexOf(d.Id) != -1) {
                        setShowOrdersForDateIds([...showOrdersForDateIds.filter((id) => id != d.Id)]);
                      } else {
                        setShowOrdersForDateIds([...showOrdersForDateIds, d.Id]);
                      }
                    }}
                  >
                    <BlockHeader>{DateHelper.asDateAtTimeAmPm(d.Date)}</BlockHeader>
                    {`${dateTicketCount} ${StringHelper.AddSWhenMany(dateTicketCount, 'ticket')} in ${d.Orders.length} ${StringHelper.AddSWhenMany(d.Orders.length, 'order')} for ${
                      event.CurrencySymbol
                    }${((linq.from(d.Orders).sum((o: any) => o.PriceAsInt) - (d.Discount ? d.Discount.Amount : 0)) / 100.0).toFixed(2)}`}
                  </Block>
                  {showOrdersForDateIds.indexOf(d.Id) != -1 &&
                    linq
                      .from(d.Orders)
                      .orderBy((o: any) => o.Id)
                      .select((o: any) => {
                        var discountAmount = linq
                          .from(o.Discounts)
                          .where((d: any) => d.OrderId == o.Id)
                          .sum((d: any) => d.Amount);

                        var totalPrice = o.PriceAsInt - discountAmount;
                        var totalPriceFormatted =
                          totalPrice == 0 ? '' : ' for ' + event.CurrencySymbol + (totalPrice / 100.0).toFixed(2);
                        var validTickets = linq
                          .from(o.Seats)
                          .where((s: any) => !s.Cancelled)
                          .toArray();

                        return (
                          <Block
                            key={'order_row_' + o.Id}
                            className="route"
                            onClick={() => {
                              setSelectedOrder(o);
                              setSection(Section.Order);
                            }}
                          >
                            <BlockHeader>
                              <td>#{o.Id}</td>
                              <td className="right">
                                <div>
                                  {validTickets.length +
                                    ' ' +
                                    StringHelper.AddSWhenMany(validTickets.length, 'ticket') +
                                    totalPriceFormatted}
                                </div>
                              </td>
                            </BlockHeader>

                            {o.UserNotes && <div className="notes">{o.UserNotes}</div>}

                            {o.Seats.filter((s) => s.Cancelled).length == o.Seats.length && (
                              <div className="tags">
                                <span className="tag danger">All tickets cancelled</span>
                              </div>
                            )}
                          </Block>
                        );
                      })
                      .toArray()}
                </>
              );
            })
            .toArray()}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
      </SpacerTable>
    </>
  );
};

export default OrdersSection;

import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../Loader';
import { IEvent } from '../../interfaces/IEvent';
import SVGMultiply from '../../svg/SVGMultiply';
import MemberTypeSelectionRow from './MemberTypeSelectionRow';
import SVGCheck from '../../svg/SVGCheck';
import SVGTick from '../../svg/SVGTick';
import StringHelper from '../../helpers/StringHelper';
import SVGGoBackArrow from '../../svg/SVGGoBackArrow';
import { IEventDate } from '../../interfaces/IEventDate';
import DateHelper from '../../helpers/DateHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import IMemberType from '../../interfaces/IMemberType';

export interface IProps {
  organisation: IOrganisation;
  confirm: (ids: number[]) => void;
  onBack: () => void;
  initialValue: string[];
  backMessage: string;
}

const MemberTypeSelection: FunctionComponent<IProps> = (props) => {
  const { organisation } = props;

  const [filter, setFilter] = useState('');
  const [selectedItems, setSelectedItems] = useState({});

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const initialItems = props.initialValue.reduce((acc, date) => {
      acc[date] = true;
      return acc;
    }, {});
    setSelectedItems(initialItems);
  }, []);

  if (!organisation) return null;

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  var rows = [];
  var sortedData = organisation.MemberTypes ?? [];

  const filteredData = [];

  sortedData.forEach((memberType: IMemberType, index) => {
    var include = true;
    if (filter != null && filter.length > 0) {
      include = false;

      if (memberType.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        include = true;
      }
    }

    if (include) {
      filteredData.push(memberType);

      var rowId = 'row_' + memberType.Id;
      rowId += '_' + index;

      rows.push(
        <MemberTypeSelectionRow
          checked={selectedItems[memberType.Id]}
          onCheckChange={() => {
            if (selectedItems[memberType.Id]) {
              delete selectedItems[memberType.Id];
            } else {
              selectedItems[memberType.Id] = true;
            }

            setSelectedItems({ ...selectedItems });
          }}
          key={rowId}
          memberType={memberType}
        />,
      );
    }
  });

  if (loading) return <Loader inline>Loading dates...</Loader>;
  const allFilteredItemsChecked = filteredData.every((rowData) => selectedItems[rowData.DateAsString]);

  return (
    <>
      <div className="admin-buttons" style={{ marginTop: 0, padding: 0 }}>
        <button
          className="admin-button icon-inline"
          onClick={() => {
            props.onBack();
          }}
        >
          <SVGGoBackArrow />
          {props.backMessage}
        </button>
        <button
          disabled={Object.keys(selectedItems).length == 0}
          className="admin-button icon-inline confirm"
          onClick={() => props.confirm(Object.keys(selectedItems).map((x) => parseInt(x)))}
        >
          <SVGTick />
          {Object.keys(selectedItems).length == 0
            ? 'No member types selected'
            : `Confirm selection of ${Object.keys(selectedItems).length} ${StringHelper.AddSWhenMany(Object.keys(selectedItems).length, 'member type')}`}
        </button>

        <button
          disabled={Object.keys(selectedItems).length == 0}
          className="admin-button icon-inline"
          onClick={() => {
            setSelectedItems({});
          }}
        >
          <SVGMultiply />
          Clear
        </button>
      </div>

      <div className="orderoverview" style={{ paddingBottom: '100px', maxWidth: '900px' }}>
        <div className="input donotprint">
          <input
            id="searchinput"
            onChange={handleSearchChanged}
            type="text"
            placeholder="Search..."
            value={query}
            onKeyPress={onFilterKeyPress.bind(this)}
            style={{ margin: '0' }}
          />

          <button onClick={clearFilterClicked}>
            <SVGMultiply />
          </button>
        </div>
        <div className="container-outer" style={{ minHeight: '300px' }}>
          <div className="container-inner" style={{ backgroundColor: 'white' }}>
            <table className="table grid-table">
              <thead>
                <tr>
                  <th style={{ width: '100px' }} className="grid-header check-cell">
                    <button
                      className={`${allFilteredItemsChecked ? ' selected' : ''}`}
                      onClick={() => {
                        if (allFilteredItemsChecked) {
                          filteredData.forEach((rowData) => {
                            delete selectedItems[rowData.DateAsString];
                          });
                        } else {
                          filteredData.forEach((rowData) => {
                            selectedItems[rowData.DateAsString] = true;
                          });
                        }

                        setSelectedItems({ ...selectedItems });
                      }}
                    >
                      <SVGCheck />
                    </button>
                  </th>
                  <th className="grid-header">Member Type</th>
                </tr>
              </thead>

              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberTypeSelection;

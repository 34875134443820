import React from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import DateHelper from '../../../helpers/DateHelper';
import { IEvent } from '../../../interfaces/IEvent';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  event: IEvent;
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
}

const RequestsSection: React.FC<IProps> = (props) => {
  const { event, overview, setSection } = props;

  return (
    <>
      <SpacerTable>
        <h1>Pending Requests</h1>
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          {overview.Requests.filter((t) => t.Seats && t.Seats.length > 0).map((request, index) => (
            <Block key={'Request_' + request.RequestId}>
              <BlockHeader>
                <td>Request #{request.RequestId}</td>
                <td className="right">{request.PriceAsString}</td>
              </BlockHeader>
              {request.Seats.length} tickets for {DateHelper.asDateAtTimeAmPm(request.EventDate)} requested by you on{' '}
              {DateHelper.asDateAtTimeAmPm(request.DateRequested)}
              {request.Notes && <div className="notes">{request.Notes}</div>}
              <div className="tags">
                {request.Seats.map((s) => {
                  const ticketCategory = event.Categories.filter((c) => c.Id === s.TicketCategoryId)[0];

                  const seatingPlan = event.SeatingPlans.find((sp) =>
                    sp.SeatCategories.find((sc) => sc.Id == s.SeatCategoryId),
                  );
                  const seatCategory =
                    seatingPlan && seatingPlan.SeatCategories.filter((c) => c.Id === s.SeatCategoryId)[0];

                  return (
                    <div
                      className="tag ticket-font"
                      key={`Seat_${s.TicketCategoryId}_${s.Group}_${s.Name}`}
                      style={{ backgroundColor: ticketCategory.Colour }}
                    >
                      {seatCategory && seatCategory.Name + ', '}
                      {ticketCategory.Name} : {s.Group}
                      {s.Name} - {event.CurrencySymbol + (s.PriceAsInt / 100.0).toFixed(2)}
                    </div>
                  );
                })}
              </div>
            </Block>
          ))}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
      </SpacerTable>
    </>
  );
};

export default RequestsSection;

import linq from 'linq';
import { FunctionComponent, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import DateHelper from '../../../helpers/DateHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrder } from '../../../interfaces/IOrder';
import { IPurchase } from '../../../interfaces/IPurchase';
import Block from '../../Block';
import BlockHeader from '../../BlockHeader';
import BlockInfo, { InfoType } from '../../BlockInfo';
import BlockTextBox from '../../BlockTextBox';
import Button from '../../Button';
import BlockCheckBox from '../../BlockCheckBox';
import Loader from '../../Loader';
import SpacerTable from '../../SpacerTable';
import moment from 'moment';

export interface IProps {
  event: IEvent;
  order: IOrder;
  onBackToOrderClick: () => void;
}

const ResaleSection: FunctionComponent<IProps> = (props) => {
  const { event, order, onBackToOrderClick } = props;
  const tickets = linq
    .from(order.Seats)
    .orderBy((s) => s.Group)
    .thenBy((s) => s.Name)
    .toArray();
  const purchase: IPurchase = order.Purchase;

  const [addRefund, setAddRefund] = useState(false);
  const [refundHandlingFee, setRefundHandlingFee] = useState(false);
  const [resaleReason, setResaleReason] = useState('');
  const [busyMessage, setBusyMessage] = useState(null);
  const [reloadMessage, setReloadMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTickets, setSelectedTickets] = useState([
    ...linq
      .from(tickets)
      .where((t) => !t.ResaleStartedDate && !t.Cancelled)
      .toArray(),
  ]);

  var allTicketsSelected =
    selectedTickets.length ===
    linq
      .from(tickets)
      .where((t) => !t.ResaleStartedDate)
      .toArray().length;

  const resellSelectedTickets = (order) => {
    setBusyMessage('Putting tickets up for resale...');
    setErrorMessage(null);

    var eventDateId = order.EventDateId;
    var reason = resaleReason;

    var resaleSeats = linq
      .from(order.Seats)
      .where(function (s: any) {
        return linq
          .from(selectedTickets)
          .where((t) => !t.ResaleStartedDate)
          .toArray()
          .includes(s);
      })
      .select(function (seat: any) {
        return {
          group: seat.Group,
          name: seat.Name,
          seatCategoryId: seat.SeatCategoryId,
          ticketCategoryId: seat.TicketCategoryId,
          orderId: order.Id,
        };
      })
      .toArray();

    var resale = {
      eventDateId: eventDateId,
      reason: reason,
      seats: resaleSeats,
      addRefund: addRefund,
      refundHandlingFee: refundHandlingFee,
    };

    AdminApi.request('PUT', `/api/Resale`, resale)
      .then(() => {
        setBusyMessage(null);
        setReloadMessage('The tickets have been put up for resale.');

        AdminApi.request('POST', '/api/EventDateHandshake', { eventDateId: eventDateId });
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  if (reloadMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Info}>{reloadMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={props.onBackToOrderClick} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  var ticketComponents = [];

  const hasDiscounts = order.Discounts != null && order.Discounts.length > 0;

  tickets.forEach((ticket) => {
    var editOrderAdminNotesInput = null;

    const showCheckbox = !ticket.ResaleStartedDate && !ticket.Cancelled;

    const resaleDate = ticket.ResaleStartedDate && moment(ticket.ResaleStartedDate).tz(event.TimeZoneIana);
    const resaleDateFormatted =
      ticket.ResaleStartedDate && resaleDate.format('ddd Do MMM YY') + ' at ' + moment(resaleDate).format('h:mma');

    const content = (
      <>
        <BlockHeader>
          <td className="ticket-font" style={{ color: ticket.SeatCategoryColour }}>
            {ticket.Group + ticket.Name} {ticket.SeatCategoryName}
          </td>
          <td className="right">
            <span style={{ color: ticket.CategoryColour }}>
              {ticket.CategoryName} {ticket.PriceAsString}
            </span>
          </td>
        </BlockHeader>

        {!showCheckbox &&
          ticket.QuestionAnswers &&
          ticket.QuestionAnswers.map((qa) => (
            <div className="question" style={{ marginTop: '6px' }}>
              <BlockHeader>{qa.QuestionName}</BlockHeader>
              {qa.Text}
            </div>
          ))}

        {ticket.AdminNotes && ticket.AdminNotes.length > 0 && (
          <span className="userNotes" style={{ display: 'block', maxWidth: '100%' }}>
            {ticket.AdminNotes}
          </span>
        )}
        {ticket.Cancelled && <div style={{ display: 'block' }}>Ticket cancelled on {ticket.CancelledDate}</div>}
        {ticket.ResaleStartedDate && (
          <div style={{ display: 'block' }}>Ticket put up for resale on {resaleDateFormatted}</div>
        )}

        {editOrderAdminNotesInput}
      </>
    );

    if (showCheckbox) {
      if (hasDiscounts) {
        ticketComponents.push(<Block key={ticket.Group + ticket.Name}>{content}</Block>);
      } else {
        ticketComponents.push(
          <BlockCheckBox
            key={ticket.Group + ticket.Name}
            checked={selectedTickets.includes(ticket)}
            onBoxClick={() => {
              if (selectedTickets.includes(ticket)) {
                const index = selectedTickets.indexOf(ticket);
                if (index > -1) {
                  selectedTickets.splice(index, 1);
                }
              } else {
                selectedTickets.push(ticket);
              }

              setSelectedTickets([...selectedTickets]);
            }}
          >
            {content}
          </BlockCheckBox>,
        );
      }
    } else {
      ticketComponents.push(<Block key={ticket.Group + ticket.Name}>{content}</Block>);
    }
  });

  var discountAmount = 0;
  if (order.Discounts != null && order.Discounts.length > 0) {
    discountAmount = linq.from(order.Discounts).sum(function (d: any) {
      return d.Amount;
    });
  }

  const isPurchase = order.Purchase != null;
  const availableTickets = linq
    .from(tickets)
    .where((t) => !t.Cancelled && !t.ResaleStartedDate)
    .toArray();

  return (
    <>
      <table className="blocks">
        <tbody>
          <BlockInfo type={InfoType.Warning}>
            Re-selling tickets will make the specified seats available for sale again. If the tickets get ordered again,
            then the seats in this order will be cancelled. Until that time this order will remain in place and the
            tickets will not be cancelled. If these tickets were paid for by card through Seaty, a refund will be
            issued.
          </BlockInfo>
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <h1>Order #{props.order.Id}</h1>
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Attendee</BlockHeader>
            {order.AttendeeName}
            <br />
            {order.Email}
          </Block>
          <Block>
            <BlockHeader>Event Date</BlockHeader>
            {DateHelper.asDateAtTimeAmPm((order.EventDate as any).DateAsString)}
          </Block>
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <h1>Tickets</h1>
      </SpacerTable>
      <div className="spacer" />
      <SpacerTable>
        <Button
          onClick={() => {
            const value = selectedTickets.length != availableTickets.length;

            availableTickets.forEach((ticket) => {
              if (value && !selectedTickets.includes(ticket)) {
                selectedTickets.push(ticket);
              } else if (!value && selectedTickets.includes(ticket)) {
                const index = selectedTickets.indexOf(ticket);
                if (index > -1) {
                  selectedTickets.splice(index, 1);
                }
              }

              setSelectedTickets([...selectedTickets]);
            });
          }}
          text={selectedTickets.length != availableTickets.length ? `Select all tickets` : 'De-select all tickets'}
        />
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks tickets">
        <tbody>
          {ticketComponents}

          {hasDiscounts ? (
            <>
              {order.Discounts.map((discount) => (
                <Block key={discount.Name}>
                  <BlockHeader>{discount.Name}</BlockHeader>
                  {CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}
                </Block>
              ))}

              <BlockInfo type={InfoType.Info}>
                This order currently has discounts applied to it. When you resale an order with discounts the entire
                order and all tickets will be resold.
              </BlockInfo>
            </>
          ) : null}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <h1>Resale</h1>
      </SpacerTable>
      <div className="spacer" />
      <table className="blocks tickets">
        <tbody>
          <Block>
            <BlockHeader>Why are these tickets being resold?</BlockHeader>
            <BlockTextBox
              name={'resaleReason'}
              placeholder={'You must enter a reason for resale these tickets.'}
              value={resaleReason}
              onChange={(e) => {
                setResaleReason(e);
              }}
              rows={2}
            />
          </Block>

          {purchase && !hasDiscounts && (
            <>
              {purchase.PurchaseCosts && !purchase.AbsorbFees && purchase.PurchaseCosts.TotalFees > 0 && (
                <>
                  <Block>
                    <BlockHeader>Card fee paid by attendee</BlockHeader>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.TotalFees)}
                  </Block>
                  <BlockInfo type={InfoType.Info}>
                    Seaty card processing fees are only returned to the attendee when all tickets in an order are
                    resold.{' '}
                  </BlockInfo>
                </>
              )}

              {purchase.PurchaseCosts && purchase.AbsorbFees && purchase.PurchaseCosts.TotalFees > 0 && (
                <>
                  <Block>
                    <BlockHeader>Card fee paid by your organisation</BlockHeader>
                    {CurrencyHelper.formatCurrency(event.CurrencySymbol, purchase.PurchaseCosts.TotalFees)}
                  </Block>
                  <BlockInfo type={InfoType.Info}>
                    Seaty card processing fees are only returned to your organisation when all tickets in an order are
                    resold.{' '}
                  </BlockInfo>
                </>
              )}

              <Block>
                <BlockHeader>Total refund to attendee on successful resale</BlockHeader>
                {CurrencyHelper.formatCurrency(
                  event.CurrencySymbol,
                  linq.from(selectedTickets).sum((s: any) => s.PriceAsInt) +
                    (purchase.AbsorbFees ? 0 : allTicketsSelected ? purchase.PurchaseCosts.TotalFees : 0) +
                    (refundHandlingFee ? purchase.PurchaseCosts.HandlingFee : 0),
                )}
              </Block>
            </>
          )}

          {errorMessage && <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button
          className="bad"
          disabled={resaleReason.length <= 4}
          onClick={() => resellSelectedTickets(order)}
          text={`Resell ${selectedTickets.length} tickets`}
        />
      </SpacerTable>

      <div className="spacer" />
      <SpacerTable>
        <Button onClick={onBackToOrderClick} text={'Back to order'} />
      </SpacerTable>
    </>
  );
};

export default ResaleSection;

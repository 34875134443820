import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../../api/AdminApi';
import orderState from '../../../../atoms/orderState';
import Loader from '../../../../components/Loader';
import { IEvent } from '../../../../interfaces/IEvent';
import { IEventDate } from '../../../../interfaces/IEventDate';
import SVGRefresh from '../../../../svg/SVGRefresh';
import './Scans.scss';
import ScansRow from './ScansRow';
import SideMenu from '../../../../components/SideMenu';
import SVGOutlineMonth from '../../../../svg/outline/SVGOutlineMonth';

interface IProps {
  event: IEvent;
  eventDate?: IEventDate;
}

const AdminScans: FunctionComponent<IProps> = (props) => {
  const [eventState, setEventState] = useState<{ event: IEvent; eventDate: IEventDate }>({
    event: null,
    eventDate: null,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [ordersState, setOrders] = useState([]);
  const [, setOrder] = useRecoilState(orderState);
  const [expandedMonths, setExpandedMonths] = useState<string[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const { event } = props;
  const eventTag = event.EventTag;

  const loadEventDate = (event: IEvent, eventDateId: number, updateUrl: boolean) => {
    const eventDate = event.Dates.filter((ed) => ed.Id === eventDateId)[0];

    setLoading(false);
    setEventState({ event: event, eventDate: eventDate });
  };

  const refresh = () => {
    if (!eventState.eventDate) return;

    setLoading(true);

    AdminApi.request(
      'GET',
      `/api/Scans?eventId=${eventState.event.Id}${eventState.eventDate ? '&eventDateId=' + eventState.eventDate.Id : ''}`,
    )
      .then((response) => {
        setLoading(false);
        setOrders(response);
      })
      .catch((message) => {
        alert(message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (eventState && eventState.event) refresh();
  }, [eventState]);

  useEffect(() => {
    if (props.eventDate) {
      loadEventDate(event, props.eventDate.Id, false);
    } else {
      setLoading(false);
      setEventState({ event: event, eventDate: null });
    }
  }, [props.eventDate?.Id]);

  const handleRowClick = function (order) {
    setOrder({ orderId: order.OrderId, event: eventState.event });
  };

  var orders = linq
    .from(ordersState)
    .select(function (o) {
      o.Tickets = linq
        .from(o.Tickets)
        .orderByDescending(function (t: any) {
          return t.DateScanned == null ? 0 : 1;
        })
        .thenBy(function (t: any) {
          return t.GroupName;
        })
        .thenBy(function (t: any) {
          if (isNaN(t.SeatName)) {
            return t.SeatName;
          } else {
            return parseInt(t.SeatName);
          }
        })
        .toArray();
      return o;
    })
    .orderByDescending(function (o) {
      return o.Tickets.length > 0 ? moment(o.Tickets[0].DateScanned) : 0;
    })
    .toArray();

  if (loading || !event) return <Loader inline>Loading scans...</Loader>;

  const getEventDateButton = (eventDate: IEventDate, text: string) => {
    return (
      <button
        className={`${eventState.eventDate?.Id == eventDate.Id ? 'selected' : ''}`}
        onClick={() => {
          if (eventDate.External && eventDate.ExternalUrl && eventDate.ExternalUrl.length > 0) {
            var r = window.confirm(
              'You are about to leave Seaty.co.uk, we take no responsibility for ticket sales that occur outside of our domain. Click continue to go to the external ticket sales provider.',
            );
            if (r == true) {
              window.location.href = eventDate.ExternalUrl;
              return;
            } else {
              return;
            }
          }

          loadEventDate(eventState.event, eventDate.Id, true);
        }}
        key={`MONTH_${moment(eventDate.Date).format('ddd DD MMM YY, HH:mm')})}`}
      >
        {text}
      </button>
    );
  };

  return (
    <>
      <Helmet>
        <title>#{eventTag} Scans</title>
        <meta name="description" content={`Manage scans for your event.`} />
      </Helmet>

      <div className="row">
        {!props.eventDate && (
          <div className="col-sm-3 col-md-2 donotprint">
            <SideMenu>
              <div className="title">Scans</div>

              {linq
                .from(event.Dates)
                .orderBy((d) => moment(d.Date).unix())
                .groupBy((d) => moment(d.Date).format('DD/MM/YYYY'))
                .select((group) => {
                  if (group.count() == 1) {
                    const eventDate = group.first();

                    return getEventDateButton(eventDate, moment(eventDate.Date).format('ddd DD MMM YY, HH:mm'));
                  }

                  return (
                    <div key={group.key().toString()}>
                      <button
                        className={`${expandedMonths.includes(group.key()) ? 'expanded' : 'collapsed'}`}
                        onClick={() =>
                          expandedMonths.includes(group.key())
                            ? setExpandedMonths(expandedMonths.filter((e) => e != group.key()))
                            : setExpandedMonths([...expandedMonths, group.key()])
                        }
                      >
                        {moment(group.first().Date).format('ddd DD MMM YY')}
                      </button>

                      {expandedMonths.includes(group.key()) && (
                        <div style={{ paddingLeft: '15px' }}>
                          {group
                            .select((eventDate: IEventDate) =>
                              getEventDateButton(eventDate, moment(eventDate.Date).format('HH:mm')),
                            )
                            .toArray()}
                        </div>
                      )}
                    </div>
                  );
                })
                .toArray()}
            </SideMenu>
          </div>
        )}

        <div className={props.eventDate ? 'col-sm-12 donotprint' : 'col-sm-9 col-md-10 donotprint'}>
          <div className="toolbar">
            <div className="buttons">
              <button
                className={`${eventState.eventDate ? '' : 'disabled'}`}
                onClick={() => {
                  refresh();
                }}
              >
                <SVGRefresh />
                Refresh
              </button>
            </div>
          </div>

          {orders == null || orders.length == 0 ? (
            <>
              <div className="info">
                {eventState.eventDate ? (
                  <div>
                    <div>
                      <strong>No Scanned Tickets</strong>
                    </div>
                    There are no reported ticket scans {moment(eventState.eventDate.Date).format('DD MMM YY, HH:mm')}
                  </div>
                ) : (
                  <div>
                    <div>
                      <strong>Select Event Date</strong>
                    </div>
                    Please select an event date to view ticket scans. You can click the refresh button to update this
                    list at any time.
                  </div>
                )}
              </div>
              <div className="info">
                <div>
                  <strong>Mobile Application</strong>
                </div>
                You can{' '}
                <a href="https://itunes.apple.com/us/app/seaty-ticket-scan/id941385198?ls=1&mt=8">
                  download the Seaty Ticket Scan app
                </a>{' '}
                from the iOS app store.
                <br />
                <br />
                If you go to your organisation editor, and then to the devices section, you can add devices with
                passcodes. These device logins will allow basic none-admin access to your event for scanning purposes
                only on as many devices with the Seaty app installed as you like. Meaning you dont need a full Seaty
                account for each scanning device and you do not have to give out admin permissions to anyone that doesnt
                need it.
              </div>
            </>
          ) : (
            <>
              <div className="scans">
                <div className="row">
                  <div className="col col-sm-8 col-sm-offset-2" style={{ display: loading ? 'inline-block' : 'none' }}>
                    <table>
                      <tbody>
                        <tr style={{ border: '0' }}>
                          <td style={{ paddingLeft: '10px', paddingRight: '8px' }}>
                            <span className="spinner round animated"></span>
                          </td>
                          <td>
                            <span>Loading Ticket Scans</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col col-sm-8 col-sm-offset-2" style={{ display: loading ? 'none' : 'block' }}>
                    {orders.map((o, index) => (
                      <ScansRow
                        handleRowClick={() => handleRowClick(o)}
                        key={'order_row_' + o.OrderId + '_' + index}
                        order={o}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminScans;

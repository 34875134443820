import { atom } from 'recoil';

// Define our type for convenience
type EventReferralRecord = Record<number, { name: string; guid: string }>;

const eventReferralState = atom<EventReferralRecord | null>({
  key: 'eventReferral',
  default: null,
  // Use the effects_UNSTABLE property to manage persistence
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      // 1. On initialization, try to read from localStorage
      const savedValue = localStorage.getItem('eventReferral');
      if (savedValue) {
        try {
          setSelf(JSON.parse(savedValue));
        } catch (error) {
          console.warn('Error parsing eventReferral from localStorage', error);
        }
      }

      // 2. Whenever the atom’s value changes, save it to localStorage
      onSet((newValue, _, isReset) => {
        if (isReset || newValue === null) {
          localStorage.removeItem('eventReferral');
        } else {
          localStorage.setItem('eventReferral', JSON.stringify(newValue));
        }
      });
    },
  ],
});

export default eventReferralState;

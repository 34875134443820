import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import LocationHelper from '../../helpers/LocationHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import SVGOutlineDetails from '../../svg/outline/SVGOutlineDetails';
import SVGOutlineImage from '../../svg/outline/SVGOutlineImage';
import SideMenu from '../../components/SideMenu';
import SVGSendMail from '../../svg/SVGSendMail';
import SVGUser from '../../svg/SVGUser';
import EmailUserPreferencesPage from './EmailUserPreferencesPage';
import UserAccountPreferencesPage from './UserAccountPreferencesPage';

export interface IOrganisationWarning {
  title: string;
  text: string;
}

interface IProps {}

export enum Section {
  MyAccount = 'Account',
  EmailPreferences = 'Email Preferences',
}

interface IUserAccountSection {
  heading: string;
  subHeading?: (o: IOrganisation) => React.ReactNode;
  section: Section;
  visible?: (o: IOrganisation) => boolean;
  svg?: any;
}

const userAccountSections: IUserAccountSection[] = [
  {
    heading: 'Account',
    section: Section.MyAccount,
    svg: <SVGUser />,
  },
  {
    heading: 'Email Preferences',
    section: Section.EmailPreferences,
    svg: <SVGSendMail />,
  },
];

const UserAccountPage: React.FC<IProps> = (props) => {
  const [section, setSection] = useState<Section>(Section.MyAccount);
  const [busyMessage, setBusyMessage] = useState<string>(null);
  const location = useLocation();

  useEffect(() => {
    const urlDetails = LocationHelper.getLocation().pathname;
    const organisationTag = urlDetails.split('/')[2];

    const queryParams = new URLSearchParams(window.location.search);
    const sectionParam: string = queryParams.get('section');

    if (sectionParam && Object.values(Section).includes(sectionParam as any)) {
      setSection(sectionParam as any);
    }
  }, [location]);

  const sections: { [key: string]: any } = {};
  sections[Section.MyAccount] = <UserAccountPreferencesPage />;
  sections[Section.EmailPreferences] = <EmailUserPreferencesPage />;

  return (
    <>
      <Helmet>
        <title>Seaty - User Account</title>
        <meta name="description" content={`Edit your organisation details.`} />
      </Helmet>

      {busyMessage && <Loader inline>{busyMessage}</Loader>}

      {!busyMessage && (
        <div className="admin-dashboard-editor">
          <div className="row">
            <div className="col-sm-3 col-md-2  donotprint">
              <SideMenu navigator>
                {userAccountSections.map((s: IUserAccountSection) => (
                  <button
                    key={s.section}
                    className={`${section == s.section ? 'selected' : ''}${s.svg ? ' has-image' : ''}`}
                    onClick={() => {
                      setSection(s.section);
                      LocationHelper.updateParam('section', s.section);
                    }}
                  >
                    {s.svg}
                    {s.heading}
                  </button>
                ))}
              </SideMenu>
            </div>
            <div className="col-sm-9 col-md-10  donotprint">{sections[section]}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserAccountPage;

import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import AttendeeHelper from '../../helpers/AttendeeHelper';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import DateHelper from '../../helpers/DateHelper';
import EmailHelper from '../../helpers/EmailHelper';
import StringHelper from '../../helpers/StringHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOption } from '../../interfaces/IOption';
import { IOrder } from '../../interfaces/IOrder';
import { ITicket } from '../../interfaces/ITicket';
import Block from '../Block';
import BlockHeader from '../BlockHeader';
import BlockInfo, { InfoType } from '../BlockInfo';
import Button from '../Button';
import BlockCheckBox from '../BlockCheckBox';
import Loader from '../Loader';
import Lookup from '../Lookup';
import SpacerTable from '../SpacerTable';
import AdminNotesInput from './AdminNotesInput';
import PaymentControl from '../stripe/PaymentControl';
import CancelSection from './sections/CancelSection';
import NumberHelper from '../../helpers/NumberHelper';
import ResaleSection from './sections/ResaleSection';
import UserHelper from '../../helpers/UserHelper';
import CacheHelper from '../../helpers/CacheHelper';
import Constants from '../../helpers/Constants';
import EnvironmentVariables from '../../EnvironmentVariables';
import { EmailEventRecordType } from '../../interfaces/IEmailEvent';

export interface IProps {
  event?: IEvent;
  orderId?: number;
}

enum Section {
  Dashboard = 'Dashboard',
  Cancel = 'Cancel',
  Resell = 'Resell',
  Tickets = 'Tickets',
  MarkAsPaid = 'MarkAsPaid',
  EditReferral = 'EditReferral',
  EditAdminNotes = 'EditAdminNotes',
  Transfer = 'Transfer',
  Log = 'Log',
}

let attendeeTimeout: number = 0;

const OrderDashboard: FunctionComponent<IProps> = (props) => {
  const [section, setSection] = useState<Section>(Section.Dashboard);
  const [order, setOrder] = useState<IOrder>(null);
  const [busyMessage, setBusyMessage] = useState(null);
  const [reloadMessage, setReloadMessage] = useState(null);
  const [attendeeEmail, setAttendeeEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [event, setEvent] = useState<IEvent>(props.event);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (props.orderId != null) {
      loadOrder(props.orderId);
    }
  }, [props.orderId]);

  const reloadOrder = (order) => {
    disableSeatSelection(order);
    setSection(Section.Dashboard);
    loadOrder(order.Id);
  };

  const loadOrder = (orderId) => {
    setOrder(null);
    setErrorMessage(null);
    setReloadMessage(null);
    setAttendeeEmail('');

    if (orderId == null || orderId.length == 0) {
      setErrorMessage('You must enter a valid order number');
      return;
    }

    setBusyMessage('Order #' + orderId + ' loading...');

    AdminApi.request('GET', `/api/Order?orderId=${orderId}`)
      .then(async (result) => {
        if (event == null) {
          setEvent(await CacheHelper.eventByTag(result.Event.EventTag));
        }

        setBusyMessage(null);
        if (!result) {
          setErrorMessage('Cannot find order.');
        }

        setOrder(result);
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  const handleGoToHomePageClick = () => {
    setSection(Section.Dashboard);
    loadOrder(props.orderId);
  };

  const renderDashboard = () => {
    var availableItemsCount = linq
      .from(order.Seats)
      .where((s: any) => !s.Cancelled)
      .toArray().length;

    var availableTicketSalesCount = linq
      .from(order.Seats)
      .where((s: any) => !s.Cancelled && !s.Merchandise)
      .toArray().length;

    var availableMerchandiseSalesCount = linq
      .from(order.Seats)
      .where((s: any) => !s.Cancelled && s.Merchandise)
      .toArray().length;

    var anyUnpaidItems =
      linq
        .from(order.Seats)
        .where((s: any) => {
          return s.Cancelled == false && s.PaymentTaken == false;
        })
        .toArray().length > 0;

    var ticketsPaidFor = linq
      .from(order.Seats)
      .where((s: any) => {
        return s.Cancelled == false && s.PaymentTaken == true;
      })
      .toArray();

    var allItemsCancelled = availableItemsCount == 0;

    const ticketsFaceValue =
      order &&
      order.Seats &&
      order.Seats.length > 0 &&
      linq
        .from(order.Seats)
        .where((s) => !s.Merchandise)
        .sum((s) => s.PriceAsInt);

    const merchandiseFaceValue =
      order &&
      order.Seats &&
      order.Seats.length > 0 &&
      linq
        .from(order.Seats)
        .where((s) => s.Merchandise)
        .sum((s) => s.PriceAsInt);

    const purchase = order && order.Purchase;

    const amountAlreadyRefunded =
      purchase && purchase.Refunds && purchase.Refunds.length > 0
        ? linq.from(purchase.Refunds).sum((r) => r.Amount)
        : 0;

    const amountAvailableToRefund = purchase ? purchase.PurchaseCosts.TotalCostWithoutFees - amountAlreadyRefunded : 0;

    const totalOriginallyPaid = purchase ? purchase.PurchaseCosts.TotalCostWithFees : 0;

    const seatyFees = purchase ? purchase.PurchaseCosts.SeatyServiceFee : 0;

    const ersFees = purchase ? purchase.PurchaseCosts.EnhancedRefundServiceFee : 0;

    const handlingFee = purchase ? purchase.PurchaseCosts.HandlingFee : 0;

    const netIncome = purchase
      ? handlingFee +
        purchase.PurchaseCosts.TotalCostWithoutFees -
        amountAlreadyRefunded -
        (purchase.AbsorbFees ? purchase.PurchaseCosts.SeatyServiceFee : 0)
      : 0;

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Order #{order.Id}</BlockHeader>
              {order.AttendeeName != null && order.AttendeeName.length > 1 ? <span>{order.AttendeeName}, </span> : null}
              <a href={`mailto:${order.Email}`}>{order.Email}</a>

              <div>Placed on {DateHelper.asDateAtTimeAmPm(order.DateOrdered)}</div>
            </Block>

            <Block>
              <BlockHeader>Event </BlockHeader>
              <a href={'/' + order.Event.EventTag}>#{order.Event.EventTag}</a> on{' '}
              <a
                href={
                  '/' + order.Event.EventTag + '/tickets/' + moment(order.EventDateAsString).format('DDMMMYYYY/HHmm')
                }
              >
                {DateHelper.asDateAtTimeAmPm(order.EventDateAsString)}
              </a>
            </Block>

            {order.CollectAtBoxOffice && (
              <Block>
                <BlockHeader>Box Office Collection</BlockHeader>
                This attendee has requested that they collect their tickets from the box office.
              </Block>
            )}

            {order.QuestionAnswers &&
              order.QuestionAnswers.map((qa) => (
                <Block>
                  <div className="question">
                    <BlockHeader>{qa.QuestionName}</BlockHeader>
                    {qa.Text}
                  </div>
                </Block>
              ))}

            {purchase && (
              <>
                <Block>
                  <BlockHeader>Purchase</BlockHeader>
                  <table>
                    {totalOriginallyPaid && totalOriginallyPaid > 0 ? (
                      <tr>
                        <td>Amount Paid</td>
                        <td style={{ textAlign: 'right' }}>
                          {CurrencyHelper.formatCurrency(event.CurrencySymbol, totalOriginallyPaid)}
                        </td>
                      </tr>
                    ) : null}
                    {merchandiseFaceValue && merchandiseFaceValue > 0 ? (
                      <tr>
                        <td>Merchandise</td>
                        <td style={{ textAlign: 'right' }}>
                          {CurrencyHelper.formatCurrency(event.CurrencySymbol, merchandiseFaceValue)}
                        </td>
                      </tr>
                    ) : null}
                    {handlingFee && handlingFee > 0 ? (
                      <tr>
                        <td>Handling Fee</td>
                        <td style={{ textAlign: 'right' }}>
                          {CurrencyHelper.formatCurrency(event.CurrencySymbol, handlingFee)}
                        </td>
                      </tr>
                    ) : null}

                    {order.Discounts != null &&
                      order.Discounts.length > 0 &&
                      order.Discounts.map((discount) => {
                        var discountName = 'Discount';

                        if (discount.Name != null && discount.Name.length > 0) {
                          if (discountName.toLowerCase().indexOf('discount') !== -1) {
                            discountName = discount.Name;
                          } else {
                            discountName = discount.Name + ' Discount';
                          }
                        }

                        return (
                          <tr key={'Discount_' + discount.Name + '_' + discount.Id}>
                            <td>{discountName}</td>
                            <td style={{ textAlign: 'right' }}>
                              -{CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}
                            </td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td>Non-Refundable Fees{purchase.AbsorbFees ? ' Absorbed' : ''}</td>
                      <td style={{ textAlign: 'right' }}>
                        -{CurrencyHelper.formatCurrency(event.CurrencySymbol, seatyFees)}
                      </td>
                    </tr>
                    {ersFees && ersFees > 0 ? (
                      <tr>
                        <td>Enhanced Refund Service</td>
                        <td style={{ textAlign: 'right' }}>
                          -{CurrencyHelper.formatCurrency(event.CurrencySymbol, ersFees)}
                        </td>
                      </tr>
                    ) : null}
                    {amountAlreadyRefunded > 0 && (
                      <tr>
                        <td>Refunded Amount</td>
                        <td style={{ textAlign: 'right' }}>
                          -{CurrencyHelper.formatCurrency(event.CurrencySymbol, amountAlreadyRefunded)}
                        </td>
                      </tr>
                    )}
                    <tr style={{ fontWeight: 'bold' }}>
                      <td>Net Income</td>
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(event.CurrencySymbol, netIncome)}
                      </td>
                    </tr>
                  </table>
                </Block>
              </>
            )}

            <Block className="route" onClick={() => setSection(Section.Tickets)}>
              <BlockHeader>
                {availableTicketSalesCount > 0 && availableMerchandiseSalesCount > 0
                  ? `${StringHelper.AddSWhenMany(availableTicketSalesCount, 'Ticket')} & ${StringHelper.AddSWhenMany(availableMerchandiseSalesCount, 'Item')}`
                  : availableMerchandiseSalesCount > 0
                    ? StringHelper.AddSWhenMany(availableMerchandiseSalesCount, 'Item')
                    : StringHelper.AddSWhenMany(availableTicketSalesCount, 'Ticket')}
              </BlockHeader>

              {allItemsCancelled ? (
                <span>All tickets have been cancelled.</span>
              ) : (
                <span>
                  {availableTicketSalesCount} {StringHelper.AddSWhenMany(availableTicketSalesCount, 'ticket')}
                  {availableMerchandiseSalesCount > 0
                    ? `, ${availableMerchandiseSalesCount} ${StringHelper.AddSWhenMany(availableMerchandiseSalesCount, 'merchandise sale')}`
                    : ``}{' '}
                  with face value of {CurrencyHelper.formatCurrency(order.Currency, order.FaceValueAsInt)}
                </span>
              )}
            </Block>

            <Block
              className="block--button"
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(EnvironmentVariables.HOME_URL + '/Order/Tickets/' + eventOrder.Guid);

                window.setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
            >
              <BlockHeader>
                {copied
                  ? `Link copied!`
                  : availableMerchandiseSalesCount > 0
                    ? 'Copy tickets & merch link'
                    : 'Copy tickets link'}
              </BlockHeader>
            </Block>

            {purchase || ticketsPaidFor.length == 0 ? null : (
              <Block>
                <BlockHeader>Payment</BlockHeader>
                {anyUnpaidItems ? '' : 'All '}
                {ticketsPaidFor.length} tickets marked as paid
              </Block>
            )}

            {anyUnpaidItems && !allItemsCancelled && (
              <Block className="route" onClick={() => handleMarkAsPaidClick(order)}>
                <BlockHeader>Add payment</BlockHeader>
              </Block>
            )}

            <Block className="route" onClick={() => setSection(Section.EditAdminNotes)}>
              <BlockHeader>
                {order.AdminNotes && order.AdminNotes.length > 0 ? `Admin notes` : `Add admin note`}
              </BlockHeader>
              {order.AdminNotes}
            </Block>

            {!allItemsCancelled && (
              <Block
                className="route"
                onClick={() => {
                  setAttendeeEmail(order.ReferralName);
                  setSection(Section.EditReferral);
                }}
              >
                <BlockHeader>
                  {order.ReferralName == null || order.ReferralName.length == 0 ? `Add referral` : `Referral`}
                </BlockHeader>
                {order.ReferralName}
              </Block>
            )}

            <Block
              className="route"
              onClick={() => {
                setAttendeeEmail('');
                setSection(Section.Transfer);
              }}
            >
              <BlockHeader>Transfer ownership</BlockHeader>
            </Block>

            {UserHelper.currentUser.Id == 1 && (
              <Block
                className="route"
                onClick={() => {
                  setSection(Section.Resell);
                }}
              >
                <BlockHeader>Resell {StringHelper.AddSWhenMany(order.Seats.length, 'ticket')}</BlockHeader>
              </Block>
            )}

            <Block className="route" onClick={() => setSection(Section.Log)}>
              <BlockHeader>Order logs</BlockHeader>
            </Block>

            {(amountAvailableToRefund != 0 || !allItemsCancelled) && (
              <Block
                id="cancelButton"
                className="route bad"
                onClick={() => {
                  setSection(Section.Cancel);
                }}
              >
                {order.Purchase ? (
                  <BlockHeader>Cancel / refund {StringHelper.AddSWhenMany(order.Seats.length, 'ticket')}</BlockHeader>
                ) : (
                  <BlockHeader>Cancel {StringHelper.AddSWhenMany(order.Seats.length, 'ticket')}</BlockHeader>
                )}
              </Block>
            )}
          </tbody>
        </table>

        {!allItemsCancelled && (
          <>
            <div className="spacer" />
            <SpacerTable>
              <Button onClick={() => handleReSendOrderEmailClick(order)} text={'Re-send order email'} />
            </SpacerTable>
          </>
        )}
      </>
    );
  };

  const renderTicketsPage = (ticketComponents) => {
    var availableTicketsCount = linq
      .from(order.Seats)
      .where((s) => !s.Cancelled && !s.Merchandise)
      .toArray().length;

    var availableMerchandiseSalesCount = linq
      .from(order.Seats)
      .where((s) => !s.Cancelled && s.Merchandise)
      .toArray().length;

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>
                <BlockHeader>Order #{order.Id}</BlockHeader>
              </BlockHeader>
              {availableTicketsCount} {StringHelper.AddSWhenMany(availableTicketsCount, 'ticket')}
              {availableMerchandiseSalesCount > 0
                ? `, ${availableMerchandiseSalesCount} ${StringHelper.AddSWhenMany(availableMerchandiseSalesCount, 'merchandise sale')}`
                : ''}{' '}
              with a face value of {CurrencyHelper.formatCurrency(order.Currency, order.FaceValueAsInt)}
            </Block>

            {ticketComponents}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button
            className="confirm"
            onClick={() => {
              setSection(Section.Tickets);
              var win = window.open('/Order/Tickets/' + eventOrder.Guid, '_blank');
              win.focus();
            }}
            text={availableMerchandiseSalesCount > 0 ? 'View tickets & merch' : 'View tickets'}
          />
        </SpacerTable>

        <div className="spacer" />
        <SpacerTable>
          <Button
            onClick={() => {
              setCopied(true);
              navigator.clipboard.writeText(EnvironmentVariables.HOME_URL + '/Order/Tickets/' + eventOrder.Guid);

              window.setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
            text={
              copied
                ? `Link copied!`
                : availableMerchandiseSalesCount > 0
                  ? 'Copy tickets & merch link'
                  : 'Copy tickets link'
            }
          />
        </SpacerTable>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => handleGoToHomePageClick()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  };

  const renderNotesPage = () => {
    return (
      <>
        <AdminNotesInput
          onSaved={handleGoToHomePageClick}
          orderNumber={order.Id}
          notes={order.AdminNotes}
          key={'orderAdminNotesEditor_' + order.Id}
        />
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => handleGoToHomePageClick()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  };

  const renderLogPage = () => {
    const logs = order.OrderLogs;

    const format = 'ddd Do MMM YYYY [at] h:mma';

    order.EmailEvents.forEach((emailEvent) => {
      logs.push({
        Id: 0,
        DateFormatted: emailEvent.Date,
        Note:
          emailEvent.RecordType == EmailEventRecordType.Delivery
            ? `Order email sent to ${emailEvent.Recipient}`
            : EmailEventRecordType.Open
              ? `Order email opened by ${emailEvent.Recipient}`
              : emailEvent.RecordType,
        UserThatDidIt: 'Seaty Emailer',
      });
    });

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Order #{order.Id} Logs</BlockHeader>
            </Block>
            {linq
              .from(order.OrderLogs)
              .orderBy((l) => moment(l.DateFormatted, format).unix())
              .toArray()
              .map((orderLog, index) => (
                <Block key={orderLog.Id + '_' + index}>
                  <BlockHeader>
                    <td className="blockHeader--small">{orderLog.UserThatDidIt}</td>
                    <td className="blockHeader--small right">
                      {moment(orderLog.DateFormatted, format).format(`D MMM YY, HH:mm`)}
                    </td>
                  </BlockHeader>
                  {orderLog.Note}
                </Block>
              ))}
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => handleGoToHomePageClick()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  };

  const handleSeatSelectedClick = (seat: ITicket) => {
    if (seat.Selected) {
      seat.Selected = false;
    } else {
      seat.Selected = true;
    }
    setOrder({ ...order });
  };

  const handleMarkAsPaidClick = (order) => {
    order.Seats.forEach((seat) => {
      if (!seat.PaymentTaken && !seat.Cancelled) {
        seat.Selectable = true;
        seat.Selected = true;
      } else {
        seat.Selectable = false;
        seat.Selected = false;
      }
    });

    setSection(Section.MarkAsPaid);
  };

  const handleReSendOrderEmailClick = (order: IOrder) => {
    setBusyMessage('Re-sending order email');

    AdminApi.request('GET', `/api/ResendOrder?orderId=${order.Id}`)
      .then((result) => {
        setBusyMessage(null);
        setReloadMessage('The order email has been re-sent.');
      })
      .catch((message) => {
        setBusyMessage(null);
        setErrorMessage(message);
      });
  };

  const disableSeatSelection = (order) => {
    order.Seats.forEach((seat) => {
      if (!seat.Cancelled) {
        seat.Selectable = false;
        seat.Selected = false;
      }
    });
  };

  const removeReferral = (order) => {
    setBusyMessage('Removing referral from order...');

    AdminApi.request('POST', '/api/ReferOrder', {
      email: '',
      orderId: order.Id,
    })
      .then((result) => {
        setBusyMessage(null);
        setReloadMessage('The referral has been removed.');
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
      });
  };

  const editReferral = (order) => {
    setBusyMessage('Referring order...');

    AdminApi.request('POST', '/api/ReferOrder', {
      email: attendeeEmail,
      orderId: order.Id,
    })
      .then((result) => {
        setBusyMessage(null);
        setReloadMessage('The order has been referred.');
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
      });
  };

  const transferOrder = (order) => {
    setBusyMessage('Transferring tickets...');

    var transfer = {
      NewEmail: attendeeEmail,
      Notes: 'Order transfer',
      OrderId: order.Id,
    };

    AdminApi.request('POST', '/api/TransferOrder', transfer)
      .then((result) => {
        if (result.Completed) {
          setReloadMessage('The tickets have been transferred.');
        } else {
          setErrorMessage('Something went wrong and the transfer did not complete');
        }
        setBusyMessage(null);
      })
      .catch((message) => {
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  const renderPaymentPage = () => {
    var email = order.Email;

    return (
      <>
        <PaymentControl
          isAdmin={true}
          attendeeName={order.AttendeeName}
          event={event}
          organisationFeePercentage={event.Organisation.ServiceFee}
          tickets={order.Seats}
          orderId={order.Id}
          minimumFee={event.CardFeeMinimum}
          feePercentage={event.CardFeePercentage}
          absorbFee={event.AbsorbFee}
          eventId={event.Id}
          paymentMadeCallback={() => console.log('Payment made')}
          payeeEmail={email}
          discounts={order.Discounts}
          currency={event.CurrencySymbol}
        />

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => handleGoToHomePageClick()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  };

  const [attendeeOptions, setAttendeeOptions] = useState<Array<IOption>>([]);
  const [lookingForAttendees, setLookingForAttendees] = useState(false);
  const handleAttendeeEmailLookupTextChanged = (lookupText: string) => {
    setAttendeeEmail(lookupText);
    window.clearTimeout(attendeeTimeout);

    if (lookupText.length > 0) {
      setLookingForAttendees(true);
      attendeeTimeout = window.setTimeout(() => {
        AttendeeHelper.lookupAttendees(lookupText).then((options) => {
          setAttendeeOptions(options);
          setLookingForAttendees(false);
        });
      }, 500);
    } else {
      setLookingForAttendees(false);
    }
  };

  const renderReferralPage = () => {
    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <Lookup
                focus={true}
                autoComplete="off"
                placeholder={'Email address'}
                type="email"
                name="emailaddress"
                value={attendeeEmail}
                options={attendeeOptions}
                loading={lookingForAttendees}
                onLookupOptionSelected={(option) => {
                  setAttendeeEmail(option.Text);
                }}
                onLookupTextChange={handleAttendeeEmailLookupTextChanged}
                header={`Referral for order #${order.Id}`}
              />
            </Block>
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button
            className="confirm"
            disabled={attendeeEmail == null || !EmailHelper.validate(attendeeEmail)}
            onClick={() => {
              editReferral(order);
            }}
            text={`Update referral`}
          />
        </SpacerTable>
        <div className="spacer" />
        <SpacerTable>
          <Button
            className="bad"
            disabled={order.ReferralName == null || order.ReferralName.length == 0}
            onClick={() => {
              removeReferral(order);
            }}
            text={`Remove existing referral`}
          />
        </SpacerTable>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => handleGoToHomePageClick()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  };

  const renderTransferPage = () => {
    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <Lookup
                focus={true}
                autoComplete="off"
                placeholder={'Email address'}
                type="email"
                name="emailaddress"
                value={attendeeEmail}
                options={attendeeOptions}
                loading={lookingForAttendees}
                onLookupOptionSelected={(option) => {
                  setAttendeeEmail(option.Text);
                }}
                onLookupTextChange={handleAttendeeEmailLookupTextChanged}
                header={`Transfer order #${order.Id} to new owner`}
              />
            </Block>
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button
            className="confirm"
            disabled={attendeeEmail == null || !EmailHelper.validate(attendeeEmail)}
            onClick={() => {
              transferOrder(order);
            }}
            text={`Transfer order`}
          />
        </SpacerTable>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => handleGoToHomePageClick()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  };

  var eventOrder: IOrder = order;

  if (reloadMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Info}>{reloadMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => reloadOrder(eventOrder)} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  if (errorMessage != null && errorMessage.length > 0) {
    return <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>;
  }

  if (errorMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => reloadOrder(order)} text={'Dismiss'} />
        </SpacerTable>
      </>
    );
  }

  if (eventOrder == null) {
    return null;
  }

  var ticketComponents = [];

  linq
    .from(eventOrder.Seats)
    .orderBy((t) => t.Merchandise)
    .thenBy((t) => (NumberHelper.isNumeric(t.Group) ? parseInt(t.Group) : t.Group))
    .thenBy((t) => (NumberHelper.isNumeric(t.Name) ? parseInt(t.Name) : t.Name))
    .toArray()
    .forEach((ticket) => {
      var seatAdminNotes = null;

      seatAdminNotes = (
        <span
          className="userNotes"
          style={section === Section.EditAdminNotes ? { display: 'none' } : { display: 'block', maxWidth: '100%' }}
          onClick={() => setSection(Section.EditAdminNotes)}
        >
          {ticket.AdminNotes}
        </span>
      );

      var editOrderAdminNotesInput = null;
      if (section === Section.EditAdminNotes) {
        // TODO: editOrderAdminNotesInput = <OrderAdminNotesInput onAdminNotesUpdated={refresh} adminNotesObject={orderSeat}></OrderAdminNotesInput>;
      }

      if (ticket.Cancelled) {
        seatAdminNotes = <div style={{ display: 'block' }}>Ticket cancelled on {ticket.CancelledDate}</div>;
      }

      if (section === Section.Cancel || section === Section.MarkAsPaid) {
        seatAdminNotes = null;
        if (ticket.PaymentTaken && section === Section.MarkAsPaid) {
          seatAdminNotes = <span style={{ display: 'block', color: 'green' }}>Payment #{ticket.PaymentId}</span>;
        }
      }

      var adminPaidMark = null;

      if (ticket.PaymentTaken && section !== Section.Cancel) {
        adminPaidMark = (
          <div className="tags">
            <span className="tag confirm">Paid</span>
          </div>
        );
      }

      const showCheckbox = ticket.Selectable && !ticket.Cancelled;

      const content = (
        <>
          <BlockHeader>
            <td className="ticket-font" style={{ color: ticket.SeatCategoryColour }}>
              {ticket.Group + ticket.Name} {ticket.SeatCategoryName}
            </td>
            <td className="right">
              <span style={{ color: ticket.CategoryColour }}>
                {ticket.CategoryName} {ticket.PriceAsString}
              </span>
            </td>
          </BlockHeader>

          {!showCheckbox &&
            ticket.QuestionAnswers &&
            ticket.QuestionAnswers.map((qa) => (
              <div className="question" style={{ marginTop: '6px' }}>
                <BlockHeader>{qa.QuestionName}</BlockHeader>
                {qa.Text}
              </div>
            ))}

          {adminPaidMark}
          {seatAdminNotes}
          {editOrderAdminNotesInput}
        </>
      );

      if (showCheckbox) {
        ticketComponents.push(
          <BlockCheckBox
            key={ticket.Group + ticket.Name}
            checked={ticket.Selected}
            onBoxClick={() => handleSeatSelectedClick(ticket)}
          >
            {content}
          </BlockCheckBox>,
        );
      } else {
        ticketComponents.push(<Block key={ticket.Group + ticket.Name}>{content}</Block>);
      }
    });

  return section === Section.Log ? (
    renderLogPage()
  ) : section === Section.Cancel ? (
    <CancelSection onBackToOrderClick={handleGoToHomePageClick} order={order} event={event} />
  ) : section === Section.Resell ? (
    <ResaleSection onBackToOrderClick={handleGoToHomePageClick} order={order} event={event} />
  ) : section === Section.Tickets ? (
    renderTicketsPage(ticketComponents)
  ) : section === Section.MarkAsPaid ? (
    renderPaymentPage()
  ) : section === Section.EditReferral ? (
    renderReferralPage()
  ) : section === Section.EditAdminNotes ? (
    renderNotesPage()
  ) : section === Section.Transfer ? (
    renderTransferPage()
  ) : (
    renderDashboard()
  );
};

export default OrderDashboard;

import React, { useCallback, useState } from 'react';

import Loader from './Loader';
import StringHelper from '../helpers/StringHelper';
import SpacerTable from './SpacerTable';

export interface IProps {
  onClick(): Promise<any>;
  description?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  icon?: any;
  warning?: string;
  text?: string;
}

const GenerateWithAIButton: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  loading,
  onClick,
  style,
  className,
  children,
  description,
  icon,
  warning,
  text,
}) => {
  const [generating, setGenerating] = useState(false);
  const [gptRuns, setGptRuns] = useState(0);
  const gtpLimit = 5;

  const execute = useCallback(() => {
    if (disabled || loading) {
      return;
    }

    setGenerating(true);
    setGptRuns(gptRuns + 1);

    onClick().then((result) => {
      setGenerating(false);
      return result;
    });
  }, [disabled, loading, onClick]);

  return (
    <>
      <button
        disabled={gptRuns >= gtpLimit || generating || disabled || loading || warning != null}
        name={'generateWithAIButton'}
        style={style}
        tabIndex={0}
        className={`button${icon ? ' button--hasIcon' : ''}${disabled ? ' disabled' : ''}${loading ? ' loading' : ''}${className ? ' ' + className : ''}`}
        onClick={onClick ? execute : null}
      >
        {loading ? (
          <Loader inline />
        ) : (
          <>
            {icon && icon}
            {gptRuns >= gtpLimit
              ? `AI Limit Reached`
              : generating
                ? `Generating...`
                : text
                  ? text
                  : `🤖 Generate with Seaty AI`}
            {description && <div className="button__description">{description}</div>}
            {children && children}
          </>
        )}
      </button>
      {warning && <div className="info">{warning}</div>}
    </>
  );
};

export default GenerateWithAIButton;

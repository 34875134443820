import React, { FunctionComponent } from 'react';
import StringHelper from '../helpers/StringHelper';

export interface IProps {
  checked: boolean;
  onChange: (e: boolean) => void;
  trueLabel: string;
  falseLabel: string;
  disabled?: boolean;
  id?: string;
}

const Switch: FunctionComponent<IProps> = (props) => {
  var text = '';

  if (props.checked) {
    if (props.trueLabel != null) {
      text = props.trueLabel;
    }
  } else {
    if (props.falseLabel != null) {
      text = props.falseLabel;
    }
  }

  return (
    <div
      className={`checkbox checkbox-slider--b-flat checkbox-slider-md ${props.disabled ? 'checkbox--disabled' : ''}`}
    >
      <label>
        <input
          id={props.id}
          onKeyDown={(e) => {
            var ENTER = 13;
            if (e.keyCode == ENTER) {
              props.onChange(!props.checked);
            }
          }}
          onChange={props.disabled ? null : (e) => props.onChange(e.target.checked)}
          type="checkbox"
          checked={props.checked}
        ></input>
        <span>{text}</span>
      </label>
    </div>
  );
};

export default Switch;

import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../../components/Loader';
import { IEvent } from '../../../../interfaces/IEvent';
import './AdminMail.scss';
import ReactQuill from 'react-quill';
import SVGSendMail from '../../../../svg/SVGSendMail';
import Dropdown from '../../../../components/Dropdown';
import { IDropDownItem } from '../../../../components/DropdownItem';
import AttendeeSelection from '../../../../components/attendee_selection/AttendeeSelection';
import AIPrompt from '../../../../components/AIPrompt';
import AdminApi from '../../../../api/AdminApi';
import SVGCalendar from '../../../../svg/SVGCalendar';
import SVGUser from '../../../../svg/SVGUser';
import EventDateSelection from '../../../../components/date_selection/EventDateSelection';
import DateHelper from '../../../../helpers/DateHelper';
import EventHelper from '../../../../helpers/EventHelper';
import InfoInput from '../../../../components/InfoInput';
import SVGGoBackArrow from '../../../../svg/SVGGoBackArrow';
import {
  AllowEventSend,
  AllowOrganisationSend,
  MailSendTo,
  MailSendToDescriptions,
  MailSendToInfo,
} from './MailSendTo';
import UserHelper from '../../../../helpers/UserHelper';
import MemberTypeSelection from '../../../../components/member_type_selection/MemberTypeSelection';
import SVGMemberType from '../../../../svg/SVGMemberType';
import CheckBox from '../../../../components/CheckBox';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGSaveSmall from '../../../../svg/SVGSaveSmall';
import { IAdminEmail } from '../../../../interfaces/IAdminMail';

export interface IProps {
  event: IEvent;
  organisation: IOrganisation;
  onBack: () => void;
  editingMail?: IAdminEmail;
  backMessage: string;
}

const AdminMailComposer: FunctionComponent<IProps> = (props) => {
  const event = props.event;
  const organisation = props.organisation;

  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const [busyMessage, setBusyMessage] = useState('');
  const [confirmedTerms, setConfirmedTerms] = useState(false);
  const [showAttendeeSelection, setShowAttendeeSelection] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [showMemberTypeSelection, setShowMemberTypeSelection] = useState(false);
  const [showAiPrompt, setShowAiPrompt] = useState(false);
  const [sendTo, setSendTo] = useState(props.event ? MailSendTo.AllEventAttendees : MailSendTo.AllOrganisationMembers);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedMemberTypes, setSelectedMemberTypes] = useState([]);
  const [sendToEmails, setSendToEmails] = useState<any>(null);
  const [showActiveEmails, setShowActiveEmails] = useState(false);
  const [showInactiveEmails, setShowInactiveEmails] = useState(false);

  const [editingMail, setEditingMail] = useState<IAdminEmail>(null);

  useEffect(() => {
    if (!props.editingMail) return;

    setBusyMessage('Loading draft mail...');
    AdminApi.request('GET', `/api/Mailshot?mailId=${props.editingMail.Id}`)
      .then((result) => {
        setSendTo(result.SendTo);
        setBody(result.Body);
        setSubject(result.Subject);
        setEditingMail(result);
        setBusyMessage(null);
      })
      .catch((message) => {
        setBusyMessage(null);
      });
  }, []);

  useEffect(() => {
    setBusyMessage('Loading emails...');
    AdminApi.request('POST', `/api/MailshotEmails`, {
      existingMailId: editingMail?.Id,
      eventId: event?.Id,
      organisationId: organisation.Id,
      sendTo: sendTo,
      subject: subject,
      body: body,
      selectedEmails: sendTo == 1 ? selectedEmails : null,
      selectedDates: sendTo == 3 ? selectedDates : null,
      selectedMemberTypes: sendTo == 7 ? selectedMemberTypes : null,
    })
      .then((result) => {
        setSendToEmails(result);
        setBusyMessage(null);
      })
      .catch((message) => {
        setBusyMessage(null);
      });
  }, [sendTo, selectedEmails, selectedDates, selectedMemberTypes]);

  const send = () => {
    setBusyMessage('Sending email...');
    AdminApi.request('POST', '/api/MailshotSend', {
      existingMailId: editingMail?.Id,
      eventId: event?.Id,
      organisationId: organisation.Id,
      sendTo: sendTo,
      subject: subject,
      body: body,
      selectedEmails: sendTo == 1 ? selectedEmails : null,
      selectedDates: sendTo == 3 ? selectedDates : null,
      selectedMemberTypes: sendTo == 7 ? selectedMemberTypes : null,
    })
      .then((result) => {
        setBusyMessage('');
        if (result.ErrorMessage) {
          alert(result.ErrorMessage);
          return;
        }
        alert('Your mail has been sent.');
        props.onBack();
      })
      .catch((message) => {
        setBusyMessage('');
        alert(message);
      });
  };

  const saveAsDraft = () => {
    setBusyMessage('Saving draft mail...');
    AdminApi.request('POST', '/api/MailshotDraft', {
      existingMailId: editingMail?.Id,
      eventId: event?.Id,
      organisationId: organisation.Id,
      sendTo: sendTo,
      subject: subject,
      body: body,
    })
      .then((result) => {
        setBusyMessage('');
        if (result.ErrorMessage) {
          alert(result.ErrorMessage);
          return;
        }
        props.onBack();
      })
      .catch((message) => {
        setBusyMessage('');
        alert(message);
      });
  };

  const aiRequest = (prompt) => {
    return AdminApi.request('POST', '/api/MailshotAIHelper', {
      prompt: prompt,
      eventTag: event?.EventTag,
      organisationTag: organisation.OrganisationTag,
    })
      .then((result) => {
        if (result) {
          const email = JSON.parse(result);

          setBody(email?.body);
          setSubject(email?.subject);
        }
      })
      .catch((message) => alert(message));
  };

  const sendToOptions = Object.values(MailSendTo)
    .filter(
      (value) =>
        (props.event && AllowEventSend[value as MailSendTo]) ||
        (props.organisation &&
          (AllowOrganisationSend[value as MailSendTo] ||
            (value == MailSendTo.AllEventAdmins && organisation.Id == 1 && UserHelper.currentUser.Id == 1))),
    ) // Only allow options that are allowed
    .filter((value) => typeof value === 'number') // Only keep numeric values
    .map((value) => ({
      description: MailSendToDescriptions[value as MailSendTo],
      value: value as MailSendTo,
      info: MailSendToInfo[value as MailSendTo],
    }));

  const hideSend =
    subject.length <= 3 ||
    (sendTo == 1 && selectedEmails.length == 0) ||
    (sendTo == 3 && selectedDates.length == 0) ||
    (sendTo == 7 && selectedMemberTypes.length == 0) ||
    EventHelper.getRichTextCharLength(body) < 10 ||
    sendToEmails?.ActiveEmails.length == 0;

  return (
    <>
      {!(showDateSelection || showAttendeeSelection || showMemberTypeSelection) && (
        <div className="admin-buttons" style={{ marginTop: 0, padding: 0 }}>
          <button
            className="admin-button icon-inline"
            onClick={() => {
              props.onBack();
            }}
          >
            <SVGGoBackArrow />
            {props.backMessage}
          </button>
        </div>
      )}

      <div className="admin-mail">
        {showAiPrompt && (
          <AIPrompt
            question={`How can I help you with your ${event ? 'event' : 'organisation'} email?`}
            tooltip={`Seaty AI knows all about your ${event ? 'event' : 'organisation'} already, you can ask it a question or give it some context and it can help you to generate an email for your ${event ? 'event' : 'organisation'}.`}
            request={aiRequest}
            onComplete={(prompt) => {
              setShowAiPrompt(false);
            }}
            onCancel={() => setShowAiPrompt(false)}
          />
        )}

        {busyMessage ? (
          <Loader inline>{busyMessage}</Loader>
        ) : showDateSelection ? (
          <EventDateSelection
            backMessage="Back to mail composer"
            initialValue={selectedDates}
            onBack={() => setShowDateSelection(false)}
            confirm={(dates) => {
              setShowDateSelection(false);
              setSelectedDates(dates);
            }}
            event={event}
          />
        ) : showMemberTypeSelection ? (
          <MemberTypeSelection
            backMessage="Back to mail composer"
            initialValue={selectedMemberTypes}
            onBack={() => setShowMemberTypeSelection(false)}
            confirm={(memberTypes) => {
              setShowMemberTypeSelection(false);
              setSelectedMemberTypes(memberTypes);
            }}
            organisation={props.organisation}
          />
        ) : showAttendeeSelection ? (
          <AttendeeSelection
            backMessage="Back to mail composer"
            initialValue={selectedEmails}
            onBack={() => setShowAttendeeSelection(false)}
            confirm={(emails) => {
              setShowAttendeeSelection(false);
              setSelectedEmails(emails);
            }}
            event={event}
          />
        ) : (
          <>
            <div className="mail-page">
              <label>Who do you want to send this message to?</label>

              <Dropdown
                style={{ maxWidth: '500px' }}
                onChange={(e: IDropDownItem) => {
                  setSendTo(e.value);
                }}
                value={sendTo}
                items={sendToOptions}
                description={null}
              />

              {sendTo == 1 && (
                <div className="admin-buttons" style={{ margin: 0, marginBottom: '20px' }}>
                  <button
                    className="admin-button icon-inline"
                    onClick={() => {
                      setShowAttendeeSelection(true);
                    }}
                  >
                    <SVGUser />
                    Select attendees ({selectedEmails.length} selected)
                  </button>
                </div>
              )}

              {sendTo == 3 && (
                <div className="admin-buttons" style={{ margin: 0, marginBottom: '20px' }}>
                  <button
                    className="admin-button icon-inline"
                    onClick={() => {
                      setShowDateSelection(true);
                    }}
                  >
                    <SVGCalendar />
                    Select dates ({selectedDates.length} selected)
                  </button>
                </div>
              )}

              {sendTo == 7 && (
                <div className="admin-buttons" style={{ margin: 0, marginBottom: '20px' }}>
                  <button
                    className="admin-button icon-inline"
                    onClick={() => {
                      setShowMemberTypeSelection(true);
                    }}
                  >
                    <SVGMemberType />
                    Select member types ({selectedMemberTypes.length} selected)
                  </button>
                </div>
              )}

              {sendTo == 3 && selectedDates.length > 0 && (
                <div className="info" style={{ marginBottom: '30px' }}>
                  <strong>This email will be sent to all attendees with a ticket for the following event dates:</strong>
                  <br />
                  {selectedDates.map((d) => `${DateHelper.asDateAtTimeAmPm(d)}`).join(', ')}
                </div>
              )}

              {sendTo == 7 && selectedMemberTypes.length > 0 && (
                <div className="info" style={{ marginBottom: '30px' }}>
                  <strong>
                    This email will be sent to all members of your organisation of the following member types:
                  </strong>
                  <br />
                  {props.organisation.MemberTypes?.filter((mt) => selectedMemberTypes.includes(mt.Id))
                    .map((mt) => `${mt.Name}`)
                    .join(', ')}
                </div>
              )}

              {/* {sendTo == 1 && selectedEmails.length > 0 && (
                <div className="info" style={{ marginBottom: '30px' }}>
                  <strong>This email will be sent out to the following selected attendees:</strong>
                  <br />
                  {selectedEmails.map((e) => `${e}`).join('; ')}
                </div>
              )} */}

              {sendToEmails && (
                <>
                  {sendToEmails?.ActiveEmails.length > 0 ? (
                    <div className="info" style={{ marginBottom: '30px' }}>
                      This email will be sent to <strong>{sendToEmails?.ActiveEmails.length}</strong> emails.
                      {showActiveEmails && <div>{sendToEmails?.ActiveEmails.map((e) => `${e}`).join('; ')}</div>}
                      <div>
                        <a href="#" onClick={() => setShowActiveEmails(!showActiveEmails)}>
                          {!showActiveEmails ? 'Show emails' : 'Hide emails'}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="info" style={{ marginBottom: '30px' }}>
                      No active emails for this selection.
                    </div>
                  )}
                </>
              )}

              {sendToEmails && sendToEmails?.InactiveEmails.length > 0 && (
                <div className="info" style={{ marginBottom: '30px' }}>
                  <strong>{sendToEmails?.InactiveEmails.length}</strong> emails have unsubscribed from this service.
                  {showInactiveEmails && <div>{sendToEmails?.InactiveEmails.map((e) => `${e}`).join('; ')}</div>}
                  <div>
                    <a href="#" onClick={() => setShowInactiveEmails(!showInactiveEmails)}>
                      {!showInactiveEmails ? 'Show inactive emails' : 'Hide inactive emails'}
                    </a>
                  </div>
                </div>
              )}

              <label>What is your message?</label>

              <div className="admin-buttons" style={{ marginTop: 0 }}>
                <button className="admin-button" onClick={() => setShowAiPrompt(true)}>
                  🤖 Ask Seaty AI to help write a message
                </button>
              </div>

              <InfoInput labelText="Subject" onChange={(value) => setSubject(value)} value={subject} />

              <ReactQuill
                theme="snow"
                value={body}
                onChange={(v, e, b) => {
                  setBody(v);
                }}
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                  ],
                }}
              />
            </div>

            {hideSend ? (
              <div className="info" style={{ marginTop: '20px' }}>
                {sendTo == 1 && selectedEmails.length == 0 && (
                  <div>
                    📧 <strong>No attendees selected</strong>. Please choose at least one attendee to send this email
                    to.
                  </div>
                )}
                {sendTo == 3 && selectedDates.length == 0 && (
                  <div>
                    📅 <strong>No event dates selected</strong>. Please select at least one event date for this email to
                    be sent.
                  </div>
                )}
                {sendTo == 7 && selectedMemberTypes.length == 0 && (
                  <div>
                    👥 <strong>No member types selected</strong>. Please select at least one member type to receive this
                    email.
                  </div>
                )}
                {subject.length <= 3 && (
                  <div>
                    ✉️ <strong>Subject too short</strong>. Please enter a subject line with at least{' '}
                    <strong>4 characters</strong> so recipients understand the purpose of the email.
                  </div>
                )}
                {EventHelper.getRichTextCharLength(body) < 10 && (
                  <div>
                    📝 <strong>Message too short</strong>. Please provide a message with at least{' '}
                    <strong>10 characters</strong> to ensure your email contains useful content.
                  </div>
                )}
                {sendToEmails?.ActiveEmails.length == 0 && (
                  <div>
                    🚫 <strong>No active emails</strong>. Please select a different recipient.
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="info" style={{ marginTop: '20px' }}>
                  <strong>Before sending</strong>, please review and confirm the following:
                  <ul>
                    <li>
                      ✅ You will use Seaty’s <strong>Event Messaging</strong> feature <strong>only</strong> to share
                      essential event information or updates — <strong>not</strong> for marketing or promotional
                      content.
                    </li>
                    <li>
                      ⚖️ You agree to comply with all <strong>applicable laws and regulations</strong> relating to email
                      communications.
                    </li>
                    <li>
                      📋 You understand that all messages are subject to Seaty’s <strong>Terms of Service</strong>,
                      including the <strong>Messaging and Notifications</strong> section. Misuse or unauthorised
                      messaging may result in account suspension.
                    </li>
                    <li>
                      🚫 You will <strong>not spam</strong> and agree to send no more than{' '}
                      <strong>one email every 15 minutes</strong> per organisation.
                    </li>
                    <li>
                      📬 You consent to using your user email <strong>{UserHelper.currentUser.Email}</strong> as the
                      reply-to address for attendee responses.
                    </li>
                  </ul>
                </div>
                <CheckBox checked={confirmedTerms} onBoxClick={() => setConfirmedTerms(!confirmedTerms)}>
                  Please tick this box to confirm you agree to the above statement and terms of Seaty Ltd.
                </CheckBox>
                <div className="admin-buttons">
                  <button disabled={!confirmedTerms} className="admin-button icon-inline" onClick={() => send()}>
                    <SVGSendMail />
                    Send Mail
                  </button>
                </div>
              </>
            )}

            <div className="admin-buttons">
              <button
                disabled={subject.length <= 3 || EventHelper.getRichTextCharLength(body) < 10}
                className="admin-button icon-inline"
                onClick={() => saveAsDraft()}
              >
                <SVGSaveSmall />
                Save As Draft
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminMailComposer;

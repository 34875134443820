export default class CurrencyHelper {
  public static formatCurrency(currency: string, value: number) {
    const absValue = (value / 100).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return value < 0 ? `-${currency}${absValue.replace('-', '')}` : `${currency}${absValue}`;
  }

  public static stringToInt(value: string) {
    return Math.round(parseFloat(value.replace(/[^0-9.-]+/g, '')) * 100.0);
  }
}

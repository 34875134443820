import { IDiscount } from './IDiscount';
import { IOrder } from './IOrder';
import { ITicketCategory } from './ITicketCategory';

export enum EventDateTimeType {
  SingleTime = 0,
  TimeRange = 1,
  DateRange = 2,
  AllDay = 3,
}

export interface IEventDate {
  TimeType?: EventDateTimeType;
  HighestPriceFormatted?: string;
  LowestPriceFormatted?: string;
  PercentageOfNotLockedSold?: any;
  PercentageSoldOrLocked?: number;
  Categories?: ITicketCategory[];
  PercentageSold?: number;
  ValidationError?: string;
  Id: number;
  Guid?: string;
  Date?: string;
  Suspend?: boolean;
  Captioned?: boolean;
  Signed?: boolean;
  InformationHeader?: string;
  Information?: string;
  SeatCategoryIds?: Array<number>;
  TicketCategoryIds?: Array<number>;
  GATicketCategoryIds?: Array<number>;
  DateAsString?: string;
  EndDateAsString?: string;
  HasOrders?: boolean;
  External?: boolean;
  ExternalUrl?: string;
  OrphanedSeats?: Array<IOrder>;
  Orders?: IOrder[];
  Discount?: IDiscount;
  ChangeMade?: boolean;
  LowestSeatedPrice?: number;
  HighestSeatedPrice?: number;
  LowestGAPrice?: number;
  HighestGAPrice?: number;
  LowestPrice?: number;
  HighestPrice?: number;
  SoldOut?: boolean;
  TimeString?: string;
  AvailableCategories?: ITicketCategory[];
}

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import BlockDatePicker, { IDatePickerValue } from './BlockDatePicker';
import BlockTimePicker, { ITimePickerValue } from './BlockTimePicker';
import './DatePicker.scss';

export interface IDateTimePickerValue {
  moment: moment.Moment;
  isDateTime: boolean;
  allHaveValue: boolean;
  isFuture: boolean;
  date: IDatePickerValue;
  time: ITimePickerValue;
  isRange: boolean;
  endTime: ITimePickerValue;
}

export interface IProps {
  groupName: string;
  initialValue?: moment.Moment;
  initialEndValue?: moment.Moment;
  onChange?(value: IDateTimePickerValue): void;
  disabled?: boolean;
  disableTime?: boolean;
  enforceFuture?: boolean;
  showDateLabel?: boolean;
  range?: boolean;
  dateOnly?: boolean;
  style?: React.CSSProperties;
}

const DateAndTimePicker: React.FC<IProps> = (props) => {
  const [date, setDate] = useState<IDatePickerValue>(
    props.initialValue
      ? {
          allHaveValue: true,
          isDate: true,
          moment: props.initialValue,
          string: props.initialValue.format('DD/MM/YYYY'),
        }
      : null,
  );

  const [time, setTime] = useState<ITimePickerValue>(
    props.initialValue
      ? {
          allHaveValue: true,
          isTime: true,
          moment: props.initialValue,
          string: props.initialValue.format('HH:mm'),
        }
      : null,
  );

  const [endTime, setEndTime] = useState<ITimePickerValue>(
    props.initialEndValue
      ? {
          allHaveValue: true,
          isTime: true,
          moment: props.initialEndValue,
          string: props.initialEndValue.format('HH:mm'),
        }
      : null,
  );

  const [initialised, setInitialised] = useState(false);

  const validateDateTime = () => {
    if (props.range)
      return (
        time &&
        time.isTime &&
        time.allHaveValue &&
        date &&
        date.isDate &&
        date.allHaveValue &&
        endTime &&
        endTime.isTime &&
        endTime.allHaveValue
      );

    return time && time.isTime && time.allHaveValue && date && date.isDate && date.allHaveValue;
  };

  useEffect(() => {
    const isValidDateTime = validateDateTime();
    const mo = isValidDateTime && moment(date.moment.format('YYYY-MM-DD') + ' ' + time.moment.format('HH:mm'));
    const isFuture = isValidDateTime && mo.isAfter();

    const response: IDateTimePickerValue = {
      allHaveValue: date && time && date.allHaveValue && time.allHaveValue,
      isDateTime: date && time && date.isDate && time.isTime,
      moment: isValidDateTime ? mo : null,
      isFuture: isFuture,
      date: date,
      time: time,
      endTime: endTime,
      isRange: props.range,
    };

    if (initialised) {
      props.onChange(response);
    }
    setInitialised(true);
  }, [date, time, endTime]);

  const [showEndTime, setShowEndTime] = useState(false);

  useEffect(() => {
    if (props.range) {
      if (!endTime) {
        setEndTime({
          allHaveValue: true,
          isTime: true,
          moment: moment(time.moment).add(1, 'h'),
          string: moment(time.moment).add(1, 'h').format('HH:mm'),
        });
      }
      setShowEndTime(true);
    } else {
      setShowEndTime(false);
    }
  }, [props.range]);

  const isValidDateTime = validateDateTime();
  const isFuture =
    isValidDateTime && moment(date.moment.format('YYYY-MM-DD') + ' ' + time.moment.format('HH:mm')).isAfter();

  const dateFormatted =
    isValidDateTime && (isFuture || !props.enforceFuture) ? (
      props.showDateLabel && (
        <>
          {date.moment.format('dddd Do MMMM YYYY')} at {time.moment.format('h:mma')}
        </>
      )
    ) : !isFuture && props.enforceFuture ? (
      <div className="info">
        <strong>Warning: </strong>Please enter a date in the future
      </div>
    ) : (
      <div className="info">
        <strong>Warning:</strong> Please enter a valid date and time
      </div>
    );

  return (
    <>
      <div className="date-time-picker-container" style={props.style}>
        <table className="date-time-picker">
          <tbody>
            <tr>
              <td style={{ width: props.range ? '45%' : '60%', paddingRight: '8px' }}>
                <BlockDatePicker
                  disabled={props.disabled}
                  initialValue={props.initialValue}
                  groupName={`DateTime${props.groupName}`}
                  onChange={(_date) => {
                    setDate(_date);
                  }}
                />
              </td>
              {!props.dateOnly && (
                <>
                  <td style={{ width: props.range ? '25%' : '40%', paddingLeft: '8px' }}>
                    <BlockTimePicker
                      disabled={props.disabled}
                      initialValue={props.initialValue}
                      groupName={`DateTime${props.groupName}`}
                      onChange={(_time) => {
                        setTime(_time);
                      }}
                    />
                  </td>{' '}
                  {showEndTime && (
                    <>
                      <td style={{ width: '5%', paddingLeft: '8px' }}>-</td>
                      <td style={{ width: '25%', paddingLeft: '2px' }}>
                        <BlockTimePicker
                          disabled={props.disabled}
                          initialValue={endTime.moment}
                          groupName={`DateTimeEnd${props.groupName}`}
                          onChange={(_time) => {
                            setEndTime(_time);
                          }}
                        />
                      </td>
                    </>
                  )}
                </>
              )}
            </tr>
          </tbody>
        </table>

        <div style={{ fontSize: '14px', padding: '0', paddingTop: '6px', fontStyle: 'italic' }}>{dateFormatted}</div>
      </div>
    </>
  );
};

export default DateAndTimePicker;

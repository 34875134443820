import './Fees.scss';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import EnvironmentVariables from '../../EnvironmentVariables';
import SVGHelper from '../../helpers/SVGHelper';

interface IProps {}

const FeesPage: React.FC<IProps> = (props) => {
  const [attendeePaysPassed, setAttendeePaysPassed] = useState('0');
  const [organiserGetsPassed, setOrganiserGetsPassed] = useState('0');
  const [attendeePaysAbsorbed, setAttendeePaysAbsorbed] = useState('0');
  const [organiserGetsAbsorbed, setOrganiserGetsAbsorbed] = useState('0');
  const [costPerTicket, setCostPerTicket] = useState(10);
  const [tickets, setTickets] = useState(2);
  const [total, setTotal] = useState('0');
  const [fee, setFee] = useState('0');
  const feePercentage = EnvironmentVariables.FEE_PERCENTAGE;
  const minimumFee = EnvironmentVariables.MIN_FEE / 100;

  const recalculateFees = function () {
    var _total = tickets * costPerTicket;
    var _fee = (_total / 100.0) * feePercentage;

    if (_fee < minimumFee) _fee = minimumFee;

    setTotal(_total.toFixed(2));
    setFee(_fee.toFixed(2));

    setAttendeePaysPassed((_total + _fee).toFixed(2));
    setOrganiserGetsPassed(_total.toFixed(2));

    setAttendeePaysAbsorbed(_total.toFixed(2));
    setOrganiserGetsAbsorbed((_total - _fee).toFixed(2));
  };

  useEffect(recalculateFees, [costPerTicket, tickets]);

  const plusTicket = function () {
    setTickets(tickets + 1);
  };

  const minusTicket = function () {
    if (tickets == 1) return;
    setTickets(tickets - 1);
  };

  const plusTicketValue = function () {
    setCostPerTicket(costPerTicket + 1);
  };

  const minusTicketValue = function () {
    if (costPerTicket == 1) return;
    setCostPerTicket(costPerTicket - 1);
  };

  return (
    <>
      <Helmet>
        <title>Seaty - Fees</title>
        <meta name="description" content={`A breakdown of the Seaty fee structure.`} />
      </Helmet>

      <Header />
      <div className="fees-page">
        <div
          className="fees-header"
          style={{ backgroundImage: `url('https://seatylive.blob.core.windows.net/images/Seats.jpg')` }}
        >
          <div className="chunky sub">We want you to know what you’ll pay.</div>
          <div className="chunky">Pricing that's transparent & simple.</div>
        </div>

        <div className="row boxes">
          <div className="col-sm-6 box">
            <div className="title">Included Services</div>

            <div className="fee">So whats free?</div>
            <div className="sub">More than you might think!</div>

            <div className="bullets">
              <div className="bullet">
                <img src={SVGHelper.get('Setup')} />
                <strong>Free</strong> event setup & management
              </div>
              <div className="bullet">
                <img src={SVGHelper.get('House')} />
                <strong>Free</strong> in-house ticket processing
              </div>
              <div className="bullet">
                <img src={SVGHelper.get('Help')} />
                <strong>Free</strong> help & support
              </div>
              <div className="bullet">
                <img src={SVGHelper.get('Discounts')} />
                Volume discounts for <strong>big</strong> sellers
              </div>
            </div>

            <a href="mailto:support@seaty.co.uk" className="modern bottom">
              Contact Us
            </a>
          </div>

          <div className="col-sm-6 box box-small right">
            <div className="title">Seaty Payment Processing</div>

            <div className="fee">{feePercentage}%</div>
            <div className="sub">Per paid online order</div>

            <div className="bullets">
              <div className="bullet">
                <img src={SVGHelper.get('Tax')} />
                Fee includes all UK taxes
              </div>
              <div className="bullet">
                <img src={SVGHelper.get('Order')} />
                No setup, monthly, or hidden fees
              </div>
              <div className="bullet">
                <img src={SVGHelper.get('Piggy')} />
                No additional fixed fee per ticket
              </div>
              <div className="bullet">
                <img src={SVGHelper.get('Report')} />
                Real-time order & fee reporting
              </div>
            </div>
            {/*
          <a href="#!" onClick={} data-bind="click: $root.registerViewModel.showRegisterDialog" className="modern bottom">
            Create Account
          </a> */}
          </div>
        </div>

        <div className="gray">
          <div className="bullets">
            <div className="bullet no-image">You can handle fees for your event in different ways.</div>
            <div className="bullet">
              <img src={SVGHelper.get('Pass')} />
              <label>Pass fees on</label>
              <div>
                The fee will be added to the cost of an order at time of purchase and your attendee will bear the cost
                of all service fees. You will get the full face value of the ticket.{' '}
              </div>
            </div>
            <div className="bullet">
              <img src={SVGHelper.get('Absorb')} />
              <label>Absorb fees</label>
              <div>
                The fee will be included in the price you set for the ticket. Your attendee's will see no additional
                fees when buying tickets and they will pay the face value. You will recive the face value after fees are
                deducted.
              </div>
            </div>
          </div>

          <svg className="diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="#fff" points="0,100 100,0 100,100" />
          </svg>
        </div>

        <div className="chunky sub" style={{ color: 'black', margin: 0 }}>
          How about an example?
        </div>
        <div className="chunky">Service Fee Calculator</div>

        <div className="row calculator">
          <div className="col-sm-6">
            <div className="info-row" style={{ textAlign: 'center', padding: 0 }}>
              <div>
                <span className="value">{tickets}</span>
                <span className="value-description">tickets</span>
              </div>
              <div className="buttons">
                <a onClick={minusTicket} href="#!">
                  <img src={SVGHelper.get('CollapseTree')} />
                </a>
                <a onClick={plusTicket} href="#!">
                  <img src={SVGHelper.get('ExpandTree')} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="info-row" style={{ textAlign: 'center' }}>
              <div>
                £<span className="value">{costPerTicket}</span>
                <span className="value-description">per ticket</span>
              </div>
              <div className="buttons">
                <a onClick={minusTicketValue} href="#!">
                  <img src={SVGHelper.get('CollapseTree')} />
                </a>
                <a onClick={plusTicketValue} href="#!">
                  <img src={SVGHelper.get('ExpandTree')} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row boxes fees" style={{ paddingBottom: '150px' }}>
          <div className="col-sm-6">
            <div className="box left no-bottom-button">
              <div className="title">Pass fees on</div>

              <div className="fee">
                £<span>{organiserGetsPassed}</span>
              </div>
              <div className="sub">total to you</div>

              <div className="bullets">
                <div className="bullet">
                  <img src={SVGHelper.get('Order')} />
                  Order price
                  <div className="value">
                    £<span>{total}</span>
                  </div>
                </div>
                <div className="bullet">
                  <img src={SVGHelper.get('Fee')} />
                  Fee
                  <div className="value">
                    £<span>{fee}</span>
                  </div>
                </div>
                <div className="bullet">
                  <img src={SVGHelper.get('Attendee')} />
                  Attendee pays
                  <div className="value">
                    £<span>{attendeePaysPassed}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="box right no-bottom-button">
              <div className="title">Absorb fees</div>

              <div className="fee">
                £<span>{organiserGetsAbsorbed}</span>
              </div>
              <div className="sub">total to you</div>

              <div className="bullets">
                <div className="bullet">
                  <img src={SVGHelper.get('Order')} />
                  Order price
                  <div className="value">
                    £<span>{total}</span>
                  </div>
                </div>
                <div className="bullet">
                  <img src={SVGHelper.get('Fee')} />
                  Fee
                  <div className="value">
                    £<span>{fee}</span>
                  </div>
                </div>
                <div className="bullet">
                  <img src={SVGHelper.get('Attendee')} />
                  Attendee pays
                  <div className="value">
                    £<span>{attendeePaysAbsorbed}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FeesPage;

export enum MailSendTo {
  AllEventAttendees = 0, // 👥 All Event Attendees
  SelectedEventAttendees = 1, // 🔍 Selected Event Attendees
  SelectedDateAttendees = 3, // 📅 Selected Date Attendees
  AllOrganisationMembers = 4, // 🏢 All Organisation Members
  SelectedOrganisationMemberTypes = 7, // 🏢 Selected Organisation Member Types
  AllOrganisationAdmins = 2, // 🛡️ All Organisation Admins
  AllOrganisationMarketingAttendees = 5, // 📢 All Organisation Marketing Attendees
  AllOrganisationSurveyAttendees = 6, // 📋 All Organisation Survey Attendees
  AllEventAdmins = 8, // 🛡️ All Event Admins (Seaty Super Admin Only)
}

export const MailSendToDescriptions: Record<MailSendTo, string> = {
  [MailSendTo.AllEventAdmins]: '🛡️ All Event Admins',
  [MailSendTo.AllEventAttendees]: '👥 All Event Attendees',
  [MailSendTo.SelectedDateAttendees]: '📅 Selected Event Date Attendees',
  [MailSendTo.SelectedEventAttendees]: '🔍 Selected Event Attendees',
  [MailSendTo.AllOrganisationMembers]: '🏢 All Organisation Members',
  [MailSendTo.SelectedOrganisationMemberTypes]: '🏢 Selected Organisation Member Types',
  [MailSendTo.AllOrganisationAdmins]: '🛡️ All Organisation Admins',
  [MailSendTo.AllOrganisationMarketingAttendees]: '📢 All Organisation Marketing Attendees',
  [MailSendTo.AllOrganisationSurveyAttendees]: '📋 All Organisation Survey Attendees',
};

export const MailSendToInfo: Record<MailSendTo, string> = {
  [MailSendTo.AllEventAdmins]: `Sends the message to all administrators of all events. This option is only available to Seaty Super Admins.`,
  [MailSendTo.AllEventAttendees]: `Sends the message to every attendee with an active ticket for this event. Cancelled tickets are excluded.`,
  [MailSendTo.SelectedDateAttendees]: `Sends the message only to attendees with an active ticket for a specified event date.`,
  [MailSendTo.SelectedEventAttendees]: `Sends the message to a selected group of event attendees chosen from a list.`,
  [MailSendTo.AllOrganisationMembers]: `Sends the message to every member of the organisation.`,
  [MailSendTo.SelectedOrganisationMemberTypes]: `Sends the message to a selected group of organisation members based on their membership type.`,
  [MailSendTo.AllOrganisationMarketingAttendees]: `Sends the message to all attendees that signed up for marketing from the organisation.`,
  [MailSendTo.AllOrganisationSurveyAttendees]: `Sends the message to all attendees that signed up to receive surveys from the organisation.`,
  [MailSendTo.AllOrganisationAdmins]: `Sends the message to all administrators in the organisation.`,
};

export const AllowEventSend: Record<MailSendTo, boolean> = {
  [MailSendTo.AllEventAdmins]: false,
  [MailSendTo.AllEventAttendees]: true,
  [MailSendTo.SelectedDateAttendees]: true,
  [MailSendTo.SelectedEventAttendees]: true,
  [MailSendTo.AllOrganisationMembers]: true,
  [MailSendTo.SelectedOrganisationMemberTypes]: true,
  [MailSendTo.AllOrganisationMarketingAttendees]: true,
  [MailSendTo.AllOrganisationSurveyAttendees]: true,
  [MailSendTo.AllOrganisationAdmins]: true,
};

export const AllowOrganisationSend: Record<MailSendTo, boolean> = {
  [MailSendTo.AllEventAdmins]: false,
  [MailSendTo.AllEventAttendees]: false,
  [MailSendTo.SelectedDateAttendees]: false,
  [MailSendTo.SelectedEventAttendees]: false,
  [MailSendTo.AllOrganisationMembers]: true,
  [MailSendTo.SelectedOrganisationMemberTypes]: true,
  [MailSendTo.AllOrganisationMarketingAttendees]: true,
  [MailSendTo.AllOrganisationSurveyAttendees]: true,
  [MailSendTo.AllOrganisationAdmins]: true,
};

import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../api/AdminApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import BlockTextBox from '../components/BlockTextBox';
import Button from '../components/Button';
import Modal from '../components/Modal';
import OrderDashboard from '../components/order_dashboard/OrderDashboard';
import SpacerTable from '../components/SpacerTable';
import DateHelper from '../helpers/DateHelper';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import { IEvent } from '../interfaces/IEvent';
import { IOrder } from '../interfaces/IOrder';

export interface IProps {
  event: IEvent;
  onClose?: () => void;
}

const OrderSearchModal = ((props) => {
  const { event } = props;
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  let [query, setQuery] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(null);
  let [timeoutId, setTimeoutId] = useState(null);
  const [results, setResults] = useState<Array<IOrder>>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    GAHelper.modal(`${event.EventTag} Order Search`);
  }, []);

  const handleSearchChanged = (value) => {
    query = value;
    setQuery(value);
    setResults(null);

    if (timeoutId) window.clearTimeout(timeoutId);

    setTimeoutId(
      window.setTimeout(function () {
        if (value != null && value.length > 1) {
          runSearch();
        }
      }, 500),
    );
  };

  const runSearch = () => {
    setLoadingMessage('Searching for orders...');
    setError(null);

    AdminApi.request('POST', '/api/OrderSearch', {
      eventId: props.event.Id,
      query: query.trim(),
      eventDateId: 0,
    })
      .then((result) => {
        if (result.Valid == true) {
          setLoadingMessage(null);
          setResults(result.Result);
        } else {
          setError(result.Message);
        }
      })
      .catch((message) => {
        alert(message);
        setLoadingMessage(null);
        setResults(null);
      });
  };

  var hasResults = results != null && results.length > 0;

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>

            {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
              <div>
                {event.Venue.Name}
                {event.Venue.Postcode && event.Venue.Postcode.length > 0 ? ', ' + event.Venue.Postcode : ''}
              </div>
            )}
          </SpacerTable>

          <div className="spacer" />

          {selectedOrderId ? (
            <>
              <OrderDashboard event={event} orderId={selectedOrderId} />
              <div className="spacer" />
              <SpacerTable>
                <Button onClick={() => setSelectedOrderId(null)} text={'Back to search'} />
              </SpacerTable>
            </>
          ) : (
            <>
              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockHeader>Search</BlockHeader>
                    <BlockTextBox
                      name={'search'}
                      type="text"
                      autoFocus
                      value={query}
                      placeholder={'Attendee name, email or order number'}
                      onChange={(e) => handleSearchChanged(e)}
                    />
                  </Block>
                  {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                </tbody>
              </table>

              {(loadingMessage || hasResults) && (
                <>
                  <div className="spacer-x2" />
                  <SpacerTable>
                    <h1>
                      {loadingMessage
                        ? loadingMessage
                        : hasResults &&
                          (results.length == 1
                            ? `Found order #${results[0].OrderId}`
                            : `Found ${results.length} orders`)}
                    </h1>
                  </SpacerTable>
                  <div className="spacer" />
                </>
              )}

              {hasResults && (
                <table className="blocks">
                  <tbody>
                    {linq
                      .from(results)
                      .orderByDescending((o) => o.OrderId)
                      .select((order) => {
                        return (
                          <Block
                            className="route"
                            onClick={() => setSelectedOrderId(order.OrderId)}
                            key={'result_' + order.OrderId}
                            id={'order' + order.OrderId}
                          >
                            <BlockHeader>Order #{order.OrderId}</BlockHeader>

                            <div>
                              {order.AttendeeName != null && order.AttendeeName.length > 1 ? (
                                <span>{order.AttendeeName}, </span>
                              ) : null}
                              {order.Email}
                            </div>

                            <div>
                              {order.Tickets} ticket{order.Tickets != 1 && 's'} on{' '}
                              {DateHelper.asDateAtTimeAmPm(order.EventDate)} for {props.event.CurrencySymbol}
                              {(order.Price / 100).toFixed(2)}
                            </div>
                          </Block>
                        );
                      })
                      .toArray()}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default OrderSearchModal;

import React, { useState } from 'react';
import { IOrganisation } from '../interfaces/IOrganisation';

interface IProps {
  organisation: IOrganisation;
}

const NeedHelp: React.FC<IProps> = (props: IProps) => {
  const organisation = props.organisation;

  return (
    <>
      <div className="strip-block">
        Need help?
        <div className="text">
          {(organisation.BoxOfficeEmail || organisation.BoxOfficeTel) && (
            <div>
              {organisation.BoxOfficeEmail && (
                <div style={{ marginBottom: '15px' }}>
                  For event and ticket questions, email the box office at{' '}
                  <a href={`mailto:${organisation.BoxOfficeEmail}`} target="_top">
                    {organisation.BoxOfficeEmail}
                  </a>
                </div>
              )}
              {organisation.BoxOfficeTel && (
                <div style={{ marginBottom: '15px' }}>Call the box office on {organisation.BoxOfficeTel}</div>
              )}
            </div>
          )}
          <div>
            For technical issues with the website, email us at{' '}
            <a className="bottomLink" href="mailto:support@seaty.co.uk">
              Support@Seaty.co.uk
            </a>
          </div>
        </div>
      </div>

      <div className="strip-block" style={{ background: 'white' }}>
        Setting up your own event?
        <div className="text">
          If you're looking for a simple ticketing system for your next event, look no further than Seaty!
          <div>
            <a href={`/Home/MoreInfo`}>Find out more</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NeedHelp;

import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import SpacerTable from '../SpacerTable';
import BlockInfo, { InfoType } from '../BlockInfo';
import Block from '../Block';
import BlockHeader from '../BlockHeader';
import Button from '../Button';
import BlockTextBox from '../BlockTextBox';
import EnvironmentVariables from '../../EnvironmentVariables';
import EmailHelper from '../../helpers/EmailHelper';
import LockService from '../../services/LockService';
import { IEventDate } from '../../interfaces/IEventDate';
import { ITicket } from '../../interfaces/ITicket';
import OrderTimerHelper from '../../helpers/OrderTimerHelper';
import Loader from '../Loader';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { IEvent } from '../../interfaces/IEvent';
import ThemeHelper from '../../helpers/ThemeHelper';
import GeneralApi from '../../api/GeneralApi';
import UserHelper from '../../helpers/UserHelper';
import { IOrder } from '../../interfaces/IOrder';
import linq from 'linq';
import StripeCompleteSummary from './StripeCompleteSummary';
import DateHelper from '../../helpers/DateHelper';
import { PaymentMethods } from '../../enums/PaymentMethods';

interface IProps {
  attendeeName: string;
  attendeeEmail: string;
  purchaseId: number;
  eventDateString: string;
  eventTimeString: string;
  eventTag: string;
  tickets: ITicket[];
  event: IEvent;
  organisation: IOrganisation;
  eventDate: IEventDate;
}

interface IPaymentIntent {
  amount: number;
  amount_details: any;
  automatic_payment_methods: any;
  canceled_at: any;
  cancellation_reason: any;
  capture_method: any;
  client_secret: string;
  confirmation_method: string;
  created: any;
  currency: any;
  description: any;
  id: any;
  last_payment_error: any;
  livemode: any;
  next_action: any;
  object: any;
  payment_method: any;
  payment_method_configuration_details: any;
  payment_method_types: any;
  processing: any;
  receipt_email: any;
  setup_future_usage: any;
  shipping: any;
  source: any;
  status: any;
}

const StripeCheckoutForm = (props: IProps) => {
  const { event } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [addressReady, setAddressReady] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [order, setOrder] = useState<IOrder>(null);
  const [error, setError] = useState(null);
  const [attendeeEmail, setAttendeeEmail] = useState<string>(props.attendeeEmail);
  const [attendeeEmailConfirmation, setAttendeeEmailConfirmation] = useState<string>(props.attendeeEmail);

  const requiresEmail = !props.attendeeEmail || props.attendeeEmail.length == 0;

  const [emailError, setEmailError] = useState(null);
  const [emailConfirmationError, setEmailConfirmationError] = useState(null);

  // const renderCompletedSection = () => {
  //   const ticketGuid = order.Guid;
  //   const ticketLink = `/Order/Tickets/${ticketGuid}`;

  //   return (
  //     <div className="spacer">
  //       <SpacerTable>
  //         <h1>Thank you!</h1>
  //         Your order has been completed, you can now go to your tickets. We have sent you a confirmation email.
  //       </SpacerTable>

  //       <div className="spacer" />

  //       {ticketLink && ticketGuid && ticketGuid.length > 0 && (
  //         <>
  //           <SpacerTable>
  //             <a href={ticketLink} className="button confirm large">
  //               View Tickets
  //             </a>
  //           </SpacerTable>
  //           <div className="spacer" />
  //         </>
  //       )}

  //       <StripeCompleteSummary
  //         orderAnswers={order.QuestionAnswers}
  //         eventDateFormatted={DateHelper.asDateAtTimeAmPm(props.eventDate.Date)}
  //         orderId={order.Id}
  //         attendeeName={order.AttendeeName}
  //         attendeeEmail={order.Email}
  //         paymentMethod={PaymentMethods.CardSeaty}
  //         tickets={order.Seats}
  //         event={event}
  //         organisation={event.Organisation}
  //         venue={event.Venue}
  //         ticketCategories={event.Categories}
  //         currency={event.CurrencySymbol}
  //       />

  //       <div className="spacer" />

  //       <SpacerTable>
  //         <a
  //           onClick={() => {
  //             props.onClose();
  //           }}
  //           className="button large"
  //         >
  //           Go to Event
  //         </a>
  //       </SpacerTable>
  //     </div>
  //   );
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setEmailConfirmationError(null);
    setEmailError(null);

    if (requiresEmail) {
      if (attendeeEmail.length == 0) {
        setError('You have not entered an email address');
        setEmailError('This field is incomplete.');
        (document.getElementById('emailaddress') as HTMLInputElement).select();
        return;
      }

      if (!EmailHelper.validate(attendeeEmail)) {
        setError('The email address that you have entered is not a valid email address.');
        setEmailError('Not a valid email address.');
        (document.getElementById('emailaddress') as HTMLInputElement).select();
        return;
      }

      if (
        attendeeEmailConfirmation.length == 0 ||
        attendeeEmailConfirmation.toLowerCase() !== attendeeEmail.toLowerCase()
      ) {
        setEmailConfirmationError('Does not match email.');
        setError('Email confirmation does not match the email field.');
        (document.getElementById('emailConfirmation') as HTMLInputElement).select();
        return;
      }
    }

    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      setError('Stripe not initialised.');
      return;
    }

    const addressElement = elements.getElement('address');
    const { complete, value } = await addressElement.getValue();

    if (!complete) {
      setError('Address field is not complete.');
      setLoading(false);
      return;
    }

    const lock = await LockService.LockTickets(props.eventDate.Id, props.tickets, null, false, props.purchaseId);
    OrderTimerHelper.reset();

    if (!lock || !lock.AllAvailable) {
      setError('Tickets are no longer available and could not be locked.');
      setLoading(false);
      return;
    }

    const returnUrl = `${EnvironmentVariables.HOME_URL}/${props.eventTag}/tickets/${props.eventDateString}/${props.eventTimeString}/CompleteOrder/${props.purchaseId}`;

    const result: any = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: {
            email: attendeeEmail,
          },
        },
      },
    });

    if (result.error) {
      if (result.error.message) {
        setError(result.error.message);
      } else {
        setError('An unknown error has occurred.');
      }
      setLoading(false);
    } else {
      if (result.paymentIntent) {
        const intent: IPaymentIntent = result.paymentIntent as IPaymentIntent;

        if (intent.status == 'succeeded') {
          GeneralApi.request('POST', '/api/StripeComplete', {
            intent: intent.id,
            secret: intent.client_secret,
            purchaseId: props.purchaseId,
            sessionId: UserHelper.getSessionId(),
          })
            .then((response) => {
              if (!response.Success) {
                if (response.Message) {
                  setError(response.Message);
                  setLoading(false);
                } else {
                  setError('An unknown error has happened. Code: Unknown 450');
                  setLoading(false);
                }
              } else if (response.Order) {
                window.location.href = `${EnvironmentVariables.HOME_URL}/${props.eventTag}/tickets/${props.eventDateString}/${props.eventTimeString}/CompleteOrder/${props.purchaseId}?mode=if_required&payment_intent=${intent.id}&payment_intent_client_secret=${intent.client_secret}&redirect_status=succeeded`;
              } else {
                setError('No order in the response. ');
                setLoading(false);
              }
            })
            .catch((message) => {
              setError(
                'Payment was not successful, please contact support@seaty.co.uk if you experience technical difficulties.',
              );
              setLoading(false);
              return Promise.reject(message);
            });
        } else {
          setError('Payment was not successful.');
          setLoading(false);
        }
      } else {
        setError('Payment did not complete correctly.');
        setLoading(false);
      }
    }
  };

  const allowedCountries = [];

  if (props.organisation && props.organisation.Currency && props.organisation.Currency.StripeCountry) {
    allowedCountries.push(props.organisation.Currency.StripeCountry);
  } else {
    allowedCountries.push('GB');
  }

  return (
    <>
      {(!addressReady || !paymentReady) && (
        <Loader theme={ThemeHelper.getEventTheme(props.event)}>Starting payment...</Loader>
      )}

      <form onSubmit={handleSubmit}>
        <table className="blocks">
          <tbody>
            {requiresEmail ? (
              <>
                <Block>
                  <BlockHeader>Email</BlockHeader>
                  <BlockTextBox
                    name="email"
                    error={emailError}
                    id="emailaddress"
                    placeholder="Email address"
                    autoFocus={true}
                    type="email"
                    value={attendeeEmail}
                    onChange={(t) => {
                      setAttendeeEmail(t);
                      setEmailError(null);
                      setEmailConfirmationError(null);
                    }}
                  />
                  <div className="spacer-x05" />
                  <BlockHeader>Confirm email</BlockHeader>
                  <BlockTextBox
                    name="confirmEmail"
                    error={emailConfirmationError}
                    placeholder="Confirm email address"
                    type="email"
                    id="emailConfirmation"
                    value={attendeeEmailConfirmation}
                    onChange={(t) => {
                      setAttendeeEmailConfirmation(t);
                      setEmailError(null);
                      setEmailConfirmationError(null);
                    }}
                  />
                </Block>
                {attendeeEmail.length > 0 &&
                attendeeEmailConfirmation.length > 0 &&
                attendeeEmail.toLowerCase() !== attendeeEmailConfirmation.toLowerCase() ? (
                  <BlockInfo type={InfoType.Warning}>
                    Email confirmation does not match the email address entered. Please enter matching email addresses
                    so we can ensure we sent the tickets to the correct person.
                  </BlockInfo>
                ) : (
                  <BlockInfo type={InfoType.Info}>
                    A booking confirmation with a link to your tickets will be sent to the above email address.
                  </BlockInfo>
                )}
              </>
            ) : (
              <>
                <Block>
                  <BlockHeader>Email</BlockHeader>
                  <BlockTextBox
                    name={'email'}
                    type="email"
                    autoComplete="off"
                    id="emailaddress"
                    disabled={true}
                    value={attendeeEmail}
                  />
                </Block>
              </>
            )}
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block>
              <div className="address">
                <AddressElement
                  onReady={() => setAddressReady(true)}
                  options={{
                    mode: 'billing',
                    allowedCountries: allowedCountries,
                    defaultValues: {
                      name: props.attendeeName ? props.attendeeName : '',
                    },
                    // fields: {
                    //   phone: 'always',
                    // },
                    // validation: {
                    //   phone: {
                    //     required: 'always',
                    //   },
                    // },
                  }}
                />
              </div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block>
              <PaymentElement onReady={() => setPaymentReady(true)} />
            </Block>
          </tbody>
        </table>

        {error && (
          <>
            <div className="spacer" />
            <table className="blocks">
              <tbody>
                <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
              </tbody>
            </table>
          </>
        )}

        <div className="spacer-x2" />
        <SpacerTable>
          <Button loading={loading} disabled={!stripe || loading} className="confirm large" text={'Buy Tickets'} />
        </SpacerTable>
      </form>
    </>
  );
};

export default StripeCheckoutForm;

export default class StringHelper {
  public static AddSWhenMany = (count, string) => {
    if (count != 1) {
      string = string + 's';
    }
    return string;
  };

  public static hasSpecialCharacters(value: string) {
    const hasSpecials = /^[a-zA-Z0-9- ]*$/.test(value);
    const hasSpaces = value.indexOf(' ') >= 0;
    return hasSpaces || !hasSpecials;
  }

  public static replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  public static toCamelCase(str: string): string {
    return str
      .replace(/[^a-zA-Z0-9\s]/g, '') // Remove special characters
      .toLowerCase()
      .split(' ')
      .map((word, index) => {
        if (index === 0) return word; // Keep the first word in lowercase
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');
  }
}

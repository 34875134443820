import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import DateAndTimePicker from '../../../components/DateAndTimePicker';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import DateHelper from '../../../helpers/DateHelper';
import UserHelper from '../../../helpers/UserHelper';
import { EventDateTimeType, IEventDate } from '../../../interfaces/IEventDate';
import SVGAsterisk from '../../../svg/SVGAsterisk';
import SVGClosedCaptioning from '../../../svg/SVGClosedCaptioning';
import SVGCopy from '../../../svg/SVGCopy';
import SVGDown from '../../../svg/SVGDown';
import SVGExternal from '../../../svg/SVGExternal';
import SVGInfoSimple from '../../../svg/SVGInfoSimple';
import SVGSigned from '../../../svg/SVGSigned';
import SVGSuspended from '../../../svg/SVGSuspended';
import SVGTrash from '../../../svg/SVGTrash';
import Dropdown from '../../../components/Dropdown';
import { IDropDownItem } from '../../../components/DropdownItem';
import SVGOutlineDates from '../../../svg/outline/SVGOutlineDates';
import SVGClock from '../../../svg/SVGClock';
import DatePicker from '../../../components/DatePicker';

export interface IProps {
  date: IEventDate;
  onChange: (date) => void;
  onDeleteClick: (date) => void;
  onCopyClick: (date) => void;
  showAll?: boolean;
}

const DateAndTimeListItem: React.FC<IProps> = (props) => {
  const showAll = props.showAll;

  const handleDeleteClick = function () {
    if (props.onDeleteClick) {
      props.onDeleteClick(props.date);
    }
  };

  const [state, setState] = useState({
    moment: moment(props.date.DateAsString),
    endMoment:
      props.date.TimeType == EventDateTimeType.TimeRange || props.date.TimeType == EventDateTimeType.DateRange
        ? moment(props.date.EndDateAsString)
        : null,
    expanded: false,
  });

  var active = !props.date.Suspend;
  var signed = props.date.Signed;
  var captioned = props.date.Captioned;
  var changeMade = props.date.ChangeMade;

  var { date } = props;

  useEffect(() => {
    if (date.TimeType == null) {
      props.onChange({ ...date, TimeType: EventDateTimeType.SingleTime });
    }
  }, []);

  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-sm-8">
            <div className="flex_from_left">
              <div>
                <DateAndTimePicker
                  style={{
                    maxWidth:
                      date.TimeType == EventDateTimeType.SingleTime
                        ? '300px'
                        : date.TimeType == EventDateTimeType.TimeRange
                          ? '400px'
                          : date.TimeType == EventDateTimeType.DateRange
                            ? '180px'
                            : date.TimeType == EventDateTimeType.AllDay
                              ? '180px'
                              : '400px',
                  }}
                  dateOnly={date.TimeType === EventDateTimeType.AllDay || date.TimeType === EventDateTimeType.DateRange}
                  range={date.TimeType === EventDateTimeType.TimeRange}
                  disabled={props.date.HasOrders && UserHelper.currentUser.Id !== 1}
                  key={'event_date_list_item_' + props.date.Guid}
                  groupName={'event_date_list_item_' + props.date.Guid}
                  initialValue={state.moment}
                  initialEndValue={state.endMoment}
                  onChange={(dateTime) => {
                    if (dateTime && dateTime.isDateTime && dateTime.moment) {
                      setState({ ...state, moment: dateTime.moment });

                      if (date.TimeType == EventDateTimeType.TimeRange && dateTime.endTime && dateTime.endTime.moment) {
                        setState({ ...state, endMoment: dateTime.endTime.moment });
                        props.onChange({
                          ...props.date,
                          DateAsString: dateTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS'),
                          EndDateAsString: dateTime.endTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS'),
                        });
                      } else {
                        props.onChange({
                          ...props.date,
                          DateAsString: dateTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS'),
                        });
                      }
                    }
                  }}
                />
              </div>
              {date.TimeType == EventDateTimeType.DateRange && (
                <div style={{ padding: '10px 0', paddingRight: '7px' }}>-</div>
              )}
              {date.TimeType === EventDateTimeType.DateRange && (
                <div>
                  <DateAndTimePicker
                    style={{ maxWidth: '180px' }}
                    dateOnly={true}
                    disabled={props.date.HasOrders && UserHelper.currentUser.Id !== 1}
                    key={'event_date_list_item_end_' + props.date.Guid}
                    groupName={'event_date_list_item_endgroup_' + props.date.Guid}
                    initialValue={state.endMoment}
                    onChange={(dateTime) => {
                      if (dateTime && dateTime.isDateTime && dateTime.moment) {
                        setState({ ...state, endMoment: dateTime.moment });
                        props.onChange({
                          ...props.date,
                          EndDateAsString: dateTime.moment.format('YYYY-MM-DD HH:mm:ss.SSS'),
                        });
                      }
                    }}
                  />
                </div>
              )}
            </div>

            {date.TimeType == EventDateTimeType.TimeRange && state.moment.isAfter(state.endMoment) && (
              <div className="info">
                <strong>Warning:</strong> The end time you have entered is before the start time.
              </div>
            )}
            {date.TimeType == EventDateTimeType.DateRange && state.moment.isAfter(state.endMoment) && (
              <div className="info">
                <strong>Warning:</strong> The end date you have entered is before the start date.
              </div>
            )}
          </div>
          <div className="col-sm-4 flex_from_right">
            {!showAll && (
              <>
                <button
                  style={{ marginLeft: '5px' }}
                  className={`admin-button icon ${state.expanded ? 'flip' : ''}`}
                  onClick={(e) => setState({ ...state, expanded: !state.expanded })}
                >
                  <SVGDown />
                </button>

                <button
                  style={{ marginLeft: '5px' }}
                  className={`admin-button icon`}
                  onClick={() => props.onCopyClick(props.date)}
                >
                  <SVGCopy />
                </button>

                {!props.date.HasOrders && (
                  <button style={{ marginLeft: '5px' }} className="admin-button icon bad" onClick={handleDeleteClick}>
                    <SVGTrash />
                  </button>
                )}
              </>
            )}

            {changeMade && (
              <div className="status-icon">
                <SVGAsterisk />
              </div>
            )}
            {props.date.Suspend && (
              <div className="status-icon">
                <SVGSuspended />
              </div>
            )}
            {props.date.Captioned && (
              <div className="status-icon">
                <SVGClosedCaptioning />
              </div>
            )}
            {props.date.Signed && (
              <div className="status-icon">
                <SVGSigned />
              </div>
            )}
            {((props.date.Information && props.date.Information.length > 0) ||
              (props.date.InformationHeader && props.date.InformationHeader.length > 0)) && (
              <div className="status-icon">
                <SVGInfoSimple />
              </div>
            )}
            {props.date.External && (
              <div className="status-icon">
                <SVGExternal />
              </div>
            )}
          </div>
        </div>

        {!state.expanded ? null : (
          <>
            {props.date.HasOrders ? (
              <div className="info" style={{ marginTop: '25px' }}>
                <strong>Existing orders</strong>
                <br />
                Event dates cannot be changed once orders have been placed. Please contact Seaty support if you need to
                update the date of your event.
              </div>
            ) : null}
            <div className="row">
              <div className="col-sm-8" style={{ padding: '10px 15px' }}>
                <div className="section">
                  <InfoLabel
                    text="What type of date time do you want to use?"
                    tooltip="Select the type of time that will be displayed to the attendee."
                  />
                  <Dropdown
                    onChange={(e: IDropDownItem) => {
                      if (e.value == EventDateTimeType.DateRange) {
                        setState({ ...state, endMoment: state.moment.clone().add(1, 'days') });
                        props.onChange({
                          ...props.date,
                          TimeType: e.value,
                          EndDateAsString: state.moment.clone().add(1, 'days').format('YYYY-MM-DD HH:mm:ss.SSS'),
                        });
                      } else if (e.value == EventDateTimeType.TimeRange) {
                        setState({ ...state, endMoment: state.moment.clone().add(1, 'hours') });
                        props.onChange({
                          ...props.date,
                          TimeType: e.value,
                          EndDateAsString: state.moment.clone().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss.SSS'),
                        });
                      } else {
                        props.onChange({
                          ...props.date,
                          TimeType: e.value,
                        });
                      }
                    }}
                    value={date.TimeType}
                    items={[
                      {
                        description: 'Single time',
                        value: EventDateTimeType.SingleTime,
                        info: `⏰ This option displays just one time slot for your event. It’s perfect when your event kicks off at a specific moment (like 3:00 PM) and you don’t need to show a duration. Just one time, one start!`,
                      },
                      {
                        description: 'Time range',
                        value: EventDateTimeType.TimeRange,
                        info: `🔄 With a time range, you can show both a start and an end time (for example, 3:00 PM – 5:00 PM). It’s great for events that span a specific period, so your attendees know exactly when to arrive and when the event wraps up.`,
                      },
                      {
                        description: 'Day range',
                        value: EventDateTimeType.DateRange,
                        info: `📅 This option is perfect when your event spans across multiple consecutive days. Instead of just one day, you can show a start date and an end date (for example, June 20 – June 23). `,
                      },
                      {
                        description: 'All day',
                        value: EventDateTimeType.AllDay,
                        info: `🌞 Choose All Day when your event lasts the entire day and doesn’t have specific start or end times. This is ideal for full-day festivities or open houses. Just note any important arrival instructions in the event description!`,
                      },
                    ]}
                    description={null}
                  />
                </div>
                <div className="section">
                  <InfoLabel
                    icon={<SVGSuspended />}
                    text="Is this date active?"
                    tooltip="If you suspend an event date it will only be accessible by an administrator and the general public will no longer be able to create new orders."
                  ></InfoLabel>
                  <Switch
                    trueLabel="Yes, this date is active"
                    falseLabel="No, suspend this date"
                    onChange={(e) => props.onChange({ ...props.date, Suspend: !e })}
                    checked={active}
                  />
                </div>
              </div>
              <div className="col-sm-8" style={{ padding: '10px 15px' }}>
                <div className="section">
                  <InfoLabel
                    icon={<SVGSigned />}
                    text="Is this a signed date?"
                    tooltip="If this event date is signed we will put additional information to tell attendees on your event date page."
                  ></InfoLabel>
                  <Switch
                    trueLabel="Yes, this is a signed date"
                    falseLabel="No, this date is not signed"
                    onChange={(e) => props.onChange({ ...props.date, Signed: e })}
                    checked={signed}
                  />
                </div>
              </div>
              <div className="col-sm-8" style={{ padding: '10px 15px' }}>
                <div className="section">
                  <InfoLabel
                    icon={<SVGClosedCaptioning />}
                    text="Does this date use closed captioning?"
                    tooltip="If this event date uses closed captioning we will put additional information to tell attendees on your event date page."
                  ></InfoLabel>
                  <Switch
                    trueLabel="Yes, uses closed captioning"
                    falseLabel="No closed captioning"
                    onChange={(e) => props.onChange({ ...props.date, Captioned: e })}
                    checked={captioned}
                  />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="section event-information">
                  <div className="section">
                    <InfoLabel
                      text="Any additional info for this date?"
                      tooltip="This is an optional field. You can enter some additional information that will appear at the top of the page for this event date."
                    ></InfoLabel>
                    <InfoInput
                      labelText="Title"
                      maxLength={100}
                      onChange={(value) => props.onChange({ ...props.date, InformationHeader: value })}
                      value={props.date.InformationHeader}
                    />
                    <InfoInput
                      rows={3}
                      labelText="Message"
                      onChange={(value) => props.onChange({ ...props.date, Information: value })}
                      value={props.date.Information}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="section event-information">
                  <div className="section">
                    <InfoLabel
                      icon={<SVGExternal />}
                      text="Is this an external event date?"
                      tooltip="External event dates are those that are being handles by another ticketing provider. Here at Seaty we believe in cooperation and friendship, so we are happy to help take your attendees to an external site if that helps you better run your events. We will present this event date and take the attendees wherever you specify in the URL that will appear when you select yes."
                    ></InfoLabel>
                    <Switch
                      trueLabel="Yes, link to an external site"
                      falseLabel="No, let Seaty provide ticketing serivces"
                      onChange={(e) => props.onChange({ ...props.date, External: e })}
                      checked={props.date.External}
                    />
                    {props.date.External && (
                      <InfoInput
                        labelText="External URL"
                        maxLength={500}
                        onChange={(value) => props.onChange({ ...props.date, ExternalUrl: value })}
                        value={props.date.ExternalUrl ?? ''}
                      />
                    )}
                  </div>
                </div>
              </div>
              {props.date.HasOrders ? null : (
                <div style={{ paddingTop: '20px' }} className="col-sm-12">
                  <button className="admin-button bad" onClick={handleDeleteClick}>
                    Delete - {state.moment && DateHelper.asDateAtTimeAmPmFromMoment(state.moment)}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DateAndTimeListItem;

export interface IEventType {
  name: EventType;
  description: string;
  emoji: string;
}

export enum EventType {
  Musical = 'Musical',
  Concert = 'Concert',
  Theatre = 'Theatre',
  Comedy = 'Comedy',
  Dance = 'Dance',
  Opera = 'Opera',
  Festival = 'Festival',
  Exhibition = 'Exhibition',
  Conference = 'Conference',
  Seminar = 'Seminar',
  Workshop = 'Workshop',
  TradeShow = 'Trade Show',
  Convention = 'Convention',
  Sports = 'Sports',
  FootballMatch = 'Football Match',
  BasketballGame = 'Basketball Game',
  TennisTournament = 'Tennis Tournament',
  Marathon = 'Marathon',
  EsportsTournament = 'Esports Tournament',
  CharityEvent = 'Charity Event',
  Gala = 'Gala',
  AwardCeremony = 'Award Ceremony',
  StandUpComedy = 'Stand-Up Comedy',
  FilmScreening = 'Film Screening',
  MoviePremiere = 'Movie Premiere',
  FoodFestival = 'Food Festival',
  WineTasting = 'Wine Tasting',
  OutdoorEvent = 'Outdoor Event',
  Fair = 'Fair',
  Circus = 'Circus',
  Airshow = 'Airshow',
  AutoShow = 'Auto Show',
  ScienceExpo = 'Science Expo',
  BookReading = 'Book Reading',
  PoetrySlam = 'Poetry Slam',
  MagicShow = 'Magic Show',
  EscapeRoom = 'Escape Room',
  GamingConvention = 'Gaming Convention',
  CulturalFestival = 'Cultural Festival',
  ReligiousGathering = 'Religious Gathering',
  HolidayCelebration = 'Holiday Celebration',
  FireworksShow = 'Fireworks Show',
  CommunityEvent = 'Community Event',
  Parade = 'Parade',
  Carnival = 'Carnival',
  Rodeo = 'Rodeo',
  WrestlingMatch = 'Wrestling Match',
  MixedMartialArts = 'Mixed Martial Arts',
  HorseRacing = 'Horse Racing',
  Other = 'Other',
}

const EventTypes: Record<EventType, IEventType> = {
  [EventType.Musical]: {
    name: EventType.Musical,
    description: 'A live performance featuring singing, dancing, and acting.',
    emoji: '🎭',
  },
  [EventType.Concert]: {
    name: EventType.Concert,
    description: 'A live music event featuring artists or bands.',
    emoji: '🎤',
  },
  [EventType.Theatre]: {
    name: EventType.Theatre,
    description: 'A dramatic performance of a play or scripted production.',
    emoji: '🎭',
  },
  [EventType.Comedy]: {
    name: EventType.Comedy,
    description: 'A live comedy show with stand-up or improv performances.',
    emoji: '😂',
  },
  [EventType.Dance]: {
    name: EventType.Dance,
    description: 'A performance event showcasing various dance styles.',
    emoji: '💃',
  },
  [EventType.Opera]: {
    name: EventType.Opera,
    description: 'A theatrical performance combining music, singing, and drama.',
    emoji: '🎼',
  },
  [EventType.Festival]: {
    name: EventType.Festival,
    description: 'A large-scale event celebrating music, culture, or arts.',
    emoji: '🎉',
  },
  [EventType.Exhibition]: {
    name: EventType.Exhibition,
    description: 'A public display of art, science, or trade-related products.',
    emoji: '🖼️',
  },
  [EventType.Conference]: {
    name: EventType.Conference,
    description: 'A formal gathering discussing a specific topic or industry.',
    emoji: '🎤',
  },
  [EventType.Seminar]: {
    name: EventType.Seminar,
    description: 'An educational event featuring discussions and presentations.',
    emoji: '📚',
  },
  [EventType.Workshop]: {
    name: EventType.Workshop,
    description: 'A hands-on training session or educational event.',
    emoji: '🛠️',
  },
  [EventType.TradeShow]: {
    name: EventType.TradeShow,
    description: 'An exhibition where businesses showcase their products.',
    emoji: '🏢',
  },
  [EventType.StandUpComedy]: {
    name: EventType.StandUpComedy,
    description: 'A comedic performance by a stand-up comedian.',
    emoji: '🎙️',
  },
  [EventType.FilmScreening]: {
    name: EventType.FilmScreening,
    description: 'A public showing of a movie or documentary.',
    emoji: '🎬',
  },
  [EventType.MoviePremiere]: {
    name: EventType.MoviePremiere,
    description: 'A special first screening of a newly released film.',
    emoji: '🌟',
  },
  [EventType.FoodFestival]: {
    name: EventType.FoodFestival,
    description: 'A gathering showcasing different foods and cuisines.',
    emoji: '🍔',
  },
  [EventType.OutdoorEvent]: {
    name: EventType.OutdoorEvent,
    description: 'An event held in an open-air setting.',
    emoji: '🏕️',
  },
  [EventType.HolidayCelebration]: {
    name: EventType.HolidayCelebration,
    description: 'A festival or party celebrating a holiday occasion.',
    emoji: '🎄',
  },
  [EventType.FireworksShow]: {
    name: EventType.FireworksShow,
    description: 'A public display of fireworks for celebration.',
    emoji: '🎆',
  },
  [EventType.Parade]: {
    name: EventType.Parade,
    description: 'A festive procession featuring floats and performers.',
    emoji: '🥁',
  },
  [EventType.Carnival]: {
    name: EventType.Carnival,
    description: 'A lively festival with rides, games, and performances.',
    emoji: '🎪',
  },
  [EventType.HorseRacing]: {
    name: EventType.HorseRacing,
    description: 'A competitive equestrian sport involving racehorses.',
    emoji: '🏇',
  },
  [EventType.Convention]: {
    name: EventType.Convention,
    description: 'A large-scale gathering for professionals or enthusiasts.',
    emoji: '🏛️',
  },
  [EventType.Sports]: {
    name: EventType.Sports,
    description: 'A live sports event featuring teams or individual athletes.',
    emoji: '🏆',
  },
  [EventType.FootballMatch]: {
    name: EventType.FootballMatch,
    description: 'A live football (soccer) match between competing teams.',
    emoji: '⚽',
  },
  [EventType.BasketballGame]: {
    name: EventType.BasketballGame,
    description: 'A live basketball game featuring competitive teams.',
    emoji: '🏀',
  },
  [EventType.TennisTournament]: {
    name: EventType.TennisTournament,
    description: 'A competitive tennis event featuring top players.',
    emoji: '🎾',
  },
  [EventType.Marathon]: {
    name: EventType.Marathon,
    description: 'A long-distance running race open to participants.',
    emoji: '🏃',
  },
  [EventType.EsportsTournament]: {
    name: EventType.EsportsTournament,
    description: 'A competitive gaming event for professional players.',
    emoji: '🎮',
  },
  [EventType.CharityEvent]: {
    name: EventType.CharityEvent,
    description: 'An event organized to raise funds for a good cause.',
    emoji: '❤️',
  },
  [EventType.Gala]: {
    name: EventType.Gala,
    description: 'A formal event featuring entertainment and dining.',
    emoji: '🎩',
  },
  [EventType.AwardCeremony]: {
    name: EventType.AwardCeremony,
    description: 'An event recognizing outstanding achievements.',
    emoji: '🏅',
  },
  [EventType.WineTasting]: {
    name: EventType.WineTasting,
    description: 'An event focused on sampling and appreciating wines.',
    emoji: '🍷',
  },
  [EventType.Fair]: {
    name: EventType.Fair,
    description: 'A community event featuring rides, games, and food.',
    emoji: '🎡',
  },
  [EventType.Circus]: {
    name: EventType.Circus,
    description: 'A traveling show featuring acrobats, clowns, and performers.',
    emoji: '🤹',
  },
  [EventType.Airshow]: {
    name: EventType.Airshow,
    description: 'A public display of aerial stunts and aircraft.',
    emoji: '✈️',
  },
  [EventType.AutoShow]: {
    name: EventType.AutoShow,
    description: 'An exhibition showcasing the latest automobiles.',
    emoji: '🚗',
  },
  [EventType.ScienceExpo]: {
    name: EventType.ScienceExpo,
    description: 'An exhibition highlighting scientific innovations and discoveries.',
    emoji: '🔬',
  },
  [EventType.BookReading]: {
    name: EventType.BookReading,
    description: 'A live reading session by an author or speaker.',
    emoji: '📖',
  },
  [EventType.PoetrySlam]: {
    name: EventType.PoetrySlam,
    description: 'A live event featuring spoken word poetry performances.',
    emoji: '📝',
  },
  [EventType.MagicShow]: {
    name: EventType.MagicShow,
    description: 'A performance showcasing magic tricks and illusions.',
    emoji: '🪄',
  },
  [EventType.EscapeRoom]: {
    name: EventType.EscapeRoom,
    description: 'A team-based puzzle-solving adventure in a themed room.',
    emoji: '🔑',
  },
  [EventType.GamingConvention]: {
    name: EventType.GamingConvention,
    description: 'A convention dedicated to video games and gaming culture.',
    emoji: '🎮',
  },
  [EventType.CulturalFestival]: {
    name: EventType.CulturalFestival,
    description: 'An event celebrating cultural heritage and traditions.',
    emoji: '🌍',
  },
  [EventType.ReligiousGathering]: {
    name: EventType.ReligiousGathering,
    description: 'An event centered around worship and spiritual activities.',
    emoji: '⛪',
  },
  [EventType.CommunityEvent]: {
    name: EventType.CommunityEvent,
    description: 'A gathering designed to strengthen community bonds.',
    emoji: '🏡',
  },
  [EventType.Rodeo]: {
    name: EventType.Rodeo,
    description: 'A competitive event featuring rodeo sports like bull riding.',
    emoji: '🤠',
  },
  [EventType.WrestlingMatch]: {
    name: EventType.WrestlingMatch,
    description: 'A live wrestling competition featuring skilled athletes.',
    emoji: '🤼',
  },
  [EventType.MixedMartialArts]: {
    name: EventType.MixedMartialArts,
    description: 'A combat sports event combining various martial arts disciplines.',
    emoji: '🥋',
  },
  [EventType.Other]: {
    name: EventType.Other,
    description: 'A different event that is not listed.',
    emoji: '',
  },
};

export default EventTypes;

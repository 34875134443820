import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import loaderState, { LoaderHelper } from '../../atoms/loaderState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import SpacerTable from '../../components/SpacerTable';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import DateHelper from '../../helpers/DateHelper';
import DeviceHelper from '../../helpers/DeviceHelper';
import SeatingPlanHelper from '../../helpers/SeatingPlanHelper';
import StringHelper from '../../helpers/StringHelper';
import StripeHelper from '../../helpers/StripeHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import { IEventDateState } from '../../interfaces/IEventDateState';
import { ISeatingPlan } from '../../interfaces/ISeatingPlan';
import { ISeatingPlanSeat } from '../../interfaces/ISeatingPlanSeat';
import { ITicket } from '../../interfaces/ITicket';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import { AdminLockSeatsModal } from '../../modals/AdminLockSeatsModal';
import { DatesModal } from '../../modals/Dates/DatesModal';
import OrderModal from '../../modals/OrderModal';
import LockService from '../../services/LockService';
import SwapService from '../../services/SwapService';
import { ITicketAvailability } from '../../services/TicketService';
import SVGCheckout from '../../svg/SVGCheckout';
import SVGLock from '../../svg/SVGLock';
import SVGSwap from '../../svg/SVGSwap';
import SVGToggleOff from '../../svg/SVGToggleOff';
import SVGToggleOn from '../../svg/SVGToggleOn';
import AdminPage from '../Admin/AdminPage';
import EventHeader from './EventHeader';
import EventInfoBanner from './EventInfoBanner';
import './EventPage.scss';
import EventSeatingPlan from './EventSeatingPlan';
import EventWarnings from './EventWarnings';
import GeneralAdmissionTicketGroups from './GeneralAdmissionTicketGroups';
import PrivateEvent from './PrivateEvent';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import SVGGoArrow from '../../svg/SVGGoArrow';
import SVGGoBackArrow from '../../svg/SVGGoBackArrow';
import NumberHelper from '../../helpers/NumberHelper';
import SwapModal from '../../modals/SwapModal';
import AdminApi from '../../api/AdminApi';
import PixelHelper from '../../helpers/PixelHelper';
import MerchandiseProductGroups from './MerchandiseProductGroups';
import Button from '../../components/Button';
import ITicketSwap from '../../services/SwapService';
import './BookingPage.scss';
import SVGHelper from '../../helpers/SVGHelper';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import Block from '../../components/Block';
import Radio from '../../components/Radio';
import BlockHeader from '../../components/BlockHeader';
import { REFUND_SERVICE_FEE } from '../../svg_pan_zoom';

export interface IProps {}

const BookingPage: FunctionComponent<IProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = UserHelper.currentUser;
  const [event, setEvent] = useState<IEvent>();
  const [seatingPlan, setSeatingPlan] = useState<ISeatingPlan>();
  const [loadingInline, setLoadingInline] = useState<string>('Loading tickets...');
  const [eventDate, setEventDate] = useState<IEventDate>(null);
  const [isMember, setIsMember] = useState<boolean>(false);
  const [datePickerEvent, setDatePickerEvent] = useState<IEvent>(null);
  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
  const [ticketSwap, setTicketSwap] = useState<ITicketSwap>(null);
  const [showLockSeats, setShowLockSeats] = useState(false);
  const [privatePasswordConfirmed, setPrivatePasswordConfirmed] = useState(false);
  const [seatedLocks, setSeatedLocks] = useState<ITicketAvailability>(null);
  const [generalLocks, setGeneralLocks] = useState<ITicketAvailability[]>(null);
  const [currentEventDateState, setCurrentEventDateState] = useState<IEventDateState>(null);
  const [seatedTickets, setSeatedTickets] = useState<Array<ISeatingPlanSeat>>([]);
  const [generalAdmissionTickets, setGeneralAdmissionTickets] = useState<Array<ITicketCategory>>([]);
  let [showOrdersInSeatingPlan, setShowOrdersInSeatingPlan] = useState(false);
  const [showPriceList, setShowPriceList] = useState(false);
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [showGeneralAdmissionSection, setShowGeneralAdmissionSection] = useState(false);
  const [showExtrasDialog, setShowExtrasDialog] = useState(false);
  const [merchInCartWhenOpened, setMerchInCartWhenOpened] = useState(false);

  const isAdmin = event && UserHelper.isCurrentUserAdmin(event.Organisation);

  const maxGeneralAdmissionPrice =
    generalAdmissionTickets && generalAdmissionTickets.length > 0
      ? linq.from(generalAdmissionTickets).max((g) => g.PriceAsInt)
      : 0;
  const minGeneralAdmissionPrice =
    generalAdmissionTickets && generalAdmissionTickets.length > 0
      ? linq.from(generalAdmissionTickets).min((g) => g.PriceAsInt)
      : 0;

  const updateGeneralAdmissionTicketsState = (_event, eventDateState) => {
    setGeneralAdmissionTickets(
      eventDateState.GeneralAdmissionTickets
        ? eventDateState.GeneralAdmissionTickets.map((t) => {
            const categoryId = t.Id;
            const groupId = t.CategoryGroupId;

            const categoryGroup = linq.from(_event.UnallocatedCategoryGroups).firstOrDefault((c) => c.Id == groupId);
            const category = linq.from(categoryGroup.Categories).firstOrDefault((c) => c.Id == categoryId);

            return {
              ...t,
              Amount: 0,
              CategoryIndex: category.Index,
              GroupIndex: categoryGroup.Index,
              Category: category,
              Group: categoryGroup,
            };
          })
        : [],
    );
  };

  const getSPState = (
    _event: IEvent,
    _seatingPlan: ISeatingPlan,
    _eventDate: IEventDate,
    eventDateState: IEventDateState,
    _locks: ITicketAvailability = seatedLocks,
    selectSeats?: ISeatingPlanSeat[],
  ) => {
    if (!_event.UseSeatingPlan) return;

    const seatCategories = {};
    const _isAdmin = _event && UserHelper.isCurrentUserAdmin(_event.Organisation);
    const isSameDate = eventDate && _eventDate.Id == eventDate.Id;

    const newSeatingPlan = {
      ..._seatingPlan,
      Seats: [],
      Objects: _seatingPlan.Objects.map((o) => {
        return { ...o };
      }),
    };

    linq
      .from(newSeatingPlan.SeatCategories)
      .where((sc) => _eventDate.SeatCategoryIds.filter((id) => id === sc.Id).length > 0)
      .toArray()
      .forEach((s) => {
        seatCategories[s.Id] = s;
      });

    const updatedSeats = _seatingPlan.Seats.map((_seat) => {
      const seat = { ..._seat };

      const seatCategoryState = eventDateState.SeatStates[seat.SeatCategoryId];
      const groupState = seatCategoryState && seatCategoryState[seat.Group];
      const seatState = groupState && groupState[seat.Name];
      const lock = !_locks
        ? null
        : linq
            .from(_locks.Seats)
            .where((l) => l.Group == seat.Group && l.Name == seat.Name && l.SeatCategoryId == seat.SeatCategoryId)
            .firstOrDefault();

      seat.AvailableCategories = linq
        .from(_event.Categories)
        .where(
          (c) =>
            _eventDate.TicketCategoryIds.indexOf(c.Id) > -1 &&
            c.SeatCategory &&
            c.SeatCategory.Id == seat.SeatCategoryId &&
            (!c.Hide || _isAdmin),
        )
        .orderByDescending((c) => {
          return c.PriceAsInt;
        })
        .thenBy(function (c) {
          return c.Name;
        })
        .toArray();

      seat.Resale = seatState ? seatState.Resale : null;
      seat.OrderId = seatState ? seatState.OrderId : 0;
      seat.TicketCategoryId = seatState ? seatState.TicketCategoryId : 0;
      seat.RequestId = seatState ? seatState.RequestId : 0;
      seat.Locked = lock != null;

      seat.IsOrdered = seat.OrderId && seat.OrderId > 0;
      seat.IsRequested = seat.RequestId && seat.RequestId > 0;

      if (showOrdersInSeatingPlan && isAdmin) {
        seat.Disabled = seat.IsOrdered ? false : true;
      } else {
        seat.Disabled =
          (seatState ? seatState.Unavailable : false) || lock != null || seat.AvailableCategories.length == 0;
      }

      seat.SeatSet = seat.OrderId ? (seat.RequestId ? null : 'request' + seat.RequestId) : 'order' + seat.OrderId;
      seat.SeatCategory = seatCategories[seat.SeatCategoryId];

      if (isSameDate && selectSeats && selectSeats.length > 0)
        seat.Selected = selectSeats.filter((s) => s.Id == seat.Id).length > 0;

      return seat;
    });

    newSeatingPlan.Seats = updatedSeats;

    SeatingPlanHelper.refreshMiddleSeats(newSeatingPlan);

    return newSeatingPlan;
  };

  const getLocksFromCache = async (eventId, eventDateId, force) => {
    let eventDateLocks = await CacheHelper.locks(eventId, eventDateId, force);

    if (!eventDateLocks || eventDateLocks.length == 0 || !eventDateLocks.map) {
      return [];
    }

    return eventDateLocks.map((r) => {
      const interval = (r.LockInterval && r.LockInterval > 0 ? r.LockInterval : 360) + 45; // 45 second buffer time
      const lockedDate = moment.utc(r.DateLocked).add(interval, 'seconds').valueOf();
      const dateNow = moment.utc().valueOf();

      if (r.LockedByAdmin) {
        return { ...r, Expired: false };
      }
      if (lockedDate < dateNow) {
        return { ...r, Expired: true };
      }
      if (r.SessionId == UserHelper.getSessionId()) {
        return { ...r, Expired: true };
      }

      return { ...r, Expired: false };
    });
  };

  const loadEventDate = async (_event: IEvent, eventDateId: number, updateUrl: boolean) => {
    const originalSeatingPlanId = seatingPlan ? seatingPlan.Id : null;

    setSeatingPlan(null);
    setLoadingInline('Loading date...');
    setSeatedLocks(null);
    setEvent(_event);
    setEventDate(null);
    seatedTickets.forEach((s) => (s.Selected = false));
    setSeatedTickets([]);
    const eventDate = _event.Dates.filter((ed) => ed.Id === eventDateId)[0];

    if (updateUrl) {
      var newUrl = '/' + _event.EventTag + '/tickets/' + moment(eventDate.DateAsString).format('DDMMMYYYY/HHmm');

      if (window.location.href != newUrl) {
        navigate(newUrl);
      }
    }

    let eventDateLocks = await getLocksFromCache(_event.Id, eventDateId, false);
    let forceApi = false;
    let parsedActiveLocks = null;

    if (eventDateLocks.length == 0) {
      setSeatedLocks(null);
    } else {
      const expiredLocks = eventDateLocks.filter((l) => l.Expired);
      forceApi = expiredLocks && expiredLocks.length > 0;

      if (forceApi) {
        eventDateLocks = await getLocksFromCache(_event.Id, eventDateId, forceApi);
      }

      const validLocks = eventDateLocks.filter((l) => !l.Expired);

      setGeneralLocks(validLocks.filter((s: any) => s.SeatCategoryId == null || s.SeatCategoryId == 0));

      parsedActiveLocks = {
        ...validLocks[0],
        Seats: validLocks
          .filter((s: any) => s.SeatCategoryId != null && s.SeatCategoryId > 0)
          .map((s: any) => {
            const ticket: ITicket = {
              Name: s.SeatName,
              Group: s.GroupName,
              SeatCategoryId: s.SeatCategoryId,
              GeneralAdmission: s.TicketCategoryGroupId != null,
              TicketCategoryId: s.TicketCategoryGroupId,
            };
            return ticket;
          }),
      };

      setSeatedLocks(parsedActiveLocks);
    }

    const state = await CacheHelper.getEventDateState(_event.Id, eventDateId, forceApi)
      .then((eventDateState) => {
        updateGeneralAdmissionTicketsState(_event, eventDateState);
        setCurrentEventDateState(eventDateState);
        setIsMember(eventDateState.IsMember);
        setEvent(_event);
        setEventDate(eventDate);
        setDatePickerEvent(null);

        if (_event.UseSeatingPlan && _event.SeatingPlans && _event.SeatingPlans.length > 0) {
          if (!_event.SeatingPlanSectionsEnabled) {
            setSeatingPlan(getSPState(_event, _event.SeatingPlans[0], eventDate, eventDateState, parsedActiveLocks));
          } else {
            if (originalSeatingPlanId) {
              const matchingSeatingPlan = _event.SeatingPlans.find((s) => s.Id == originalSeatingPlanId);
              if (matchingSeatingPlan) {
                setSeatingPlan(getSPState(_event, matchingSeatingPlan, eventDate, eventDateState, parsedActiveLocks));
              }
            }
          }
        }

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      })
      .then(() => {
        window.setTimeout(() => {
          setLoadingInline(null);
        }, 100);
      });

    return state;
  };

  // (window as any).load = () => {
  //   loadEventDate(event, eventDate.Id, false);
  // };

  const [eventDatePart, setEventDatePart] = useState(location.pathname.split('/')[3]);
  const [eventTimePart, setEventTimePart] = useState(location.pathname.split('/')[4]);
  const [eventTag, setEventTag] = useState(location.pathname.split('/')[1]);

  useEffect(() => {
    const fetchEventData = async () => {
      const _eventUrlDetails = location.pathname;
      const _eventTag = _eventUrlDetails.split('/')[1];
      const _eventDatePart = _eventUrlDetails.split('/')[3];
      const _eventTimePart = _eventUrlDetails.split('/')[4];

      const isSameEvent = event && event.EventTag == _eventTag;

      const eventData: IEvent = isSameEvent
        ? event
        : await CacheHelper.eventWithMessage(setLoaders, loaders, _eventTag).catch(() => {
            return new Promise((resolve) =>
              setTimeout(async () => {
                await CacheHelper.eventWithMessage(setLoaders, loaders, _eventTag)
                  .then((e) => resolve(e))
                  .catch((err) => alert('There was an error when downloading event data, please refresh the page...'));
              }, 800),
            );
          });

      let matchingEventDateId = 0;
      Object.keys(eventData.EventDateIds).forEach((eventDateIdKey) => {
        const dateString = eventData.EventDateIds[eventDateIdKey];
        if (moment(dateString).format('DDMMMYYYY/HHmm') === _eventDatePart + '/' + _eventTimePart) {
          matchingEventDateId = parseInt(eventDateIdKey);
        }
      });

      if (!isSameEvent) {
        const _isAdmin = UserHelper.isCurrentUserAdmin(eventData.Organisation);

        if (_isAdmin && eventData.ShowDiscountCode) {
          await AdminApi.request('GET', `/api/DiscountCode?eventId=${eventData.Id}`).then((r) => {
            eventData.Discounts = r;
          });
        }
      }

      const hasMerchandise =
        eventData &&
        eventData.UnallocatedCategoryGroups &&
        eventData.UnallocatedCategoryGroups.length > 0 &&
        eventData.UnallocatedCategoryGroups.filter((c) => c.Merchandise).length > 0;

      if (eventData.UseSeatingPlan && (eventData.UseUnallocatedTickets || hasMerchandise)) {
        eventData.SeatingPlanSectionsEnabled = true;
        setShowGeneralAdmissionSection(false);
      } else if (eventData.UseUnallocatedTickets || hasMerchandise) {
        setShowGeneralAdmissionSection(true);
      }

      setEventTag(_eventTag);
      setEventDatePart(_eventDatePart);
      setEventTimePart(_eventTimePart);
      setEvent(eventData);

      if (matchingEventDateId) {
        await loadEventDate(eventData, matchingEventDateId, false);
      } else {
        setLoadingInline(null);
      }
      StripeHelper.get(eventData.Organisation.StripeAccountId);
    };

    fetchEventData();

    var root = document.getElementsByTagName('html')[0];
    root.classList.add('white');

    return () => {
      root.classList.remove('white');
    };
  }, [location]);

  useEffect(() => {
    if (!event) return;

    let pixel = false;
    if (event.FacebookPixelId) {
      pixel = true;
      PixelHelper.init(event.FacebookPixelId, null, {
        autoConfig: true,
        debug: EnvironmentVariables.isDev(),
      });
    }

    if (event.Organisation && event.Organisation.FacebookPixelId) {
      pixel = true;
      PixelHelper.init(event.Organisation.FacebookPixelId, null, {
        autoConfig: true,
        debug: EnvironmentVariables.isDev(),
      });
    }

    if (event.Tour && event.Tour.FacebookPixelId) {
      pixel = true;
      PixelHelper.init(event.Tour.FacebookPixelId, null, {
        autoConfig: true,
        debug: EnvironmentVariables.isDev(),
      });
    }

    const _isAdmin = UserHelper.isCurrentUserAdmin(event.Organisation);

    if (pixel) {
      if (event.FacebookPixelId) PixelHelper.trackSingle(event.FacebookPixelId, 'PageView', { isAdmin: _isAdmin });
      if (event.Organisation && event.Organisation.FacebookPixelId)
        PixelHelper.trackSingle(event.Organisation.FacebookPixelId, 'PageView', { isAdmin: _isAdmin });
      if (event.Tour && event.Tour.FacebookPixelId)
        PixelHelper.trackSingle(event.Tour.FacebookPixelId, 'PageView', { isAdmin: _isAdmin });
    }
  }, [event?.Id]);

  const checkForSingleSeats = (): boolean => {
    if (!isAdmin && event.UseSeatingPlan && seatingPlan && event.PreventSingleSeatsEnabled) {
      const singleSeats = SeatingPlanHelper.getSingleSeats(seatingPlan);
      if (singleSeats.length > 0) {
        var seatsString = '';

        singleSeats.forEach((seat) => {
          seatsString += seat.Group + seat.Name + ', ';
        });

        seatsString = seatsString.substring(0, seatsString.length - 2);

        alert(
          'You have selected seats which leave seats ' +
            seatsString +
            ' alone. Unfortunately you cannot leave single seats for this event. Please change your selection so that no single seats are left alone. ',
        );

        return true;
      }
    }

    return false;
  };

  const orderTickets = () => {
    setShowOrderModal(true);
  };

  const isMerchAvailable =
    event &&
    event.UnallocatedCategoryGroups &&
    event.UnallocatedCategoryGroups.length > 0 &&
    event.UnallocatedCategoryGroups.filter((c) => c.Merchandise).length > 0;

  const now = event && moment().tz(event.TimeZoneIana);
  const hasEnded =
    eventDate && now > moment(eventDate.DateAsString).add(event.ExtraSaleMinutes, 'minutes').tz(event.TimeZoneIana);
  const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
  const salesStarted = event && onlineFrom <= now;

  var permissionToBuy = eventDate && (salesStarted || isAdmin);
  var permissionToRequest =
    event &&
    !isAdmin &&
    (salesStarted || isAdmin) &&
    eventDate != null &&
    event.RequestingEnabled &&
    currentUser != null;
  if (permissionToRequest && event.Organisation.MemberOnlyRequests && !isMember) {
    permissionToRequest = false;
  }
  var permissionForAny = permissionToBuy || permissionToRequest || isAdmin;

  const generalAdmissionTicketsCount = linq
    .from(generalAdmissionTickets)
    .where((t) => !t.Merchandise)
    .sum((t) => t.Amount);

  const merchInBasketCount = linq
    .from(generalAdmissionTickets)
    .where((t) => t.Merchandise)
    .sum((t) => t.Amount);

  const selectedTicketCount = (seatedTickets ? seatedTickets.length : 0) + generalAdmissionTicketsCount;

  if (event && event.Private && !isAdmin && !privatePasswordConfirmed) {
    return (
      <>
        <Header hideLinks={true} />
        <PrivateEvent onPasswordConfirmed={() => setPrivatePasswordConfirmed(true)} event={event} />
        <Footer />
      </>
    );
  }

  let minSeatedPrice: number = null;
  let maxSeatedPrice: number = null;

  eventDate &&
    event.UseSeatingPlan &&
    seatingPlan &&
    seatingPlan.Seats &&
    linq
      .from(seatingPlan.Seats)
      .groupBy((seat) => seat.SeatCategoryId)
      .select((groupedSeats) => {
        const firstSeat = groupedSeats.first();

        const prices = groupedSeats
          .selectMany((g) => g.AvailableCategories)
          .distinct()
          .where((s) => s.Bookable)
          .toArray();

        if (prices.length == 0) return null;

        const min = linq.from(prices).min((p) => p.PriceAsInt);
        const max = linq.from(prices).max((p) => p.PriceAsInt);

        if (minSeatedPrice == null || min < minSeatedPrice) minSeatedPrice = min;
        if (maxSeatedPrice == null || max > maxSeatedPrice) maxSeatedPrice = max;

        return {
          ...firstSeat.SeatCategory,
          prices: prices,
        };
      })
      .toArray();

  const seatingPlanPrices: ITicketCategory[][] =
    seatingPlan &&
    linq
      .from(seatingPlan.Seats)
      .groupBy((seat) => seat.SeatCategoryId)
      .select((groupedSeats) => {
        const prices = groupedSeats
          .selectMany((g) => g.AvailableCategories)
          .distinct()
          .where((s) => s.Bookable)
          .toArray();

        if (prices.length == 0) return null;

        return prices as ITicketCategory[];
      })
      .toArray();

  const bookingPage = (
    <>
      <Helmet>
        {hasEnded && <meta name="robots" content="noindex" />}
        <meta property="og:url" content={`https://Seaty.co.uk/${eventTag}`} />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`#${eventTag} - Tickets`} />
        <meta property="og:description" content={`Buy tickets for #${eventTag}.`} />
        <meta property="og:image" content={event ? event.ImageUrl : ''} />
        <title>
          #{eventTag}
          {eventDate ? ` - ${DateHelper.asDateAtTimeAmPm(eventDate.DateAsString)}` : ''}
        </title>
        <meta name="description" content={`Buy tickets for #${eventTag}.`} />
      </Helmet>

      {!isAdmin && (
        <>
          <Header title={event && event.Name} />
        </>
      )}

      <EventHeader
        buttonText={
          <>
            <div>Book</div>
            <div>Tickets</div>
          </>
        }
        onCenterClick={() => setDatePickerEvent(event)}
        isAdmin={isAdmin}
        loading={loadingInline != null}
        event={event}
        currentEventDateId={eventDate && eventDate.Id}
        loadEventDate={async (eventDateId: number) => {
          await loadEventDate(event, eventDateId, true);
        }}
        onPriceClick={() => setShowPriceList(true)}
      />

      {event && (
        <div
          className={`event-page${showOrdersInSeatingPlan ? ' show-all-orders' : ''}${DeviceHelper.mobileAndTabletCheck() ? ' mobile-device' : ''}`}
        >
          <EventWarnings
            event={event}
            eventDate={eventDate}
            isAdmin={isAdmin}
            hasEnded={hasEnded}
            salesStarted={salesStarted}
            onlineFrom={onlineFrom}
          />
          <EventInfoBanner eventDate={eventDate} />

          {!event.UseSeatingPlan && generalAdmissionTickets.length == 0 && (
            <>
              <div className="strip-block" style={{ paddingTop: '100px', paddingBottom: '300px', background: 'white' }}>
                <div className="row content" style={{ background: 'white' }}>
                  <div style={{ textAlign: 'left' }} className="col-sm-12">
                    <div>🕒❌</div>
                    <div>Time slot not available!</div>
                    <div className="text">
                      This time slot is not available, please try a different date or time. <br />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {eventDate && eventDate.External && (
            <div className="info-message">
              <div>External Event</div>
              <div className="text">This is an external event and you cannot book tickets through Seaty.co.uk</div>
            </div>
          )}

          {eventDate && !eventDate.External && (
            <>
              {!loadingInline && event.UseSeatingPlan && event.SeatingPlanSectionsEnabled && (
                <div className="admin-dashboard">
                  <div>
                    {!seatingPlan && !showGeneralAdmissionSection ? (
                      <div className="seating-plan-section-selector">
                        <div className="toolbar horizontal">
                          {(event.UseUnallocatedTickets || isMerchAvailable) && (
                            <div className="title">Choose an option:</div>
                          )}
                          {!(event.UseUnallocatedTickets || isMerchAvailable) && (
                            <div className="title">Where would you like to sit?</div>
                          )}
                          <div className="buttons">
                            {linq
                              .from(event.SeatingPlans)
                              .orderBy((sp) => sp.Index)
                              .toArray()
                              .map((_sp) => {
                                const sp = getSPState(event, _sp, eventDate, currentEventDateState, seatedLocks);

                                const priceList = [];
                                linq
                                  .from(sp.Seats)
                                  .groupBy((seat) => seat.SeatCategoryId)
                                  .forEach((groupedSeats) => {
                                    const prices = groupedSeats
                                      .selectMany((g) => g.AvailableCategories)
                                      .distinct()
                                      .where((s) => s.Bookable)
                                      .orderBy((s) => s.PriceAsInt);

                                    if (prices.toArray().length != 0) {
                                      priceList.push({
                                        name: prices.first().SeatCategory.Name,
                                        min: prices.first().PriceAsInt,
                                        max: prices.last().PriceAsInt,
                                      });
                                    }
                                  });

                                let prices = `-`;

                                if (priceList.length > 0) {
                                  const minPrice = linq.from(priceList).min((p) => p.min);
                                  const maxPrice = linq.from(priceList).max((p) => p.max);

                                  if (!NumberHelper.isNumeric(minPrice)) {
                                    prices = 'Sold out';
                                  } else if (minPrice == maxPrice) {
                                    prices = CurrencyHelper.formatCurrency(event.CurrencySymbol, minPrice);
                                  } else {
                                    prices = `${CurrencyHelper.formatCurrency(event.CurrencySymbol, minPrice)} - ${CurrencyHelper.formatCurrency(event.CurrencySymbol, maxPrice)}`;
                                  }
                                }

                                return (
                                  <div key={sp.Id}>
                                    <button
                                      name={StringHelper.toCamelCase(sp.Name)}
                                      className="icon-right"
                                      onClick={() => {
                                        setSeatingPlan(
                                          getSPState(
                                            event,
                                            sp,
                                            eventDate,
                                            currentEventDateState,
                                            seatedLocks,
                                            seatedTickets,
                                          ),
                                        );
                                        window.setTimeout(() => {
                                          (window as any).recenter();
                                        }, 100);
                                      }}
                                    >
                                      <SVGGoArrow />
                                      <div>{!sp.Name || sp.Name.length == 0 ? 'Seated Tickets' : sp.Name}</div>
                                      <div>
                                        {prices}
                                        {prices == 'Sold out' || prices == '-' ? '' : '*'}
                                      </div>
                                    </button>
                                  </div>
                                );
                              })}
                            {(event.UseUnallocatedTickets || isMerchAvailable) && (
                              <button
                                className="icon-right"
                                onClick={() => {
                                  setShowGeneralAdmissionSection(true);
                                }}
                              >
                                <SVGGoArrow />
                                {event.UseUnallocatedTickets && !isMerchAvailable && (
                                  <div>General Admission Tickets</div>
                                )}
                                {event.UseUnallocatedTickets && isMerchAvailable && (
                                  <div>General Admission Tickets & Extras</div>
                                )}
                                {!event.UseUnallocatedTickets && isMerchAvailable && <div>Extras</div>}
                                <div>
                                  {maxGeneralAdmissionPrice == minGeneralAdmissionPrice
                                    ? CurrencyHelper.formatCurrency(event.CurrencySymbol, maxGeneralAdmissionPrice)
                                    : `${CurrencyHelper.formatCurrency(event.CurrencySymbol, minGeneralAdmissionPrice)} - ${CurrencyHelper.formatCurrency(event.CurrencySymbol, maxGeneralAdmissionPrice)}`}
                                  {event.AbsorbFee ? '' : '*'}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>

                        <p>
                          {!event.AbsorbFee && (
                            <div style={{ textAlign: 'center' }}>
                              * Prices are exclusive of service{' '}
                              {(event.HandlingFee && event.HandlingFee > 0) ||
                              (event.HandlingFeePercentage && event.HandlingFeePercentage > 0)
                                ? 'and handling '
                                : ''}
                              fees.
                            </div>
                          )}
                        </p>
                      </div>
                    ) : (
                      <div className="seating-plan-section-toolbar">
                        <div className="toolbar">
                          <div className="buttons justify-left">
                            <button
                              name="backButton"
                              onClick={() => {
                                setSeatingPlan(null);
                                setShowGeneralAdmissionSection(false);
                              }}
                            >
                              <SVGGoBackArrow />
                              Back
                            </button>
                          </div>
                          <div className="buttons">
                            <select
                              id="sections"
                              value={seatingPlan ? seatingPlan.Id.toString() : 0}
                              onChange={(v: any) => {
                                if (v.target.value == 0) {
                                  setSeatingPlan(null);
                                  setShowGeneralAdmissionSection(true);
                                  return;
                                } else {
                                  setShowGeneralAdmissionSection(false);
                                }

                                const sp = event.SeatingPlans.find(
                                  (s) => s.Id.toString() == v.target.value || s.Guid == v.target.value,
                                );
                                setSeatingPlan(
                                  getSPState(event, sp, eventDate, currentEventDateState, seatedLocks, seatedTickets),
                                );
                                setTimeout(() => {
                                  (window as any).recenter();
                                }, 100);
                              }}
                            >
                              {(event.UseUnallocatedTickets || isMerchAvailable) && (
                                <option value={0}>
                                  {event.UseUnallocatedTickets && !isMerchAvailable && <>General Admission Tickets</>}
                                  {event.UseUnallocatedTickets && isMerchAvailable && (
                                    <>General Admission Tickets & Extras</>
                                  )}
                                  {!event.UseUnallocatedTickets && isMerchAvailable && <>Extras</>}
                                </option>
                              )}
                              {linq
                                .from(event.SeatingPlans)
                                .toArray()
                                .map((s) => (
                                  <option key={s.Id} value={s.Id.toString()}>
                                    {`${!s.Name || s.Name.length == 0 ? 'Seated Tickets' : s.Name}`}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {event.UseSeatingPlan && seatingPlan && (
                <>
                  <EventSeatingPlan
                    verticalPadding={
                      event && isAdmin
                        ? event.SeatingPlanSectionsEnabled
                          ? 275
                          : 225
                        : event.SeatingPlanSectionsEnabled
                          ? 295
                          : 245
                    }
                    prices={seatingPlanPrices}
                    setShowPriceList={setShowPriceList}
                    eventDateString={DateHelper.asDateAtTimeAmPm(eventDate.Date)}
                    isAdmin={isAdmin}
                    event={event}
                    showOrders={showOrdersInSeatingPlan}
                    seatingPlan={seatingPlan}
                    onSelectedSeatsChange={(seats) => {
                      const seatsNotInPlan = linq
                        .from(seatedTickets)
                        .where((s) => !linq.from(seatingPlan.SeatCategories).any((sc) => sc.Id == s.SeatCategoryId))
                        .toArray();

                      setSeatedTickets(seatsNotInPlan.concat(seats));
                    }}
                    disabled={!eventDate}
                    onOrderModalClosed={() => {
                      setLoadingInline('Loading date...');
                      LockService.DeleteSessionLocks(eventDate.Id, true).then(() => {
                        loadEventDate(event, eventDate.Id, false);
                      });
                    }}
                    toolbarButtons={
                      isAdmin ? (
                        <>
                          {event.UseSeatingPlan && eventDatePart && eventDatePart.length > 0 && (
                            <button
                              onClick={() => {
                                showOrdersInSeatingPlan = !showOrdersInSeatingPlan;
                                setShowOrdersInSeatingPlan(showOrdersInSeatingPlan);
                                seatedTickets.forEach((s) => (s.Selected = false));
                                setSeatedTickets([]);
                                setSeatingPlan(
                                  getSPState(event, seatingPlan, eventDate, currentEventDateState, seatedLocks),
                                );
                                updateGeneralAdmissionTicketsState(event, currentEventDateState);
                              }}
                            >
                              <label> {showOrdersInSeatingPlan ? 'Hide' : 'Show'} orders</label>
                              {!showOrdersInSeatingPlan ? <SVGToggleOff /> : <SVGToggleOn />}
                            </button>
                          )}
                        </>
                      ) : null
                    }
                  />
                </>
              )}
              {showGeneralAdmissionSection &&
                (event.UseUnallocatedTickets || isMerchAvailable) &&
                currentEventDateState &&
                currentEventDateState.GeneralAdmissionTickets &&
                currentEventDateState.GeneralAdmissionTickets.length > 0 && (
                  <div
                    className="general-tickets admin-dashboard"
                    style={{ paddingBottom: event.UseSeatingPlan ? '0' : '400px' }}
                  >
                    <GeneralAdmissionTicketGroups
                      locks={generalLocks}
                      eventDate={eventDate}
                      onTicketsChange={(ticketCategory, quantity) => {
                        ticketCategory.Amount = quantity;
                        setGeneralAdmissionTickets([...generalAdmissionTickets]);
                      }}
                      tickets={generalAdmissionTickets}
                      isAdmin={isAdmin}
                    />
                    <MerchandiseProductGroups
                      locks={generalLocks}
                      eventDate={eventDate}
                      onTicketsChange={(ticketCategory, quantity) => {
                        ticketCategory.Amount = quantity;
                        setGeneralAdmissionTickets([...generalAdmissionTickets]);
                      }}
                      tickets={generalAdmissionTickets}
                      isAdmin={isAdmin}
                    />
                  </div>
                )}
            </>
          )}

          {loadingInline && (
            <div className="loading-over-tickets">
              <Loader inline={true}>{loadingInline}</Loader>
            </div>
          )}

          {eventDatePart &&
            eventDate &&
            !eventDate.External &&
            eventDatePart.length > 0 &&
            (isAdmin || !hasEnded) &&
            permissionForAny &&
            (event.UseSeatingPlan || generalAdmissionTickets.length > 0) && (
              <div className={`donotprint floating${DeviceHelper.iOS() ? ' ios' : ''}`}>
                <div className="toolbar not-responsive">
                  <div className={`status${selectedTicketCount > 0 || merchInBasketCount > 0 ? ' good' : ''}`}>
                    {selectedTicketCount > 0 || merchInBasketCount > 0
                      ? `${selectedTicketCount} ${StringHelper.AddSWhenMany(selectedTicketCount, 'ticket')}${merchInBasketCount > 0 ? `, ${merchInBasketCount} ${StringHelper.AddSWhenMany(merchInBasketCount, 'product')}` : ''}`
                      : 'Select items'}
                  </div>
                  <div className="buttons">
                    {eventDatePart &&
                      eventDate &&
                      !eventDate.External &&
                      eventDatePart.length > 0 &&
                      (isAdmin || !hasEnded) &&
                      permissionForAny && (
                        <button
                          name="orderButton"
                          className={`${selectedTicketCount > 0 || merchInBasketCount > 0 ? ' confirm' : 'disabled'}`}
                          onClick={
                            selectedTicketCount > 0 || merchInBasketCount > 0
                              ? () => {
                                  if (checkForSingleSeats()) return;

                                  setMerchInCartWhenOpened(isMerchAvailable && merchInBasketCount > 0);
                                  if (isAdmin) {
                                    orderTickets();
                                  } else {
                                    isMerchAvailable && merchInBasketCount === 0
                                      ? setShowExtrasDialog(true)
                                      : setShowOrderModal(true);
                                  }
                                }
                              : null
                          }
                        >
                          <SVGCheckout />
                          Order
                        </button>
                      )}

                    {isAdmin && (
                      <>
                        {event.UseSeatingPlan && eventDatePart && eventDatePart.length > 0 && (
                          <button
                            className={`${seatedTickets.length > 0 ? '' : ' disabled'} shrink-400`}
                            onClick={
                              seatedTickets.length > 0
                                ? () => {
                                    setShowLockSeats(true);
                                  }
                                : null
                            }
                          >
                            <SVGLock />
                            Lock
                          </button>
                        )}
                        {eventDatePart && eventDatePart.length > 0 && (
                          <button
                            className={`${selectedTicketCount > 0 ? '' : ' disabled'} shrink-400`}
                            onClick={
                              selectedTicketCount > 0
                                ? () => {
                                    LoaderHelper.add(setLoaders, loaders, 'SWAP_TICKETS', 'Starting swap...');

                                    SwapService.StartSwap(event, eventDate, seatedTickets, generalAdmissionTickets)
                                      .then((result) => setTicketSwap(result))
                                      .catch((error) => {
                                        alert(error);
                                      })
                                      .finally(() => {
                                        LoaderHelper.remove(setLoaders, loaders, 'SWAP_TICKETS');
                                      });
                                  }
                                : null
                            }
                          >
                            <SVGSwap />
                            Swap
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

          {showLockSeats && (
            <AdminLockSeatsModal
              onClose={() => {
                loadEventDate(event, eventDate.Id, false);
                setShowLockSeats(false);
              }}
              event={event}
              eventDate={eventDate}
              seats={seatedTickets}
            />
          )}

          {datePickerEvent && (
            <DatesModal
              currentEventDate={eventDate}
              onClose={() => setDatePickerEvent(null)}
              isAdmin={isAdmin}
              eventTag={datePickerEvent.EventTag}
            />
          )}
          {ticketSwap && (
            <SwapModal
              onClose={() => {
                setLoadingInline('Loading tickets...');
                setTicketSwap(null);
                setShowOrderModal(false);
                LockService.DeleteSessionLocks(eventDate.Id, true).then(() => {
                  loadEventDate(event, eventDate.Id, false);
                });
              }}
              isMember={isMember}
              ticketSwap={ticketSwap}
            />
          )}

          {showOrderModal && (
            <OrderModal
              hasMerchandiseWhenOpened={isMerchAvailable}
              organisation={event.Organisation}
              onClose={() => {
                setLoadingInline('Loading tickets...');
                setTicketSwap(null);
                setShowOrderModal(false);
                setShowExtrasDialog(false);
                LockService.DeleteSessionLocks(eventDate.Id, true).then(() => {
                  loadEventDate(event, eventDate.Id, false);
                });
              }}
              isAdmin={isAdmin}
              isMember={isMember}
              event={event}
              eventDate={eventDate}
              user={currentUser}
              selectedSeats={seatedTickets}
              generalAdmissionTickets={generalAdmissionTickets}
            />
          )}
        </div>
      )}

      {showExtrasDialog && currentEventDateState && (
        <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={() => setShowExtrasDialog(false)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              {isMerchAvailable && (
                <>
                  <SpacerTable>
                    <h1>Optional Extras ✨</h1>
                    <div>Fancy adding a little something extra to your order? 🤩</div>

                    <div
                      className="general-tickets admin-dashboard"
                      style={{
                        padding: '0',
                        paddingBottom: '0',
                      }}
                    >
                      <MerchandiseProductGroups
                        locks={generalLocks}
                        eventDate={eventDate}
                        onTicketsChange={(ticketCategory, quantity) => {
                          ticketCategory.Amount = quantity;
                          setGeneralAdmissionTickets([...generalAdmissionTickets]);
                        }}
                        tickets={generalAdmissionTickets}
                        isAdmin={isAdmin}
                      />
                    </div>

                    <div className="spacer" />
                  </SpacerTable>
                </>
              )}
              <SpacerTable>
                <Button
                  text={`Continue`}
                  className={`${selectedTicketCount > 0 || merchInBasketCount > 0 ? ' confirm' : 'disabled'}`}
                  onClick={
                    selectedTicketCount > 0 || merchInBasketCount > 0
                      ? () => {
                          orderTickets();
                          setShowExtrasDialog(false);
                        }
                      : null
                  }
                />
              </SpacerTable>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}

      {showPriceList && (
        <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={() => setShowPriceList(false)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              <SpacerTable>
                <h1>{event.Name}</h1>
                <div>
                  {event.Venue.Name} on {eventDate.Date && DateHelper.asDateAtTimeAmPm(eventDate.Date)}
                </div>

                <div className={'bookingPrices'}>
                  {linq
                    .from(seatingPlan.Seats)
                    .groupBy((seat) => seat.SeatCategoryId)
                    .orderBy((groupedSeats) => {
                      const firstSeat = groupedSeats.first();
                      return firstSeat.SeatCategory.Index;
                    })
                    .select((groupedSeats) => {
                      const firstSeat = groupedSeats.first();

                      const prices = groupedSeats
                        .selectMany((g) => g.AvailableCategories)
                        .distinct()
                        .where((s) => s.Bookable)
                        .select((t, index) => (
                          <div key={t.Name + '_' + index} className="bookingPrice__ticketCategory">
                            <div className="bookingPrice__ticketCategoryName">{t.Name}</div>
                            <div className="bookingPrice__ticketCategoryPrice">{t.PriceAsString}*</div>
                          </div>
                        ))
                        .toArray();

                      if (prices.length == 0) return null;

                      return (
                        <div className="bookingPrice" key={'SeatCategoryPrices_' + firstSeat.SeatCategory.Id}>
                          <div
                            className="bookingPrice__colour"
                            style={{ backgroundColor: firstSeat.SeatCategory.Colour }}
                          >
                            {firstSeat.SeatCategory.Icon == 'wheelchair' && (
                              <img className="key-item-wheelchair" src={SVGHelper.get('Wheelchair_Light')} />
                            )}
                            {firstSeat.SeatCategory.Icon == 'info' && <img src={SVGHelper.get('Info_Solo_Light')} />}
                          </div>
                          <div className="bookingPrice__seatCategory">
                            <div className="bookingPrice__seatCategoryName">{firstSeat.SeatCategory.Name}</div>
                            {firstSeat.SeatCategory.Description && firstSeat.SeatCategory.Description.length > 0 && (
                              <div className="bookingPrice__seatCategoryDescription">
                                {firstSeat.SeatCategory.Description}
                              </div>
                            )}
                          </div>
                          {prices}
                        </div>
                      );
                    })
                    .toArray()}
                  {!event.AbsorbFee && (
                    <div className="bookingPrices__bottomMessage">
                      * Prices are exclusive of service{' '}
                      {(event.HandlingFee && event.HandlingFee > 0) ||
                      (event.HandlingFeePercentage && event.HandlingFeePercentage > 0)
                        ? 'and handling '
                        : ''}
                      fees.
                    </div>
                  )}
                </div>
              </SpacerTable>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
    </>
  );

  if (isAdmin) {
    return (
      <AdminPage loadEventDate={loadEventDate} hideFooter={true}>
        {bookingPage}
      </AdminPage>
    );
  } else {
    return bookingPage;
  }
};

export default BookingPage;

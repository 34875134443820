import { FunctionComponent, useState } from 'react';
import AdminApi from '../../../../api/AdminApi';
import Block from '../../../../components/Block';
import BlockHeader from '../../../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../../../components/BlockInfo';
import BlockTextBox from '../../../../components/BlockTextBox';
import Button from '../../../../components/Button';
import Info from '../../../../components/Info';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import SpacerTable from '../../../../components/SpacerTable';
import IMemberType from '../../../../interfaces/IMemberType';
import { IOrganisation } from '../../../../interfaces/IOrganisation';

interface IProps {
  onClose: () => void;
  organisation: IOrganisation;
  memberType: IMemberType;
  disableDelete: boolean;
}

const AddMemberTypeModal: FunctionComponent<IProps> = (props) => {
  const [busyMessage, setBusyMessage] = useState(null);
  const [name, setName] = useState(props.memberType.Name);
  const [infoMessage, setInfoMessage] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const saveMember = () => {
    setBusyMessage('Updating member...');

    AdminApi.request('PUT', '/api/MemberType', {
      id: props.memberType ? props.memberType.Id : null,
      name: name,
      organisationId: props.organisation.Id,
      isDefault: props.memberType.Default,
    })
      .then((response) => {
        setInfoMessage('Member type saved. You can now close this window.');
        setBusyMessage(null);
      })
      .catch((message) => {
        setInfoMessage(null);
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  const deleteMember = () => {
    setBusyMessage('Deleting member...');

    AdminApi.request('DELETE', '/api/MemberType', {
      id: props.memberType.Id,
      organisationId: props.organisation.Id,
    })
      .then((response) => {
        setInfoMessage('Member type deleted. You can now close this window.');
        setBusyMessage(null);
      })
      .catch((message) => {
        setInfoMessage(null);
        setErrorMessage(message);
        setBusyMessage(null);
      });
  };

  const renderAddMember = () => {
    if (busyMessage) return <Loader inline={true}>{busyMessage}</Loader>;

    if (infoMessage) {
      return (
        <>
          <table className="blocks">
            <tbody>
              <BlockInfo>{infoMessage}</BlockInfo>
            </tbody>
          </table>
        </>
      );
    }

    if (errorMessage != null) {
      return (
        <>
          <table className="blocks">
            <tbody>
              <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>
            </tbody>
          </table>
          <div className="spacer" />
          <SpacerTable>
            <Button onClick={() => setErrorMessage(null)} text={'Dismiss'} />
          </SpacerTable>
        </>
      );
    }

    return (
      <>
        <table className="blocks">
          <tbody>
            {deleting ? (
              <Block>
                <Info>
                  Are you sure you want to delete this member type, if any member is using it they will be set to the
                  default member type.
                </Info>
              </Block>
            ) : (
              <Block>
                <BlockHeader>What is the name of the member type?</BlockHeader>
                <BlockTextBox
                  name="memberName"
                  placeholder={'Member type name'}
                  autoFocus={true}
                  value={name}
                  onChange={(e) => {
                    setName(e);
                  }}
                />
              </Block>
            )}
          </tbody>
        </table>
        <div className="spacer" />

        {!deleting && (
          <SpacerTable>
            <Button
              disabled={name.length < 2}
              className="confirm"
              onClick={() => saveMember()}
              text={'Save member type'}
            />
            {!props.disableDelete && props.memberType && !props.memberType.Default && props.memberType.Id > 0 && (
              <Button
                className="bad"
                onClick={() => {
                  setDeleting(true);
                }}
                text={'Delete member type'}
              />
            )}
          </SpacerTable>
        )}

        {deleting && (
          <SpacerTable>
            <Button className="bad" onClick={() => deleteMember()} text={'Yes, delete this member type'} />
            <Button
              disabled={name.length < 2}
              onClick={() => {
                setDeleting(false);
              }}
              text={'Cancel'}
            />
          </SpacerTable>
        )}
      </>
    );
  };

  return (
    <Modal theme={props.organisation.Theme} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>Member Type Editor</h1>
            {props.organisation.Name}
          </SpacerTable>

          <div className="spacer" />

          {renderAddMember()}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
};

export default AddMemberTypeModal;

import linq from 'linq';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Block from '../../../components/Block';
import BlockDatePicker, { IDatePickerValue } from '../../../components/BlockDatePicker';
import BlockHeader from '../../../components/BlockHeader';
import { ITimePickerValue } from '../../../components/BlockTimePicker';
import Button from '../../../components/Button';
import BlockCheckBox from '../../../components/BlockCheckBox';
import Dropdown from '../../../components/Dropdown';
import InfoLabel from '../../../components/InfoLabel';
import Modal from '../../../components/Modal';
import SpacerTable from '../../../components/SpacerTable';
import Week from '../../../components/Week';
import DateHelper from '../../../helpers/DateHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IEventDate } from '../../../interfaces/IEventDate';
import SVGAsterisk from '../../../svg/SVGAsterisk';
import SVGCalendar from '../../../svg/SVGCalendar';
import SVGClosedCaptioning from '../../../svg/SVGClosedCaptioning';
import SVGCopy from '../../../svg/SVGCopy';
import SVGExternal from '../../../svg/SVGExternal';
import SVGInfoSimple from '../../../svg/SVGInfoSimple';
import SVGList from '../../../svg/SVGList';
import SVGPlus from '../../../svg/SVGPlus';
import SVGSigned from '../../../svg/SVGSigned';
import SVGSuspended from '../../../svg/SVGSuspended';
import SVGTrash from '../../../svg/SVGTrash';
import Toolbar from '../toolbar/Toolbar';
import DateAndTimeListItem from './DateAndTimeListItem';
import DateAndTimeWeekItem from './DateAndTimeWeekItem';
import { IDropDownItem } from '../../../components/DropdownItem';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

enum DateSort {
  DateDescending = 'Date Descending',
  DateAscending = 'Date Ascending',
}

enum ViewMode {
  Calendar = 'Calendar',
  List = 'List',
}

const DateAndTimeSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [copyAdditionalInfo, setCopyAdditionalInfo] = useState(false);
  const [addAmount, setAddAmount] = useState('30');
  const [addAmountType, setAddAmountType] = useState('minute');
  const [sortOrder, setSortOrder] = useState<DateSort>(DateSort.DateAscending);
  const [viewMode, setViewMode] = useState<ViewMode>(
    event && event.Dates.length > 20 ? ViewMode.Calendar : ViewMode.List,
  );
  const [calendarWeek, setCalendarWeek] = useState(null);
  const [weekEventDates, setWeekEventDates] = useState(null);

  const getSortedDates = (eventDates, order?) => {
    const o = order ? order : sortOrder;
    if (o == DateSort.DateDescending) {
      return linq
        .from(eventDates)
        .orderByDescending((ed) => moment(ed.DateAsString).unix())
        .toArray();
    }
    if (o == DateSort.DateAscending) {
      return linq
        .from(eventDates)
        .orderBy((ed) => moment(ed.DateAsString).unix())
        .toArray();
    }
  };

  const [sortedDates, setSortedDates] = useState(getSortedDates(event.Dates));
  const [copyDate, setCopyDate] = useState<{
    date: IEventDate;
    times: { eventDate: IEventDate; checked: boolean }[];
  }>();

  const onCopyClick = (eventDate: IEventDate) => {
    setCopyAdditionalInfo(false);

    setCopyingToDate({
      ...copyingToDate,
      date: {
        allHaveValue: true,
        isDate: true,
        moment: moment(eventDate.DateAsString).add(1, 'day'),
        string: moment(eventDate.DateAsString).add(1, 'day').format('DD/MM/YYYY'),
      },
    });

    setCopyDate({
      date: eventDate,
      times: linq
        .from(event.Dates)
        .where(
          (ed) => moment(ed.DateAsString).format('YYYY-MM-DD') == moment(eventDate.DateAsString).format('YYYY-MM-DD'),
        )
        .select((d) => {
          return { eventDate: d, checked: true };
        })
        .toArray(),
    });
  };

  const getFirstDate = (): IEventDate => {
    return linq
      .from(event.Dates)
      .orderBy((ed) => moment(ed.DateAsString).unix())
      .firstOrDefault();
  };

  const [editingEventDate, setEditingEventDate] = useState<IEventDate>(null);

  useEffect(() => {
    const c = moment(calendarWeek).startOf('day');
    const start = c.clone();
    const end = c.clone().add(7, 'days').endOf('day');

    console.log(start.format('YYYY-MM-DD'));
    console.log(end.format('YYYY-MM-DD'));

    const eventDatesInWeek = linq
      .from(event.Dates)
      .where((ed) => moment(ed.DateAsString).isAfter(start) && moment(ed.DateAsString).isBefore(end))
      .toArray();

    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        linq
          .from(eventDatesInWeek)
          .where((ed) => moment(ed.DateAsString).format('YYYY-MM-DD') == c.clone().add(i, 'days').format('YYYY-MM-DD'))
          .orderBy((ed) => moment(ed.DateAsString).unix())
          .toArray(),
      );
    }

    const timeItems = [];
    days.forEach((dayEventDates, index) => {
      const dayMoment = start.clone().add(index, 'days');

      timeItems.push(
        <>
          <div className="admin-buttons">
            <button
              onClick={() => {
                let newDate = null;

                if (event.Dates.length == 0) {
                  newDate = moment(`${dayMoment.format('YYYY-MM-DD')} 09:00`);
                } else if (dayEventDates.length == 0) {
                  const firstDate: IEventDate = getFirstDate();
                  newDate = moment(
                    `${dayMoment.format('YYYY-MM-DD')} ${moment(firstDate.DateAsString).format('HH:mm')}`,
                  );
                } else {
                  const lastDate: IEventDate = linq
                    .from(dayEventDates)
                    .orderBy((ed) => moment(ed.DateAsString).unix())
                    .lastOrDefault();
                  newDate = (moment(lastDate.DateAsString) as any).add(addAmount, addAmountType);
                }

                if (newDate.isAfter(dayMoment.endOf('day'))) {
                } else {
                  addDate(newDate);
                }
              }}
              className="admin-button icon"
            >
              <SVGPlus />
            </button>
            {dayEventDates.length > 0 && (
              <button onClick={() => onCopyClick(dayEventDates[0])} className="admin-button icon">
                <SVGCopy />
              </button>
            )}
          </div>
          {dayEventDates.map((ed: IEventDate) => (
            <DateAndTimeWeekItem
              key={ed.Guid}
              onTimeClick={(d) => setEditingEventDate(d)}
              eventDate={ed}
              isEditing={editingEventDate && editingEventDate.Guid == ed.Guid}
              onSaveClick={(d) => {
                ed.DateAsString = d.DateAsString;
                ed.ChangeMade = true;
                const sortedDates = getSortedDates([...event.Dates]);
                onEventUpdated({ ...event, Dates: sortedDates });
                setEditingEventDate(null);
                setSortedDates(sortedDates);
              }}
              onSuspendClick={(d) => {
                ed.ChangeMade = true;
                ed.Suspend = !ed.Suspend;
                const sortedDates = getSortedDates([...event.Dates]);
                onEventUpdated({ ...event, Dates: sortedDates });
                setEditingEventDate(null);
                setSortedDates(sortedDates);
              }}
              onDeleteClick={(_eventDate) => {
                setEditingEventDate(null);
                event.Dates = event.Dates.filter(
                  (d) => (d.Id !== 0 && d.Id !== _eventDate.Id) || (d.Id === 0 && d.Guid != _eventDate.Guid),
                );

                const sortedDates = getSortedDates([...event.Dates]);
                onEventUpdated({ ...event, Dates: sortedDates });
                setSortedDates(sortedDates);
              }}
              onCancelClick={() => setEditingEventDate(null)}
            />
          ))}
        </>,
      );
    });

    setWeekEventDates(timeItems);
  }, [calendarWeek, sortedDates, addAmount, addAmountType, editingEventDate]);

  var lastEventDate: IEventDate = linq
    .from(event.Dates)
    .orderBy((d) => moment(d.DateAsString).unix())
    .lastOrDefault();

  const [copyingToDate, setCopyingToDate] = useState<{ date: IDatePickerValue; time: ITimePickerValue }>({
    date: {
      allHaveValue: true,
      isDate: true,
      moment: moment(moment().format('YYYY-MM-DD') + ' 00:00'),
      string: moment().format('DD/MM/YYYY'),
    },
    time: {
      allHaveValue: true,
      isTime: true,
      moment: moment(moment().format('YYYY-MM-DD') + ' 00:00'),
      string: moment().format('HH:mm'),
    },
  });

  const addDate = function (m?: moment.Moment) {
    var newDate: IEventDate = {
      Id: 0,
      DateAsString: DateHelper.getFormattedDate() + ' ' + DateHelper.getFormattedTime().split('.')[0],
      Guid: GuidHelper.new(),
      ChangeMade: true,
      TimeType: lastEventDate.TimeType,
      EndDateAsString: lastEventDate.EndDateAsString,
    };

    if (m) {
      newDate.DateAsString = m.format('YYYY-MM-DD HH:mm');
    } else if (event.Dates.length > 0) {
      const plus1h = moment(lastEventDate.DateAsString).add(1, 'days');
      newDate.DateAsString = plus1h.format('YYYY-MM-DD HH:mm');
    }

    event.AllocatedCategoryGroups.forEach((group) => {
      group.SelectedEventDates.push(newDate.Guid);
    });

    event.UnallocatedCategoryGroups.forEach((group) => {
      group.SelectedEventDates.push(newDate.Guid);
    });
    event.Dates = [...event.Dates, newDate];

    onEventUpdated({
      ...event,
      Dates: event.Dates,
      UnallocatedCategoryGroups: [...event.UnallocatedCategoryGroups],
      AllocatedCategoryGroups: [...event.AllocatedCategoryGroups],
    });
    setSortedDates(getSortedDates(event.Dates));
  };

  const addAmountNumber: number = NumberHelper.isNumeric(addAmount) ? parseInt(addAmount) : 1;

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}

          {viewMode == ViewMode.Calendar && (
            <>
              <button className="option" onClick={() => setViewMode(ViewMode.List)}>
                <label>List View</label>
                <div className="icon">
                  <SVGList />
                </div>
              </button>
            </>
          )}
          {viewMode == ViewMode.List && (
            <>
              <button className="option" onClick={() => setViewMode(ViewMode.Calendar)}>
                <label>Week View</label>
                <div className="icon">
                  <SVGCalendar />
                </div>
              </button>
            </>
          )}
        </div>
      </Toolbar>

      {viewMode == ViewMode.Calendar && (
        <>
          <InfoLabel text={`Weekly event date calendar for #${event.EventTag}`}>
            Here, you can add, tweak, or remove your event dates in a weekly calendar view <SVGCalendar />. You can't
            change or delete <SVGTrash /> dates with orders, but can suspend them. Event dates with no orders that you
            can edit will show white, those with orders will show green, when edited they will show an asterisk next to
            them <SVGAsterisk />, and those that are suspended will show a suspended next to them. <SVGSuspended />
          </InfoLabel>

          <Week
            initialWeek={event.Dates.length == 0 ? null : moment(getFirstDate().DateAsString).toDate()}
            dayItems={weekEventDates}
            onWeekChange={(week) => setCalendarWeek(week)}
          />

          <h2>Calendar options</h2>
          <InfoLabel
            tooltip="When you click the plus button to add an event date to a day in the calendar and there are already times in that day, we will add on the amount of time specified below. This makes it really easy to add many event times to each day at set intervals. Remember, as long as no orders are placed against that event date, you can edit it at any time."
            text="When adding an event date add:"
          ></InfoLabel>
          <div className="flex_from_left">
            <div className="input">
              <input
                onChange={(e) => setAddAmount(e.target.value)}
                type="number"
                value={addAmount}
                style={{ margin: '0' }}
              />
            </div>
            <Dropdown
              onChange={(e: IDropDownItem) => setAddAmountType(e.value)}
              value={addAmountType}
              items={[
                { description: StringHelper.AddSWhenMany(addAmountNumber, 'Hour'), value: 'hour' },
                { description: StringHelper.AddSWhenMany(addAmountNumber, 'Minute'), value: 'minute' },
              ]}
              description={null}
            />
          </div>
        </>
      )}

      {viewMode == ViewMode.List && (
        <>
          <div className="info">
            Here, you can add, tweak, or remove your event dates 🗓️. You can't change or delete dates with orders, but
            can suspend them 🛑. Click the down arrow next to each date for more options 🔽. Plus, our quick add buttons
            help you add dates in a set sequence after your last one.
          </div>

          <h2>Quick add</h2>
          {lastEventDate && event.Dates.length > 0 && (
            <div style={{ marginBottom: '30px' }}>
              <div className="flex_from_left" style={{ marginBottom: '10px' }}>
                <div className="flex_from_left">
                  <div className="input">
                    <input
                      onChange={(e) => setAddAmount(e.target.value)}
                      type="number"
                      value={addAmount}
                      style={{ margin: '0' }}
                    />
                  </div>
                  <Dropdown
                    onChange={(e: IDropDownItem) => setAddAmountType(e.value)}
                    value={addAmountType}
                    items={[
                      { description: StringHelper.AddSWhenMany(addAmountNumber, 'Year'), value: 'year' },
                      { description: StringHelper.AddSWhenMany(addAmountNumber, 'Day'), value: 'day' },
                      { description: StringHelper.AddSWhenMany(addAmountNumber, 'Minute'), value: 'minute' },
                    ]}
                    description={null}
                  />
                  <button
                    disabled={addAmountNumber < 1}
                    className="admin-button"
                    onClick={() =>
                      addDate((moment(lastEventDate.DateAsString) as any).add(addAmountNumber, addAmountType))
                    }
                  >
                    {addAmountNumber < 1 ? (
                      'Invalid amount'
                    ) : (
                      <>
                        Add +{addAmountNumber} {StringHelper.AddSWhenMany(addAmountNumber, addAmountType)}
                      </>
                    )}
                  </button>
                </div>
                <button
                  className="admin-button"
                  onClick={() => addDate(moment(lastEventDate.DateAsString).add(1, 'hours'))}
                >
                  Add +1 hour
                </button>
                <button
                  className="admin-button"
                  onClick={() => addDate(moment(lastEventDate.DateAsString).add(1, 'days'))}
                >
                  Add +1 day
                </button>
              </div>
            </div>
          )}

          <h2>
            Event date list ({sortedDates.length})
            <div className="flex_from_right">
              <Dropdown
                onChange={(e: IDropDownItem) => {
                  setSortedDates(getSortedDates(event.Dates, e.value));
                }}
                value={sortOrder}
                items={[
                  { description: 'Sort by date ascending', value: DateSort.DateAscending },
                  { description: 'Sort by date descending', value: DateSort.DateDescending },
                ]}
                description={null}
              />
            </div>
          </h2>

          <div className="fields">
            {sortedDates.map((date) => (
              <div className="field element element-event-date" key={'event_date_list_item_' + date.Guid}>
                <DateAndTimeListItem
                  onChange={(_date: IEventDate) => {
                    _date.ChangeMade = true;

                    Object.keys(_date).forEach((key) => {
                      date[key] = _date[key];
                    });

                    onEventUpdated({ ...event });
                  }}
                  onCopyClick={(eventDate: IEventDate) => onCopyClick(eventDate)}
                  onDeleteClick={(date) => {
                    event.Dates = event.Dates.filter(
                      (d) => (d.Id !== 0 && d.Id !== date.Id) || (d.Id === 0 && d.Guid != date.Guid),
                    );
                    onEventUpdated({ ...event, Dates: event.Dates });
                    setSortedDates(getSortedDates(event.Dates));
                  }}
                  date={date}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {copyDate && (
        <Modal onCloseClick={() => setCopyDate(null)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              <SpacerTable>
                <h1>Copy event date times</h1>
                Choose the times you would like copied to a new event date.
              </SpacerTable>

              <div className="spacer" />

              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockHeader>Copying from:</BlockHeader>
                    {moment(copyDate.date.DateAsString).format('dddd Do MMMM YYYY')}
                  </Block>
                  <Block>
                    <BlockHeader>Copying to:</BlockHeader>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '80%', paddingRight: '8px' }}>
                            <BlockDatePicker
                              initialValue={copyingToDate.date && copyingToDate.date.moment}
                              groupName={`DateTimeOnSale`}
                              onChange={(_date) => {
                                setCopyingToDate({ ...copyingToDate, date: _date });
                              }}
                            />
                          </td>
                          <td></td>
                          {/* <td style={{ width: '40%', paddingLeft: '8px' }}>
                            <BlockTimePicker
                              initialValue={copyingToDate.time && copyingToDate.time.moment}
                              groupName={`DateTimeOnSale`}
                              onChange={(_time) => {
                                setCopyingToDate({ ...copyingToDate, time: _time });
                              }}
                            />
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </Block>

                  <BlockCheckBox
                    checked={copyAdditionalInfo}
                    onBoxClick={() => {
                      setCopyAdditionalInfo(!copyAdditionalInfo);
                    }}
                  >
                    Would you like to copy the additional options from each time such as if they are suspended, signed,
                    have closed caption, extra info, etc?
                  </BlockCheckBox>

                  <Block>
                    <BlockHeader>Event times to copy:</BlockHeader>
                  </Block>

                  {copyDate.times.map((t) => (
                    <BlockCheckBox
                      key={moment(t.eventDate.DateAsString).format('HH:mm')}
                      checked={t.checked}
                      onBoxClick={() => {
                        t.checked = !t.checked;
                        setCopyDate({ ...copyDate, times: [...copyDate.times] });
                      }}
                    >
                      {moment(t.eventDate.DateAsString).format('HH:mm')}

                      {copyAdditionalInfo && (
                        <>
                          {t.eventDate.Suspend && (
                            <div className="status-icon">
                              <SVGSuspended />
                            </div>
                          )}
                          {t.eventDate.Captioned && (
                            <div className="status-icon">
                              <SVGClosedCaptioning />
                            </div>
                          )}
                          {t.eventDate.Signed && (
                            <div className="status-icon">
                              <SVGSigned />
                            </div>
                          )}
                          {((t.eventDate.Information && t.eventDate.Information.length > 0) ||
                            (t.eventDate.InformationHeader && t.eventDate.InformationHeader.length > 0)) && (
                            <div className="status-icon">
                              <SVGInfoSimple />
                            </div>
                          )}
                          {t.eventDate.External && (
                            <div className="status-icon">
                              <SVGExternal />
                            </div>
                          )}
                        </>
                      )}
                    </BlockCheckBox>
                  ))}
                </tbody>
              </table>

              <div className="spacer" />

              <SpacerTable>
                <Button
                  className="confirm"
                  onClick={() => {
                    linq
                      .from(copyDate.times)
                      .where((t) => t.checked)
                      .forEach((t) => {
                        const dateString = `${copyingToDate.date.moment.format('YYYY/MM/DD')} ${moment(t.eventDate.DateAsString).format('HH:mm')}`;

                        var newDate: IEventDate = {
                          Id: 0,
                          DateAsString: moment(dateString).format('YYYY-MM-DD HH:mm'),
                          Guid: GuidHelper.new(),
                          ChangeMade: true,
                        };

                        if (copyAdditionalInfo) {
                          newDate = {
                            ...newDate,
                            Captioned: t.eventDate.Captioned,
                            Suspend: t.eventDate.Suspend,
                            Signed: t.eventDate.Signed,
                            Information: t.eventDate.Information,
                            InformationHeader: t.eventDate.InformationHeader,
                          };
                        }

                        event.AllocatedCategoryGroups.forEach((group) => {
                          group.SelectedEventDates.push(newDate.Guid);
                        });

                        event.UnallocatedCategoryGroups.forEach((group, index) => {
                          group.SelectedEventDates.push(newDate.Guid);
                        });

                        event.Dates.push(newDate);
                      });

                    onEventUpdated({
                      ...event,
                      UnallocatedCategoryGroups: [...event.UnallocatedCategoryGroups],
                      AllocatedCategoryGroups: [...event.AllocatedCategoryGroups],
                      Dates: linq
                        .from([...event.Dates])
                        .orderBy((d) => moment(d.DateAsString).unix())
                        .toArray(),
                    });
                    setSortedDates(getSortedDates(event.Dates));
                    setCopyDate(null);
                  }}
                  disabled={
                    linq
                      .from(copyDate.times)
                      .where((t) => t.checked)
                      .count() == 0
                  }
                  text={`Copy ${linq
                    .from(copyDate.times)
                    .where((t) => t.checked)
                    .count()} event times`}
                />
                <Button className="cancel" onClick={() => setCopyDate(null)} text="Cancel" />
              </SpacerTable>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
    </>
  );
};

export default DateAndTimeSection;

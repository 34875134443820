import React, { FunctionComponent, useRef, useState } from 'react';

import { IOption } from '../interfaces/IOption';
import SelectOption from './SelectOption';

export interface IProps {
  expanded?: boolean;
  onSelectedValueChange?(option: IOption, meta?: any): void;
  options: Array<IOption>;
  selectedId?: any;
  readOnly?: boolean;
  nothingSelectedText?: string;
  meta?: any;
  id?: string;
}

export default ((props) => {
  const [expanded, setExpanded] = useState<boolean>(props.expanded);

  const HoverDropdown = React.forwardRef((props, ref: any) => (
    <div className="dropdown hover">
      <table ref={ref}>
        <tbody>
          {firstOption}
          {expanded && options.length > 1 ? optionComponents : null}
        </tbody>
      </table>
    </div>
  ));

  const dropdownRef = useRef();

  const handleSelectOptionClicked = (option: IOption) => {
    let valueChanged = option.Id != props.selectedId;
    setExpanded(false);

    if (props.onSelectedValueChange && valueChanged) {
      props.onSelectedValueChange(option, props.meta);
    }
  };

  let optionComponents: Array<React.ReactNode> = [];
  let options = props.options ? props.options : [];

  if (props.readOnly) {
    options = props.options.filter((o) => {
      return o.Id == props.selectedId;
    });
  }

  const selectedId = props.selectedId;
  let selectedOption: IOption = null;

  options.forEach((o: IOption) => {
    if (selectedId == o.Id) {
      selectedOption = o;
    } else {
      optionComponents.push(
        <tr className="break" key={'SPACER_' + o.Id}>
          <td></td>
          <td></td>
          <td></td>
        </tr>,
      );
      optionComponents.push(
        <SelectOption
          key={o.Id}
          onClick={options.length <= 1 ? null : handleSelectOptionClicked.bind(this)}
          option={o}
        ></SelectOption>,
      );
    }
  });

  const firstOption = (
    <tr
      className="firstSelectOption"
      onClick={
        options.length <= 1
          ? null
          : () => {
              setExpanded(!expanded);
            }
      }
    >
      <td>
        {selectedOption != null
          ? selectedOption.Text
          : props.nothingSelectedText
            ? props.nothingSelectedText
            : 'Nothing selected'}
        {selectedOption != null && selectedOption.Tags && (
          <div className="select-tags">
            {selectedOption.Tags.map((tag) => (
              <span className="select-tag">{tag}</span>
            ))}
          </div>
        )}
      </td>
      {selectedOption != null && selectedOption.TextRight ? (
        <td className="right nowrap">{selectedOption.TextRight}</td>
      ) : (
        <td></td>
      )}
      {options.length > 1 ? (
        <td className="arrow">
          <span></span>
        </td>
      ) : null}
    </tr>
  );

  const changeOptionByArrow = (option: IOption) => {
    let valueChanged = option.Id != props.selectedId;
    if (props.onSelectedValueChange && valueChanged) {
      props.onSelectedValueChange(option, props.meta);
    }
  };

  return (
    <div
      id={props.id}
      className={'select' + (expanded ? ' expanded' : '') + (options.length > 1 ? ' has-options' : ' no-options')}
    >
      {expanded && (
        <div
          className="backdrop"
          onClick={() => {
            setExpanded(false);
          }}
        ></div>
      )}

      <table
        ref={dropdownRef}
        className="dropdown"
        tabIndex={0}
        onKeyDown={(e) => {
          if (options.length == 0) return;

          const currentIndex = options.indexOf(selectedOption);

          if (e.key === 'Tab') {
            setExpanded(false);
          }

          if (e.key === 'Enter') {
            setExpanded(!expanded);
          }

          if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (selectedOption == null) {
              changeOptionByArrow(options[0]);
            } else {
              if (currentIndex == options.length - 1) {
                changeOptionByArrow(options[0]);
              } else {
                changeOptionByArrow(options[currentIndex + 1]);
              }
            }
          } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (selectedOption == null) {
              changeOptionByArrow(options[options.length - 1]);
            } else {
              if (currentIndex == 0) {
                changeOptionByArrow(options[options.length - 1]);
              } else {
                changeOptionByArrow(options[currentIndex - 1]);
              }
            }
          }
        }}
      >
        <tbody>{firstOption}</tbody>
      </table>

      <HoverDropdown />
    </div>
  );
}) as FunctionComponent<IProps>;

import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import Block from '../../components/Block';
import BlockDatePicker, { IDatePickerValue } from '../../components/BlockDatePicker';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import BlockTimePicker, { ITimePickerValue } from '../../components/BlockTimePicker';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import Info from '../../components/Info';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import SpacerTable from '../../components/SpacerTable';
import CacheHelper from '../../helpers/CacheHelper';
import EventHelper from '../../helpers/EventHelper';
import GuidHelper from '../../helpers/GuidHelper';
import StringHelper from '../../helpers/StringHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import SVGCopy from '../../svg/SVGCopy';
import SVGEditEvent from '../../svg/SVGEditEvent';
import SVGInfo from '../../svg/SVGInfo';
import SVGNew from '../../svg/SVGNew';
import SVGSave from '../../svg/SVGSave';
import SVGTicket from '../../svg/SVGTicket';
import DateHelper from '../../helpers/DateHelper';
import SeatingPlanSvg from '../../components/seating_plan_svg/SeatingPlan';
import { ViewMode } from '../Editor/event/EventEditorPage';
import Button from '../../components/Button';
import LocationHelper from '../../helpers/LocationHelper';
import EventTypes from '../../interfaces/IEventType';
import ReactQuill from 'react-quill';

interface IWizardResult {
  Organisations: IWizardOrganisation[];
}

interface IWizardOrganisation {
  Id: number;
  Name: string;
  Tag: string;
  Events: IWizardEvent[];
  Tours: IWizardTour[];
}

interface IWizardTour {
  Id: number;
  Name: string;
  Tag: string;
}

interface IWizardEvent {
  Id: number;
  Name: string;
  Tag: string;
}

export enum EventWizardSection {
  EventOptions,
  NewEvent,
  ChooseEventToCopy,
  EventSaved,
  CopyEvent,
  SearchAllEvents,
}

enum EventType {
  Seated,
  General,
  Both,
}

export interface IProps {
  onClose: () => void;
  organisation?: IOrganisation;
  tour?: ITour;
  startFrom?: EventWizardSection;
}

const WizardEvent: React.FC<IProps> = (props) => {
  const { onClose, organisation, tour } = props;

  const [state, setState] = useState<{
    eventTourId: number;
    wizardResult: IWizardResult;
    busy: string;
    section: EventWizardSection;
    event: IEvent;
    copyingEvent: IEvent;
    query: string;
    error: string;
    eventType: EventType;
    dates: { date: IDatePickerValue; time: ITimePickerValue }[];
    eventName: string;
    eventTag: string;
    eventOnSale: { date: IDatePickerValue; time: ITimePickerValue };
    type: string;
    eventDescription: string;
    venueName: string;
    venueStreetAddress: string;
    venueTown: string;
    venuePostCode: string;
  }>({
    eventTourId: tour ? tour.Id : 0,
    wizardResult: null,
    busy: 'Setting things up...',
    section: props.startFrom ? props.startFrom : EventWizardSection.EventOptions,
    event: EventHelper.getDefault(),
    copyingEvent: null,
    query: '',
    eventType: EventType.Seated,
    error: null,
    dates: [{ date: null, time: null }],
    eventName: '',
    eventTag: '',
    eventOnSale: {
      date: {
        allHaveValue: true,
        isDate: true,
        moment: moment(moment().add(1, 'week').format('YYYY-MM-DD') + ' 00:00'),
        string: moment().format('DD/MM/YYYY'),
      },
      time: {
        allHaveValue: true,
        isTime: true,
        moment: moment(moment().add(1, 'week').format('YYYY-MM-DD') + ' 00:00'),
        string: moment().format('HH:mm'),
      },
    },
    type: null,
    eventDescription: '',
    venueName: '',
    venueStreetAddress: '',
    venueTown: '',
    venuePostCode: '',
  });

  const {
    wizardResult,
    section,
    busy,
    eventTourId,
    event,
    copyingEvent,
    query,
    eventType,
    error,
    dates,
    eventName,
    eventTag,
    eventOnSale,
    type,
    eventDescription,
    venueName,
    venueStreetAddress,
    venueTown,
    venuePostCode,
  } = state;

  const pathName = LocationHelper.getLocation().pathname;
  const path = pathName.split('/');

  const [currentURLEvent, setCurrentURLEvent] = useState<IEvent>(null);

  const [gptRuns, setGptRuns] = useState(0);
  const gtpLimit = 5;

  useEffect(() => {
    if (path.length == 5 && path[2] == 'tickets' && path[1].length > 0) {
      CacheHelper.eventByTag(path[1]).then((_e) => {
        setCurrentURLEvent(_e);
      });
    } else if (path.length == 2 && path[1].length > 0) {
      CacheHelper.eventByTag(path[1]).then((_e) => {
        setCurrentURLEvent(_e);
      });
    }

    AdminApi.request('GET', '/api/EventWizard')
      .then((results) => {
        setState({ ...state, busy: null, wizardResult: results });
      })
      .catch((message) => alert(message));
  }, []);

  const [generatingEventDescription, setGeneratingEventDescription] = useState(false);

  const generateEventDescription = () => {
    setGeneratingEventDescription(true);
    setGptRuns(gptRuns + 1);
    AdminApi.request('POST', '/api/ChatGptEventDescription', {
      name: eventName,
      eventTag: eventTag,
      type: type,
      organisationId: organisation.Id,
      dates: dates.map((d) => d.date.moment.format('YYYY-MM-DD') + ' ' + d.time.moment.format('HH:mm')),
      venueName: venueName,
      venueStreetAddress: venueStreetAddress,
      venueTown: venueTown,
      venuePostCode: venuePostCode,
      seated: eventType === EventType.Seated || eventType === EventType.Both,
      general: eventType === EventType.General || eventType === EventType.Both,
      eventDescription: eventDescription,
    })
      .then((result) => {
        if (result) {
          setState({ ...state, eventDescription: result });
          setGeneratingEventDescription(false);
        }
      })
      .catch((message) => alert(message));
  };

  const eventOptionsSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Create Event</h1>
          Let's set up some of the basics for your {organisation.Name} event, before taking you to the full event
          editor.
          {tour && ` This event will be added to tour #${tour.TourTag}.`}
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block
              id="makeNewEventButton"
              className="action has-icon"
              onClick={() =>
                setState({ ...state, section: EventWizardSection.NewEvent, event: EventHelper.getDefault() })
              }
            >
              <SVGNew />
              <BlockHeader>Make a new event</BlockHeader>
              <div>Create a brand new event for {organisation.Name}.</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block
              id="copyExistingEventButton"
              className="action has-icon"
              onClick={() => setState({ ...state, section: EventWizardSection.ChooseEventToCopy })}
            >
              <SVGCopy />
              <BlockHeader>Copy {organisation.Name} event</BlockHeader>
              <div>Take the details of your event from one that already exists for your organisation. </div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block
              id="copySeatyEvent"
              className="action has-icon"
              onClick={() => setState({ ...state, section: EventWizardSection.SearchAllEvents })}
            >
              <SVGCopy />
              <BlockHeader>Search and copy other Seaty event</BlockHeader>
              <div>
                You can search all our events, old and new, by venue, event name or organisation name and create a new
                event with the same seating plan and venue details for your organisation.
              </div>
            </Block>
          </tbody>
        </table>
        {currentURLEvent && (
          <>
            <div className="spacer" />
            <table className="blocks">
              <tbody>
                <Block
                  className="action has-icon"
                  onClick={() => {
                    setState({
                      ...state,
                      section: EventWizardSection.CopyEvent,
                      busy: null,
                      eventName: currentURLEvent.Name,
                      copyingEvent: currentURLEvent,
                    });
                  }}
                >
                  <SVGCopy />
                  <BlockHeader>Copy the current event #{currentURLEvent.EventTag}</BlockHeader>
                  <div>
                    You are currently viewing the event {currentURLEvent.Name}, click here if you would you like to copy
                    the details from this event to your new one.
                  </div>
                </Block>
              </tbody>
            </table>
          </>
        )}
        <div className="spacer" />
      </>
    );
  };

  const copyEventSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Copy an Event</h1>
          We have listed all the events you have access to, select one to copy most of the details from it to your new
          event and go to the full event editor. This event will be linked to the organisation #
          {organisation.OrganisationTag}.
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Search</BlockHeader>
              <BlockTextBox
                name="query"
                type="text"
                autoFocus
                value={query}
                placeholder={'Event name or tag'}
                onChange={(e) => setState({ ...state, query: e })}
              />
            </Block>

            {linq
              .from(wizardResult.Organisations)
              .selectMany((o) => o.Events)
              .where((e: any) => e.Name.includes(query) || e.EventTag.includes(query))
              .orderBy((e) => e.Name)
              .thenBy((e) => e.EventTag)
              .select((e) => (
                <React.Fragment key={e.Id}>
                  <Block
                    className="route"
                    onClick={() => {
                      setState({ ...state, busy: 'Loading event details...' });

                      CacheHelper.eventByTag(e.EventTag).then((_e) => {
                        setState({
                          ...state,
                          section: EventWizardSection.CopyEvent,
                          busy: null,
                          eventName: _e.Name,
                          eventDescription: _e.Description,
                          type: _e.Type,
                          venueName: _e.Venue?.Name,
                          venueStreetAddress: _e.Venue?.StreetAddress,
                          venueTown: _e.Venue?.City,
                          venuePostCode: _e.Venue?.Postcode,
                          copyingEvent: _e,
                        });
                      });
                    }}
                  >
                    <BlockHeader>{e.Name}</BlockHeader>
                    <div>#{e.EventTag}</div>
                  </Block>
                </React.Fragment>
              ))
              .toArray()}
          </tbody>
        </table>
        <div className="spacer" />
      </>
    );
  };

  const [searchResults, setSearchResults] = useState<Array<IEvent>>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(null);
  const [preview, setPreview] = useState<IEvent>(null);

  useEffect(() => {
    if (!searchTerm || searchTerm.length == 0) {
      setSearchResults(null);
      return;
    }

    setLoading('Searching for events...');

    AdminApi.request('POST', '/api/SearchEvents', {
      seatingPlanOnly: true,
      term: searchTerm,
      organisationTag: '',
      allTime: true,
    })
      .then((response) => {
        setLoading(null);
        setSearchResults(response);
      })
      .catch(() => {
        setLoading(null);
      });
  }, [searchTerm]);

  const containerRef = useRef();

  const searchEventsSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Search for Events</h1>
          Use the search field below to enter a phrase from the event name, venue name or address or organisation name
          and submit to search all our events. You can then select the event to preview and confirm the seating plan.
          This new event will be linked to your organisation #{organisation.OrganisationTag}. All details, tickets and
          prices can be added or modified later in the full event editor once the copy is complete.
        </SpacerTable>

        <div className="spacer" />

        {loading ? (
          <Loader inline>{loading}</Loader>
        ) : preview ? (
          <>
            <table className="blocks">
              <tbody>
                <Block>
                  <BlockHeader>Preview Event</BlockHeader>
                </Block>

                <BlockInfo>
                  Below is a preview of the seating plan for {preview.Name}. Please confirm this is the plan you want to
                  copy to continue.
                </BlockInfo>
              </tbody>
            </table>
            {linq
              .from(preview.SeatingPlans)
              .orderBy((s) => s.Index)
              .toArray()
              .map((seatingPlan) => (
                <React.Fragment key={seatingPlan.Id}>
                  <div className="event-seating-plan seating-plan-svg-content is-admin">
                    <SeatingPlanSvg
                      viewMode={ViewMode.Background}
                      containerHeight={400}
                      containerWidth={
                        containerRef && containerRef.current && (containerRef.current as HTMLDivElement).clientWidth
                      }
                      seatingPlan={seatingPlan}
                    />
                  </div>
                </React.Fragment>
              ))}
            <SpacerTable>
              <Button
                className="confirm"
                text={'Continue'}
                onClick={() => {
                  setState({
                    ...state,
                    section: EventWizardSection.CopyEvent,
                    busy: null,
                    eventName: preview.Name,
                    copyingEvent: preview,
                  });
                }}
              />
              <Button className="cancel" text={'Back'} onClick={() => setPreview(null)} />
            </SpacerTable>
          </>
        ) : (
          <>
            <table className="blocks">
              <tbody>
                <Block>
                  <BlockHeader>Search</BlockHeader>
                  <BlockTextBox
                    name="query"
                    onKeyPress={(e) => {
                      if (e.charCode === 13 || e.charCode === 32) {
                        setSearchTerm(state.query);
                      }
                    }}
                    type="text"
                    autoFocus
                    value={query}
                    placeholder={'Event name or tag'}
                    onChange={(e) => setState({ ...state, query: e })}
                  />
                </Block>
              </tbody>
            </table>
            <SpacerTable>
              <Button
                className="confirm"
                text={'Submit'}
                onClick={() => {
                  setSearchTerm(state.query);
                }}
              />
            </SpacerTable>

            <div className="spacer"></div>

            <table className="blocks">
              <tbody>
                {!searchTerm || searchTerm.length == 0 ? (
                  <BlockInfo>Please enter a search term and submit...</BlockInfo>
                ) : !searchResults || searchResults.length == 0 ? (
                  <BlockInfo>No events found that for your search term...</BlockInfo>
                ) : (
                  <>
                    <Block>
                      <BlockHeader>{searchResults.length} Results</BlockHeader>
                    </Block>
                    {linq
                      .from(searchResults)
                      .orderByDescending((e) => moment(e.MinDate).unix())
                      .select((e) => (
                        <React.Fragment key={e.Id}>
                          <Block
                            id={e.EventTag}
                            className="route"
                            onClick={() => {
                              setState({ ...state, busy: 'Loading event details...' });

                              CacheHelper.eventByTag(e.EventTag).then((_e) => {
                                // setState({ ...state, section: EventWizardSection.CopyEvent, busy: null, eventName: e.Name, copyingEvent: _e });
                                setState({ ...state, busy: null });

                                const seatCategories = {};

                                _e.SeatingPlans.forEach((seatingPlan) => {
                                  linq
                                    .from(seatingPlan.SeatCategories)
                                    .toArray()
                                    .forEach((s) => {
                                      seatCategories[s.Id] = s;
                                    });

                                  seatingPlan.Seats = seatingPlan.Seats.map((_seat) => {
                                    const seat = { ..._seat };
                                    seat.SeatCategory = seatCategories[seat.SeatCategoryId];
                                    return seat;
                                  });
                                });

                                setPreview(_e);
                              });
                            }}
                          >
                            <div>{e.Organisation.Name}</div>
                            <BlockHeader>{e.Name}</BlockHeader>
                            <div>{DateHelper.fromTo(e.MinDate, e.MaxDate)}</div>
                            {e.Venue.Name && (
                              <div>
                                {e.Venue.Name}
                                {e.Venue.City && <span>, {e.Venue.City}</span>}
                              </div>
                            )}
                          </Block>
                        </React.Fragment>
                      ))
                      .toArray()}
                  </>
                )}
              </tbody>
            </table>
          </>
        )}

        <div className="spacer" />
      </>
    );
  };

  const eventOnSaleValid =
    eventOnSale.time &&
    eventOnSale.time.isTime &&
    eventOnSale.time.allHaveValue &&
    eventOnSale.date &&
    eventOnSale.date.isDate &&
    eventOnSale.date.allHaveValue;
  const eventOnSaleIsFuture =
    eventOnSaleValid &&
    moment(eventOnSale.date.moment.format('YYYY-MM-DD') + ' ' + eventOnSale.time.moment.format('HH:mm')).isAfter();

  const eventOnSaleFormatted =
    eventOnSaleValid && eventOnSaleIsFuture ? (
      <>
        Event live from {eventOnSale.date.moment.format('dddd Do MMMM YYYY')} at{' '}
        {eventOnSale.time.moment.format('h:mma')}
      </>
    ) : !eventOnSaleIsFuture ? (
      <>Please enter a date in the future</>
    ) : (
      <>Please enter a valid date and time</>
    );

  let allDatesValid = true;
  let allDatesFuture = true;

  dates.forEach((d, index) => {
    const isValidDate =
      d.time && d.time.isTime && d.time.allHaveValue && d.date && d.date.isDate && d.date.allHaveValue;
    const isFuture =
      isValidDate && moment(d.date.moment.format('YYYY-MM-DD') + ' ' + d.time.moment.format('HH:mm')).isAfter();

    if (!isValidDate) {
      allDatesValid = false;
    }
    if (!isFuture) {
      allDatesFuture = false;
    }
  });

  const validEventSave =
    allDatesValid &&
    allDatesFuture &&
    eventName.length > 2 &&
    eventTag.length > 2 &&
    type?.length > 2 &&
    eventDescription.length > 2 &&
    venueName.length > 2 &&
    venueStreetAddress.length > 2 &&
    venueTown.length > 2 &&
    venuePostCode.length > 2;

  const saveEvent = () => {
    setState({ ...state, error: null, busy: 'Saving event...' });

    let baseEvent = copyingEvent ? EventHelper.copyEvent(organisation, copyingEvent) : event;

    let e: IEvent = {
      ...baseEvent,
      Name: eventName,
      EventTag: eventTag,
      Type: type as any,
      Description: eventDescription,
      Venue: {
        City: venueTown,
        Name: venueName,
        Postcode: venuePostCode,
        StreetAddress: venueStreetAddress,
      },
      OnlineFrom: eventOnSale.date.moment.format('YYYY-MM-DD') + ' ' + eventOnSale.time.moment.format('HH:mm:ss.SSS'),
      Dates: dates.map((d) => ({
        Id: 0,
        DateAsString: d.date.moment.format('YYYY-MM-DD') + ' ' + d.time.moment.format('HH:mm'),
        Guid: GuidHelper.new(),
      })),
      OrganisationId: organisation.Id,
      TourId: eventTourId && eventTourId > 0 ? eventTourId : null,
      ...(copyingEvent
        ? {}
        : {
            UseSeatingPlan: eventType === EventType.Seated || eventType === EventType.Both,
            UseUnallocatedTickets: eventType === EventType.General || eventType === EventType.Both,
          }),
    };

    EventHelper.save(
      e,
      (b) => {
        setState({ ...state, busy: b });
      },
      null,
    )
      .then((e: IEvent) => {
        setState({ ...state, busy: null, event: e, section: EventWizardSection.EventSaved });
      })
      .catch((error) => {
        setState({ ...state, busy: null, error: error });
      });
  };

  const eventDetailSection = () => {
    return (
      <>
        {copyingEvent ? (
          <SpacerTable>
            <h1>Copying Event</h1>
            Tell us some of the basics about your new event for #{organisation.OrganisationTag}. When your new event is
            set up you will be given access to the full event editor.
          </SpacerTable>
        ) : (
          <SpacerTable>
            <h1>Create an Event</h1>
            Tell us some of the basics about your event for #{organisation.OrganisationTag}. When your new event is set
            up you will be given access to the full event editor.
          </SpacerTable>
        )}

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            {copyingEvent && (
              <BlockInfo>
                We will copy basic details such as venue, seating plan and ticket setup options from the event #
                {copyingEvent.EventTag} you have selected.{' '}
              </BlockInfo>
            )}
            <Block>
              <BlockHeader>What is the name of your event?</BlockHeader>
              <BlockTextBox
                name="eventName"
                autoFocus={true}
                placeholder="Event name"
                value={eventName}
                onChange={(e) => setState({ ...state, eventName: e })}
                rows={1}
              />
              <div className="spacer-x05" />
              <BlockHeader>What unique event tag would you like?</BlockHeader>
              <BlockTextBox
                name="eventTag"
                maxLength={22}
                placeholder="Event tag"
                value={eventTag}
                onChange={(value) => {
                  !StringHelper.hasSpecialCharacters(value) && setState({ ...state, eventTag: value });
                }}
                rows={1}
              />
            </Block>
            <BlockInfo>
              An event tag is your unique link to Seaty.co.uk/{eventTag.length > 0 ? eventTag.toLowerCase() : 'tag'} and
              an easy way to refer to your event.
            </BlockInfo>
            <Block>
              <BlockHeader>What type of event is this?</BlockHeader>
              <Select
                id="eventType"
                onSelectedValueChange={(option) => setState({ ...state, type: option.Id })}
                selectedId={type}
                options={Object.values(EventTypes).map((type) => {
                  return {
                    Id: type.name,
                    Text: `${type.emoji} ${type.name}`,
                    Description: type.description,
                  };
                })}
              />
            </Block>
            <Block>
              <BlockHeader>Where is this event to be held?</BlockHeader>
              <BlockTextBox
                name="venueName"
                placeholder="Venue name"
                value={venueName}
                onChange={(e) => setState({ ...state, venueName: e })}
              />
              <div className="spacer-x05" />
              <BlockHeader>What is the venue address?</BlockHeader>
              <BlockTextBox
                name="venueStreetAddress"
                placeholder="Street address"
                value={venueStreetAddress}
                onChange={(e) => setState({ ...state, venueStreetAddress: e })}
              />
              <div className="spacer-x05" />
              <BlockTextBox
                name="venueTown"
                placeholder="Town or city"
                value={venueTown}
                onChange={(e) => setState({ ...state, venueTown: e })}
              />
              <div className="spacer-x05" />
              <div style={{ width: '250px' }}>
                <BlockTextBox
                  name="venuePostCode"
                  placeholder="Postal code"
                  value={venuePostCode}
                  onChange={(e) => setState({ ...state, venuePostCode: e })}
                />
              </div>
            </Block>
            {organisation && organisation.Tours && organisation.Tours.length > 0 && (
              <Block>
                <BlockHeader>Is this event part of a tour?</BlockHeader>
                <Select
                  selectedId={eventTourId}
                  onSelectedValueChange={(v) => setState({ ...state, eventTourId: v.Id as number })}
                  options={[
                    { Text: 'No, do not use a tour', Id: 0 },
                    ...linq
                      .from(organisation.Tours)
                      .orderByDescending((t) => t.Id)
                      .toArray()
                      .map((t) => {
                        return { Text: t.Name, Id: t.Id };
                      }),
                  ]}
                ></Select>
              </Block>
            )}
            <Block>
              <BlockHeader>When is your event?</BlockHeader>

              {dates.map((d, index) => {
                let initialValue = null;

                if (d.date && d.date.moment && d.time && d.time.moment) {
                  initialValue = moment(d.date.moment.format('YYYY-MM-DD') + ' ' + d.time.moment.format('HH:mm'));
                }

                return (
                  <div style={{ marginBottom: '6px' }}>
                    <DateAndTimePicker
                      enforceFuture
                      groupName={index.toString()}
                      initialValue={initialValue}
                      onChange={(dateTime) => {
                        dates[index].date = dateTime.date;
                        dates[index].time = dateTime.time;
                        setState({ ...state, dates: dates });
                      }}
                    />
                  </div>
                );
              })}

              <div className="block-buttons right">
                <button
                  onClick={() => {
                    const lastDate = dates[dates.length - 1];
                    const isValidDateTime =
                      lastDate.time &&
                      lastDate.time.isTime &&
                      lastDate.time.allHaveValue &&
                      lastDate.date &&
                      lastDate.date.isDate &&
                      lastDate.date.allHaveValue;

                    if (isValidDateTime) {
                      setState({
                        ...state,
                        dates: [
                          ...dates,
                          {
                            date: {
                              ...lastDate.date,
                              moment: lastDate.date.moment.clone().add('1', 'day'),
                              string: lastDate.date.moment.clone().add('1', 'day').format('DD/MM/YYYY'),
                            },
                            time: lastDate.time,
                          },
                        ],
                      });
                    } else {
                      setState({ ...state, dates: [...dates, { date: null, time: null }] });
                    }
                  }}
                  className="block-button"
                >
                  Add another date
                </button>
                <button
                  className={`block-button bad${dates.length == 1 ? ' disabled' : ''}`}
                  onClick={() => {
                    if (dates.length > 1) {
                      setState({ ...state, dates: dates.slice(0, -1) });
                    }
                  }}
                >
                  Remove
                </button>
              </div>
            </Block>

            {!copyingEvent && (
              <Block>
                <BlockHeader>How will your tickets be sold?</BlockHeader>
                <Radio
                  selectedId={eventType}
                  onValueChanged={(option) => setState({ ...state, eventType: option.Id })}
                  options={[
                    {
                      Text: `Seating plan`,
                      Info: 'Attendees will select seated tickets from a seating plan.',
                      Id: EventType.Seated,
                    },
                    {
                      Text: 'General admission',
                      Info: 'Tickets will have no seating plan and will have set quantities.',
                      Id: EventType.General,
                    },
                  ]}
                />
              </Block>
            )}

            <Block>
              <BlockHeader>How would you describe this event?</BlockHeader>
              <ReactQuill
                theme="snow"
                value={eventDescription}
                onChange={(v, e, b) => {
                  setState({ ...state, eventDescription: v });
                }}
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                  ],
                }}
              />
              <div className="block-buttons right" style={{ marginTop: '8px' }}>
                <button
                  onClick={generateEventDescription}
                  disabled={
                    gptRuns >= gtpLimit ||
                    generatingEventDescription ||
                    !(
                      eventName.length > 2 &&
                      eventTag.length > 2 &&
                      type?.length > 2 &&
                      dates.length > 0 &&
                      venueName.length > 2 &&
                      venueStreetAddress.length > 2 &&
                      venueTown.length > 2 &&
                      venuePostCode.length > 2 &&
                      allDatesValid &&
                      allDatesFuture
                    )
                  }
                  className="block-button"
                >
                  {generatingEventDescription
                    ? `Generating...`
                    : gptRuns >= gtpLimit
                      ? `AI Limit Reached`
                      : `🤖 Generate with Seaty AI`}
                </button>
              </div>
            </Block>

            <Block>
              <BlockHeader>When do you want tickets to go on sale?</BlockHeader>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '60%', paddingRight: '8px' }}>
                      <BlockDatePicker
                        initialValue={eventOnSale.date && eventOnSale.date.moment}
                        groupName={`DateTimeOnSale`}
                        onChange={(_date) => {
                          setState({ ...state, eventOnSale: { ...eventOnSale, date: _date } });
                        }}
                      />
                    </td>
                    <td style={{ width: '40%', paddingLeft: '8px' }}>
                      <BlockTimePicker
                        initialValue={eventOnSale.time && eventOnSale.time.moment}
                        groupName={`DateTimeOnSale`}
                        onChange={(_time) => {
                          setState({ ...state, eventOnSale: { ...eventOnSale, time: _time } });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="spacer-x05" />
              <Info>{eventOnSaleFormatted}.</Info>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            {validEventSave ? (
              <Block id="saveAndCopyEvent" className={`action has-icon`} onClick={() => saveEvent()}>
                <SVGSave />

                {copyingEvent ? (
                  <>
                    <BlockHeader>Save & copy event</BlockHeader>
                    <div>Submit these details and create a new event based on #{copyingEvent.EventTag}.</div>
                  </>
                ) : (
                  <>
                    <BlockHeader>Save & create new event</BlockHeader>
                    <div>Submit these details and create a new event for {organisation.Name}.</div>
                  </>
                )}
              </Block>
            ) : (
              <Block className={`action disabled has-icon `}>
                <SVGInfo />
                <BlockHeader>Awaiting details</BlockHeader>
                <div>You must enter the required fields before you can save your event.</div>
              </Block>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const eventSavedSection = () => {
    return (
      <>
        <SpacerTable>
          <h1>Congrationations!</h1>
          You now have the basics of your event set up. Now its time to go to the full event editor where you can do
          things like create a seating plan, add tickets, discounts, images and themes.
        </SpacerTable>

        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Organisation</BlockHeader>
              {organisation.Name}
            </Block>
            {eventTourId && eventTourId > 0 ? (
              <Block>
                <BlockHeader>Tour</BlockHeader>
                {linq.from(organisation.Tours).firstOrDefault((t) => t.Id == eventTourId)?.Name}
              </Block>
            ) : null}
            <Block>
              <BlockHeader>Event name</BlockHeader>
              {eventName}
            </Block>
            <Block>
              <BlockHeader>Event tag</BlockHeader>
              Seaty.co.uk/{eventTag}
            </Block>
            <Block>
              <BlockHeader>Ticket type</BlockHeader>
              {eventType === EventType.Seated && 'Seated tickets'}
              {eventType === EventType.General && 'General admission tickets'}
              {eventType === EventType.Both && 'Seated & general admission tickets'}
            </Block>
            <Block>
              <BlockHeader>Dates</BlockHeader>
              {dates.map((d, index) => {
                return (
                  <React.Fragment key={index}>
                    <div>
                      {d.date.moment.format('dddd Do MMMM YYYY')} at {d.time.moment.format('h:mma')}
                    </div>
                  </React.Fragment>
                );
              })}
            </Block>

            <BlockInfo type={InfoType.Warning}>
              Make sure you save your event often after editing a section. You can save at any time, even when you have
              not yet finished setting up your event, and no changes will be submitted for you event until you save.
            </BlockInfo>
            <BlockInfo>
              Hints and tips for many fields are available by selecting the info icon next to the name of an option.
            </BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />

        <table className="blocks">
          <tbody>
            <Block
              id="openEventEditorButton"
              className="action has-icon"
              onClick={() => {
                window.location.href = `/Organisation/${organisation.OrganisationTag}/Event/${event.EventTag}/Edit`;
              }}
            >
              <SVGEditEvent />
              <BlockHeader>Open event editor</BlockHeader>
              <div>Go to the full editor and add more detail to your event.</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block
              className="action has-icon"
              onClick={() => {
                window.location.href = `/${event.EventTag}`;
              }}
            >
              <SVGTicket />
              <BlockHeader>Go to your event</BlockHeader>
              <div>Go to your event landing page at Seaty.co.uk/{event.EventTag}</div>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
      </>
    );
  };

  return (
    <Modal
      onCloseClick={props.onClose}
      theme={tour && tour.Theme ? tour.Theme : organisation && organisation.Theme ? organisation.Theme : null}
    >
      <div className="content" ref={containerRef}>
        <div className="ticket-rip" />

        <div className="body">
          {busy && <Loader inline={true}>{busy}</Loader>}

          <div style={{ display: busy ? 'none' : 'block' }}>
            {section === EventWizardSection.EventOptions && eventOptionsSection()}
            {section === EventWizardSection.NewEvent && eventDetailSection()}
            {section === EventWizardSection.CopyEvent && eventDetailSection()}
            {section === EventWizardSection.ChooseEventToCopy && copyEventSection()}
            {section === EventWizardSection.SearchAllEvents && searchEventsSection()}
            {section === EventWizardSection.EventSaved && eventSavedSection()}

            {error && (
              <table className="blocks" style={{ marginTop: '25px' }}>
                <tbody>
                  <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
};

export default WizardEvent;

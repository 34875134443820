import { FunctionComponent } from 'react';
import StringHelper from '../../../../helpers/StringHelper';
import SVGHelper from '../../../../helpers/SVGHelper';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import SVGMember from '../../../../svg/SVGMember';

interface IRowData {
  TicketCount: number;
  TicketPeopleCount: number;
  MerchandiseCount: number;
  MerchandiseItemCount: number;
  Email: string;
  IsMember: boolean;
  IsAdmin: boolean;
  UserId: number;
  User: string;
  PurchaserName: string;
  AttendeeName: string;
  MemberName: string;
  MemberId: number;
  UserImageUrl: string;
  Price: string;
  PriceAsInt: number;
  Paid: boolean;
  PaidAsInt: number;
  Owed: string;
  OwedAsInt: number;
  Tickets: number;
  People: number;
  CurrencySymbol: string;
  ReferralsReceived: number;
  ReferralsReceivedPriceAsInt: number;
  ReferralsGiven: number;
  ReferralsGivenPriceAsInt: number;
  MemberType: string;
  Quota: number;
}

export interface IProps {
  eventId: number;
  eventTag: string;
  currency: string;
  membershipsEnabled: boolean;
  data: IRowData;
  handleRowClick: (daya: any) => void;
  hideMoney: boolean;
  hideEmail: boolean;
  getName: (rowData) => string;
  includeReferrals: boolean;
}

const AdminBalanceRow: FunctionComponent<IProps> = (props) => {
  const handleRowClick = () => {
    if (props.handleRowClick) {
      props.handleRowClick(props.data);
    }
  };

  var rowData: IRowData = props.data;
  var balance = <span></span>;

  if (rowData.OwedAsInt == 0) {
    if (rowData.PriceAsInt == 0) {
      balance = <span>-</span>;
    } else {
      balance = (
        <span>
          <img alt="Okay" className="balanceImage tick" src={SVGHelper.get('Ok')} />
        </span>
      );
    }
  } else if (rowData.OwedAsInt < 0) {
    balance = (
      <span className="success">
        <img alt="Balance" className="balanceImage over" src={SVGHelper.get('Overpaid')} />
        {rowData.Owed.replace('-', '')}
      </span>
    );
  } else {
    balance = (
      <span className="danger">
        ({rowData.Owed})<img alt="Balance" className="balanceImage" src={SVGHelper.get('Owed')} />
      </span>
    );
  }

  var ticketsSold = rowData.TicketCount;

  var peopleCount = rowData.TicketPeopleCount;

  var ticketScore =
    rowData.TicketCount + (props.includeReferrals ? rowData.ReferralsReceived - rowData.ReferralsGiven : 0);

  var quotaPercentage = 0;
  if (rowData.Quota != null && rowData.Quota > 0) {
    quotaPercentage = (ticketScore / rowData.Quota) * 100;
    if (quotaPercentage > 100.0) {
      quotaPercentage = 100;
    }
  }

  if (rowData.MemberType == null || rowData.MemberType.length == 0) {
    rowData.MemberType = 'Member';
  }

  const name = props.getName(rowData);

  const showBreakdown = props.includeReferrals && (rowData.ReferralsGiven > 0 || rowData.ReferralsReceived > 0);

  return (
    <tr onClick={handleRowClick} className="route" style={{ display: 'table-row' }}>
      <td style={{ maxWidth: '400px' }}>
        {name && name.length > 0 && <div>{name}</div>}
        {props.hideEmail ? null : <div style={{ opacity: '0.7' }}>{rowData.Email}</div>}
      </td>
      <td className="quota-cell" data-name="Member" style={{ textAlign: 'right' }}>
        {!rowData.IsMember || !props.membershipsEnabled ? null : (
          <div className="quota">
            <div>{rowData.MemberType}</div>

            {rowData.Quota != null && rowData.Quota > 0 ? (
              ticketScore >= rowData.Quota ? (
                <div className={'quota-info'}>{rowData.Quota} Quota Complete</div>
              ) : (
                <div className={'quota-info'}>
                  Quota {ticketScore} / {rowData.Quota}
                </div>
              )
            ) : null}

            {rowData.Quota != null && rowData.Quota > 0 ? (
              <div
                className={
                  'quota-bar donotprint' +
                  (quotaPercentage >= 100 ? ' complete' : quotaPercentage >= 50 ? ' almost' : '')
                }
              >
                <div className="progress" style={{ width: quotaPercentage + '%' }}></div>
              </div>
            ) : null}
          </div>
        )}
      </td>
      {/* <td className="grid-table-icon">{rowData.IsMember ? <SVGMember /> : null}</td> */}

      <td data-name="User" style={{ textAlign: 'right' }}>
        {`${ticketScore} ${StringHelper.AddSWhenMany(ticketsSold, 'ticket')}`}

        {showBreakdown && (
          <div className="ticket-breakdown">
            <div>{`${ticketsSold} tickets sold`}</div>
            {rowData.ReferralsReceived > 0 && <div>+{rowData.ReferralsReceived} tickets referrals</div>}
            {rowData.ReferralsGiven > 0 && <div>-{rowData.ReferralsGiven} tickets referred</div>}
          </div>
        )}

        {peopleCount != null && peopleCount > 0 && peopleCount != ticketsSold && (
          <div className="ticket-breakdown">({peopleCount} people)</div>
        )}

        {rowData.MerchandiseCount > 0 && (
          <div>{`${rowData.MerchandiseCount} ${StringHelper.AddSWhenMany(rowData.MerchandiseCount, 'merch sale')}`}</div>
        )}
        {rowData.MerchandiseItemCount != null &&
          rowData.MerchandiseItemCount > 0 &&
          rowData.MerchandiseItemCount != rowData.MerchandiseCount && (
            <div className="ticket-breakdown">
              ({rowData.MerchandiseItemCount} {StringHelper.AddSWhenMany(rowData.MerchandiseItemCount, 'item')})
            </div>
          )}
      </td>
      {props.hideMoney ? null : (
        <td data-name="PriceAsInt" style={{ textAlign: 'right' }}>
          <div>
            {CurrencyHelper.formatCurrency(
              props.currency,
              props.includeReferrals
                ? rowData.PriceAsInt + rowData.ReferralsReceivedPriceAsInt - rowData.ReferralsGivenPriceAsInt
                : rowData.PriceAsInt,
            )}
          </div>

          {showBreakdown && (
            <div className="ticket-breakdown ">
              <div>{CurrencyHelper.formatCurrency(props.currency, rowData.PriceAsInt)} sales</div>
              {rowData.ReferralsReceived > 0 && (
                <div>
                  +{CurrencyHelper.formatCurrency(props.currency, rowData.ReferralsReceivedPriceAsInt)} referrals
                </div>
              )}
              {rowData.ReferralsGiven > 0 && (
                <div>-{CurrencyHelper.formatCurrency(props.currency, rowData.ReferralsGivenPriceAsInt)} referred</div>
              )}
            </div>
          )}
        </td>
      )}
      {props.hideMoney || props.includeReferrals ? null : (
        <td data-name="OwedAsInt" style={{ textAlign: 'right' }}>
          {balance}
        </td>
      )}
    </tr>
  );
};

export default AdminBalanceRow;

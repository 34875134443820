import React, { useState } from 'react';
import { IEvent } from '../../../interfaces/IEvent';
import SVGDiscount from '../../../svg/SVGDiscount';
import Toolbar from '../toolbar/Toolbar';
import Discount from './Discount';
import { IDiscount, IDiscountCode } from '../../../interfaces/IDiscount';
import SVGGoBackArrow from '../../../svg/SVGGoBackArrow';
import NumberHelper from '../../../helpers/NumberHelper';
import StringHelper from '../../../helpers/StringHelper';
import linq from 'linq';
import GuidHelper from '../../../helpers/GuidHelper';
import SVGTrash from '../../../svg/SVGTrash';
import DateHelper from '../../../helpers/DateHelper';
import moment from 'moment';
import SVGOrders from '../../../svg/SVGOrders';
import { useRecoilState } from 'recoil';
import orderState from '../../../atoms/orderState';
import SVGCancel from '../../../svg/SVGCancel';
import RequestModal from '../../Admin/Sections/Requests/RequestModal';
import SVGRequest from '../../../svg/SVGRequest';

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const DiscountsSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;
  const [manageDiscountCodes, setManageDiscountCodes] = useState<IDiscount>(null);
  const [addAmount, setAddAmount] = useState('1');
  const [requestId, setRequestId] = useState(null);
  const [order, setOrder] = useRecoilState(orderState);

  var rowStyles = {};
  var discounts = [];

  if (event.Discounts == null) {
    event.Discounts = [];
  }

  event.Discounts.forEach((discount, index) => {
    discounts.push(
      <Discount
        setManageDiscountCodes={setManageDiscountCodes}
        onEventUpdated={() => onEventUpdated({ ...event })}
        handleDeleteClick={() => {
          event.Discounts = event.Discounts.filter((x) => x != discount);
          onEventUpdated({ ...event });
        }}
        event={event}
        discount={discount}
        key={'event_discount_' + index}
      />,
    );
  });

  const addNew = () => {
    event.Discounts.push({
      Id: 0,
      Amount: 1,
      CheapestQuantity: 1,
      Name: '',
      DiscountType: 0,
      FixedAmount: 0,
      Percentage: 0,
      Criteria: [],
      ApplicationMode: 0,
      Code: '',
      Quantity: 1,
      Stack: false,
    });

    onEventUpdated({ ...event });
  };

  if (manageDiscountCodes) {
    const addAmountNumber: number = NumberHelper.isNumeric(addAmount) ? parseInt(addAmount) : 1;
    const discount = manageDiscountCodes;

    return (
      <>
        {requestId && (
          <RequestModal
            event={event}
            readOnly={true}
            request={{ Id: requestId } as any}
            onClose={() => {
              setRequestId(null);
            }}
          />
        )}
        <Toolbar>
          <div className="options">
            {props.globalOptions}
            <button className="option" onClick={() => setManageDiscountCodes(null)}>
              <label>Back to discounts</label>
              <div className="icon">
                <SVGGoBackArrow />
              </div>
            </button>
            <button
              className="option"
              onClick={() => {
                if (!discount.Codes) discount.Codes = [];

                discount.Codes = discount.Codes.filter((c) => c.DateUsed != null);
                onEventUpdated({ ...event, Discounts: [...event.Discounts] });
              }}
            >
              <label>Clear unused</label>
              <div className="icon">
                <SVGCancel />
              </div>
            </button>
          </div>
        </Toolbar>
        <h2>Discount codes for {manageDiscountCodes.Name}</h2>
        <div style={{ marginBottom: '30px' }}>
          <div className="flex_from_left" style={{ marginBottom: '10px' }}>
            <div className="flex_from_left">
              <div className="input">
                <input
                  maxLength={3}
                  onChange={(e) => setAddAmount(e.target.value)}
                  type="number"
                  value={addAmount}
                  style={{ margin: '0' }}
                />
              </div>
              <button
                disabled={addAmountNumber < 1}
                className="admin-button"
                onClick={() => {
                  if (!discount.Codes) discount.Codes = [];

                  for (let i = 0; i < addAmountNumber; i++) {
                    const characters = 'ABCDEFGHKLMNPQRSTUVWXYZ23456789';
                    let result = '';
                    const charactersLength = characters.length;
                    let isUnique = false;

                    // Keep generating a code until a unique one is found
                    while (!isUnique) {
                      result = '';
                      for (let j = 0; j < 8; j++) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                      }
                      // Check if the generated code is unique
                      isUnique = !discount.Codes.some((discountCode) => discountCode.Code === result);
                    }

                    const discountCode: IDiscountCode = { Id: 0, Code: result, Guid: GuidHelper.new() };
                    discount.Codes.push(discountCode);
                  }

                  onEventUpdated({ ...event, Discounts: [...event.Discounts] });
                }}
              >
                {addAmountNumber < 1 ? (
                  'Invalid amount'
                ) : (
                  <>
                    Add +{addAmountNumber} {StringHelper.AddSWhenMany(addAmountNumber, 'discount code')}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="container-outer" style={{ minHeight: '300px', padding: '0 15px' }}>
          <div className="container-inner" style={{ backgroundColor: 'white' }}>
            <table className="table grid-table">
              <thead>
                <tr>
                  <th className="grid-header">Status</th>
                  <th className="grid-header">Discount Code</th>
                  <th className="grid-header">Date Used</th>
                  <th className="grid-header"></th>
                </tr>
              </thead>

              <tbody className="smooth-details">
                {linq
                  .from(discount.Codes ?? [])
                  .select((discountCode: IDiscountCode) => {
                    return (
                      <React.Fragment key={discountCode.Code}>
                        <tr>
                          <td>
                            <div
                              className={`status-tag tag-${discountCode.OrderId ? 'darkgreen' : discountCode.RequestId ? 'requested' : 'default'}`}
                            >
                              {discountCode.OrderId ? 'Ordered' : discountCode.RequestId ? 'Requested' : 'Unused'}
                            </div>
                          </td>
                          <td>{discountCode.Code}</td>
                          {discountCode.DateUsed ? (
                            <td>
                              {DateHelper.asDateAtTimeAmPmFromMoment(
                                moment(discountCode.DateUsed).tz(event.TimeZoneIana),
                              )}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          <td>
                            {discountCode.OrderId && (
                              <button
                                style={{ marginLeft: '5px' }}
                                className="admin-button icon"
                                onClick={() => {
                                  setOrder({ event: event, orderId: discountCode.OrderId });
                                }}
                              >
                                <SVGOrders />
                              </button>
                            )}
                            {discountCode.RequestId && !discountCode.OrderId && (
                              <button
                                style={{ marginLeft: '5px' }}
                                className="admin-button icon"
                                onClick={() => {
                                  setRequestId(discountCode.RequestId);
                                }}
                              >
                                <SVGRequest />
                              </button>
                            )}
                            {!discountCode.DateUsed && (
                              <button
                                style={{ marginLeft: '5px' }}
                                className="admin-button icon bad"
                                onClick={() => {
                                  discount.Codes = discount.Codes.filter((d) => d != discountCode);
                                  props.onEventUpdated({ ...props.event, Discounts: [...event.Discounts] });
                                }}
                              >
                                <SVGTrash />
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                  .toArray()}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Toolbar>
        <div className="options">
          {props.globalOptions}
          <button className="option" onClick={addNew}>
            <label>Add Discount</label>
            <div className="icon">
              <SVGDiscount />
            </div>
          </button>
        </div>
      </Toolbar>
      <h2>Discounts</h2>
      <div className="row discounts-section" style={rowStyles}>
        <div className="col-xs-12">
          {discounts}
          <button id="addDiscountButton" className="admin-button" onClick={addNew}>
            Add a discount
          </button>
        </div>
      </div>
    </>
  );
};

export default DiscountsSection;

export default class Constants {
  public static SeatingPlanSectionsEnabled = true;
  public static SeatyUserVar = 'seaty_user';
  static MaxEventDescriptionLength = 5000;
  static VenueDescriptionLength = 2500;
  public static CookieAcceptedName = 'cookies_accepted';

  public static RedPercentageEventDate = 90;
  public static YellowPercentageEventDate = 75;
  public static YellowPercentageEvent = 80;
  public static DaysOldEventsShouldBeVisibleToPublic = 10;
}

import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { LazyImage } from '../../components/LazyImage';
import SVGHelper from '../../helpers/SVGHelper';
import { IEvent } from '../../interfaces/IEvent';
import EventDates from '../../modals/Dates/EventDates';
import { AdmissionTypes } from '../Editor/event/TicketSetupSection';
import { IEventDate } from '../../interfaces/IEventDate';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import linq from 'linq';
import Constants from '../../helpers/Constants';
import ShareLinks from './ShareLinks';
import LocationHelper from '../../helpers/LocationHelper';
import eventReferralState from '../../atoms/eventReferralState';
import { useRecoilState } from 'recoil';
import UserHelper from '../../helpers/UserHelper';
import EventHelper from '../../helpers/EventHelper';
import RichTextView from '../../components/RichTextView';
import EventTypes, { EventType } from '../../interfaces/IEventType';
import AdminApi from '../../api/AdminApi';
import showSignUpState from '../../atoms/showSignUpState';

export interface IProps {
  event: IEvent;
  isAdmin: boolean;
  futureDates: IEventDate[];
  pastDates: IEventDate[];
  setDatePickerEvent: (event: IEvent) => void;
}

const EventDetails: FunctionComponent<IProps> = (props) => {
  const { event, isAdmin } = props;
  const { Organisation, Venue } = event;

  const [eventReferral, setEventReferral] = useRecoilState(eventReferralState);
  const [showSignUp, setShowSignUp] = useRecoilState(showSignUpState);
  const [subscribing, setSubscribing] = useState(false);
  const referral = eventReferral && eventReferral[event.Id];

  useEffect(() => {
    const referralGuid = LocationHelper.getQueryVariable('refId');
    let referralName = LocationHelper.getQueryVariable('refName');

    if (referralName) referralName = atob(referralName);

    if (referralGuid) {
      setEventReferral({ ...eventReferral, [event.Id]: { name: referralName, guid: referralGuid } });
    }
  }, []);

  const goToMap = () => {
    window.open(`http://maps.google.com?daddr=${event.Lat},${event.Lng}`);
  };

  event.Dates.forEach((ed) => {
    (ed as any).IsPast = moment(ed.DateAsString).add(12, 'hours') <= moment();
  });

  const isPast = !linq.from(event.Dates).any((ed) => !ed.IsPast);

  const availabilityClassName =
    event.SoldOut || isPast || event.Suspend
      ? ' sold-out'
      : event.PercentageOfNotLockedSold > Constants.RedPercentageEventDate
        ? ' almost-sold-out'
        : event.PercentageOfNotLockedSold > Constants.YellowPercentageEvent
          ? ' selling-well'
          : ' good-availability';

  const eventType = event.Type ? EventTypes[event.Type] : EventTypes[EventType.Other];

  return (
    <>
      <div className="strip-block" style={{ borderTop: 0, backgroundColor: 'white', paddingBottom: '25px' }}>
        <div className="row">
          <div className="col-sm-5 hide-mobile">
            {event.ImageUrl && (
              <LazyImage
                className="event-image main"
                alt="Event"
                style={{ maxWidth: '400px', display: 'inline-block' }}
                src={event.ImageUrl}
              />
            )}
          </div>

          <div className="col-sm-7" id="adminOptionsContainer">
            {event.Private && (
              <div style={{ marginBottom: '30px' }}>
                Private Event
                {isAdmin && <div className="text">* As an admin you do not require a password to book tickets.</div>}
              </div>
            )}
            {referral && (
              <div style={{ marginBottom: '15px' }}>
                You’ve Arrived with a Friend’s Referral
                <div className="text">
                  🎉 Woohoo! We see you’ve arrived at Seaty with a special referral link given to you by{' '}
                  <strong>{referral.name}</strong>. If you decide to snag tickets for this event, we’ll make sure the
                  awesome friend who shared it gets the credit. Enjoy the show! 🤩
                </div>
              </div>
            )}
            <div style={{ marginBottom: '15px' }}>
              {event.Name}
              <div className="text">Presented by {event.Organisation.Name}</div>
            </div>
            {event && event.Dates && event.Dates.length < 4 ? (
              <div className="date-days">
                <div>Book Tickets</div>

                {event.Dates.filter((d) => d.External).length > 0 && (
                  <div style={{ paddingBottom: '20px' }} className="text">
                    * Times marked with an asterisk are handled outside of the jurisdiction of Seaty.co.uk and provided
                    as a link to an external service.
                  </div>
                )}
                <EventDates events={[event]} isAdmin={props.isAdmin} showPastDates={true} />
              </div>
            ) : (
              <div className="chunky-buttons" style={{ marginBottom: '40px' }}>
                <button
                  className={`has-button-status${availabilityClassName}`}
                  onClick={() => props.setDatePickerEvent(event)}
                >
                  Book tickets
                  <div className="button-status">
                    {event.Suspend
                      ? 'Suspended'
                      : isPast
                        ? 'Elapsed'
                        : event.SoldOut
                          ? 'Sold out'
                          : event.PercentageOfNotLockedSold > Constants.RedPercentageEventDate
                            ? 'Limited tickets'
                            : event.PercentageOfNotLockedSold > Constants.YellowPercentageEvent
                              ? 'Getting busy'
                              : 'Tickets available'}
                  </div>
                </button>
              </div>
            )}

            <div style={{ marginBottom: '30px' }}>
              {UserHelper.currentUser && !UserHelper.currentUser.Guest ? (
                <>Share this event & earn credit! 🎉</>
              ) : (
                `Share this event`
              )}
              <ShareLinks eventTag={event.EventTag} />
            </div>

            {event.Description &&
              event.Description.length > 0 &&
              EventHelper.getRichTextCharLength(event.Description) > 3 && (
                <>
                  About this event
                  <div className="text" style={{ marginBottom: '30px' }}>
                    <ReactQuill theme="snow" readOnly={true} modules={{ toolbar: false }} value={event.Description} />
                  </div>
                </>
              )}

            {eventType.name == EventType.Other ? (
              ''
            ) : (
              <div style={{ marginBottom: '35px' }}>
                {eventType.emoji} {eventType.name}
                <div className="text"> {eventType.description}</div>
              </div>
            )}

            {Organisation && (Organisation.Id == 280 || Organisation.Id == 332) ? (
              <div style={{ marginBottom: '40px' }} id="mc_embed_signup">
                <form
                  action="https://strictlytheatreco.us15.list-manage.com/subscribe/post?u=31884894ba5579789edbc8573&amp;id=372c3af0c9"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll">
                    Join the {Organisation.Name} mailing list
                    <input
                      type="email"
                      name="EMAIL"
                      className="email"
                      id="mce-EMAIL"
                      placeholder="Email address"
                      required
                    />
                    <div style={{ position: 'absolute', left: '-10000px' }} aria-hidden="true">
                      <input type="text" name="b_31884894ba5579789edbc8573_372c3af0c9" tabIndex={-1} />
                    </div>
                    <div className="clear chunky-buttons">
                      <input
                        style={{ margin: '0' }}
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                      />
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              event.AllowMarketing &&
              event.ShowMarketingSignUp && (
                <div style={{ marginBottom: '40px' }} id="mc_embed_signup">
                  <div id="mc_embed_signup_scroll">
                    Join the mailing list
                    {UserHelper.currentUser && !UserHelper.currentUser.Guest ? (
                      <>
                        <div className="text" style={{ marginBottom: '5px' }}>
                          Would you like to join the marketing mailing list for <br />
                          {Organisation.Name}?
                        </div>
                        <div className="chunky-buttons">
                          <button
                            style={{ margin: '0' }}
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="button"
                            disabled={subscribing}
                            onClick={(e) => {
                              e.preventDefault();
                              setSubscribing(true);
                              AdminApi.request('POST', `/api/SignUpToOrganisationMarketing`, {
                                organisationId: event.OrganisationId,
                              })
                                .then(() => {
                                  setSubscribing(false);
                                  alert('You have successfully subscribed to the mailing list.');
                                })
                                .catch((message) => {
                                  alert('There was an error in saving new state. Please refresh the page.');
                                });
                            }}
                          >
                            {subscribing ? 'Please wait...' : 'Subscribe'}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text" style={{ marginBottom: '5px' }}>
                          To get marketing, news and updates from {Organisation.Name} you will first need a Seaty
                          account. Sign up for Seaty and come back here to subscribe to the mailing list.
                        </div>
                        <div className="chunky-buttons">
                          <button
                            style={{ margin: '0' }}
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="button"
                            disabled={subscribing}
                            onClick={(e) => {
                              e.preventDefault();
                              setShowSignUp(true);
                            }}
                          >
                            Sign up to Seaty
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )
            )}
            {event.TermsAndConditions && (
              <div style={{ marginBottom: '20px' }}>
                Terms & conditions
                <div className="text">
                  {event.AdmissionType == AdmissionTypes.PrintedAndMobile ? (
                    <div>
                      When you arrive at this event, tickets will be accepted shown on your mobile phone or printed off.
                    </div>
                  ) : event.AdmissionType == AdmissionTypes.PrintedOnly ? (
                    <div>
                      When you arrive at this event, only printed tickets will be accepted. Please ensure you bring a
                      physical copy of all tickets with you or you may not be allowed to enter.
                    </div>
                  ) : event.AdmissionType == AdmissionTypes.Exchange ? (
                    <div>
                      Seaty tickets must be taken to the venue box office upon arrival and exchanged for a venue ticket
                      to gain admission.
                    </div>
                  ) : (
                    'Unknown admission type'
                  )}
                  {event.TermsAndConditions}
                  <div>* Terms enforced by {Organisation.Name}</div>
                </div>
              </div>
            )}
          </div>

          <div className="col-sm-5 show-mobile">
            {event.ImageUrl && (
              <LazyImage
                className="event-image main"
                alt="Event"
                style={{ maxWidth: '400px', display: 'inline-block' }}
                src={event.ImageUrl}
              />
            )}
          </div>
        </div>
      </div>

      <div className="strip-block" style={{ backgroundColor: 'white' }}>
        <div className="row">
          <div className="col-sm-5 left">
            <img style={{ width: '150px' }} src={SVGHelper.get('Route')} />
          </div>

          <div className="col-sm-7">
            {Venue.Name}

            <div className="text" style={{ marginBottom: '30px' }}>
              {Venue.StreetAddress != null && <div>{Venue.StreetAddress}</div>}
              {Venue.City && <div>{Venue.City}</div>}
              {Venue.Postcode && <div>{Venue.Postcode}</div>}
            </div>

            <RichTextView value={Venue.Description} label="About this venue" spacing={'30px'} />
            <RichTextView value={Venue.Directions} label="Directions" spacing={'30px'} />
            <RichTextView value={Venue.CarParking} label="Car Parking" spacing={'30px'} />

            {event.Lat != null && event.Lat.length > 0 && event.Lng != null && event.Lng.length > 0 && (
              <a href={'#'} onClick={goToMap}>
                View on Google Maps
              </a>
            )}
          </div>
        </div>
      </div>

      {event.PersonnelGroups &&
        event.PersonnelGroups.length > 0 &&
        event.PersonnelGroups.map((personnelGroup) => (
          <React.Fragment key={personnelGroup.Id}>
            <div className="strip-block personnel-strip-block">
              {personnelGroup.Name}

              <div className="personnel-group-view">
                {personnelGroup.Personnel &&
                  personnelGroup.Personnel.length > 0 &&
                  personnelGroup.Personnel.map((personnel) => (
                    <React.Fragment key={personnel.Id}>
                      <div className="personnel-view">
                        <div className="image">
                          <img src={personnel.ImageUrl}></img>
                        </div>
                        {personnel.Name && personnel.Name.length > 0 && (
                          <label className="personnel-name">{personnel.Name}</label>
                        )}
                        {personnel.Role && personnel.Role.length > 0 && (
                          <label className="personnel-role">{personnel.Role}</label>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </React.Fragment>
        ))}
    </>
  );
};

export { EventDetails };

import { FunctionComponent } from 'react';
import EventHelper from '../helpers/EventHelper';
import ReactQuill from 'react-quill';

export interface IProps {
  value?: string;
  label?: string;
  style?: React.CSSProperties;
  spacing?: string;
}

const RichTextView: FunctionComponent<IProps> = (props) => {
  return (
    props.value &&
    EventHelper.getRichTextCharLength(props.value) > 3 && (
      <div style={{ ...props.style, marginBottom: props.spacing }}>
        {props.label}
        <div className="text" style={{ marginBottom: '30px' }}>
          <ReactQuill theme="snow" readOnly={true} modules={{ toolbar: false }} value={props.value} />
        </div>
      </div>
    )
  );
};

export default RichTextView;

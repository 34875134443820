import { FunctionComponent, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Radio from '../../components/Radio';
import SpacerTable from '../../components/SpacerTable';
import NumberHelper from '../../helpers/NumberHelper';
import { IEvent } from '../../interfaces/IEvent';

interface IProps {
  event: IEvent;
  refundMadeCallback: (state) => void;
  payeeEmail: string;
  balance: number;
  currency: string;
}

enum RefundAmount {
  FullBalance = 'fullbalance',
  Other = 'other',
}

const RefundControl: FunctionComponent<IProps> = (props) => {
  const [refundNotes, setRefundNotes] = useState('');
  const [refundAmountType, setRefundAmountType] = useState(RefundAmount.Other);
  const [refundErrorMessage, setRefundErrorMessage] = useState(null);
  const [busyMessage, setLoadingMessage] = useState(null);
  const [customRefundAmount, setCustomRefundAmount] = useState('');

  const balance = props.balance ? props.balance : 0;
  const currency = props.currency;

  const getIsValidAmount = (value) => {
    if (refundAmountType == RefundAmount.FullBalance) {
      if (balance > 0) {
        return true;
      }
    } else {
      if (NumberHelper.isNumeric(value)) {
        return true;
      }
    }
    return false;
  };

  const submitRefund = () => {
    setLoadingMessage('Submitting refund');
    submitRefundApi(function (result) {
      if (result.Valid) {
        setLoadingMessage(null);
        setRefundErrorMessage(null);

        if (props.refundMadeCallback) {
          props.refundMadeCallback(result);
        }
      } else {
        setLoadingMessage(null);
        setRefundErrorMessage(result.ErrorMessage);
      }
    });
  };

  const submitRefundApi = (callback) => {
    AdminApi.request('POST', '/api/BalancePayment', {
      payeeEmail: props.payeeEmail,
      eventId: props.event.Id,
      amount:
        0 - (refundAmountType == RefundAmount.FullBalance ? balance / 100.0 : parseFloat(customRefundAmount)) * 100,
      notes: refundNotes,
      isRefund: true,
      type: 1,
    })
      .then(() => {
        callback({ Valid: true });
      })
      .catch((message) => {
        callback({ ErrorMessage: message, Valid: false });
      });
  };

  var refundAmount = refundAmountType == RefundAmount.FullBalance ? balance / 100.0 : parseFloat(customRefundAmount);

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  return (
    <>
      <table className="blocks">
        <tbody>
          <BlockInfo>
            Adding a balance refund will not refund card payments. To refund a card payment you must go to the order and
            click 'Cancel Order'.
          </BlockInfo>

          {balance <= 0 ? null : (
            <Block>
              <BlockHeader>How much is being refunded?</BlockHeader>
              <Radio
                selectedId={refundAmountType}
                onValueChanged={(option) => setRefundAmountType(option.Id)}
                options={[
                  {
                    Text: `Full balance of ${currency + (props.balance / 100.0).toFixed(2)}`,
                    Id: RefundAmount.FullBalance,
                  },
                  { Text: 'Other amount', Id: RefundAmount.Other },
                ]}
              />
            </Block>
          )}

          {refundAmountType != RefundAmount.Other ? null : (
            <Block>
              <BlockHeader>Amount</BlockHeader>
              <BlockTextBox
                name="amount"
                type="number"
                style={{ minWdith: '150px', width: '50%' }}
                placeholder={'Enter an amount'}
                value={customRefundAmount}
                autoFocus={true}
                symbol={props.currency}
                onChange={(e) => {
                  setCustomRefundAmount(e);
                }}
              />
            </Block>
          )}

          <Block>
            <BlockHeader>Refund notes</BlockHeader>
            <BlockTextBox
              name="notes"
              value={refundNotes}
              onChange={(e) => {
                setRefundNotes(e);
              }}
              rows={2}
            />
          </Block>

          {refundErrorMessage && <BlockInfo type={InfoType.Error}>{refundErrorMessage}</BlockInfo>}
        </tbody>
      </table>
      <div className="spacer" />

      <SpacerTable>
        <Button
          disabled={!getIsValidAmount(refundAmount)}
          className="confirm"
          onClick={submitRefund}
          text={
            getIsValidAmount(refundAmount)
              ? `Submit refund of ${currency + parseFloat(refundAmount.toString()).toFixed(2)}`
              : 'Enter an amount'
          }
        />
      </SpacerTable>
    </>
  );
};

export default RefundControl;

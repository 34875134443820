import Cookies from 'js-cookie';
import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import Loader from '../Loader';
import SVGHelper from '../../helpers/SVGHelper';
import { IEvent } from '../../interfaces/IEvent';
import EventAttendeeModal from '../../modals/AttendeeOverview/EventAttendeeModal';
import SVGMultiply from '../../svg/SVGMultiply';
import { AdminSection } from '../../views/Admin/AdminSections';
import AttendeeSelectionRow from './AttendeeSelectionRow';
import SideMenu from '../SideMenu';
import SVGOutlineMembership from '../../svg/outline/SVGOutlineMembership';
import SVGOutlinePersonnel from '../../svg/outline/SVGOutlinePersonnel';
import SVGOutlineReferral from '../../svg/outline/SVGOutlineReferral';
import SVGOutlineReferralHide from '../../svg/outline/SVGOutlineReferralHide';
import SVGCheck from '../../svg/SVGCheck';
import SVGTick from '../../svg/SVGTick';
import StringHelper from '../../helpers/StringHelper';
import SVGTickets from '../../svg/SVGTickets';
import SVGGoBackArrow from '../../svg/SVGGoBackArrow';

enum SortBy {
  TicketCount = 1,
  TotalSales = 2,
  ReferralTicketCount = 3,
  ReferralTotalSales = 4,
  Balance = 5,
  Name = 6,
  Email = 7,
}

enum SortDirections {
  Ascending = 1,
  Decending = 2,
}

const getAndResetCookieInt = (name: string): number => {
  const value = Cookies.get(name);

  if (value != null) {
    Cookies.set(name, value, { expires: 5, path: '/' });
    return parseInt(value);
  }
  return 0;
};

const getAndResetCookieBool = (name: string): boolean => {
  const value = Cookies.get(name);

  if (value != null && value == 'true') {
    Cookies.set(name, 'true', { expires: 5, path: '/' });
    return true;
  } else {
    Cookies.set(name, 'false', { expires: 5, path: '/' });
    return false;
  }
};

export interface IProps {
  event: IEvent;
  confirm: (emails: string[]) => void;
  onBack: () => void;
  initialValue: string[];
  backMessage: string;
}

const AttendeeSelection: FunctionComponent<IProps> = (props) => {
  const { event } = props;
  const organisation = event.Organisation;

  const eventTag = event.EventTag;
  const membershipsEnabled = event.Organisation.MembershipsEnabled;

  const [filter, setFilter] = useState('');
  const [selectedEmails, setSelectedEmails] = useState({});
  const [sortDirection, setSortDirection] = useState(
    getAndResetCookieInt(eventTag + '_OrdersAndPaymentsSortDirection'),
  );
  const [sortBy, setSortBy] = useState(getAndResetCookieInt(eventTag + '_OrdersAndPaymentsSortBy'));

  const [includeReferrals, _setIncludeReferrals] = useState(getAndResetCookieBool(eventTag + '_IncludeReferrals'));
  const setIncludeReferrals = (value) => {
    Cookies.set(eventTag + '_IncludeReferrals', value, { expires: 5, path: '/' });
    _setIncludeReferrals(value);
  };

  const [showOnlyMembers, _setShowOnlyMembers] = useState(getAndResetCookieBool(eventTag + '_ShowOnlyMembers'));
  const setShowOnlyMembers = (value) => {
    Cookies.set(eventTag + '_ShowOnlyMembers', value, { expires: 5, path: '/' });
    _setShowOnlyMembers(value);
  };

  const [ordersAndPayments, setOrdersAndPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [selectedEmail, setSelectedEmail] = useState(null);

  const getOrdersAndPayments = (callback) => {
    AdminApi.request('GET', `/api/OrdersAndPayments?eventTag=${eventTag}`)
      .then((response) => callback(response))
      .catch((error) => alert('Error: ' + error));
  };

  const toTitleCase = (str) => {
    if (!str) return '';

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getName = (rowData) => {
    var response = '';

    if (rowData.IsMember && rowData.MemberName != null && rowData.MemberName.length > 0) {
      response = rowData.MemberName;
    } else if (rowData.UserId != 0) {
      response = rowData.User;
    } else {
      response = rowData.AttendeeName;
    }

    return toTitleCase(response);
  };

  const loadOrdersAndPayments = (silent?: boolean) => {
    setLoading(!silent);

    getOrdersAndPayments((response) => {
      setLoading(false);
      setOrdersAndPayments(
        linq
          .from(response.OrdersAndPayments)
          .select((rowData: any) => {
            rowData.PurchaserName = getName(rowData);

            return rowData;
          })
          .toArray(),
      );
    });
  };

  useEffect(() => {
    const initialEmails = props.initialValue.reduce((acc, email) => {
      acc[email] = true;
      return acc;
    }, {});
    setSelectedEmails(initialEmails);

    loadOrdersAndPayments();
  }, []);

  if (!event) return null;

  const setSortCookie = (sortBy, sortDirection) => {
    Cookies.set(eventTag + '_OrdersAndPaymentsSortDirection', sortDirection, { expires: 5, path: '/' });
    Cookies.set(eventTag + '_OrdersAndPaymentsSortBy', sortBy, { expires: 5, path: '/' });
  };

  const handleTicketsHeaderClick = () => {
    var d = SortDirections.Decending;
    if (sortBy == SortBy.TicketCount) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }

    setSortBy(SortBy.TicketCount);
    setSortDirection(d);
    setSortCookie(SortBy.TicketCount, d);
  };

  const handleTotalHeaderClick = () => {
    var d = SortDirections.Decending;
    if (sortBy == SortBy.TotalSales) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }
    setSortBy(SortBy.TotalSales);
    setSortDirection(d);
    setSortCookie(SortBy.TotalSales, d);
  };

  const handleBalanceHeaderClick = () => {
    var d = SortDirections.Decending;
    if (sortBy == SortBy.Balance) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }
    setSortBy(SortBy.Balance);
    setSortDirection(d);
    setSortCookie(SortBy.Balance, d);
  };

  const handleNameHeaderClick = () => {
    var d = SortDirections.Ascending;
    if (sortBy == SortBy.Name) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }

    setSortBy(SortBy.Name);
    setSortDirection(d);
    setSortCookie(SortBy.Name, d);
  };

  const handleEmailHeaderClick = () => {
    var d = SortDirections.Ascending;
    if (sortBy == SortBy.Email) {
      d = sortDirection == SortDirections.Ascending ? SortDirections.Decending : SortDirections.Ascending;
    }

    setSortBy(SortBy.Email);
    setSortDirection(d);
    setSortCookie(SortBy.Email, d);
  };

  const handleRowClick = (rowData) => {
    setSelectedEmail(rowData.Email);
  };

  const overviewRefreshed = (overview) => {
    var row = linq
      .from(ordersAndPayments)
      .where(function (o) {
        return o.Email == overview.Email;
      })
      .firstOrDefault();

    row.OwedAsInt = overview.TotalOwedAsInt;
    row.Owed = overview.TotalOwedAsString;

    row.PriceAsInt = overview.TotalOrdersPriceAsInt;
    row.Price = overview.TotalOrdersPriceAsString;

    row.PricePlusReferralPriceAsInt = overview.TotalOrdersAndReferralsPriceAsString;

    row.ReferralPriceAsInt = overview.TotalReferralsPriceAsInt;
    row.ReferralPrice = overview.TotalReferralsPriceAsString;

    row.Tickets = overview.TotalTickets;
    row.ReferralOrder = overview.TotalReferralTickets;

    row.IsMember = overview.IsMember;

    setOrdersAndPayments(ordersAndPayments);
  };

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  var rows = [];
  var sortedData = ordersAndPayments;

  if (showOnlyMembers) {
    sortedData = linq
      .from(sortedData)
      .where((x) => {
        return x.IsMember;
      })
      .toArray();
  }

  if (sortBy == SortBy.TicketCount) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((rowData) =>
          includeReferrals
            ? rowData.TicketPeopleCount + rowData.ReferralsReceived - rowData.ReferralsGiven
            : rowData.TicketPeopleCount,
        )
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((rowData) =>
          includeReferrals
            ? rowData.TicketPeopleCount + rowData.ReferralsReceived - rowData.ReferralsGiven
            : rowData.TicketPeopleCount,
        )
        .thenBy((x) => getName(x))
        .toArray();
    }
  } else if (sortBy == SortBy.TotalSales) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((rowData) =>
          includeReferrals
            ? rowData.PriceAsInt + rowData.ReferralsReceivedPriceAsInt - rowData.ReferralsGivenPriceAsInt
            : rowData.PriceAsInt,
        )
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((rowData) =>
          includeReferrals
            ? rowData.PriceAsInt + rowData.ReferralsReceivedPriceAsInt - rowData.ReferralsGivenPriceAsInt
            : rowData.PriceAsInt,
        )
        .thenBy((x) => getName(x))
        .toArray();
    }
  } else if (sortBy == SortBy.Balance) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((x) => x.OwedAsInt)
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((x) => x.OwedAsInt)
        .thenBy((x) => getName(x))
        .toArray();
    }
  } else if (sortBy == SortBy.Name) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((x) => getName(x))
        .thenBy((x) => x.Email)
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((x) => getName(x))
        .thenBy((x) => x.Email)
        .toArray();
    }
  } else if (sortBy == SortBy.Email) {
    if (sortDirection == SortDirections.Ascending) {
      sortedData = linq
        .from(sortedData)
        .orderBy((x) => x.Email)
        .thenBy((x) => getName(x))
        .toArray();
    } else {
      sortedData = linq
        .from(sortedData)
        .orderByDescending((x) => x.Email)
        .thenBy((x) => getName(x))
        .toArray();
    }
  }

  const filteredData = [];

  sortedData.forEach((rowData, index) => {
    var include = true;
    if (filter != null && filter.length > 0) {
      include = false;

      if (
        rowData.Email.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        rowData.User.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        rowData.PurchaserName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      ) {
        include = true;
      }
    }

    if (include) {
      filteredData.push(rowData);

      var rowId = 'orderandpaymentsrow_' + rowData.Email;

      if (rowData.MemberId != null) {
        rowId += '_' + rowData.MemberId;
      }

      rowId += '_' + index;

      rows.push(
        <AttendeeSelectionRow
          checked={selectedEmails[rowData.Email]}
          onCheckChange={() => {
            if (selectedEmails[rowData.Email]) {
              delete selectedEmails[rowData.Email];
            } else {
              selectedEmails[rowData.Email] = true;
            }

            setSelectedEmails({ ...selectedEmails });
            console.log(selectedEmails);
          }}
          currency={event.Organisation.Currency.Symbol}
          includeReferrals={includeReferrals}
          getName={getName}
          eventTag={eventTag}
          membershipsEnabled={membershipsEnabled}
          handleRowClick={() => handleRowClick(rowData)}
          key={rowId}
          eventId={event.Id}
          data={rowData}
        />,
      );
    }
  });

  if (loading) return <Loader inline>Loading attendees...</Loader>;
  const allFilteredEmailsChecked = filteredData.every((rowData) => selectedEmails[rowData.Email]);

  return (
    <>
      <div className="admin-buttons" style={{ marginTop: 0, padding: 0 }}>
        <button
          className="admin-button icon-inline"
          onClick={() => {
            props.onBack();
          }}
        >
          <SVGGoBackArrow />
          {props.backMessage}
        </button>
        <button
          disabled={Object.keys(selectedEmails).length == 0}
          className="admin-button icon-inline confirm"
          onClick={() => props.confirm(Object.keys(selectedEmails))}
        >
          <SVGTick />
          {Object.keys(selectedEmails).length == 0
            ? 'No attendees selected'
            : `Confirm selection of ${Object.keys(selectedEmails).length} ${StringHelper.AddSWhenMany(Object.keys(selectedEmails).length, 'attendee')}`}
        </button>

        <button
          className="admin-button icon-inline"
          onClick={() => {
            filteredData.forEach((rowData) => {
              if (rowData.TicketCount > 0) {
                selectedEmails[rowData.Email] = true;
              }
            });

            setSelectedEmails({ ...selectedEmails });
          }}
        >
          <SVGTickets />
          Select all with tickets
        </button>
        <button
          disabled={Object.keys(selectedEmails).length == 0}
          className="admin-button icon-inline"
          onClick={() => {
            setSelectedEmails({});
          }}
        >
          <SVGMultiply />
          Clear
        </button>
      </div>

      <div className="row">
        <div className="col-sm-3 col-md-2 donotprint">
          <SideMenu>
            {membershipsEnabled && (
              <button className="has-image" onClick={() => setShowOnlyMembers(!showOnlyMembers)}>
                {showOnlyMembers ? <SVGOutlinePersonnel /> : <SVGOutlineMembership />}
                {showOnlyMembers ? 'Show everyone' : 'Show members'}
              </button>
            )}
            {membershipsEnabled && (
              <button className="has-image" onClick={() => setShowOnlyMembers(!showOnlyMembers)}>
                {showOnlyMembers ? <SVGOutlinePersonnel /> : <SVGOutlineMembership />}
                {showOnlyMembers ? 'Show everyone' : 'Show members'}
              </button>
            )}
            {event.ReferralsEnabled && (
              <button className="has-image" onClick={() => setIncludeReferrals(!includeReferrals)}>
                {includeReferrals ? <SVGOutlineReferral /> : <SVGOutlineReferralHide />}
                {!includeReferrals ? 'Show referrals' : 'Hide referrals'}
              </button>
            )}
          </SideMenu>
        </div>
        <div className={'col-sm-9 col-md-10'}>
          <div className="orderoverview" style={{ paddingBottom: '100px', maxWidth: '900px' }}>
            <div className="input donotprint">
              <input
                id="searchinput"
                onChange={handleSearchChanged}
                type="text"
                placeholder="Search..."
                value={query}
                onKeyPress={onFilterKeyPress.bind(this)}
                style={{ margin: '0' }}
              />

              <button onClick={clearFilterClicked}>
                <SVGMultiply />
              </button>
            </div>
            <div className="container-outer" style={{ minHeight: '300px' }}>
              <div className="container-inner" style={{ backgroundColor: 'white' }}>
                <table className="table grid-table">
                  <thead>
                    <tr>
                      <th className="grid-header check-cell">
                        <button
                          className={`${allFilteredEmailsChecked ? ' selected' : ''}`}
                          onClick={() => {
                            if (allFilteredEmailsChecked) {
                              filteredData.forEach((rowData) => {
                                delete selectedEmails[rowData.Email];
                              });
                            } else {
                              filteredData.forEach((rowData) => {
                                selectedEmails[rowData.Email] = true;
                              });
                            }

                            setSelectedEmails({ ...selectedEmails });
                          }}
                        >
                          <SVGCheck />
                        </button>
                      </th>
                      <th className="grid-header">
                        <>
                          <a onClick={handleEmailHeaderClick}>
                            Email
                            {sortBy == SortBy.Email ? (
                              <span>
                                {sortDirection == SortDirections.Ascending ? (
                                  <img className="sortImage" src={SVGHelper.get('Sort_AZ')} />
                                ) : (
                                  <img className="sortImage" src={SVGHelper.get('Sort_ZA')} />
                                )}
                              </span>
                            ) : null}
                          </a>
                          /
                        </>
                        <a onClick={handleNameHeaderClick}>
                          Name
                          {sortBy == SortBy.Name ? (
                            <span>
                              {sortDirection == SortDirections.Ascending ? (
                                <img className="sortImage" src={SVGHelper.get('Sort_AZ')} />
                              ) : (
                                <img className="sortImage" src={SVGHelper.get('Sort_ZA')} />
                              )}
                            </span>
                          ) : null}
                        </a>
                      </th>
                      <th className="grid-header"></th>
                      <th className="grid-header" style={{ textAlign: 'right' }}>
                        <a onClick={handleTicketsHeaderClick}>
                          {'Tickets'}
                          {sortBy == SortBy.TicketCount ? (
                            <span>
                              {sortDirection == SortDirections.Ascending ? (
                                <img className="sortImage" src={SVGHelper.get('Sort_12')} />
                              ) : (
                                <img className="sortImage" src={SVGHelper.get('Sort_21')} />
                              )}
                            </span>
                          ) : null}
                        </a>
                      </th>
                      <th className="grid-header" style={{ textAlign: 'right' }}>
                        <a onClick={handleTotalHeaderClick}>
                          Total
                          {sortBy == SortBy.TotalSales ? (
                            <span>
                              {sortDirection == SortDirections.Ascending ? (
                                <img className="sortImage" src={SVGHelper.get('Sort_12')} />
                              ) : (
                                <img className="sortImage" src={SVGHelper.get('Sort_21')} />
                              )}
                            </span>
                          ) : null}
                        </a>
                      </th>
                      {!includeReferrals && (
                        <th className="grid-header" style={{ textAlign: 'right' }}>
                          <a onClick={handleBalanceHeaderClick}>
                            Balance
                            {sortBy == SortBy.Balance ? (
                              <span>
                                {sortDirection == SortDirections.Ascending ? (
                                  <img className="sortImage" src={SVGHelper.get('Sort_12')} />
                                ) : (
                                  <img className="sortImage" src={SVGHelper.get('Sort_21')} />
                                )}
                              </span>
                            ) : null}
                          </a>
                        </th>
                      )}
                      <th className="grid-header"></th>
                    </tr>
                  </thead>

                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedEmail && (
        <EventAttendeeModal
          organisation={organisation}
          event={event}
          email={selectedEmail}
          overviewRefreshed={overviewRefreshed}
          onClose={() => {
            setSelectedEmail(null);
            loadOrdersAndPayments(true);
          }}
        />
      )}
    </>
  );
};

export default AttendeeSelection;

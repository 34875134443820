import GeneralApi from '../api/GeneralApi';
import { LoaderHelper } from '../atoms/loaderState';
import EnvironmentVariables from '../EnvironmentVariables';
import { IEvent } from '../interfaces/IEvent';
import { IEventDateState } from '../interfaces/IEventDateState';
import { IOrganisation } from '../interfaces/IOrganisation';
import { ITour } from '../interfaces/ITour';
import { ITicketAvailability } from '../services/TicketService';
import JsonFileHelper from './JsonFileHelper';
import moment from 'moment';

export default class CacheHelper {
  public static featuredEvents: Array<IEvent> = [];

  public static EVENT_VERSION: string = '01072024';
  public static TOUR_VERSION: string = '13092024';
  public static ORGANISATION_VERSION: string = '14052024';
  public static EVENT_DATE_STATE_VERSION: string = '08082024';
  public static LOCKS_VERSION: string = '13/03/2024 14:30';

  public static eventWithMessage(setLoaders, loaders, eventTag: string): Promise<IEvent> {
    LoaderHelper.add(setLoaders, loaders, 'EVENT_CACHE', 'Loading event...');
    return CacheHelper.eventByTag(eventTag).finally(() => {
      LoaderHelper.remove(setLoaders, loaders, 'EVENT_CACHE');
    });
  }

  public static eventByTag(eventTag: string): Promise<IEvent> {
    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/event-cache${EnvironmentVariables.isDev() ? '-test' : ''}/${eventTag.toLowerCase()}.json?_=${new Date().getTime()}`,
    )
      .then((cache) => {
        if (cache.CacheVersion != CacheHelper.EVENT_VERSION) {
          return GeneralApi.request('GET', `/api/EventCache?eventTag=${eventTag.toLowerCase()}`, null, true);
        }
        return cache;
      })
      .catch(() => {
        return GeneralApi.request('GET', `/api/EventCache?eventTag=${eventTag.toLowerCase()}`, null, true);
      })
      .then((e: IEvent) => {
        if (EnvironmentVariables.isDev()) {
          window.localStorage.setItem('event', JSON.stringify(e));
        }

        if (e.UseSeatingPlan && (e as any).SeatingPlan && !e.SeatingPlans) {
          e.SeatingPlans = [(e as any).SeatingPlan];
        }

        if (e.SeatingPlans) {
          e.SeatingPlans.forEach((sp) => {
            const seatCategories = sp.SeatCategories;
            const map = {};

            if (!sp.SeatScale) {
              sp.SeatScale = 1.0;
            }

            seatCategories.forEach((sc) => {
              map[sc.Id.toString()] = sc;
            });

            sp.Seats.forEach((s) => {
              if (s.SeatCategoryId) {
                s.SeatCategory = map[s.SeatCategoryId.toString()];
              }
            });
          });
        } else {
          e.SeatingPlans = [];
        }

        if (e.TourId) {
          return CacheHelper.tourById(e.TourId)
            .then((o) => {
              e.Tour = o;
              return e;
            })
            .then((e) => {
              return CacheHelper.organisationById(e.OrganisationId).then((o) => {
                e.Organisation = o;
                return e;
              });
            });
        } else {
          return CacheHelper.organisationById(e.OrganisationId).then((o) => {
            e.Organisation = o;
            return e;
          });
        }
      });
  }

  public static organisationByTagWithMessage(setLoaders, loaders, organisationTag: string): Promise<IOrganisation> {
    LoaderHelper.add(setLoaders, loaders, 'ORGANISATION_CACHE', 'Loading organisation...');
    return CacheHelper.organisationByTag(organisationTag).finally(() =>
      LoaderHelper.remove(setLoaders, loaders, 'ORGANISATION_CACHE'),
    );
  }

  public static organisationByTag(organisationTag: string): Promise<IOrganisation> {
    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/organisation-cache${EnvironmentVariables.isDev() ? '-test' : ''}/${organisationTag.toLowerCase()}.json?_=${new Date().getTime()}`,
    )
      .then((cache) => {
        if (cache.CacheVersion != CacheHelper.ORGANISATION_VERSION) {
          return GeneralApi.request(
            'GET',
            `/api/OrganisationCacheByTag?organisationTag=${organisationTag.toLowerCase()}`,
            null,
            true,
          );
        }
        return cache;
      })
      .catch(() => {
        return GeneralApi.request(
          'GET',
          `/api/OrganisationCacheByTag?organisationTag=${organisationTag.toLowerCase()}`,
          null,
          true,
        );
      });
  }

  public static organisationById(organisationId: number): Promise<IOrganisation> {
    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/organisation-cache-id${EnvironmentVariables.isDev() ? '-test' : ''}/${organisationId}.json?_=${new Date().getTime()}`,
    ).catch(() => {
      return GeneralApi.request('GET', `/api/OrganisationCacheById?organisationId=${organisationId}`, null, true);
    });
  }

  public static locks(eventId: number, eventDateId: number, forceApi?: boolean): Promise<ITicketAvailability[]> {
    if (forceApi) {
      return GeneralApi.request('GET', `/api/Lock?eventDateId=${eventDateId}`, null, true);
    }

    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/lock-cache${EnvironmentVariables.isDev() ? '-test' : ''}/${eventId}_${eventDateId}.json?_=${new Date().getTime()}`,
    )
      .then((cache) => {
        if (cache.CacheVersion != CacheHelper.LOCKS_VERSION) {
          return GeneralApi.request('GET', `/api/Lock?eventDateId=${eventDateId}`, null, true);
        }
        return cache;
      })
      .catch(() => {
        return GeneralApi.request('GET', `/api/Lock?eventDateId=${eventDateId}`, null, true);
      })
      .then((lockCache) => {
        return lockCache.Locks;
      });
  }

  public static getEventDateState(eventId: number, eventDateId: number, forceApi?: boolean): Promise<IEventDateState> {
    if (forceApi) {
      return GeneralApi.request('GET', `/api/EventState?eventDateId=${eventDateId}`, null, true);
    }

    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/event-date-state-cache${EnvironmentVariables.isDev() ? '-test' : ''}/${eventId}_${eventDateId}.json?_=${new Date().getTime()}`,
    )
      .then((savedCache) => {
        if (savedCache && savedCache.GeneralAdmissionTickets && savedCache.GeneralAdmissionTickets.length > 0) {
          savedCache.GeneralAdmissionTickets.forEach((g) => {
            if (g.OldestLock) {
              const lockedDate = moment.utc(g.OldestLock);
              const maxAllowable = moment.utc().add(0 - (savedCache.LockInterval + 100), 'seconds');

              const isCacheOutOfDate = lockedDate.valueOf() < maxAllowable.valueOf();

              if (isCacheOutOfDate) {
                return GeneralApi.request('GET', `/api/EventState?eventDateId=${eventDateId}`, null, true);
              }
            }
          });
        }

        if (savedCache.CacheVersion != CacheHelper.EVENT_DATE_STATE_VERSION) {
          return GeneralApi.request('GET', `/api/EventState?eventDateId=${eventDateId}`, null, true);
        }
        return savedCache;
      })
      .catch(() => {
        return GeneralApi.request('GET', `/api/EventState?eventDateId=${eventDateId}`, null, true);
      });
  }

  public static tourByTag(tourTag: string): Promise<ITour> {
    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/tour-cache${EnvironmentVariables.isDev() ? '-test' : ''}/${tourTag.toLowerCase()}.json?_=${new Date().getTime()}`,
    )
      .then((cache) => {
        if (cache.CacheVersion != CacheHelper.TOUR_VERSION) {
          return GeneralApi.request('GET', `/api/TourCacheByTag?tourTag=${tourTag.toLowerCase()}`, null, true);
        }
        return cache;
      })
      .catch(() => {
        return GeneralApi.request('GET', `/api/TourCacheByTag?tourTag=${tourTag.toLowerCase()}`, null, true);
      })
      .then((tour: ITour) => {
        return CacheHelper.organisationById(tour.OrganisationId).then((o) => {
          tour.Organisation = o;
          return tour;
        });
      });
  }

  public static tourById(tourId: number): Promise<ITour> {
    return JsonFileHelper.download(
      `https://seatylive.blob.core.windows.net/tour-cache-id${EnvironmentVariables.isDev() ? '-test' : ''}/${tourId}.json?_=${new Date().getTime()}`,
    )
      .then((cache) => {
        if (cache.CacheVersion != CacheHelper.TOUR_VERSION) {
          return GeneralApi.request('GET', `/api/TourCacheById?tourId=${tourId}`, null, true);
        }
        return cache;
      })
      .catch(() => {
        return GeneralApi.request('GET', `/api/TourCacheById?tourId=${tourId}`, null, true);
      })
      .then((tour: ITour) => {
        return CacheHelper.organisationById(tour.OrganisationId).then((o) => {
          tour.Organisation = o;
          return tour;
        });
      });
  }

  public static tourByTagWithMessage(setLoaders, loaders, tourTag: string): Promise<ITour> {
    LoaderHelper.add(setLoaders, loaders, 'TOUR_CACHE', 'Loading tour...');
    return CacheHelper.tourByTag(tourTag).finally(() => LoaderHelper.remove(setLoaders, loaders, 'TOUR_CACHE'));
  }

  public static tourByIdWithMessage(setLoaders, loaders, tourId: number): Promise<ITour> {
    LoaderHelper.add(setLoaders, loaders, 'TOUR_CACHE', 'Loading tour...');
    return CacheHelper.tourById(tourId).finally(() => LoaderHelper.remove(setLoaders, loaders, 'TOUR_CACHE'));
  }
}

import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../../api/AdminApi';
import wizardEventState from '../../../../atoms/wizardEventState';
import Loader from '../../../../components/Loader';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import { ITour } from '../../../../interfaces/ITour';
import SVGMultiply from '../../../../svg/SVGMultiply';
import SVGPlus from '../../../../svg/SVGPlus';
import SVGToggleOff from '../../../../svg/SVGToggleOff';
import SVGToggleOn from '../../../../svg/SVGToggleOn';
import { AdminSection } from '../../AdminSections';
import './AdminEvents.scss';
import EventRow from './EventRow';
import OrganisationRow from './OrganisationRow';
import TourRow from './TourRow';
import Cookies from 'js-cookie';
import UserHelper from '../../../../helpers/UserHelper';

interface IMyEventsOrganisation {
  OrganisationId: number;
  OrganisationName: string;
  Organisation: IOrganisation;
  Events: IEventOverview[];
  Tours: IMyEventsTour[];
}

interface IMyEventsTour {
  TourId: number;
  TourName: string;
  TourTag: string;
  Tour: ITour;
  Events: IEventOverview[];
}

interface IEventOverview {
  OnlineFromDate: string;
  TimeZoneIana: string;
  EventId: number;
  EventName: string;
  CurrencySymbol: string;
  GroupName: string;
  CurrentTime?: string;
  CurrentTimeAsString?: string;
  SoldSeatedTicketsPercentage?: number;
  AvailableSeatedTickets: number;
  SoldSeatedTickets: number;
  SoldSeatedTicketsValue: number;
  SoldGATicketsPercentage?: number;
  AvailableGATickets: number;
  SoldGATickets: number;
  SoldGATicketsValue: number;
  ImageUrl: string;
  Organisation: IOrganisation;
  OrganisationId?: number;
  OrganisationName?: string;
  OrganisationTag?: string;
  OrganisationImageUrl?: string;
  VenueId?: number;
  VenueName: string;
  RequestsCount: number;
  AvailableTickets: number;
  SoldTickets: number;
  SoldTicketsValue: number;
  EventTag: string;
  Dates: any[];
  GeneralAdmission: boolean;
  EventDate?: string;
  EventDateAsString?: string;
  EventDateId?: number;
  TourId: number;
  TourName: string;
  TourTag: string;
}

interface IProps {
  organisation?: IOrganisation;
  tour?: ITour;
}

const AdminEvents: React.FC<IProps> = (props) => {
  const [organisation, setOrganisation] = useState<IOrganisation>(props.organisation);
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
  const tour = props.tour;

  const [showEndedEvents, setShowEndedEvents] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<IEventOverview[]>([]);
  const [, setEventWizardOptions] = useRecoilState(wizardEventState);

  useEffect(() => {
    if (!organisation) {
      setLoading(true);

      AdminApi.request('GET', `/api/UserOrganisations`)
        .then((response: string) => {
          if (response == null) {
            alert('No response from user organisations (1).');
          } else {
            const organisations = JSON.parse(response) as IOrganisation[];
            if (organisations.length == 0) {
              alert('Cannot find any organisations for this user (1).');
            } else {
              setOrganisations(organisations);

              const lastUsedOrganisation = Cookies.get('last-managed-organisation');

              if (lastUsedOrganisation) {
                const organisation = organisations.find((o) => o.OrganisationTag == lastUsedOrganisation);

                if (organisation) {
                  setOrganisation(organisation);
                }
              }
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      reload(showEndedEvents);
    }
  }, []);

  const reload = (endedEvents: boolean) => {
    setLoading(true);
    AdminApi.request(
      'GET',
      `/api/MyEvents?showOldEvents=${endedEvents}${organisation ? `&organisationId=${organisation.Id}` : ''}${tour ? `&tourId=${tour.Id}` : ''}`,
    )
      .then((eventDates: IEventOverview[]) => {
        eventDates = eventDates.map((r) => {
          return { ...r };
        });

        var groupedByEvent = linq
          .from(eventDates)
          .groupBy((o) => o.OrganisationId)
          .selectMany((organisationGroup) => {
            return organisationGroup
              .groupBy((e) => e.EventId)
              .select((g) => {
                const event = g.first();
                const eventOverview: IEventOverview = {
                  ...event,
                  Organisation: {
                    Id: event.OrganisationId,
                    Name: event.OrganisationName,
                    Roles: [],
                    OrganisationTag: event.OrganisationTag,
                    LogoUrl: event.OrganisationImageUrl,
                    DisableTheme: true,
                    StreetAddress: '',
                    City: '',
                    Postcode: '',
                  },
                  AvailableSeatedTickets: g.sum((o) => o.AvailableSeatedTickets),
                  AvailableGATickets: g.sum((o) => o.AvailableGATickets),
                  RequestsCount: g.sum((o) => o.RequestsCount),
                  AvailableTickets: g.sum((o) => o.AvailableGATickets + o.AvailableSeatedTickets),
                  SoldSeatedTickets: g.sum((o) => o.SoldSeatedTickets),
                  SoldGATickets: g.sum((o) => o.SoldGATickets),
                  SoldSeatedTicketsValue: g.sum((o) => o.SoldSeatedTicketsValue),
                  SoldGATicketsValue: g.sum((o) => o.SoldGATicketsValue),
                  SoldTickets: g.sum((o) => o.SoldGATickets + o.SoldSeatedTickets),
                  SoldTicketsValue: g.sum((o) => o.SoldGATicketsValue + o.SoldSeatedTicketsValue),
                  Dates: g.orderBy((e) => moment.utc(e.EventDateAsString)).toArray(),
                  SoldMerchandise: g.sum((o) => o.SoldMerchandise),
                  SoldMerchandiseValue: g.sum((o) => o.SoldMerchandiseValue),
                  RemainingMerchandise: g.sum((o) => o.RemainingMerchandise),
                  AvailableMerchandise: g.sum((o) => o.AvailableMerchandise),
                };
                return eventOverview;
              })
              .toArray();
          })
          .orderByDescending((e) => (e.Dates.length == 0 ? '' : moment.utc(e.Dates[0].EventDateAsString)))
          .toArray();

        setEvents(groupedByEvent);
        setLoading(false);
      })
      .catch((message) => {
        alert(message);
        setLoading(false);
      });
  };

  var eventRows = [];

  var organisationRows: IMyEventsOrganisation[] = linq
    .from(events)
    .where(
      (e) =>
        filter.length == 0 ||
        (e.EventName == null ? '' : e.EventName).toLowerCase().includes(filter.toLowerCase()) ||
        (e.GroupName == null ? '' : e.GroupName).toLowerCase().includes(filter.toLowerCase()) ||
        (e.EventTag == null ? '' : e.EventTag).toLowerCase().includes(filter.toLowerCase()) ||
        (e.OrganisationTag == null ? '' : e.OrganisationTag).toLowerCase().includes(filter.toLowerCase()) ||
        (e.TourTag == null ? '' : e.TourTag).toLowerCase().includes(filter.toLowerCase()) ||
        (e.TourName == null ? '' : e.TourName).toLowerCase().includes(filter.toLowerCase()) ||
        (e.VenueName == null ? '' : e.VenueName).toLowerCase().includes(filter.toLowerCase()) ||
        (e.OrganisationName == null ? '' : e.OrganisationName).toLowerCase().includes(filter.toLowerCase()),
    )
    .groupBy((o) => o.OrganisationId)
    .select((g) => {
      return {
        OrganisationId: g.first().OrganisationId,
        OrganisationName: g.first().OrganisationName,
        Organisation: g.first().Organisation,
        Events: g.toArray(),
        Tours: g
          .where((e) => e.TourId != null)
          .groupBy((e) => e.TourId)
          .select((tourGroup) => {
            const first = tourGroup.first();
            const tour: IMyEventsTour = {
              TourId: first.TourId,
              TourName: first.TourName,
              TourTag: first.TourTag,
              Events: tourGroup.toArray(),
              Tour: {
                Id: first.TourId,
                Name: first.TourName,
                TourTag: first.TourTag,
                DisableTheme: true,
              },
            };

            return tour;
          })
          .toArray(),
      };
    })
    .orderBy((o) =>
      linq
        .from(o.Events)
        .where((e) => e.EventId !== 0)
        .count() === 0
        ? 1
        : 0,
    )
    .thenBy((o) => o.OrganisationName)
    .toArray();

  organisationRows.forEach((o) => {
    if (!organisation) {
      eventRows.push(
        <OrganisationRow
          createEventClick={(o) => setEventWizardOptions({ organisation: o })}
          key={'organisation_' + o.OrganisationId}
          organisation={o.Organisation}
          events={o.Events}
        />,
      );
    }

    if (o.Tours && o.Tours.length > 0) {
      linq
        .from(o.Tours)
        .orderBy((e) => e.TourName)
        .forEach((tour) => {
          if (!tour) {
            eventRows.push(<TourRow key={'tour_' + tour.TourId} tour={tour.Tour} events={tour.Events} />);
          }

          linq
            .from(tour.Events)
            .orderBy((e) => (e.Dates.length > 0 ? moment.utc(e.Dates[0].EventDateAsString).unix() : 0))
            .forEach((evt, index) => {
              eventRows.push(
                <EventRow lastInGroup={index == o.Events.length - 1} key={'event_' + evt.EventId} event={evt} />,
              );
            });
        });

      if (
        linq
          .from(o.Events)
          .where((e) => !e.TourId)
          .toArray().length > 0
      ) {
        eventRows.push(
          <TourRow
            key={'tour_'}
            tour={{ Name: 'No Tour', Id: 0, DisableTheme: true }}
            events={linq
              .from(o.Events)
              .where((e) => !e.TourId)
              .toArray()}
          />,
        );

        linq
          .from(o.Events)
          .where((e) => e.EventId !== 0 && !e.TourId)
          .orderBy((e) => (e.Dates.length > 0 ? moment.utc(e.Dates[0].EventDateAsString).unix() : 0))
          .forEach((evt, index) => {
            eventRows.push(
              <EventRow lastInGroup={index == o.Events.length - 1} key={'event_' + evt.EventId} event={evt} />,
            );
          });
      }
    } else {
      linq
        .from(o.Events)
        .where((e) => e.EventId !== 0 && !e.TourId)
        .orderBy((e) => (e.Dates.length > 0 ? moment.utc(e.Dates[0].EventDateAsString).unix() : 0))
        .forEach((evt, index) => {
          eventRows.push(
            <EventRow lastInGroup={index == o.Events.length - 1} key={'event_' + evt.EventId} event={evt} />,
          );
        });
    }
  });

  return (
    <>
      <Helmet>
        <title>{organisation ? `#${organisation.OrganisationTag} Events` : `Seaty - Events`}</title>
        <meta
          name="description"
          content={organisation ? `#${organisation.OrganisationTag} list of events.` : `Seaty - List of users events.`}
        />
      </Helmet>

      <div className="toolbar">
        <div className="title">{AdminSection.Events}</div>
        <div className="buttons">
          {organisation && (
            <button
              onClick={() =>
                setEventWizardOptions({
                  organisation: organisation,
                  tour: tour,
                  onClose: () => reload(showEndedEvents),
                })
              }
            >
              <SVGPlus />
              Create Event
            </button>
          )}
          <button
            onClick={() => {
              var value = !showEndedEvents;

              setShowEndedEvents(value);

              reload(value);
            }}
          >
            {!showEndedEvents ? <SVGToggleOff /> : <SVGToggleOn />}
            {showEndedEvents ? 'Hide' : 'Show'} Inactive Events
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6"></div>
        <div className="col-sm-6">
          <div className="donotprint" style={{ margin: '0' }}>
            <div className="input">
              <input
                id="searchinput"
                onChange={(e) => setFilterText(e.target.value)}
                type="text"
                placeholder="Search..."
                value={filterText}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    setFilter(filterText);
                  }
                }}
                style={{ margin: '0' }}
              />

              <button
                onClick={() => {
                  setFilter('');
                  setFilterText('');
                }}
              >
                <SVGMultiply />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="orderoverview" style={{ paddingBottom: '100px' }}>
        {!loading && (events == null || events.length == 0) ? (
          <div className="info">
            <div>
              <strong>No events found</strong>
            </div>
            You have not yet created any events!
          </div>
        ) : (
          <>
            {loading && <Loader inline>Loading your events...</Loader>}

            <div style={{ display: loading ? 'none' : 'block' }}>
              <div className="container-outer" style={{ minHeight: '300px' }}>
                <div className="container-inner" style={{ backgroundColor: 'white' }}>
                  <table className="table grid-table">
                    <thead>
                      <tr>
                        <th style={{ width: '25px', borderTopLeftRadius: '6px' }}></th>
                        <th className="grid-header"></th>
                        <th className="grid-header" style={{ textAlign: 'left', width: '130px' }}></th>
                        <th className="grid-header" style={{ textAlign: 'left', width: '130px' }}>
                          Sold
                        </th>
                        <th className="grid-header" style={{ textAlign: 'left', width: '130px' }}>
                          Amount
                        </th>
                        <th className="grid-header" style={{ textAlign: 'right', width: '130px' }}>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>{eventRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminEvents;

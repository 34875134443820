import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../api/AdminApi';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../components/BlockInfo';
import BlockTextBox from '../../components/BlockTextBox';
import Button from '../../components/Button';
import BlockCheckBox from '../../components/BlockCheckBox';
import Loader from '../../components/Loader';
import Lookup from '../../components/Lookup';
import OrderDashboard from '../../components/order_dashboard/OrderDashboard';
import PaymentControl, { PaymentType } from '../../components/stripe/PaymentControl';
import SpacerTable from '../../components/SpacerTable';
import AttendeeHelper from '../../helpers/AttendeeHelper';
import CacheHelper from '../../helpers/CacheHelper';
import DateHelper from '../../helpers/DateHelper';
import EmailHelper from '../../helpers/EmailHelper';
import StringHelper from '../../helpers/StringHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOption } from '../../interfaces/IOption';
import { IPayment } from '../../interfaces/IPayment';
import RefundControl from './RefundControl';
import { IReferral } from '../../interfaces/IReferral';
import MembershipButton from './MembershipButton';
import { IOrganisationMember } from '../../services/MemberService';
import MembershipSection from './MembershipSection';
import { IOrganisation } from '../../interfaces/IOrganisation';
import EnvironmentVariables from '../../EnvironmentVariables';

export interface IProps {
  organisation: IOrganisation;
  eventId: number;
  email: string;
  overviewRefreshedCallback: (overview: any) => void;
}

enum Section {
  Dashboard = 'Dashboard',
  AddPayment = 'AddPayment',
  AddRefund = 'AddRefund',
  AddReferral = 'AddReferral',
  ReferralOrderReceived = 'Referral',
  ReferralsReceived = 'Referrals',
  Membership = 'Membership',
  Balance = 'Balance',
  AdminDashboard = 'AdminDashboard',
  TicketsByOrder = 'TicketsByOrder',
  TicketsByDate = 'TicketsByDate',
  Payments = 'Payments',
  EditPayment = 'EditPayment',
  Refunds = 'Refunds',
  Payment = 'Payment',
  BalancePaymentRefundSection = 'BalancePaymentRefundSection',
  Refund = 'Refund',
}

interface IOverview {
  TotalMerchandiseSales: number;
  TotalReferralsPriceAsString: string;
  TotalReferralTickets: string;
  Email: string;
  Event: IEvent;
  TotalPaidAsInt: number;
  TotalOwedAsInt: number;
  Dates: any;
  Orders: any;
  Referrals: IReferral[];
  User: any;
  Member: IOrganisationMember;
  IsMember: boolean;
  EventId: number;
  UserId: number;
  TotalTickets: number;
  TotalOrdersPriceAsString: any;
  MembershipsEnabled: any;
  MemberType: any;
  MemberTypes: any;
  Payments: any;
  TotalOwedAsString: string;
  Discounts: any;
}

let attendeeTimeout: number = 0;

const EventAttendeeOverview: FunctionComponent<IProps> = (props) => {
  const [section, setSection] = useState<Section>(Section.Dashboard);

  const [showPaidOrders, setShowPaidOrders] = useState(true);
  const [busyMessage, setBusyMessage] = useState('Refreshing overview...');
  const [overview, setOverview] = useState<IOverview>(null);
  const [errorMessage, setError] = useState(null);
  const [reloadMessage, setReloadMessage] = useState(null);

  const { eventId, email, overviewRefreshedCallback } = props;
  const [event, setEvent] = useState<IEvent>();

  useEffect(() => {
    if (props.email != null) {
      refresh(Section.Dashboard);
    }
  }, [email]);

  const downloadEventCache = async (eventTag: string) => {
    const eventData: IEvent = await CacheHelper.eventByTag(eventTag);
    setEvent(eventData);
  };

  const refresh = (section?: Section) => {
    setBusyMessage('Refreshing overview...');
    setReloadMessage(null);

    AdminApi.request('GET', `/api/AttendeeOverviewEvent?eventId=${eventId}&email=${email}`)
      .then((response) => {
        downloadEventCache(response.Event.EventTag).then(() => {
          response.Dates.forEach((date) => {
            date.Orders = linq
              .from(response.Orders)
              .where((o: any) => o.DateId === date.Id)
              .toArray();
            date.Tickets = linq.from(date.Orders).sum(function (o: any) {
              return o.Tickets;
            });
            date.MerchandiseSales = linq.from(date.Orders).sum(function (o: any) {
              return o.MerchandiseSales;
            });
          });
          setOverview(response);
          setSection(section == null ? Section.Dashboard : section);
          setBusyMessage(null);

          if (overviewRefreshedCallback) {
            overviewRefreshedCallback(response);
          }
        });
      })
      .catch(() => {
        setSection(Section.Dashboard);
        setBusyMessage(null);
        setError('Error when finding orders');
      });
  };

  const renderAddRefundSection = () => {
    return (
      <>
        <RefundControl
          event={event}
          refundMadeCallback={() => refresh(Section.Balance)}
          payeeEmail={overview.Email}
          currency={overview.Event.CurrencySymbol}
          balance={overview.TotalPaidAsInt}
        ></RefundControl>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => refresh(Section.Balance)} text={'Back to balance'} />
        </SpacerTable>
      </>
    );
  };

  const renderEditPaymentSection = () => {
    return (
      <>
        <PaymentControl
          isAdmin={true}
          organisationFeePercentage={overview.Event.OrganisationFeePercentage}
          minimumFee={overview.Event.MinimumFee}
          feePercentage={overview.Event.FeePercentage}
          absorbFee={overview.Event.AbsorbFee}
          eventId={overview.Event.Id}
          paymentMadeCallback={() => refresh(Section.Balance)}
          payeeEmail={overview.Email}
          currency={overview.Event.CurrencySymbol}
          event={event}
          payment={selectedPayment}
        />

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => refresh(Section.Payment)} text={'Back to payment'} />
        </SpacerTable>
      </>
    );
  };

  const renderAddPaymentSection = () => {
    return (
      <>
        <PaymentControl
          isAdmin={true}
          organisationFeePercentage={overview.Event.OrganisationFeePercentage}
          minimumFee={overview.Event.MinimumFee}
          feePercentage={overview.Event.FeePercentage}
          absorbFee={overview.Event.AbsorbFee}
          eventId={overview.Event.Id}
          paymentMadeCallback={() => refresh(Section.Balance)}
          payeeEmail={overview.Email}
          currency={overview.Event.CurrencySymbol}
          balance={overview.TotalOwedAsInt}
          event={event}
        />

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => refresh(Section.Balance)} text={'Back to balance'} />
        </SpacerTable>
      </>
    );
  };

  const addAPayment = () => {
    setError(null);
    setSection(Section.AddPayment);
  };

  const addARefund = () => {
    setError(null);
    setSection(Section.AddRefund);
  };

  const [selectedEventDate, setSelectedEventDate] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [copied, setCopied] = useState(false);

  const goToOrdersClick = () => {
    var dates = overview.Dates;
    var orders = overview.Orders;

    if (hasOneDate()) {
      if (hasOneOrder()) {
        setSelectedEventDate(dates[0]);
        setSelectedOrderId(orders[0].Id);
        setSection(Section.AdminDashboard);
      } else {
        setSelectedEventDate(dates[0]);
        setSection(Section.TicketsByOrder);
      }
    } else {
      setSection(Section.TicketsByDate);
    }
  };

  const goToReferralsClick = () => {
    var referrals = overview.Referrals;

    setSection(Section.ReferralsReceived);
  };

  const hasOneDate = () => {
    var dates = overview.Dates;

    return dates.length == 1;
  };

  const hasOneOrder = () => {
    var orders = overview.Orders;

    return orders.length == 1;
  };

  const getUserField = () => {
    return (
      <Block>
        <BlockHeader>Attendee</BlockHeader>
        {overview.UserId == 0 ? null : <div>{overview.User.Name}</div>}
        <a href={`mailto:${overview.Email}`}>{overview.Email}</a>
        {overview.UserId != 0 ? null : (
          <div>
            {overview.Email == null || overview.Email.length == 0
              ? 'There is no email address specified for this member.'
              : 'This email address has no account on Seaty.'}
          </div>
        )}
      </Block>
    );
  };

  const renderDashboardSection = () => {
    var orders = overview.Orders;
    var referrals = overview.Referrals;

    return (
      <>
        <table className="blocks">
          <tbody>
            {getUserField()}

            {overview.Email == null || overview.Email.length == 0 ? null : (
              <>
                <Block className="route" onClick={goToOrdersClick}>
                  <BlockHeader>
                    {StringHelper.AddSWhenMany(orders.length, 'Order')}
                    {orders.length == 1 ? ' #' + orders[0].Id : ''}
                  </BlockHeader>
                  {overview.TotalTickets + ' ' + StringHelper.AddSWhenMany(overview.TotalTickets, 'ticket')}
                  {overview.TotalMerchandiseSales > 0 &&
                    `, ${overview.TotalMerchandiseSales} ${StringHelper.AddSWhenMany(overview.TotalMerchandiseSales, 'merchandise sale')}`}
                  {orders.length == 1 ? null : ' in ' + orders.length + ' orders'} for{' '}
                  {overview.TotalOrdersPriceAsString}
                </Block>
                {referrals.length > 0 && (
                  <Block className="route" onClick={goToReferralsClick}>
                    <BlockHeader>{StringHelper.AddSWhenMany(referrals.length, 'Referral')}</BlockHeader>
                    {overview.TotalReferralTickets +
                      ' ' +
                      StringHelper.AddSWhenMany(overview.TotalReferralTickets, 'ticket')}{' '}
                    in {referrals.length} {StringHelper.AddSWhenMany(referrals.length, 'order')} referrals received
                  </Block>
                )}

                {getBalanceField(false)}

                {overview.TotalTickets > 0 && (
                  <>
                    <Block
                      className="route"
                      onClick={() => {
                        var win = window.open(
                          '/Order/AllTickets?email=' + email + '&event=' + overview.Event.EventTag,
                          '_blank',
                        );
                        win.focus();
                      }}
                    >
                      <BlockHeader>View all tickets</BlockHeader>
                    </Block>

                    <Block
                      className="route"
                      onClick={() => {
                        setSection(Section.AddReferral);
                      }}
                    >
                      <BlockHeader>Refer all tickets</BlockHeader>
                    </Block>
                  </>
                )}
              </>
            )}

            {overview.MembershipsEnabled && (
              <MembershipButton
                organisationName={overview.Event.OrganisationName}
                isMember={overview.IsMember}
                memberTypeName={overview.MemberType && overview.MemberType.Name}
                onClick={() => setSection(Section.Membership)}
                quota={overview.MemberType && overview.MemberType.Quota}
              />
            )}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => refresh()} text={'Refresh'} />
        </SpacerTable>
      </>
    );
  };

  const renderMembershipSection = () => {
    return (
      <>
        <MembershipSection event={event} organisation={props.organisation} overview={overview} setError={setError} />
        <div className="spacer" />
        <SpacerTable>
          <Button
            onClick={() => {
              refresh();
              setSection(Section.Dashboard);
            }}
            text={'Back to overview'}
          />
        </SpacerTable>
      </>
    );
  };

  const getBalanceField = (info) => {
    return (
      <Block className={info ? '' : 'route'} onClick={info ? null : () => setSection(Section.Balance)}>
        <BlockHeader>
          {overview.TotalOwedAsInt > 0 ? (
            <span className="danger">Balance due</span>
          ) : overview.TotalOwedAsInt < 0 ? (
            <span className="success">Balance overpaid</span>
          ) : (
            'Balance'
          )}
        </BlockHeader>

        {overview.TotalOwedAsInt == 0 ? (
          <span>{overview.Payments.length > 0 ? 'Paid in full' : overview.TotalOwedAsString}</span>
        ) : null}
        {overview.TotalOwedAsInt < 0 ? (
          <span className="success">{overview.TotalOwedAsString.replace('-', '')}</span>
        ) : null}
        {overview.TotalOwedAsInt > 0 ? <span className="danger">({overview.TotalOwedAsString})</span> : null}
      </Block>
    );
  };

  const [attendeeEmail, setAttendeeEmail] = useState('');
  const [attendeeOptions, setAttendeeOptions] = useState<Array<IOption>>([]);
  const [lookingForAttendees, setLookingForAttendees] = useState(false);
  const handleAttendeeEmailLookupTextChanged = (lookupText: string) => {
    setAttendeeEmail(lookupText);
    window.clearTimeout(attendeeTimeout);

    if (lookupText.length > 0) {
      setLookingForAttendees(true);
      attendeeTimeout = window.setTimeout(() => {
        AttendeeHelper.lookupAttendees(lookupText).then((options) => {
          setAttendeeOptions(options);
          setLookingForAttendees(false);
        });
      }, 500);
    } else {
      setLookingForAttendees(false);
    }
  };

  const renderReferralSection = () => {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo>
              Submitting this referral will refer all tickets assigned to this person to the new email address. This
              will not transfer the tickets, they will remain linked to the original attendees account. Referrals are
              only used for counting quotas and grouping tickets together in Orders & Payments.
            </BlockInfo>
            <Block>
              <Lookup
                focus={true}
                autoComplete="off"
                placeholder={'Email address'}
                type="email"
                name="emailaddress"
                value={attendeeEmail}
                options={attendeeOptions}
                loading={lookingForAttendees}
                onLookupOptionSelected={(option) => {
                  setAttendeeEmail(option.Text);
                }}
                onLookupTextChange={handleAttendeeEmailLookupTextChanged}
                header={`Change referral`}
              />
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button
            disabled={attendeeEmail == null || !EmailHelper.validate(attendeeEmail)}
            className="confirm"
            onClick={() => referAllTickets()}
            text={'Submit'}
          />
        </SpacerTable>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
        </SpacerTable>
      </>
    );
  };

  const referAllTickets = () => {
    AdminApi.request('POST', '/api/ReferAll', {
      email: attendeeEmail,
      eventId: eventId,
      sourceEmail: overview.Email,
    })
      .then((response) => {
        setReloadMessage(`All orders for this user have been referred to ${attendeeEmail}`);
      })
      .catch((message) => {
        alert(message);
        setError('Error when adding referral for all tickets.');
      });
  };

  const renderBalanceSection = () => {
    var currency = overview.Event.CurrencySymbol;
    var payments = linq
      .from(overview.Payments)
      .where((p: any) => p.IsRefund == false)
      .toArray();

    var refunds = linq
      .from(overview.Payments)
      .where((p: any) => p.IsRefund == true)
      .toArray();

    var totalPaid = currency + (linq.from(payments).sum((p: any) => p.AmountPaidAsInt) / 100.0).toFixed(2);
    var totalRefunded = currency + (linq.from(refunds).sum((p: any) => p.AmountPaidAsInt) / 100.0).toFixed(2);

    return (
      <>
        <table className="blocks">
          <tbody>
            {getUserField()}
            {getBalanceField(true)}
            <Block
              className="route"
              onClick={() => {
                var win = window.open(`/${overview.Event.EventTag}/AttendeeStatement?email=${email}`, '_blank');
                win.focus();
              }}
            >
              <BlockHeader>Print a statement</BlockHeader>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block className="route" onClick={() => setSection(Section.Payments)}>
              <BlockHeader>Payments</BlockHeader>
              {payments.length == 0 ? (
                <span>None made</span>
              ) : (
                <span>{payments.length + ' totalling ' + totalPaid}</span>
              )}
            </Block>
            <Block className="route" onClick={addAPayment}>
              <BlockHeader>Add a balance payment</BlockHeader>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />
        <table className="blocks">
          <tbody>
            <Block className="route" onClick={() => setSection(Section.Refunds)}>
              <BlockHeader>Refunds</BlockHeader>
              {refunds.length == 0 ? (
                <span>None issued</span>
              ) : (
                <span>{refunds.length + ' totalling ' + totalRefunded.replace('-', '')}</span>
              )}
            </Block>
            <Block className="route" onClick={addARefund}>
              <BlockHeader>Add a balance refund</BlockHeader>
            </Block>
          </tbody>
        </table>
        <div className="spacer" />

        <SpacerTable>
          <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
        </SpacerTable>
      </>
    );
  };

  const [showCancelledOrders, setShowCancelledOrders] = useState(false);
  const [orderSearchInput, setOrderSearchInput] = useState('');

  const renderReferralsReceivedSection = () => {
    var referrals = overview.Referrals;

    var referralRows = linq
      .from(referrals)
      .orderBy((o: any) => o.AttendeeName)
      .select((referral: any) => {
        return (
          <Block
            key={'referral_row' + referral.OrderNumber}
            className="route"
            onClick={() => {
              setSelectedOrderId(parseInt(referral.OrderNumber));
              setSection(Section.ReferralOrderReceived);
            }}
          >
            <BlockHeader>Order #{referral.OrderNumber}</BlockHeader>
            <div>{referral.AttendeeName}</div>
            <div>
              {referral.Seats.length} {StringHelper.AddSWhenMany(referral.Seats.length, 'ticket')} referred
            </div>
          </Block>
        );
      })
      .toArray();

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Referrals received</BlockHeader>
            </Block>

            {getUserField()}

            {referralRows.length > 0 ? referralRows : <BlockInfo>No referrals found.</BlockInfo>}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
        </SpacerTable>
      </>
    );
  };

  const renderTicketsByOrderSection = () => {
    var currency = overview.Event.CurrencySymbol;
    var eventDate: any = linq
      .from(overview.Dates)
      .where((d: any) => d.Id == selectedEventDate.Id)
      .firstOrDefault();

    var orders = eventDate.Orders;
    var searchTerm = orderSearchInput;
    var showCancelled = showCancelledOrders;

    var orderRows = linq
      .from(orders)
      .orderBy((o: any) => moment(o.DateOrdered).utc())
      .where((o: any) => {
        if (searchTerm == null || searchTerm.length == 0) {
          return true;
        }
        var orderNumberMatched = (o.Id + '').toLowerCase().indexOf(searchTerm) != -1;
        var attendeeNameMatched = o.AttendeeName.toLowerCase().indexOf(searchTerm) != -1;
        return orderNumberMatched || attendeeNameMatched;
      })
      .where(
        (o: any) => ((!o.Free || (showPaidOrders && o.Free)) && (!o.Paid || (showPaidOrders && o.Paid))) || o.Cancelled,
      )
      .where((o: any) => (showCancelled && o.Cancelled) || !o.Cancelled)
      .orderBy((o: any) => o.Id)
      .select((o: any) => {
        var discountAmount = linq
          .from(overview.Discounts)
          .where((d: any) => d.OrderId == o.Id)
          .sum((d: any) => d.Amount);

        var total = o.Price - discountAmount;

        return (
          <Block
            key={'order_row_' + o.Id}
            className="route"
            onClick={() => {
              setSelectedOrderId(o.Id);
              setSection(Section.AdminDashboard);
            }}
          >
            <BlockHeader>#{o.Id}</BlockHeader>
            {o.AttendeeName != null && o.AttendeeName.length > 0 && o.AttendeeName != overview.User.Name ? (
              <div>
                {o.AttendeeName.length > 16 && !(o.AttendeeName.length < 20)
                  ? o.AttendeeName.substring(0, 12) + '...'
                  : o.AttendeeName}
              </div>
            ) : null}

            {o.Email != overview.Email ? <div>{o.Email}</div> : null}
            {o.Cancelled ? null : (
              <div>
                {o.Tickets == 0 && total == 0
                  ? null
                  : o.Tickets +
                    ' ' +
                    StringHelper.AddSWhenMany(o.Tickets, 'ticket') +
                    (o.MerchandiseSales == 0
                      ? ''
                      : ', ' +
                        o.MerchandiseSales +
                        ' ' +
                        StringHelper.AddSWhenMany(o.MerchandiseSales, 'merchandise sale')) +
                    ' for ' +
                    currency +
                    (total / 100.0).toFixed(2)}
              </div>
            )}

            <div className="tags">
              {discountAmount != null && discountAmount > 0 ? <span className="tag ice">Discount</span> : null}
              {o.Cancelled ? (
                <span className="tag danger">Cancelled</span>
              ) : o.Free ? (
                <span className="tag ice">Free</span>
              ) : o.Paid ? (
                <span className="tag confirm">Paid</span>
              ) : null}
            </div>
          </Block>
        );
      })
      .toArray();

    var anyPaidOrders =
      linq
        .from(orders)
        .where((o: any) => o.Paid == true)
        .toArray().length > 0;
    var anyCancelled =
      linq
        .from(orders)
        .where((o: any) => o.Cancelled == true)
        .toArray().length > 0;

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Orders for {DateHelper.asDateAtTimeAmPm(eventDate.Date)}</BlockHeader>
            </Block>

            {getUserField()}
            {orders.length > 5 && (
              <Block>
                <BlockTextBox
                  name="search"
                  placeholder={'Search orders'}
                  autoFocus={true}
                  value={orderSearchInput}
                  onChange={(e) => {
                    setOrderSearchInput(e);
                  }}
                />
              </Block>
            )}

            {anyPaidOrders && (
              <BlockCheckBox checked={showPaidOrders} onBoxClick={() => setShowPaidOrders(!showPaidOrders)}>
                Show paid orders
              </BlockCheckBox>
            )}

            {anyCancelled && (
              <BlockCheckBox
                onBoxClick={() => setShowCancelledOrders(!showCancelledOrders)}
                checked={showCancelledOrders}
              >
                Show cancellations
              </BlockCheckBox>
            )}

            {orderRows.length > 0 ? orderRows : <BlockInfo>No orders found.</BlockInfo>}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          {hasOneDate() ? (
            <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
          ) : (
            <Button onClick={() => setSection(Section.TicketsByDate)} text={'Back to dates'}></Button>
          )}
        </SpacerTable>
      </>
    );
  };

  const eventDateClicked = (eventDate) => {
    setSelectedEventDate(eventDate);
    setSection(Section.TicketsByOrder);
    setOrderSearchInput('');
    setShowPaidOrders(true);
    setShowCancelledOrders(false);
  };

  const renderReferralOrderSection = () => {
    return (
      <>
        <OrderDashboard event={event} orderId={selectedOrderId} />
        <div className="spacer" />
        <SpacerTable>
          {hasOneDate() && hasOneOrder() ? (
            <Button onClick={() => refresh(Section.Dashboard)} text={'Back to overview'} />
          ) : (
            <Button onClick={() => refresh(Section.ReferralsReceived)} text={'Back to referrals'} />
          )}
        </SpacerTable>
      </>
    );
  };

  const renderOrderDashboardSection = () => {
    return (
      <>
        <OrderDashboard event={event} orderId={selectedOrderId} />
        <div className="spacer" />
        <SpacerTable>
          {hasOneDate() && hasOneOrder() ? (
            <Button onClick={() => refresh(Section.Dashboard)} text={'Back to overview'} />
          ) : (
            <Button onClick={() => refresh(Section.TicketsByOrder)} text={'Back to orders'} />
          )}
        </SpacerTable>
      </>
    );
  };

  const renderTicketsByDateSection = () => {
    var currency = overview.Event.CurrencySymbol;

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Orders by Event Date</BlockHeader>
            </Block>

            {getUserField()}

            {linq
              .from(overview.Dates)
              .orderBy((d: any) => moment(d.Date).utc())
              .select((d: any) => {
                var allOrdersPaid = true;
                d.Orders.forEach((o) => {
                  if (!o.Paid) {
                    if (!o.Cancelled) {
                      if (!o.Free) {
                        allOrdersPaid = false;
                      }
                    }
                  }
                });

                return (
                  <Block
                    key={DateHelper.asDateAtTimeAmPm(d.Date)}
                    className="route"
                    onClick={() => eventDateClicked(d)}
                  >
                    <BlockHeader>{DateHelper.asDateAtTimeAmPm(d.Date)}</BlockHeader>
                    {`${d.Tickets} ${StringHelper.AddSWhenMany(d.Tickets, 'ticket')}${d.MerchandiseSales > 0 ? `, ${d.MerchandiseSales} ${StringHelper.AddSWhenMany(d.MerchandiseSales, 'merchandise sale')}` : ''} in ${d.Orders.length} ${StringHelper.AddSWhenMany(d.Orders.length, 'order')} for ${currency}${(
                      (linq.from(d.Orders).sum((o: any) => o.Price) - d.DiscountsAsInt) /
                      100.0
                    ).toFixed(2)}`}
                    {d.Tickets.length > 0 && allOrdersPaid && (
                      <div className="tags">
                        <span className="tag confirm">All Paid</span>
                      </div>
                    )}
                  </Block>
                );
              })
              .toArray()}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
        </SpacerTable>
      </>
    );
  };

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedRefund, setSelectedRefund] = useState(null);

  const renderPaymentSection = () => {
    var payment: IPayment = selectedPayment;
    var paymentDetails;

    if (payment.TypeId == 1) {
      paymentDetails = <span>Cash</span>;
    } else if (payment.TypeId == 2) {
      paymentDetails = <span>Cheque</span>;
    } else if (payment.TypeId == 3) {
      paymentDetails = <span>Card outside of Seaty</span>;
    } else if (payment.TypeId == 4) {
      paymentDetails = <span>Bank transfer</span>;
    } else if (payment.TypeId == 5) {
      if (payment.OrderId) {
        paymentDetails = <span>Ticket purchase by card online through Seaty</span>;
      } else {
        paymentDetails = <span>Balance payment by card online through Seaty</span>;
      }
    }
    console.log(payment.TypeId);
    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Payment #{payment.Id}</BlockHeader>
            </Block>
            {payment.TypeId == 5 && !payment.OrderId && (
              <BlockInfo>
                This is a card balance payment. Card balance payments are those that have been made by credit or debit
                card by an attendee to settle their outstanding balance. It is not attached to any order.
              </BlockInfo>
            )}
            <Block>
              <BlockHeader>Date created</BlockHeader>
              {DateHelper.asDateAtTimeAmPm(payment.DatePaymentMade)}
            </Block>
            <Block>
              <BlockHeader>Payee email</BlockHeader>
              {payment.PayeeEmail}
            </Block>
            <Block>
              <BlockHeader>Created by</BlockHeader>
              {payment.ProcessedBy}
            </Block>
            {payment.OrderId != null ? (
              <Block>
                <BlockHeader>Linked to order</BlockHeader>
                {payment.OrderId}
              </Block>
            ) : null}
            <Block>
              <BlockHeader>Amount</BlockHeader>
              {payment.AmountPaidAsString.replace('-', '')}
            </Block>
            <Block>
              <BlockHeader>Method</BlockHeader>
              {paymentDetails}
            </Block>
            {payment.Notes != null && payment.Notes.length > 0 ? (
              <Block>
                <BlockHeader>Notes</BlockHeader>
                {payment.Notes}
              </Block>
            ) : null}
          </tbody>
        </table>
        {payment.TypeId == 5 && !payment.OrderId && payment.PurchaseId && (
          <>
            <div className="spacer" />
            <SpacerTable>
              <Button
                className="bad"
                onClick={() => {
                  setCancellingReason('');
                  setCancellationError(null);
                  setBalancePaymentRefundComplete(false);
                  setRefundBalancePayment(selectedPayment);
                  setSection(Section.BalancePaymentRefundSection);
                }}
                text={'Refund card balance payment'}
              />
            </SpacerTable>
          </>
        )}
        {(payment.TypeId == PaymentType.Cash ||
          payment.TypeId == PaymentType.Cheque ||
          payment.TypeId == PaymentType.BankTransfer ||
          payment.TypeId == PaymentType.CardOutSideSeaty) && (
          <>
            <div className="spacer" />
            <SpacerTable>
              <Button onClick={() => setSection(Section.EditPayment)} text={'Edit payment'} />
            </SpacerTable>
          </>
        )}
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Payments)} text={'Back to payments'} />
        </SpacerTable>
      </>
    );
  };

  const [refundBalancePayment, setRefundBalancePayment] = useState<IPayment>(null);
  const [cancellingReason, setCancellingReason] = useState('');
  const [cancellationError, setCancellationError] = useState(null);
  const [balancePaymentRefundComplete, setBalancePaymentRefundComplete] = useState(false);

  const refundBalancePaymentSection = () => {
    return (
      refundBalancePayment && (
        <>
          <table className="blocks">
            <tbody>
              <Block>
                <BlockHeader>
                  <BlockHeader>Refund balance payment #{refundBalancePayment.Id}</BlockHeader>
                </BlockHeader>
              </Block>

              <BlockInfo>
                You are about to refund a card balance payment. This will issue funds back to the attendees card. This
                cannot be undone. Card balance payments are not attached to any order and refunding it will simply issue
                the funds back to the card and add the refund amount to this attendees Seaty account.
              </BlockInfo>

              <Block>
                <BlockHeader>Why are you cancelling?</BlockHeader>
                <BlockTextBox
                  name="cancellingReason"
                  placeholder={'You must enter a reason for refunding this card balance payment.'}
                  autoFocus={true}
                  value={cancellingReason}
                  onChange={(e) => {
                    setCancellingReason(e);
                  }}
                  rows={2}
                />
              </Block>

              {cancellationError && <BlockInfo type={InfoType.Error}>{cancellationError}</BlockInfo>}
            </tbody>
          </table>

          <div className="spacer" />
          <SpacerTable>
            <Button
              className="bad"
              disabled={cancellingReason.length <= 4}
              onClick={() => {
                setBusyMessage('Refunding payment...');
                setCancellationError(null);

                AdminApi.request('DELETE', `/api/BalanceCardPayment`, {
                  paymentId: refundBalancePayment.Id,
                  reason: cancellingReason,
                })
                  .then(() => {
                    setBusyMessage(null);
                    setReloadMessage('The payment has been refunded.');
                  })
                  .catch((message) => {
                    setCancellationError(message);
                    setBusyMessage(null);
                  });
              }}
              text={`Refund card balance payment`}
            />
          </SpacerTable>

          <div className="spacer" />
          <SpacerTable>
            <Button
              onClick={() => {
                setCancellingReason('');
                setCancellationError(null);
                setBalancePaymentRefundComplete(false);
                setRefundBalancePayment(null);
                setSection(Section.Payment);
              }}
              text={balancePaymentRefundComplete ? 'Return to payment' : 'Cancel'}
            />
          </SpacerTable>
        </>
      )
    );
  };

  const renderRefundSection = () => {
    var payment = selectedRefund;

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Refund #{payment.Id}</BlockHeader>
            </Block>

            <Block>
              <BlockHeader>Date refunded</BlockHeader>
              {DateHelper.asDateAtTimeAmPm(payment.DatePaymentMade)}
            </Block>
            <Block>
              <BlockHeader>Payee email</BlockHeader>
              {payment.PayeeEmail}
            </Block>
            <Block>
              <BlockHeader>Refunded by</BlockHeader>
              {payment.ProcessedBy}
            </Block>
            <Block>
              <BlockHeader>Amount</BlockHeader>
              {payment.AmountPaidAsString.replace('-', '')}
            </Block>
            <Block>
              <BlockHeader>Method</BlockHeader>
              Refund
            </Block>
            {payment.Notes != null && payment.Notes.length > 0 && (
              <Block>
                <BlockHeader>Notes</BlockHeader>
                {payment.Notes}
              </Block>
            )}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Payments)} text={'Back to overview'}></Button>
        </SpacerTable>
      </>
    );
  };

  const renderPaymentsSection = () => {
    var currency = overview.Event.CurrencySymbol;
    var payments = linq
      .from(overview.Payments)
      .where(function (p: any) {
        return p.IsRefund == false;
      })
      .toArray();
    var totalPaid =
      currency +
      (
        linq.from(payments).sum(function (p: any) {
          return p.AmountPaidAsInt;
        }) / 100.0
      ).toFixed(2);

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Payments overview</BlockHeader>
            </Block>

            <Block>
              <BlockHeader>Payments</BlockHeader>
              {payments.length == 0 ? (
                <span>None made</span>
              ) : (
                <span>{payments.length + ' totalling ' + totalPaid}</span>
              )}
            </Block>
            {linq
              .from(payments)
              .orderByDescending((p: any) => moment(p.DatePaymentMade).utc())
              .select((p: any) => {
                var paymentDetails;
                if (p.TypeId == 1) {
                  paymentDetails = <span>{p.AmountPaidAsString} in cash</span>;
                } else if (p.TypeId == 2) {
                  paymentDetails = <span>{p.AmountPaidAsString} by cheque</span>;
                } else if (p.TypeId == 3) {
                  paymentDetails = <span>{p.AmountPaidAsString} by card outside of Seaty</span>;
                } else if (p.TypeId == 4) {
                  paymentDetails = <span>{p.AmountPaidAsString} by bank transfer</span>;
                } else if (p.TypeId == 5) {
                  paymentDetails = <span>{p.AmountPaidAsString} by card online</span>;
                }

                return (
                  <Block
                    key={p.OrderId + '_' + moment(p.DatePaymentMade).utc()}
                    className="route"
                    onClick={() => {
                      setSection(Section.Payment);
                      setSelectedPayment(p);
                    }}
                  >
                    <BlockHeader>{paymentDetails}</BlockHeader>
                    {DateHelper.asDateAtTimeAmPm(p.DatePaymentMade)}

                    <div className="tags">
                      {p.OrderId == null && p.TypeId == 5 ? (
                        <span className="tag ice">Card Balance Payment</span>
                      ) : null}
                      {p.OrderId != null ? <span className="tag ice">Order #{p.OrderId}</span> : null}
                    </div>
                  </Block>
                );
              })
              .toArray()}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Balance)} text={'Back to balance'}></Button>
        </SpacerTable>
      </>
    );
  };

  const renderRefundsSection = () => {
    var currency = overview.Event.CurrencySymbol;
    var refunds = linq
      .from(overview.Payments)
      .where(function (p: any) {
        return p.IsRefund == true;
      })
      .toArray();
    var totalRefunded =
      currency +
      (
        linq.from(refunds).sum(function (p: any) {
          return p.AmountPaidAsInt;
        }) / 100.0
      ).toFixed(2);

    return (
      <>
        <table className="blocks">
          <tbody>
            <Block>
              <BlockHeader>Refunds overview</BlockHeader>
            </Block>

            <Block>
              <BlockHeader>Refunds</BlockHeader>
              {refunds.length == 0 ? (
                <span>None issued</span>
              ) : (
                <span>{refunds.length + ' totalling ' + totalRefunded.replace('-', '')}</span>
              )}
            </Block>
            {linq
              .from(refunds)
              .orderByDescending((p: any) => moment(p.DatePaymentMade).utc())
              .select((p: any) => {
                var paymentDetails = <span>{p.AmountPaidAsString.replace('-', '')} refunded</span>;

                return (
                  <Block
                    key={p.OrderId + '_' + moment(p.DatePaymentMade).utc()}
                    className="route"
                    onClick={() => {
                      setSection(Section.Refund);
                      setSelectedRefund(p);
                    }}
                  >
                    <BlockHeader>{DateHelper.asDateAtTimeAmPm(p.DatePaymentMade)}</BlockHeader>
                    {paymentDetails}
                  </Block>
                );
              })
              .toArray()}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => setSection(Section.Balance)} text={'Back to balance'}></Button>
        </SpacerTable>
      </>
    );
  };

  if (reloadMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Info}>{reloadMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onClick={() => refresh()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  if (errorMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Error}>{errorMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button
            onClick={() => {
              setError(null);
              refresh();
            }}
            text={'Dismiss'}
          />
        </SpacerTable>
      </>
    );
  }

  const sectionRenderMap: { [key in Section]?: () => JSX.Element | null } = {
    [Section.Balance]: renderBalanceSection,
    [Section.Dashboard]: renderDashboardSection,
    [Section.AddReferral]: renderReferralSection,
    [Section.Membership]: renderMembershipSection,
    [Section.Payments]: renderPaymentsSection,
    [Section.Refunds]: renderRefundsSection,
    [Section.Payment]: renderPaymentSection,
    [Section.BalancePaymentRefundSection]: refundBalancePaymentSection,
    [Section.Refund]: renderRefundSection,
    [Section.TicketsByDate]: renderTicketsByDateSection,
    [Section.ReferralOrderReceived]: renderReferralOrderSection,
    [Section.ReferralsReceived]: renderReferralsReceivedSection,
    [Section.TicketsByOrder]: renderTicketsByOrderSection,
    [Section.AdminDashboard]: renderOrderDashboardSection,
    [Section.AddPayment]: renderAddPaymentSection,
    [Section.EditPayment]: renderEditPaymentSection,
    [Section.AddRefund]: renderAddRefundSection,
    // Add more mappings as necessary
  };

  // Render the component based on the current section
  const renderSection = () => {
    const renderFunction = sectionRenderMap[section];
    return renderFunction ? renderFunction() : null;
  };

  return renderSection();
};

export default EventAttendeeOverview;

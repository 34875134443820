import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../api/AdminApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import BlockTextBox from '../components/BlockTextBox';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import GAHelper from '../helpers/GAHelper';
import { IUser } from '../interfaces/IUser';

export interface IProps {
  onClose?: () => void;
  onSubmit: (user: IUser) => void;
}

const UserSearchModal = ((props) => {
  let [query, setQuery] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(null);
  let [timeoutId, setTimeoutId] = useState(null);
  const [results, setResults] = useState<Array<IUser>>(null);
  const [error, setError] = useState(null);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    GAHelper.modal(`User Search`);
  }, []);

  const handleSearchChanged = (value) => {
    query = value;
    setTyping(true);
    setQuery(value);
    setResults(null);

    if (timeoutId) window.clearTimeout(timeoutId);

    setTimeoutId(
      window.setTimeout(function () {
        if (value != null && value.length > 1) {
          runSearch();
        }
        setTyping(false);
      }, 500),
    );
  };

  const runSearch = () => {
    setLoadingMessage('Searching for users...');
    setError(null);

    AdminApi.request('POST', '/api/FindUser', {
      text: query,
    })
      .then((response) => {
        setLoadingMessage(null);
        setResults(response.Users);
      })
      .catch((message) => {
        setLoadingMessage(null);
        setError(message);
      });
  };

  var hasResults = results != null && results.length > 0;

  return (
    <Modal onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>User Search</h1>
            Enter an email address and wait for the search to run.
          </SpacerTable>

          <div className="spacer" />

          <table className="blocks">
            <tbody>
              <Block>
                <BlockTextBox
                  name="email"
                  type="email"
                  autoFocus
                  value={query}
                  placeholder={'Email address'}
                  onChange={(e) => handleSearchChanged(e)}
                />
              </Block>
              {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
              {loadingMessage && <BlockInfo type={InfoType.Info}>{loadingMessage}</BlockInfo>}
              {!typing && query.length > 0 && (!results || results.length === 0) && !loadingMessage && !error && (
                <BlockInfo type={InfoType.Info}>No results found that match your query.</BlockInfo>
              )}
            </tbody>
          </table>

          {hasResults && (
            <>
              <div className="spacer-x2" />
              <SpacerTable>
                <h1>Results</h1>
              </SpacerTable>
              <div className="spacer" />
              <table className="blocks">
                <tbody>
                  {linq
                    .from(results)
                    .orderByDescending((user) => user.FullName)
                    .select((user) => {
                      return (
                        <Block className="route" onClick={() => props.onSubmit(user)} key={'result_' + user.Id}>
                          <BlockHeader>{user.FullName}</BlockHeader>
                          <div>{user.Email}</div>
                        </Block>
                      );
                    })
                    .toArray()}
                </tbody>
              </table>
            </>
          )}
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default UserSearchModal;

import React, { FunctionComponent, useEffect } from 'react';
import Modal from '../../components/Modal';
import SpacerTable from '../../components/SpacerTable';
import GAHelper from '../../helpers/GAHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import { IEvent } from '../../interfaces/IEvent';
import EventAttendeeOverview from './EventAttendeeOverview';
import { IOrganisation } from '../../interfaces/IOrganisation';

export interface IProps {
  organisation: IOrganisation;
  event: IEvent;
  email: string;
  onClose: () => void;
  overviewRefreshed: (overview: any) => void;
}

const EventAttendeeModal = ((props) => {
  const { event, organisation } = props;

  useEffect(() => {
    GAHelper.modal('Event Attendee Overview');
  }, []);

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>{event.Organisation.Name}</div>

            {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
              <div>
                {event.Venue.Name}
                {event.Venue.Postcode && event.Venue.Postcode.length > 0 ? ', ' + event.Venue.Postcode : ''}
              </div>
            )}
          </SpacerTable>

          <div className="spacer" />

          <EventAttendeeOverview
            organisation={organisation}
            eventId={props.event.Id}
            email={props.email}
            overviewRefreshedCallback={props.overviewRefreshed}
          />
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export default EventAttendeeModal;

import React, { useEffect, useState } from 'react';
import './SideMenu.scss';
import SVGOutlineMinus from '../svg/outline/SVGOutlineMinus';
import SVGOutlinePlus from '../svg/outline/SVGOutlinePlus';
import StringHelper from '../helpers/StringHelper';

export interface ISideMenuItem {
  label?: boolean;
  text: string;
  icon?: any;
  onClick?: () => void;
  items?: ISideMenuItem[];
  selected?: boolean;
  expandable?: boolean;
  to?: string;
}

export interface IProps {
  title?: string;
  navigator?: boolean;
  items?: ISideMenuItem[];
}

const SideMenu: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const [sideMenuOpened, setSideMenuOpened] = useState(false);
  const [expandedYears, setExpandedYears] = useState<string[]>([]);

  const checkItems = (items: ISideMenuItem[]) => {
    if (!items) return;
    items.forEach((item, index) => {
      const key = `${item.text}_${index}`;
      const hasSelectedItems = item.items && item.items.length > 0 && item.items.filter((i) => i.selected).length > 0;
      if (item.expandable && hasSelectedItems) {
        setExpandedYears([...expandedYears, key]);
      }

      item.items && item.items.length > 0 && checkItems(item.items);
    });
  };

  useEffect(() => {
    checkItems(props.items);
  }, [props.items]);

  const renderItems = (items: ISideMenuItem[]) => {
    return items.map((item, index) => {
      const key = `${item.text}_${index}`;
      const hasSelectedItems = item.items && item.items.length > 0 && item.items.filter((i) => i.selected).length > 0;
      const expanded = item.expandable && expandedYears.includes(key);

      return (
        <React.Fragment key={key}>
          {item.label ? (
            <label className={`side-menu-button ${item.icon ? `has-image` : ``} `}>{item.text}</label>
          ) : (
            <button
              name={StringHelper.toCamelCase(item.text) + 'Button'}
              className={`side-menu-button${item.expandable ? ' side-menu-button-expandable' : ''} ${hasSelectedItems ? ' side-menu-button-has-selected-items' : ''}${item.icon ? ` has-image` : ``}${item.selected ? ' selected' : ''}`}
              onClick={
                item.expandable
                  ? () =>
                      expandedYears.includes(key)
                        ? setExpandedYears(expandedYears.filter((e) => e != key))
                        : setExpandedYears([...expandedYears, key])
                  : item.onClick
              }
            >
              {item.expandable ? expanded ? <SVGOutlineMinus /> : <SVGOutlinePlus /> : item.icon}
              {item.text}
            </button>
          )}

          {(!item.expandable || expanded) && item.items && item.items.length > 0 && (
            <div className={`side-menu-sub-items${item.label ? ' side-menu-label-sub-items' : ''}`}>
              {renderItems(item.items)}
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div
      className={`side-menu${props.navigator ? ' side-menu-navigator' : ''}${sideMenuOpened ? ' side-menu-open' : ''}`}
      onClick={props.navigator && (() => setSideMenuOpened(!sideMenuOpened))}
    >
      {props.title && <div className="title">{props.title}</div>}
      {props.children}
      {props.items && props.items.length > 0 && renderItems(props.items)}
    </div>
  );
};

export default SideMenu;

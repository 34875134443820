import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AdminApi from '../../../api/AdminApi';
import eventMenuState from '../../../atoms/eventMenuState';
import Loader from '../../../components/Loader';
import CacheHelper from '../../../helpers/CacheHelper';
import EventHelper from '../../../helpers/EventHelper';
import LocationHelper from '../../../helpers/LocationHelper';
import ScriptHelper from '../../../helpers/ScriptHelper';
import SeatingPlanHelper from '../../../helpers/SeatingPlanHelper';
import StringHelper from '../../../helpers/StringHelper';
import { IEvent } from '../../../interfaces/IEvent';
import { IOrganisation } from '../../../interfaces/IOrganisation';
import { ISeatingPlanSeat } from '../../../interfaces/ISeatingPlanSeat';
import UserEventSearchModal from '../../../modals/UserEventSearchModal';
import SVGAdmin from '../../../svg/SVGAdmin';
import SVGCopy from '../../../svg/SVGCopy';
import SVGSave from '../../../svg/SVGSave';
import SVGWarning from '../../../svg/SVGWarning';
import '../Editor.scss';
import { IImageUploadRequest } from '../ImageUploader';
import ThemeSection from '../ThemeSection';
import DateAndTimeSection from './DateAndTimeSection';
import EventSection from './EventSection';
import DiscountsSection from './DiscountsSection';
import GeneralTicketsSection from './GeneralTicketsSection';
import ImageSection from './ImageSection';
import MapSection from './MapSection';
import MarketingSection from './MarketingSection';
import MembershipQuotasSection from './MembershipQuotasSection';
import QuestionsSection from './QuestionSection';
import SeatedTicketsSection from './SeatedTicketsSection';
import SeatingPlanSection from './SeatingPlanSection';
import TicketSetupSection from './TicketSetupSection';
import VenueSection from './VenueSection';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';
import SeatingPlanSections from './SeatingPlanSections';
import NumberHelper from '../../../helpers/NumberHelper';
import { ITicketCategory } from '../../../interfaces/ITicketCategory';
import HistorySection from '../History/HistorySection';
import Constants from '../../../helpers/Constants';
import PersonnelGroupsSection from './PersonnelGroupsSection';
import SVGOutlineDates from '../../../svg/outline/SVGOutlineDates';
import SVGOutlineVenue from '../../../svg/outline/SVGOutlineVenue';
import SVGOutlineImage from '../../../svg/outline/SVGOutlineImage';
import SVGOutlineTheme from '../../../svg/outline/SVGOutlineTheme';
import SVGOutlineMap from '../../../svg/outline/SVGOutlineMap';
import SVGOutlineTicket from '../../../svg/outline/SVGOutlineTicket';
import SVGOutlineTicketSetup from '../../../svg/outline/SVGOutlineTicketSetup';
import SVGOutlineSeatingPlan from '../../../svg/outline/SVGOutlineSeatingPlan';
import SVGOutlineDiscount from '../../../svg/outline/SVGOutlineDiscount';
import SVGOutlinePersonnel from '../../../svg/outline/SVGOutlinePersonnel';
import SVGOutlineQuestion from '../../../svg/outline/SVGOutlineQuestion';
import SVGOutlineMarketing from '../../../svg/outline/SVGOutlineMarketing';
import SVGOutlineChart from '../../../svg/outline/SVGOutlineChart';
import SVGOutlineHistory from '../../../svg/outline/SVGOutlineHistory';
import SVGOutlineDetails from '../../../svg/outline/SVGOutlineDetails';
import SideMenu from '../../../components/SideMenu';
import SVGMerchandise from '../../../svg/SVGMerchandise';
import MerchandiseSection from './MerchandiseSection';

export interface IEventWarning {
  title: string;
  text: string;
}

interface IProps {}

export enum Section {
  Details = 'Details',
  Dates = 'Dates',
  Venue = 'Venue',
  Image = 'Image',
  Theme = 'Theme',
  Map = 'Map',
  TicketSetup = 'TicketSetup',
  SeatingPlan = 'SeatingPlan',
  SeatedTickets = 'SeatedTickets',
  GeneralTickets = 'GeneralTickets',
  Merchandise = 'Merchandise',
  Discounts = 'Discounts',
  Marketing = 'Marketing',
  Questions = 'Questions',
  Organisation = 'Organisation',
  SeatingPlanSections = 'SeatingPlanSections',
  History = 'History',
  PersonnelGroups = 'Personnel',
}

export enum ViewMode {
  Attendee = 'attendee',
  Design = 'Design',
  Label = 'Label',
  Category = 'Category',
  Offset = 'Offset',
  Background = 'Background',
  Preview = 'Preview',
}

interface IEventSection {
  heading: string;
  subHeading?: (event: IEvent) => React.ReactNode;
  icon?: string;
  svg?: any;
  section: Section;
  visible?: (event: IEvent) => boolean;
}

const EventEditorPage: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [, setEventMenu] = useRecoilState(eventMenuState);
  const [section, setSection] = useState<Section>(Section.Details);
  const [busyMessage, setBusyMessage] = useState<string>('Loading event...');
  const [imageRequests, setImageRequests] = useState<{ [key: string]: IImageUploadRequest }>({});

  const [seatingPlan, setSeatingPlan] = useState<ISeatingPlan>(null);
  const [organisation, setOrganisation] = useState<IOrganisation>(null);
  const [event, setEventState] = useState<IEvent>(null);
  const [eventHistory, setEventHistory] = useState<IEvent[]>([]);
  const [actualEventTag, setActualEventTag] = useState<string>(null);

  const hasOrders = event && event.Dates && event.Dates.filter((d) => d.HasOrders).length > 0;

  const eventSections: IEventSection[] = [
    {
      heading: 'Event',
      subHeading: (e: IEvent) => {
        return (
          <>{e.Name.length > 0 ? <span className="sub">{e.Name}</span> : <span className="sub">Information</span>}</>
        );
      },
      icon: 'Event',
      svg: <SVGOutlineDetails />,
      section: Section.Details,
    },
    {
      heading: 'Dates',
      subHeading: (e: IEvent) => {
        return (
          <span className="sub">{e.Dates == null || e.Dates.length == 0 ? 'No dates' : e.Dates.length + ' dates'}</span>
        );
      },
      icon: 'EventDates',
      section: Section.Dates,
      svg: <SVGOutlineDates />,
    },
    {
      heading: 'Venue',
      subHeading: (e: IEvent) => {
        return <span className="sub">{e.Venue.Name}</span>;
      },
      icon: 'Venue',
      section: Section.Venue,
      svg: <SVGOutlineVenue />,
    },
    {
      heading: 'Image',
      icon: 'Image',
      section: Section.Image,
      svg: <SVGOutlineImage />,
    },
    {
      heading: 'Theme',
      icon: 'Theme.svg',
      section: Section.Theme,
      svg: <SVGOutlineTheme />,
    },
    {
      heading: 'Map',
      icon: 'Map',
      section: Section.Map,
      svg: <SVGOutlineMap />,
    },
    {
      heading: 'Ticket setup',
      icon: 'TicketSetup.svg',
      section: Section.TicketSetup,
      svg: <SVGOutlineTicketSetup />,
    },
    {
      heading: 'Seating plan',
      icon: 'SeatingPlan.svg',
      section: Section.SeatingPlan,
      visible: (e: IEvent) => e.UseSeatingPlan,
      svg: <SVGOutlineSeatingPlan />,
    },
    {
      heading: 'Seated tickets',
      icon: 'SeatedTicket.svg',
      section: Section.SeatedTickets,
      subHeading: (e: IEvent) => {
        return (
          <>
            {e.AllocatedCategoryGroups && e.AllocatedCategoryGroups.length > 0 ? (
              <span className="sub">{e.AllocatedCategoryGroups.length} seated tickets</span>
            ) : (
              <span className="sub">No tickets</span>
            )}
          </>
        );
      },
      visible: (e: IEvent) => e.UseSeatingPlan,
      svg: <SVGOutlineTicket />,
    },
    {
      heading: 'General tickets',
      icon: '',
      section: Section.GeneralTickets,
      visible: (e: IEvent) => e.UseUnallocatedTickets,
      svg: <SVGOutlineTicket />,
    },
    {
      heading: 'Merch & Extras',
      section: Section.Merchandise,
      svg: <SVGMerchandise />,
    },
    {
      heading: 'Discounts',
      subHeading: (e: IEvent) => {
        return (
          <span className="sub">
            {e.Discounts == null || e.Discounts.length == 0 ? 'No discounts' : e.Discounts.length + ' discounts'}
          </span>
        );
      },
      icon: 'Discounts.svg',
      section: Section.Discounts,
      svg: <SVGOutlineDiscount />,
    },
    {
      heading: 'Personnel',
      subHeading: (e: IEvent) => {
        return (
          <span className="sub">
            {e.PersonnelGroups == null || e.PersonnelGroups.length == 0
              ? 'No personnel groups'
              : e.PersonnelGroups.length + ' personnel groups'}
          </span>
        );
      },
      icon: 'Personnel.svg',
      section: Section.PersonnelGroups,
      svg: <SVGOutlinePersonnel />,
    },
    {
      heading: 'Questions',
      subHeading: (e: IEvent) => {
        return (
          <span className="sub">
            {e.Questions == null || e.Questions.length == 0 ? 'No questions' : e.Questions.length + ' questions'}
          </span>
        );
      },
      icon: 'Questions.svg',
      section: Section.Questions,
      svg: <SVGOutlineQuestion />,
    },
    {
      heading: 'Marketing ',
      icon: 'Marketing',
      section: Section.Marketing,
      svg: <SVGOutlineMarketing />,
    },
    {
      heading: 'Membership quotas',
      icon: 'Organisation.svg',
      section: Section.Organisation,
      visible: (e) => e.Organisation.MembershipsEnabled,
      svg: <SVGOutlineChart />,
    },
    {
      heading: 'History',
      icon: 'History.svg',
      section: Section.History,
      visible: (e) => false,
      svg: <SVGOutlineHistory />,
    },
  ];

  const updateEventHistory = () => {
    if (!event) return;
    const currentHistory = eventHistory.length > 5 ? eventHistory.filter((e, i) => i != 0) : eventHistory;
    setEventHistory([...currentHistory, JSON.parse(JSON.stringify(event))]);
  };

  const setEvent = (_event: IEvent, updateHistory: boolean = true) => {
    if (updateHistory) updateEventHistory();
    setEventState(_event);
  };

  const loadEvent = (_event: IEvent) => {
    if (_event.UseSeatingPlan) {
      let anyWithoutIndex = false;
      _event.SeatingPlans.forEach((sp, index) => {
        SeatingPlanHelper.loadSeatingPlanForEdit(sp);

        if (!NumberHelper.isNumeric(sp.Index)) {
          anyWithoutIndex = true;
        }

        const allocatedCategoryGroupsForSeatingPlan = _event.AllocatedCategoryGroups.filter((acg) =>
          linq
            .from(acg.Categories)
            .any((c) => c.SeatCategory && linq.from(sp.SeatCategories).any((sc) => sc.Id == c.SeatCategory.Id)),
        );

        if (linq.from(allocatedCategoryGroupsForSeatingPlan).any((a) => a.HasOrders)) {
          sp.HasOrders = true;
        } else {
          sp.HasOrders = false;
        }
      });
      if (anyWithoutIndex) {
        _event.SeatingPlans.forEach((sp, index) => {
          sp.Index = index;
        });
      }
    }

    _event = SeatingPlanHelper.repairAllocatedCategoryGroups(_event);

    const firstPlan =
      _event.UseSeatingPlan && _event.SeatingPlans && _event.SeatingPlans.length > 0 ? _event.SeatingPlans[0] : null;

    setEvent(_event, false);
    setEventHistory([]);
    setBusyMessage(null);
    setActualEventTag(_event.EventTag);
    const queryParams = new URLSearchParams(window.location.search);
    const seatingPlanParam: string = queryParams.get('seating_plan');
    if (NumberHelper.isNumeric(seatingPlanParam)) {
      const planThatMatchesParam = _event.SeatingPlans.find((sp) => sp.Id == parseInt(seatingPlanParam));

      if (planThatMatchesParam) {
        setSeatingPlan(planThatMatchesParam);
      } else {
        setSeatingPlan(firstPlan);
      }
    } else {
      setSeatingPlan(firstPlan);
    }

    return _event;
  };

  const fetchEvent = (eventTag, abortController?: AbortController): Promise<any> => {
    setEventHistory([]);
    return AdminApi.request('GET', `/api/EventEdit?eventTag=${eventTag}`, null, abortController)
      .then((_event: IEvent) => {
        return loadEvent(_event);
      })
      .catch((message) => {});
  };

  const urlDetails = LocationHelper.getLocation().pathname;

  useEffect(() => {
    const organisationTag = urlDetails.split('/')[2];
    const eventTag = urlDetails.split('/')[4];

    const queryParams = new URLSearchParams(window.location.search);
    const copyEventParam: string = queryParams.get('copy');
    const sectionParam: string = queryParams.get('section');

    if (sectionParam && Object.values(Section).includes(sectionParam as any)) {
      setSection(sectionParam as any);
    }

    ScriptHelper.loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDxQ6F0vbZjIvnS3PxfgFASeVtVZ52k1Pw',
      null,
      null,
      true,
      true,
    );

    const ac = new AbortController();

    CacheHelper.organisationByTag(organisationTag).then((o) => {
      setOrganisation(o);

      if (urlDetails.split('/')[3] == 'Event' && urlDetails.split('/')[4] == 'Create') {
        const newEvent = EventHelper.getDefault();
        newEvent.Organisation = o;
        newEvent.OrganisationId = o.Id;

        setEventState(newEvent);
        setBusyMessage(null);

        if (copyEventParam && copyEventParam.length > 0) {
          copyEvent(copyEventParam, o);
        }

        return;
      }

      return fetchEvent(eventTag, ac);
    });

    return () => {
      ac.abort();
    };
  }, []);

  const copyEvent = (eventTag, o: IOrganisation) => {
    setBusyMessage('Copying event...');

    return fetchEvent(eventTag)
      .then((e) => {
        const newEvent = EventHelper.copyEvent(o, e);
        newEvent.Id = event && event.Id > 0 ? event.Id : 0;

        setEvent(newEvent, false);
        setEventHistory([]);
        setCopyEventModalOpen(false);
        setBusyMessage(null);
      })
      .catch(() => {
        alert('Something went wrong when copying event.');
        setEvent(null);
      });
  };

  const getEventWarnings = (): IEventWarning[] => {
    if (!event) return [];

    const warnings: IEventWarning[] = [];

    if (
      event.Description &&
      EventHelper.getRichTextCharLength(event.Description) > Constants.MaxEventDescriptionLength
    ) {
      warnings.push({
        title: 'Description too long',
        text: `You must enter an event description of ${Constants.MaxEventDescriptionLength} characters or less.`,
      });
    }

    if (!event.Name || event.Name.length <= 2) {
      warnings.push({ title: 'Invalid name', text: 'You must enter an event name longer than 2 letters.' });
    }

    if (!event.EventTag || event.EventTag.length <= 2) {
      warnings.push({ title: 'Event tag length', text: 'You must enter an event tag longer than 2 letters.' });
    }

    if (StringHelper.hasSpecialCharacters(event.EventTag)) {
      warnings.push({
        title: 'Invalid event tag',
        text: 'Event tags cannot contain special letters, make sure its just plain text.',
      });
    }

    if (event.Dates.length == 0) {
      warnings.push({ title: 'No event dates', text: 'You must add at least one event date.' });
    }

    if (event.Dates && event.Dates.length > 0) {
      linq
        .from(event.Dates)
        .groupBy((ed) => ed.DateAsString)
        .where((g) => g.count() > 1)
        .toArray()
        .forEach((g) => {
          warnings.push({
            title: `Duplicate event dates`,
            text: `There are two event dates with the same time of ${moment(g.first().DateAsString).format('DD/MM/YYYY HH:mm')}. `,
          });
        });

      event.Dates.forEach((ed) => {
        if (ed.OrphanedSeats && ed.OrphanedSeats.length > 0) {
          ed.OrphanedSeats.forEach((o) => {
            const seatingPlan = event.SeatingPlans.find((sp) =>
              sp.SeatCategories.find((sc) => sc.Id == o.Seats[0].SeatCategoryId),
            );

            var existingSeat =
              seatingPlan &&
              linq
                .from(seatingPlan.Seats)
                .where((s) => {
                  return s.SeatCategory != null;
                })
                .firstOrDefault(
                  (s) =>
                    s.Group.toUpperCase() == o.Seats[0].Group.toUpperCase() &&
                    s.Name.toUpperCase() == o.Seats[0].Name.toUpperCase() &&
                    s.SeatCategory.Id == o.Seats[0].SeatCategoryId,
                );

            if (!existingSeat && o.Seats[0].SeatCategoryId !== 0) {
              warnings.push({
                title: `Order ${o.Id} has orphaned ticket`,
                text: `${o.Seats[0].SeatCategoryName} - ${o.Seats[0].Group}${o.Seats[0].Name} ticket for date ${moment(
                  (o.EventDate as any).DateAsString,
                ).format(
                  'ddd Do MMM YYYY',
                )} has no matching seat in the seating plan for this event. Please cancel this order or restore the seat to the Seating Plan to continue. `,
              });
            }
          });
        }
      });
    }

    return warnings;
  };

  const getSeatingPlanWarnings = (): IEventWarning[] => {
    if (!event || !event.UseSeatingPlan) return [];

    const warnings: IEventWarning[] = [];

    event.SeatingPlans.forEach((seatingPlan: ISeatingPlan) => {
      let seats = seatingPlan.Seats;

      let seatsWithoutNamesCount = 0;
      let seatsWithSameNames = [];
      let seatsWithSameNamesCount = 0;
      let seatsWithPartialNamesCount = 0;

      // if (seats.length == 0) {
      //   warnings.push({ title: 'No seats selected', text: 'You must select some seats before you can save. On the design tab, either click on or drag over some seats to select them. ' });
      // }

      seats.forEach((seat: ISeatingPlanSeat) => {
        if (seat.Group == '-' && seat.Name == '-') {
          seatsWithoutNamesCount++;
        } else if (seat.Group == '-' || seat.Name == '-') {
          seatsWithPartialNamesCount++;
        } else {
          let seatsWithMyName = 0;

          seats.forEach((s) => {
            if (
              s.SeatCategory &&
              s.SeatCategory.Guid == seat.SeatCategory.Guid &&
              s.Group == seat.Group &&
              s.Name == seat.Name
            ) {
              seatsWithMyName++;
            }
          });

          const warningsForThisSeatCount = linq
            .from(seatsWithSameNames)
            .where(function (s) {
              return s.seat.Name == seat.Name && s.seat.Group == seat.Group;
            })
            .toArray().length;

          if (seatsWithMyName > 1 && warningsForThisSeatCount == 0) {
            seatsWithSameNames.push({ seat: seat, howMany: seatsWithMyName });
            seatsWithSameNamesCount++;
          }
        }
      });

      if (seatsWithoutNamesCount > 0 || seatsWithPartialNamesCount > 0) {
        let warning = '';

        if (seatsWithoutNamesCount > 0) {
          warning += 'You have ' + seatsWithoutNamesCount + ' seats without a name. \n';
        }
        if (seatsWithPartialNamesCount > 0) {
          warning += 'You have ' + seatsWithPartialNamesCount + ' seats with only half a name. \n';
        }

        if (seatsWithoutNamesCount > 0 && seatsWithPartialNamesCount > 0) {
          warning =
            'You have ' +
            seatsWithoutNamesCount +
            ' seats without a name and ' +
            seatsWithPartialNamesCount +
            ' seats with only half a name. \n';
        }

        warnings.push({ title: 'Invalid seating plan', text: warning });
      }

      if (seatsWithSameNamesCount > 0) {
        let warning = '';

        seatsWithSameNames.forEach((seatWarning) => {
          warning +=
            seatWarning.howMany +
            ' seats exist with the name ' +
            seatWarning.seat.Group +
            seatWarning.seat.Name +
            ' in the same seat category. \n';
        });

        warning +=
          ' You should make sure each seat has an individual group and seat name combination within the same seat category. To rename a seat, click on the label tab, select the seats with duplicate names and enter a unique group and seat name.';

        warnings.push({ title: 'Duplicate seats', text: warning });
      }
    });

    return warnings;
  };

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [copyEventModalOpen, setCopyEventModalOpen] = useState(false);

  const warnings: IEventWarning[] = getEventWarnings().concat(getSeatingPlanWarnings());

  if (!(warnings.length > 0 || eventHistory.length === 0)) {
    window.onbeforeunload = function () {
      return 'You have made changes to this event, are you sure you want to leave?';
    };
  } else {
    window.onbeforeunload = null;
  }

  const globalOptions = (
    <>
      <div className={`option dropdown ${warnings.length > 0 ? ' warning' : ''} list${optionsOpen ? ' open' : ''}`}>
        {warnings.length === 0 ? (
          <label>Save</label>
        ) : (
          <label>
            {warnings.length} {StringHelper.AddSWhenMany(warnings.length, 'Task')}
          </label>
        )}

        {optionsOpen && <div className="click-off" onClick={() => setOptionsOpen(false)}></div>}
        <span className="icon">{warnings.length === 0 ? <SVGSave /> : <SVGWarning />}</span>

        <button
          name="saveButton"
          className="action"
          disabled={warnings.length > 0 || eventHistory.length === 0}
          onClick={() => (warnings.length === 0 ? save(event) : setOptionsOpen(true))}
        ></button>
        <button className="notch" onClick={() => setOptionsOpen(!optionsOpen)}></button>

        {copyEventModalOpen && (
          <UserEventSearchModal
            onClose={() => setCopyEventModalOpen(false)}
            onSubmit={(e) => copyEvent(e.EventTag, organisation)}
            title="Copy an event"
            description="Select an event to copy. CAUTION: When you copy an event all details from your current event will be replaced with that one."
            setLoadingMessage={setBusyMessage}
          />
        )}

        <div className="dropdown-options">
          {!hasOrders && (
            <button
              onClick={() => {
                setCopyEventModalOpen(true);
                setOptionsOpen(false);
              }}
            >
              <span className="dropdown-option-icon">
                <SVGCopy />
              </span>
              <span className="description">Copy existing event</span>
              <p>You can load in a copy of an existing event to make it easier when creating similar events.</p>
            </button>
          )}

          {event && event.Id && event.Id > 0 && actualEventTag && (
            <button
              onClick={() => {
                const urlDetails = LocationHelper.getLocation().pathname;
                const eventTag = urlDetails.split('/')[4];

                setEventMenu({ eventTag: eventTag });
                setOptionsOpen(false);
              }}
            >
              <span className="dropdown-option-icon">
                <SVGAdmin />
              </span>
              <span className="description">Options</span>
              <p>Open the administrator options dialog.</p>
            </button>
          )}

          {warnings.map((warning, index) => (
            <div className="info-row warning" key={index}>
              <span className="dropdown-option-icon">
                <SVGWarning />
              </span>
              <span className="description">{warning.title}</span>
              {warning.text && <p>{warning.text}</p>}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const onImageRequested = (request: IImageUploadRequest) => {
    imageRequests[request.group] = request;
    setImageRequests({ ...imageRequests });
    updateEventHistory();
  };

  const sections: { [key: string]: any } = {};
  sections[Section.Details] = (
    <EventSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Image] = (
    <ImageSection
      globalOptions={globalOptions}
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      event={event}
    />
  );
  sections[Section.Marketing] = (
    <MarketingSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Theme] = (
    <ThemeSection
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      setBusyMessage={setBusyMessage}
      globalOptions={globalOptions}
      event={event}
      onEventUpdated={(e: IEvent) => setEvent(e)}
    />
  );
  sections[Section.Organisation] = (
    <MembershipQuotasSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Dates] = (
    <DateAndTimeSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Venue] = (
    <VenueSection
      hasOrders={hasOrders}
      globalOptions={globalOptions}
      event={event}
      onEventUpdated={(e: IEvent) => setEvent(e)}
    />
  );
  sections[Section.Map] = (
    <MapSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.TicketSetup] = (
    <TicketSetupSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );

  const seatingPlanSectionSeatingPlan =
    event && event.UseSeatingPlan
      ? event.SeatingPlanSectionsEnabled
        ? seatingPlan
        : event.SeatingPlans && event.SeatingPlans.length > 0
          ? event.SeatingPlans[0]
          : null
      : null;
  sections[Section.SeatingPlan] = seatingPlanSectionSeatingPlan && (
    <SeatingPlanSection
      seatingPlan={seatingPlanSectionSeatingPlan}
      onImageRequested={onImageRequested}
      hasOrders={hasOrders}
      setBusyMessage={setBusyMessage}
      globalOptions={globalOptions}
      event={event}
      onEventUpdated={(e: IEvent) => setEvent(e)}
    />
  );

  sections[Section.SeatedTickets] = (
    <SeatedTicketsSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.SeatingPlanSections] = (
    <SeatingPlanSections globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.GeneralTickets] = (
    <GeneralTicketsSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Merchandise] = (
    <MerchandiseSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Discounts] = (
    <DiscountsSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.Questions] = (
    <QuestionsSection globalOptions={globalOptions} event={event} onEventUpdated={(e: IEvent) => setEvent(e)} />
  );
  sections[Section.PersonnelGroups] = (
    <PersonnelGroupsSection
      onImageRequested={onImageRequested}
      imageRequests={imageRequests}
      globalOptions={globalOptions}
      event={event}
      onEventUpdated={(e: IEvent) => setEvent(e)}
    />
  );
  sections[Section.History] = <HistorySection organisation={null} tour={null} event={event} />;

  function isBase64(input: string): boolean {
    const base64Regex = /^(?:[A-Za-z\d+/]*={0,2}(?:[A-Za-z\d+/]+={0,2})*)$/;
    return base64Regex.test(input);
  }

  const cleanupSeatCategories = (e: IEvent) => {
    if (!e.UseSeatingPlan) {
      e.AllocatedCategoryGroups = [];
      return;
    }

    const allSeatCategories = linq.from(e.SeatingPlans).selectMany((p) => p.SeatCategories);

    e.AllocatedCategoryGroups.forEach((cg) => {
      // Remove all categories that have a seat category that no longer exists.
      cg.Categories = linq
        .from(cg.Categories)
        .where((c: ITicketCategory) => allSeatCategories.any((sc) => sc.Id == c.SeatCategory.Id))
        .toArray();
    });
  };

  const save = (e: IEvent) => {
    if (e.UseSeatingPlan && e.SeatingPlans) {
      cleanupSeatCategories(e);
      e.SeatingPlans.forEach((seatingPlan) => {
        if (seatingPlan && seatingPlan.BackgroundUrl && isBase64(seatingPlan.BackgroundUrl)) {
          seatingPlan.BackgroundUrl = null;
        }
      });
    }

    return EventHelper.save(e, setBusyMessage, imageRequests)
      .then((savedEvent: IEvent) => {
        savedEvent.Dates &&
          savedEvent.Dates.length > 0 &&
          savedEvent.Dates.forEach((ed) => {
            ed.ChangeMade = false;
          });

        setEventHistory([]);
        setImageRequests({});
        setBusyMessage('All done!');

        const queryParams = new URLSearchParams(window.location.search);
        const modeParam: string = queryParams.get('mode');
        const seatingPlanParam: string = queryParams.get('seating_plan');

        navigate(
          `/Organisation/${organisation.OrganisationTag}/Event/${savedEvent.EventTag}/Edit?section=${section as any}${modeParam && modeParam.length > 0 ? `&mode=${modeParam}` : ''}${
            seatingPlanParam && seatingPlanParam.length > 0 ? `&seating_plan=${seatingPlanParam}` : ''
          }`,
        );

        window.setTimeout(() => {
          setBusyMessage('Reloading event...');

          loadEvent(savedEvent);
        }, 100);

        return savedEvent;
      })
      .catch((message) => {
        alert(message);
        setBusyMessage(null);
        return Promise.reject(message);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {busyMessage ? 'Event Editor' : event && event.Id > 0 ? `Edit #${event.EventTag}` : `Create Event`}
        </title>
        <meta name="description" content={`Edit your event details.`} />
      </Helmet>

      {busyMessage ? (
        <Loader inline>{busyMessage}</Loader>
      ) : (
        event && (
          <div className="admin-dashboard-editor">
            <div className="row">
              <div className="col-sm-3 col-md-2 donotprint">
                <SideMenu navigator>
                  {eventSections.map((s: IEventSection) => {
                    if (event.SeatingPlanSectionsEnabled && s.section == Section.SeatingPlan) {
                      return (
                        <React.Fragment key={s.section}>
                          <label className={`expanded${s.svg ? ' has-image' : ''}`} onClick={() => {}}>
                            {s.svg}
                            Seating plan
                          </label>

                          <div style={{ paddingLeft: '15px' }}>
                            <button
                              className={`${section == Section.SeatingPlanSections ? 'selected' : ''}`}
                              onClick={() => {
                                setSection(Section.SeatingPlanSections);
                                LocationHelper.updateParam('section', Section.SeatingPlanSections);
                              }}
                            >
                              Sections
                            </button>
                            {linq
                              .from(event.SeatingPlans)
                              .orderBy((sp) => sp.Index)
                              .toArray()
                              .map((sp) => (
                                <button
                                  key={`${sp.Id}--${sp.Guid}`}
                                  className={`${section == Section.SeatingPlan && seatingPlan == sp ? 'selected' : ''}`}
                                  onClick={() => {
                                    setSection(Section.SeatingPlan);

                                    setSeatingPlan(null);
                                    window.setTimeout(() => {
                                      setSeatingPlan(sp);
                                      LocationHelper.updateParam('section', Section.SeatingPlan);
                                      if (sp.Id) {
                                        LocationHelper.updateParam('seating_plan', sp.Id);
                                      }
                                    }, 100);
                                  }}
                                >
                                  {sp.Name}
                                  {sp.ChangesMade ? ' *' : ''}
                                </button>
                              ))}
                          </div>
                        </React.Fragment>
                      );
                    }

                    return (
                      <button
                        name={StringHelper.toCamelCase(s.heading) + 'Button'}
                        key={s.section}
                        style={{ display: s.visible ? (!s.visible(event) ? 'none' : null) : null }}
                        className={`${section == s.section ? 'selected' : ''}${s.svg ? ' has-image' : ''}`}
                        onClick={() => {
                          setSection(s.section);
                          LocationHelper.updateParam('section', s.section);
                        }}
                      >
                        {s.svg}
                        {s.heading}
                      </button>
                    );
                  })}
                </SideMenu>
              </div>
              <div className="col-sm-9 col-md-10 donotprint">{sections[section]}</div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default EventEditorPage;

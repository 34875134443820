import linq from 'linq';
import moment, { duration, Moment } from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import SpacerTable from '../components/SpacerTable';
import { PaymentMethods } from '../enums/PaymentMethods';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import { ISeatCategory } from '../interfaces/ISeatCategory';
import { ISeatingPlanSeat } from '../interfaces/ISeatingPlanSeat';
import { ITicketCategory } from '../interfaces/ITicketCategory';
import { IUser } from '../interfaces/IUser';
import LockService from '../services/LockService';
import TicketService from '../services/TicketService';
import Order, { IOrderConfig } from '../views/Order/Order';
import OrderTimerHelper from '../helpers/OrderTimerHelper';
import { IOrganisation } from '../interfaces/IOrganisation';

export interface IProps {
  organisation: IOrganisation;
  event: IEvent;
  eventDate: IEventDate;
  user?: IUser;
  isAdmin: boolean;
  isMember: boolean;
  onClose: () => void;
  selectedSeats?: Array<ISeatingPlanSeat>;
  generalAdmissionTickets?: Array<ITicketCategory>;
  paymentMethod?: PaymentMethods;
  hasMerchandiseWhenOpened: boolean;
}

export enum PaymentGateway {
  None = 'None',
  Stripe = 'Stripe',
  Pay = 'Pay',
}

const OrderModal: FunctionComponent<IProps> = (props) => {
  const [allTicketsAvailable, setAllTicketsAvailable] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(true);
  let [dateLocked, setDateLocked] = useState<Moment>(null);
  const [error, setError] = useState<{ title: string; text: string }>(null);
  const [completedOrderId, setCompletedOrderId] = useState<number>(null);
  const [orderConfig, setOrderConfig] = useState<IOrderConfig>(null);
  const [ticketCategories, setTicketCategories] = useState<Array<ITicketCategory>>([]);
  const [seatCategories, setSeatCategories] = useState<Array<ISeatCategory>>([]);
  const { user, event, eventDate, selectedSeats, isAdmin, generalAdmissionTickets } = props;
  const [lockTimer, setLockTimer] = useState(null);
  const [gateway, setGateway] = useState<PaymentGateway>(PaymentGateway.Stripe);

  useEffect(() => {
    OrderTimerHelper.onTimeout = () => {
      setAllTicketsAvailable(false);
    };

    GAHelper.modal(`${event.EventTag} Order`);

    return () => {
      OrderTimerHelper.onTimeout = null;
    };
  }, []);

  const lockTickets = (
    tickets,
    abortController?: AbortController,
    clearSession: boolean = false,
    purchaseId: number = null,
  ): Promise<any> => {
    setError(null);

    return LockService.LockTickets(eventDate.Id, tickets, abortController, clearSession, purchaseId)
      .then((result) => {
        if (result.AllAvailable) {
          OrderTimerHelper.start(result.LockInterval);
        }

        return result;
      })
      .catch((err) => {
        if (err != 'Aborted by the client.') {
          return Promise.reject(err);
        }
      });
  };

  const lockTicketsOrder = (tickets): Promise<boolean> => {
    setBusy(true);
    return lockTickets(tickets, null, false)
      .catch((message) => {
        setError({ title: 'Sorry, tickets not available...', text: message });

        return false;
      })
      .finally(() => setBusy(false));
  };

  useEffect(() => {
    setBusy(true);

    const hasSeatingPlans = event.UseSeatingPlan && event.SeatingPlans && event.SeatingPlans.length > 0;
    if (event.UseSeatingPlan && hasSeatingPlans) {
      const seatCategories = linq
        .from(event.SeatingPlans)
        .selectMany((sp) => sp.SeatCategories)
        .where((sc) => eventDate.SeatCategoryIds.filter((id) => id === sc.Id).length > 0)
        .toArray();

      setSeatCategories(seatCategories);
    }

    const ticketCategories = linq
      .from(event.Categories.concat(generalAdmissionTickets))
      .where((tc) => eventDate.TicketCategoryIds.filter((id) => id === tc.Id).length > 0)
      .toArray();
    setTicketCategories(ticketCategories);

    const tickets = TicketService.getSelectedSeatedTickets(selectedSeats).concat(
      TicketService.getSelectedGeneralAdmissionTickets(isAdmin, generalAdmissionTickets),
    );

    setAllTicketsAvailable(false);
    setCompletedOrderId(null);
    setError(null);

    const ac = new AbortController();

    lockTickets(tickets, ac, true)
      .then((result) => {
        if (result.AllAvailable) {
          setAllTicketsAvailable(true);

          setOrderConfig({
            event: event,
            eventDate: eventDate,
            tickets: tickets,
            user: user,
          });
        } else {
          setError({
            title: 'Not available',
            text:
              result.ErrorMessage && result.ErrorMessage.length > 0
                ? result.ErrorMessage
                : 'These tickets are no longer available.',
          });
        }

        setBusy(null);
      })
      .catch((err) => {
        setError({ title: 'Sorry, tickets not available...', text: err });
        setBusy(false);
      });

    return () => {
      ac.abort();
      clearTimeout(lockTimer);
    };
  }, []);

  let onClose = () => {
    clearTimeout(lockTimer);

    if (event.Discounts && event.Discounts.length > 0) {
      event.Discounts.forEach((d) => {
        d.Activated = false;
        d.Codes = [];
      });
    }

    props.onClose();
  };

  const showOrder = orderConfig && (allTicketsAvailable || completedOrderId != null);

  return (
    <>
      <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={onClose}>
        <div className="content">
          <div className="ticket-rip" />
          {error ? (
            <div className="body">
              <SpacerTable>
                <h1>{error.title}</h1>
                {error.text}
              </SpacerTable>
            </div>
          ) : (
            <div className="body">
              {showOrder ? (
                <Order
                  gateway={gateway}
                  paymentMethod={props.paymentMethod}
                  lockTickets={lockTicketsOrder}
                  isMember={props.isMember}
                  isAdmin={props.isAdmin}
                  organisation={props.organisation}
                  stripeConnectAccountId={event.Organisation.StripeAccountId}
                  ticketCategories={ticketCategories}
                  seatCategories={seatCategories}
                  theme={ThemeHelper.getEventTheme(event)}
                  backClicked={onClose}
                  onOrderCompleted={(orderId) => {
                    setCompletedOrderId(orderId);
                    OrderTimerHelper.stop();
                  }}
                  orderConfig={orderConfig}
                />
              ) : (
                <SpacerTable>
                  <h1>We're sorry!</h1>
                  The tickets you are trying to order are not available. This could mean they have just been reserved by
                  another attendee or that your order has timed out.
                </SpacerTable>
              )}
            </div>
          )}
          <div className="ticket-rip bottom" />
        </div>
      </Modal>

      {busy && <Loader theme={ThemeHelper.getEventTheme(event)} />}
    </>
  );
};

export default OrderModal;

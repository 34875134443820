import React, { Fragment, FunctionComponent } from 'react';

import { IOption } from '../interfaces/IOption';
import Block from './Block';
import BlockHeader from './BlockHeader';
import BlockCheckBox from './BlockCheckBox';

export interface IProps {
  className?: string;
  selectedId: any;
  options: Array<IOption>;
  onValueChanged?(option: any): void;
}

export interface IRadioState {}

const Radio: FunctionComponent<IProps> = (props) => {
  const { options } = props;

  const handleRadioOnCheckedChange = (option: IOption) => {
    let valueChanged = option.Id != props.selectedId;

    if (props.onValueChanged && valueChanged) {
      props.onValueChanged(option);
    }
  };

  return (
    <table className={'radio' + (props.className ? ' ' + props.className : '')}>
      <tbody>
        {options.map((o: IOption, index: number) => (
          <Fragment key={o.Id + '_' + o.Text + '_' + index}>
            <BlockCheckBox
              fullClick
              checked={o.Id == props.selectedId}
              onBoxClick={() => handleRadioOnCheckedChange(o)}
            >
              {o.Header && o.Header.length > 0 && (
                <BlockHeader>
                  <td>{o.Header}</td>
                  <td className="right">{o.HeaderRight}</td>
                </BlockHeader>
              )}
              {o.Text}
              {o.Info && <div className="info">{o.Info}</div>}
            </BlockCheckBox>
            {o.Child && <Block>{o.Child}</Block>}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Radio;

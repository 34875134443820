import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../../components/Loader';
import './AdminMail.scss';
import ReactQuill from 'react-quill';
import AdminApi from '../../../../api/AdminApi';
import InfoInput from '../../../../components/InfoInput';
import SVGGoBackArrow from '../../../../svg/SVGGoBackArrow';
import { IAdminEmail } from '../../../../interfaces/IAdminMail';

export interface IProps {
  mailId: number;
  onBack: () => void;
}

const AdminMailViewer: FunctionComponent<IProps> = (props) => {
  const [busyMessage, setBusyMessage] = useState('');
  const [mail, setMail] = useState<IAdminEmail>(null);

  const getMail = async () => {
    setBusyMessage('Loading mailshot...');
    AdminApi.request('GET', `/api/Mailshot?mailId=${props.mailId}`)
      .then((result) => {
        setBusyMessage(null);
        setMail(result);
      })
      .catch((message) => {
        setBusyMessage(null);
      });
  };

  useEffect(() => {
    getMail();
  }, []);

  return (
    <>
      <div className="admin-buttons" style={{ marginTop: 0, padding: 0 }}>
        <button
          className="admin-button icon-inline"
          onClick={() => {
            props.onBack();
          }}
        >
          <SVGGoBackArrow />
          Back
        </button>
      </div>

      <div className="admin-mail">
        {busyMessage ? (
          <Loader inline>{busyMessage}</Loader>
        ) : (
          mail && (
            <>
              <div className="mail-page">
                <InfoInput labelText="Subject" onChange={(value) => {}} value={mail.Subject} />

                <ReactQuill
                  className="quill-as-input"
                  readOnly={true}
                  modules={{ toolbar: false }}
                  theme="snow"
                  value={mail.Body}
                />
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default AdminMailViewer;

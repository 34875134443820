import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Loader from '../../components/Loader';
import UserHelper from '../../helpers/UserHelper';
import GeneralApi from '../../api/GeneralApi';

interface IProps {}

const UserAccountPreferencesPage: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Helmet>
        <title>Seaty - Account</title>
        <meta name="description" content={`Seaty.co.uk.`} />
      </Helmet>

      <article>
        {loading ? (
          <Loader inline>Loading...</Loader>
        ) : (
          <div>
            <div className="toolbar">
              <div className="title">Account</div>
              <div className="buttons"></div>
            </div>

            <label>Email:</label>
            <br />
            {UserHelper.currentUser.Email}
            <br />
            <br />
            <label>Name:</label>
            <br />
            {UserHelper.currentUser.Name}
            <br />
            <br />
            <label>Password:</label>
            <br />
            <button
              className="admin-button"
              onClick={() => {
                setLoading(true);
                GeneralApi.request('GET', `/api/RecoverPassword?email=${UserHelper.currentUser.Email}`).then(
                  (value) => {
                    setLoading(false);
                  },
                );
              }}
            >
              Send change password email
            </button>
            <br />
            <br />
          </div>
        )}
      </article>
    </>
  );
};

export default UserAccountPreferencesPage;

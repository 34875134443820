import linq from 'linq';
import React, { useEffect, useRef, useState } from 'react';
import AdminApi from '../../../api/AdminApi';
import Block from '../../../components/Block';
import BlockTextBox from '../../../components/BlockTextBox';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import SeatingPlan, {
  IPlaceholder as IPlaceHolder,
  ISeatClickArgs,
} from '../../../components/seating_plan_svg/SeatingPlan';
import SpacerTable from '../../../components/SpacerTable';
import ColourHelper from '../../../helpers/ColourHelper';
import GuidHelper from '../../../helpers/GuidHelper';
import SeatingPlanHelper from '../../../helpers/SeatingPlanHelper';
import SVGHelper from '../../../helpers/SVGHelper';
import { ICategoryGroup, IEvent } from '../../../interfaces/IEvent';
import { ISeatCategory } from '../../../interfaces/ISeatCategory';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';
import { ISeatingPlanObject } from '../../../interfaces/ISeatingPlanObject';
import { ISeatingPlanSeat } from '../../../interfaces/ISeatingPlanSeat';
import { SeatCategoryModal } from '../../../modals/SeatCategoryModal';
import SVGMinus from '../../../svg/SVGMinus';
import SVGPlus from '../../../svg/SVGPlus';
import Toolbar from '../toolbar/Toolbar';
import { ViewMode } from './EventEditorPage';
import SeatingPlanSearchModal from './SeatingPlanSearchModal';
import ImageUploader, { IImageUploadRequest, ImageRequestGroup, ImageUploaderType } from '../ImageUploader';
import SVGMultiply from '../../../svg/SVGMultiply';
import SVGImage from '../../../svg/SVGImage';
import LocationHelper from '../../../helpers/LocationHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import SVGDown from '../../../svg/SVGDown';
import SVGEditEvent from '../../../svg/SVGEditEvent';

enum DesignMode {
  Seats = 'seats',
  Label = 'label',
  Selected = 'selected',
  Stage = 'stage',
  Standing = 'standing',
  TableArea = 'tablearea',
  Walkway = 'walkway',
  StairsY = 'stairs',
  StairsX = 'stairshorizontal',
  Door = 'door',
  Down = 'down',
  Up = 'up',
  Left = 'left',
  Right = 'right',
  Toilet = 'toilet',
  FemaleToilet = 'femaleToilet',
  MaleToilet = 'maleToilet',
  Table = 'table',
  Elevator = 'elevator',
  Speaker = 'speaker',
  Pillar = 'pillar',
  Disabled = 'disabled',
  Conductor = 'conductor',
  Drum = 'drum',
  Guitar = 'guitar',
  Piano = 'piano',
  DJ = 'dj',
}

interface IDesignMode {
  Name: string;
  designMode: DesignMode;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  icon?: string;
}

const designModes: IDesignMode[] = [
  {
    Name: 'Seat',
    designMode: DesignMode.Seats,
    borderRadius: '100%',
    backgroundColor: 'rgb(8, 32, 43)',
  },
  {
    Name: 'Stage',
    designMode: DesignMode.Stage,
    borderRadius: '6px',
    backgroundColor: '#DCDCEF',
  },
  {
    Name: 'Label',
    designMode: DesignMode.Label,
    borderRadius: '6px',
    backgroundColor: 'rgb(229, 230, 243)',
    border: '0',
  },
  {
    Name: 'Standing',
    designMode: DesignMode.Standing,
    borderRadius: '6px',
    backgroundColor: '#E6E6E6',
  },
  {
    Name: 'Table',
    designMode: DesignMode.TableArea,
    borderRadius: '100%',
    backgroundColor: 'rgb(247, 215, 121)',
  },
  {
    Name: 'Walkway',
    designMode: DesignMode.Walkway,
    borderRadius: '6px',
    backgroundColor: '#EFEFEF',
  },
  {
    Name: 'Stairs Y',
    designMode: DesignMode.StairsY,
    icon: SVGHelper.get('Stairs'),
  },
  {
    Name: 'Stairs X',
    designMode: DesignMode.StairsX,
    icon: SVGHelper.get('StairsHorizontal'),
  },
  {
    Name: 'Door',
    designMode: DesignMode.Door,
    icon: SVGHelper.get('Door'),
  },
  {
    Name: 'Up',
    designMode: DesignMode.Up,
    icon: SVGHelper.get('Up'),
  },
  {
    Name: 'Down',
    designMode: DesignMode.Down,
    icon: SVGHelper.get('Down'),
  },
  {
    Name: 'Left',
    designMode: DesignMode.Left,
    icon: SVGHelper.get('Left'),
  },
  {
    Name: 'Right',
    designMode: DesignMode.Right,
    icon: SVGHelper.get('Right'),
  },
  {
    Name: 'Toilets',
    designMode: DesignMode.Toilet,
    icon: SVGHelper.get('Toilet'),
  },
  {
    Name: 'Toilets',
    designMode: DesignMode.MaleToilet,
    icon: SVGHelper.get('MaleToilet'),
  },
  {
    Name: 'Toilets',
    designMode: DesignMode.FemaleToilet,
    icon: SVGHelper.get('FemaleToilet'),
  },
  {
    Name: 'Elevator',
    designMode: DesignMode.Elevator,
    icon: SVGHelper.get('Elevator'),
  },
  {
    Name: 'Speaker',
    designMode: DesignMode.Speaker,
    icon: SVGHelper.get('Speaker'),
  },
  {
    Name: 'Pillar',
    designMode: DesignMode.Pillar,
    icon: SVGHelper.get('Pillar'),
  },
  {
    Name: 'Disabled',
    designMode: DesignMode.Disabled,
    icon: SVGHelper.get('Disabled'),
  },
  {
    Name: 'DJ',
    designMode: DesignMode.DJ,
    icon: SVGHelper.get('Dj'),
  },
  {
    Name: 'Conductor',
    designMode: DesignMode.Conductor,
    icon: SVGHelper.get('Conductor'),
  },
  {
    Name: 'Drum',
    designMode: DesignMode.Drum,
    icon: SVGHelper.get('Drum'),
  },
  {
    Name: 'Guitar',
    designMode: DesignMode.Guitar,
    icon: SVGHelper.get('Guitar'),
  },
  {
    Name: 'Piano',
    designMode: DesignMode.Piano,
    icon: SVGHelper.get('Piano'),
  },
];

export interface IProps {
  seatingPlan: ISeatingPlan;
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
  setBusyMessage: (value: string) => void;
  hasOrders: boolean;
  onImageRequested: (request: IImageUploadRequest) => void;
}

const SeatingPlanSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated, setBusyMessage } = props;

  const [fullScreen, setFullScreen] = useState(false);
  const [showSeatingPlan, setShowSeatingPlan] = useState(true);
  const seatingPlan = props.seatingPlan;
  const [history, setHistory] = useState<ISeatingPlan[]>(
    event.SeatingPlans && event.SeatingPlans.length > 0 ? [event.SeatingPlans[0]] : [],
  );

  const [editMode, setEditMode] = useState(ViewMode.Design);

  const clone = (sp) => {
    return JSON.parse(JSON.stringify(sp));
  };

  const resetHistory = (_sp?) => {
    setHistory([clone(_sp ? _sp : seatingPlan)]);
  };

  const onSeatingPlanUpdated = (_seatingPlan: ISeatingPlan) => {
    setHistory([...(history.length > 20 ? history.filter((e, i) => i != 0) : history), clone(_seatingPlan)]);
    _seatingPlan.ChangesMade = true;
    seatingPlan.Objects = [...seatingPlan.Objects];
    onEventUpdated({ ...event });
  };

  const [inputRows, setInputRows] = useState<string>(seatingPlan.Rows.toString());
  const [inputColumns, setInputColumns] = useState<string>(seatingPlan.Columns.toString());
  const [inputSeatName, setInputSeatName] = useState<string>('');
  const [inputGroupName, setInputGroupName] = useState<string>('');
  const [designMode, setDesignMode] = useState<DesignMode>(DesignMode.Seats);
  const containerRef = useRef();
  const [designModeOpen, setDesignModeOpen] = useState(false);
  const [modeOpen, setModeOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [findSeatingPlan, setFindSeatingPlan] = useState(false);

  useEffect(() => {
    resetHistory();
  }, []);

  const undo = () => {
    if (history.length <= 1) return;

    const previousPlan = history[history.length - 2];

    seatingPlan.Seats = previousPlan.Seats;
    seatingPlan.Objects = previousPlan.Objects;
    seatingPlan.Rows = previousPlan.Rows;
    seatingPlan.Columns = previousPlan.Columns;

    if (editMode == ViewMode.Design) {
      seatingPlan.Seats.forEach((s) => (s.Selected = true));
    } else {
      seatingPlan.Seats.forEach((s) => (s.Selected = false));
    }

    setInputRows(previousPlan.Rows.toString());
    setInputColumns(seatingPlan.Columns.toString());

    onEventUpdated({ ...event });

    // updateSeatingPlan(history[history.length - 2]);
    setHistory([...history.filter((e, i) => i != history.length - 1)]);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const modeParam: string = queryParams.get('mode');

    if (modeParam == ViewMode.Design) {
      seatingPlan.Seats.forEach((s) => (s.Selected = true));
    } else {
      seatingPlan.Seats.forEach((s) => (s.Selected = false));
    }

    if (modeParam == ViewMode.Background) {
      enterBackgroundMode();
    } else if (modeParam == ViewMode.Category) {
      enterCategoryMode();
    } else if (modeParam == ViewMode.Design) {
      enterDesignMode();
    } else if (modeParam == ViewMode.Label) {
      enterLabelMode();
    } else if (modeParam == ViewMode.Offset) {
      enterOffsetMode();
    } else if (modeParam == ViewMode.Attendee) {
      enterPreviewMode();
    }
  }, []);

  const keydown = (e: KeyboardEvent) => {
    if (e.ctrlKey || e.metaKey) {
      if (e.code === 'KeyZ') {
        undo();
      }
    }

    if (editMode == ViewMode.Offset && getSelectedSeats().length > 0) {
      if (e.key == 'ArrowUp') {
        minusVerticalOffsetClick();
        e.preventDefault();
      } else if (e.key == 'ArrowDown') {
        plusVerticalOffsetClick();
        e.preventDefault();
      } else if (e.key == 'ArrowLeft') {
        minusHorizontalOffsetClick();
        e.preventDefault();
      } else if (e.key == 'ArrowRight') {
        plusHorizontalOffsetClick();
        return e.preventDefault();
      }
    } else {
      if (e.key == 'ArrowUp') {
        moveSeatsUp();
        e.preventDefault();
      } else if (e.key == 'ArrowDown') {
        moveSeatsDown();
        e.preventDefault();
      } else if (e.key == 'ArrowLeft') {
        moveSeatsLeft();
        e.preventDefault();
      } else if (e.key == 'ArrowRight') {
        moveSeatsRight();
        return e.preventDefault();
      }
    }
  };

  useEffect(() => {
    (window as any).export = () => {};

    (window as any).import = (json) => {
      const sp: ISeatingPlan = JSON.parse(json);

      seatingPlan.Rows = sp.Rows;
      seatingPlan.Columns = sp.Columns;
      seatingPlan.Objects = [];

      sp.Seats.forEach((s) => {
        let matchingSeat: ISeatingPlanSeat = null;
        const matchingNamedSeats = seatingPlan.Seats.filter((q) => q.Group == s.Group && q.Name == s.Name);

        if (matchingNamedSeats.length > 0) {
          matchingSeat = matchingNamedSeats.find((q) => q.SeatCategory.Name == s.SeatCategory.Name);
        }

        if (matchingSeat) {
          matchingSeat.Row = s.Row;
          matchingSeat.Column = s.Column;
          matchingSeat.OffsetX = s.OffsetX;
          matchingSeat.OffsetY = s.OffsetY;
        } else {
          const seat: ISeatingPlanSeat = {
            SeatCategoryGuid: seatingPlan.SeatCategories[0].Guid,
            SeatCategoryId: seatingPlan.SeatCategories[0].Id,
            SeatCategoryColour: seatingPlan.SeatCategories[0].Colour,
            SeatCategory: seatingPlan.SeatCategories[0],
            SeatCategoryName: seatingPlan.SeatCategories[0].Name,
            Row: s.Row,
            Column: s.Column,
            Name: s.Name,
            Group: s.Group,
            OffsetX: s.OffsetX,
            OffsetY: s.OffsetY,
            Locked: false,
          };

          seatingPlan.Seats.push(seat);
        }
      });

      changeMade();
    };

    return () => {
      (window as any).export = null;
      (window as any).import = null;
    };
  }, [history]);

  useEffect(() => {
    document.addEventListener('keydown', keydown);

    return () => {
      document.removeEventListener('keydown', keydown);
    };
  }, [history]);

  const changeMade = function () {
    onSeatingPlanUpdated(seatingPlan);
  };

  const defaultMode = function () {
    setEditMode(ViewMode.Design);
    setDesignMode(DesignMode.Seats);
    const seats = seatingPlan.Seats;

    if (editMode == ViewMode.Design) {
      const seats = seatingPlan.Seats;

      seats.forEach((seat) => {
        seat.Selected = false;
      });
    }

    seats.forEach((seat) => {
      seat.Selected = false;
    });
  };

  const enterDesignMode = function () {
    defaultMode();
    LocationHelper.updateParam('mode', ViewMode.Design);
  };

  const enterLabelMode = function () {
    if (editMode == ViewMode.Label) {
      return;
    }
    defaultMode();
    setEditMode(ViewMode.Label);
    setDesignMode(DesignMode.Seats);
    LocationHelper.updateParam('mode', ViewMode.Label);
  };

  const enterOffsetMode = function () {
    if (editMode == ViewMode.Offset) {
      return;
    }
    defaultMode();
    setEditMode(ViewMode.Offset);
    setDesignMode(DesignMode.Seats);
    LocationHelper.updateParam('mode', ViewMode.Offset);
  };

  const enterPreviewMode = function () {
    if (editMode == ViewMode.Attendee) {
      return;
    }
    SeatingPlanHelper.refreshMiddleSeats(seatingPlan);
    defaultMode();
    setEditMode(ViewMode.Attendee);
    setDesignMode(DesignMode.Seats);
    LocationHelper.updateParam('mode', ViewMode.Attendee);
  };

  const enterBackgroundMode = function () {
    if (editMode == ViewMode.Background) {
      return;
    }
    defaultMode();
    setEditMode(ViewMode.Background);
    setDesignMode(DesignMode.Seats);
    LocationHelper.updateParam('mode', ViewMode.Background);
  };

  const enterCategoryMode = function () {
    if (editMode == ViewMode.Category) {
      return;
    }
    defaultMode();
    setEditMode(ViewMode.Category);
    setDesignMode(DesignMode.Seats);
    LocationHelper.updateParam('mode', ViewMode.Category);
  };

  const addRow = function () {
    if (seatingPlan.Rows >= 100) {
      return;
    }

    seatingPlan.Rows++;
    setInputRows(seatingPlan.Rows.toString());
    changeMade();
  };

  const removeRow = function () {
    if (seatingPlan.Rows <= 10) {
      return;
    }

    const seats = seatingPlan.Seats;
    const rowToBeRemoved = seatingPlan.Rows;

    seatingPlan.Seats = linq
      .from(seats)
      .where(function (s) {
        return s.Row != rowToBeRemoved;
      })
      .toArray();
    seatingPlan.Objects = linq
      .from(seatingPlan.Objects)
      .where(function (s) {
        return s.row != rowToBeRemoved - 1;
      })
      .toArray();

    seatingPlan.Rows--;
    setInputRows(seatingPlan.Rows.toString());
    changeMade();
  };

  const addColumn = function () {
    if (seatingPlan.Columns >= 100) {
      return;
    }

    seatingPlan.Columns++;
    setInputColumns(seatingPlan.Columns.toString());
    changeMade();
  };

  const removeColumn = function () {
    if (seatingPlan.Columns <= 10) {
      return;
    }

    const seats = seatingPlan.Seats;
    const columnToBeRemoved = seatingPlan.Columns;

    seatingPlan.Seats = linq
      .from(seats)
      .where(function (s) {
        return s.Column != columnToBeRemoved;
      })
      .toArray();

    seatingPlan.Objects = linq
      .from(seatingPlan.Objects)
      .where(function (s) {
        return s.column != columnToBeRemoved - 1;
      })
      .toArray();

    seatingPlan.Columns--;
    setInputColumns(seatingPlan.Columns.toString());
    changeMade();
  };

  const addColumnClick = function () {
    addColumn();
    setInputColumns(seatingPlan.Columns.toString());
  };

  const removeColumnClick = function () {
    removeColumn();
    setInputColumns(seatingPlan.Columns.toString());
  };

  const addRowClick = function () {
    addRow();
    setInputRows(seatingPlan.Rows.toString());
  };

  const removeRowClick = function () {
    removeRow();
    setInputRows(seatingPlan.Rows.toString());
  };

  const columnInputValueChange = function (e) {
    const oldValue = seatingPlan.Columns;
    let newValue = e.target.value;

    if (!isNaN(parseFloat(newValue)) && isFinite(newValue)) {
      newValue = parseInt(newValue);
    } else {
      setInputColumns(newValue);
      return;
    }

    if (newValue > 100 || newValue < 10) {
      setInputColumns(newValue);
      return;
    }

    const difference = oldValue - newValue;

    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        removeColumn();
      }
    } else {
      for (let i = 0; i > difference; i--) {
        addColumn();
      }
    }

    seatingPlan.Columns = newValue;
  };

  const rowInputValueChange = function (e) {
    const oldValue = seatingPlan.Rows;
    let newValue = e.target.value;

    if (!isNaN(parseFloat(newValue)) && isFinite(newValue)) {
      newValue = parseInt(newValue);
    } else {
      setInputRows(newValue);
      return;
    }

    if (newValue > 100 || newValue < 10) {
      setInputRows(newValue);
      return;
    }

    const difference = oldValue - newValue;

    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        removeRow();
      }
    } else {
      for (let i = 0; i > difference; i--) {
        addRow();
      }
    }

    seatingPlan.Rows = newValue;
  };

  const moveSeatsLeft = function () {
    moveSeats(0, -1);
  };

  const moveSeatsUp = function () {
    moveSeats(-1, 0);
  };

  const moveSeats = function (rowModifier, columnModifier) {
    const seats = seatingPlan.Seats;
    const objects = seatingPlan.Objects;

    const selectedSeats = linq
      .from(seats)
      .where(function (s) {
        return s.Selected == true;
      })
      .toArray();

    let validToMove = true;

    const seatsThatAreNotSelected = linq
      .from(seats)
      .where(function (s) {
        return !linq
          .from(selectedSeats)
          .where(function (innerS) {
            return innerS == s;
          })
          .any();
      })
      .toArray();

    selectedSeats.forEach(function (selectedSeat) {
      if (selectedSeat.Row + rowModifier < 1 || selectedSeat.Row + rowModifier > seatingPlan.Rows) {
        validToMove = false;
        return;
      } else if (
        selectedSeat.Column + columnModifier < 1 ||
        selectedSeat.Column + columnModifier > seatingPlan.Columns
      ) {
        validToMove = false;
        return;
      }

      if (editMode != ViewMode.Design) {
        const seatInNewSpot = linq
          .from(seatsThatAreNotSelected)
          .where(function (s) {
            return s.Row == selectedSeat.Row + rowModifier && s.Column == selectedSeat.Column + columnModifier;
          })
          .firstOrDefault();

        const objectInNewSpot = linq
          .from(objects)
          .where(function (obj) {
            return (
              obj.row == selectedSeat.Row - 1 + rowModifier && obj.column == selectedSeat.Column - 1 + columnModifier
            );
          })
          .firstOrDefault();

        if (seatInNewSpot != null || objectInNewSpot != null) {
          validToMove = false;
          return;
        }
      }
    });

    if (editMode == ViewMode.Design) {
      objects.forEach(function (o) {
        if (o.row + rowModifier < 0 || o.row + rowModifier >= seatingPlan.Rows) {
          validToMove = false;
          return;
        } else if (o.column + columnModifier < 0 || o.column + columnModifier >= seatingPlan.Columns) {
          validToMove = false;
          return;
        }
      });
    }

    if (!validToMove) {
      return;
    }

    selectedSeats.forEach(function (selectedSeat) {
      selectedSeat.Row = selectedSeat.Row + rowModifier;
      selectedSeat.Column = selectedSeat.Column + columnModifier;
    });

    if (editMode == ViewMode.Design) {
      objects.forEach(function (o) {
        o.row = o.row + rowModifier;
        o.column = o.column + columnModifier;
      });
    }

    selectedSeats.forEach(function (selectedSeat) {
      const seatInSameSpot = linq
        .from(seats)
        .where(function (s) {
          return s != selectedSeat && s.Row == selectedSeat.Row && s.Column == selectedSeat.Column;
        })
        .firstOrDefault();

      if (seatInSameSpot != null) {
        const i = seats.indexOf(seatInSameSpot);
        if (i != -1) {
          seats.splice(i, 1);
        }
      }
    });

    seatingPlan.Seats = seats;
    seatingPlan.Objects = objects;

    changeMade();
  };

  const moveSeatsRight = function () {
    moveSeats(0, 1);
  };

  const moveSeatsDown = function () {
    moveSeats(1, 0);
  };

  const seatNameInputValueChange = function (e) {
    const newValue = e.target.value;
    const oldValue = inputSeatName;

    if (newValue.length > 3 || /^[a-zA-Z0-9- ]*$/.test(newValue) == false) {
      setInputSeatName(oldValue);
      return;
    }

    if (newValue == '*') {
      return;
    }

    getSelectedSeats().forEach((seat, index) => {
      if (newValue.length <= 0) {
        seat.Name = '-';
      } else {
        seat.Name = newValue;
      }
    });

    setInputSeatName(newValue);
    changeMade();
  };

  const onPlaceholderClick = (c: number, r: number) => {
    if (designMode === DesignMode.Seats) {
      seatingPlan.Seats.push(SeatingPlanHelper.getDefaultSeat(seatingPlan, c, r));
    } else {
      seatingPlan.Objects = [
        ...seatingPlan.Objects,
        {
          designMode: designMode,
          column: c - 1,
          row: r - 1,
        },
      ];
    }

    changeMade();
  };

  const getSeatLeftOfSeat = (seat: ISeatingPlanSeat): ISeatingPlanSeat | undefined => {
    return seatingPlan.Seats.find(
      (_seat: ISeatingPlanSeat) => _seat.Row === seat.Row && _seat.Column === seat.Column - 1,
    );
  };

  const getSeatRightOfSeat = (seat: ISeatingPlanSeat): ISeatingPlanSeat | undefined => {
    return seatingPlan.Seats.find(
      (_seat: ISeatingPlanSeat) => _seat.Row === seat.Row && _seat.Column === seat.Column + 1,
    );
  };

  const selectAllLeftOfSeat = (seat: ISeatingPlanSeat, value: boolean) => {
    for (let _seat = seat; _seat; _seat = getSeatLeftOfSeat(_seat)) {
      _seat.Selected = value;
    }
  };

  const selectAllRightOfSeat = (seat: ISeatingPlanSeat, value: boolean) => {
    for (let _seat = seat; _seat; _seat = getSeatRightOfSeat(_seat)) {
      _seat.Selected = value;
    }
  };

  const onSeatClick = function (seat: ISeatingPlanSeat, args: ISeatClickArgs) {
    if (editMode == ViewMode.Design) {
      seatingPlan.Seats = seatingPlan.Seats.filter(
        (s: ISeatingPlanSeat) => !(s.Row == seat.Row && s.Column == seat.Column),
      );

      if (designMode != DesignMode.Seats) {
        seatingPlan.Objects = [
          ...seatingPlan.Objects,
          {
            designMode: designMode,
            column: seat.Column - 1,
            row: seat.Row - 1,
          },
        ];
      } else if (!seat.Selected) {
        if (seat && !seat.SeatCategoryGuid) {
          seat.SeatCategoryGuid = seatingPlan.SeatCategories[0].Guid;
          seat.SeatCategoryId = seatingPlan.SeatCategories[0].Id;
          seat.SeatCategoryColour = seatingPlan.SeatCategories[0].Colour;
          seat.SeatCategoryName = seatingPlan.SeatCategories[0].Name;
          seat.SeatCategory = seatingPlan.SeatCategories[0];
        }

        seatingPlan.Seats.push(seat);
      }
    } else {
      if (args.shift && args.control) {
        const selectedValue = !seat.Selected;

        const seats = seatingPlan.Seats.filter(
          (_seat: ISeatingPlanSeat) => _seat.SeatCategoryId === seat.SeatCategoryId && _seat.Group === seat.Group,
        );
        seats.forEach((s) => (s.Selected = selectedValue));
      } else if (args.control) {
        const selectedValue = !seat.Selected;

        selectAllLeftOfSeat(seat, selectedValue);
        selectAllRightOfSeat(seat, selectedValue);
        seat.Selected = selectedValue;
      } else {
        seat.Selected = !seat.Selected;
      }
    }

    if (editMode == ViewMode.Label) {
      handleSeatsSelectedLabelMode();
    }

    changeMade();
  };

  const onSelection = (seats: ISeatingPlanSeat[], placeholders: IPlaceHolder[], objects: ISeatingPlanObject[]) => {
    if (objects.length == 0 && seats.length == 0 && placeholders.length == 0) {
      if (editMode !== ViewMode.Design) {
        seatingPlan.Seats.forEach((seat) => {
          seat.Selected = false;
        });
      }
      changeMade();
      return;
    }

    if (editMode == ViewMode.Design) {
      placeholders.forEach((p) => {
        if (designMode === DesignMode.Seats) {
          seats.length == 0 && seatingPlan.Seats.push(SeatingPlanHelper.getDefaultSeat(seatingPlan, p.c, p.r));
        } else {
          seatingPlan.Objects = [
            ...seatingPlan.Objects,
            {
              designMode: designMode,
              column: p.c - 1,
              row: p.r - 1,
            },
          ];
        }
      });

      seatingPlan.Seats = seatingPlan.Seats.filter(
        (s: ISeatingPlanSeat) => seats.filter((seat) => s.Row == seat.Row && s.Column == seat.Column).length == 0,
      );

      if (designMode != DesignMode.Seats) {
        seats.forEach((seat) => {
          seatingPlan.Objects = [
            ...seatingPlan.Objects,
            {
              designMode: designMode,
              column: seat.Column - 1,
              row: seat.Row - 1,
            },
          ];
        });
      } else {
        if (seats.filter((s) => !s.Selected).length > 0) {
          seats.forEach((seat) => {
            if (!seat.SeatCategoryGuid) {
              seat.SeatCategoryGuid = seatingPlan.SeatCategories[0].Guid;
              seat.SeatCategoryId = seatingPlan.SeatCategories[0].Id;
              seat.SeatCategoryColour = seatingPlan.SeatCategories[0].Colour;
              seat.SeatCategoryName = seatingPlan.SeatCategories[0].Name;
              seat.SeatCategory = seatingPlan.SeatCategories[0];
            }

            seatingPlan.Seats.push(seat);
          });
        }
      }

      if (designMode == DesignMode.Seats) {
        objects.forEach((object) => {
          var seat = SeatingPlanHelper.getDefaultSeat(seatingPlan, object.column + 1, object.row + 1);
          seat.Selected = true;
          seats.length == 0 && seatingPlan.Seats.push(seat);
        });

        seatingPlan.Objects = seatingPlan.Objects.filter(
          (o: ISeatingPlanObject) =>
            objects.filter((object) => o.row == object.row && o.column == object.column).length == 0,
        );
      } else {
        if (objects.filter((o) => o.designMode !== designMode).length === 0) {
          seatingPlan.Objects = seatingPlan.Objects.filter(
            (o: ISeatingPlanObject) =>
              objects.filter((object) => o.row == object.row && o.column == object.column).length == 0,
          );
        } else {
          objects.forEach((object) => {
            object.designMode = designMode;
          });
        }
      }
    } else {
      seatingPlan.Seats.forEach((seat: ISeatingPlanSeat) => (seat.Selected = false));

      if (seats.filter((s) => !s.Selected).length > 0) {
        seats.forEach((seat) => {
          seat.Selected = true;
        });
      } else {
        seats.forEach((seat) => {
          seat.Selected = !seat.Selected;
        });
      }
    }

    if (editMode == ViewMode.Label) {
      handleSeatsSelectedLabelMode();
    }

    changeMade();
  };

  const onObjectClick = function (object: ISeatingPlanObject) {
    if (editMode !== ViewMode.Design && object.designMode == DesignMode.Label) {
      setLabelText(object.text || '');
      setSelectedLabel(object);
    } else if (editMode == ViewMode.Design && (designMode == DesignMode.Seats || designMode == object.designMode)) {
      seatingPlan.Objects = seatingPlan.Objects.filter(
        (o: ISeatingPlanObject) => !(o.row == object.row && o.column == object.column),
      );
    } else if (editMode == ViewMode.Design) {
      object.designMode = designMode;
    }

    changeMade();
  };

  const getSelectedSeats = function () {
    return linq
      .from(seatingPlan.Seats)
      .where(function (s) {
        return s.Selected == true;
      })
      .toArray();
  };

  const groupNameInputValueChange = function (e) {
    const newValue = e.target.value.toUpperCase();
    const oldValue = inputGroupName;

    if (newValue.length > 3 || /^[a-zA-Z0-9- ]*$/.test(newValue) == false) {
      setInputGroupName(oldValue);

      return;
    }

    if (newValue == '*') {
      return;
    }

    getSelectedSeats().forEach((seat, index) => {
      if (newValue.length <= 0) {
        seat.Group = '-';
      } else {
        seat.Group = newValue;
      }
    });

    setInputGroupName(newValue);

    changeMade();
  };

  const seats = function () {
    if (editMode == ViewMode.Design) {
      return linq
        .from(seatingPlan.Seats)
        .where(function (s) {
          return s.Selected == true;
        })
        .toArray();
    }

    return seatingPlan.Seats;
  };

  const handleSeatsSelectedLabelMode = function () {
    const selectedSeats = getSelectedSeats();
    if (selectedSeats.length == 0) {
      setInputSeatName('');
      setInputGroupName('');
    }

    if (selectedSeats.length == 1) {
      const seat = selectedSeats[0];
      setInputSeatName(seat.Name);
      setInputGroupName(seat.Group);
    }

    if (selectedSeats.length > 1) {
      let groupNamesMatch = true;
      let seatNamesMatch = true;

      selectedSeats.forEach((seat, index) => {
        if (selectedSeats[0].Group != seat.Group) {
          groupNamesMatch = false;
        }
        if (selectedSeats[0].Name != seat.Name) {
          seatNamesMatch = false;
        }
      });

      if (groupNamesMatch) {
        const value = selectedSeats[0].Group;
        setInputGroupName(value == '-' ? '' : value);
      } else {
        setInputGroupName('*');
      }

      if (seatNamesMatch) {
        const value = selectedSeats[0].Name;
        setInputSeatName(value == '-' ? '' : value);
      } else {
        setInputSeatName('*');
      }
    }
  };

  const handleAutoGroupAscending = function () {
    let selectedSeats = linq
      .from(getSelectedSeats())
      .orderBy(function (s) {
        return s.Row;
      })
      .thenBy(function (s) {
        return s.Column;
      })
      .toArray();

    if (selectedSeats.length == 0) {
      selectedSeats = linq
        .from(seats())
        .orderBy(function (s) {
          return s.Row;
        })
        .thenBy(function (s) {
          return s.Column;
        })
        .toArray();

      selectedSeats.forEach((seat, index) => {
        seat.Selected = true;
      });
    }

    autoGroupSeats(selectedSeats);
  };

  const handleAutoGroupDescending = function () {
    let selectedSeats = linq
      .from(getSelectedSeats())
      .orderByDescending(function (s) {
        return s.Row;
      })
      .thenByDescending(function (s) {
        return s.Column;
      })
      .toArray();

    if (selectedSeats.length == 0) {
      selectedSeats = linq
        .from(seats())
        .orderByDescending(function (s) {
          return s.Row;
        })
        .thenByDescending(function (s) {
          return s.Column;
        })
        .toArray();

      selectedSeats.forEach((seat, index) => {
        seat.Selected = true;
      });
    }

    autoGroupSeats(selectedSeats);
  };

  function allEqual(input) {
    return input.split('').every((char) => char === input[0]);
  }

  const autoGroupSeats = function (seats) {
    let baseChar = 'A'.charCodeAt(0);

    let stringLength = 1;

    for (let i = 0; i < seats.length; i++) {
      const seat = seats[i];

      if (i == 0 && (inputGroupName.length == 1 || allEqual(inputGroupName))) {
        if (inputGroupName != '*' && inputGroupName.length > 0) {
          baseChar = inputGroupName.charCodeAt(0);
        }

        if (inputGroupName.length > 1 && allEqual(inputGroupName)) {
          stringLength = inputGroupName.length;
        }
      }

      seat.Group = '';

      for (var i2 = 0; i2 < stringLength; i2++) {
        seat.Group += String.fromCharCode(baseChar);
      }

      if (seats[i + 1] != null && seat.Row != seats[i + 1].Row) {
        baseChar++;
      }
    }

    if (seats.length > 1) {
      setInputSeatName('*');
    } else if (seats.length == 1) {
      setInputSeatName(seats[0].Name);
    } else {
      setInputSeatName('');
    }

    changeMade();
  };

  const handleAutoNumberAscending = function () {
    let selectedSeats = linq
      .from(getSelectedSeats())
      .orderBy(function (s) {
        return s.Row;
      })
      .thenBy(function (s) {
        return s.Column;
      })
      .toArray();

    if (selectedSeats.length == 0) {
      selectedSeats = linq
        .from(seats())
        .orderBy(function (s) {
          return s.Row;
        })
        .thenBy(function (s) {
          return s.Column;
        })
        .toArray();

      selectedSeats.forEach((seat, index) => {
        seat.Selected = true;
      });
    }

    autoNumberSeats(selectedSeats);
  };

  const handleAutoNumberDescending = function () {
    let selectedSeats = linq
      .from(getSelectedSeats())
      .orderByDescending(function (s) {
        return s.Row;
      })
      .thenByDescending(function (s) {
        return s.Column;
      })
      .toArray();

    if (selectedSeats.length == 0) {
      selectedSeats = linq
        .from(seats())
        .orderByDescending(function (s) {
          return s.Row;
        })
        .thenByDescending(function (s) {
          return s.Column;
        })
        .toArray();

      selectedSeats.forEach((seat, index) => {
        seat.Selected = true;
      });
    }

    autoNumberSeats(selectedSeats);
  };

  const autoNumberSeats = function (seats) {
    let baseNumber = 1;

    if (
      inputSeatName != null &&
      !isNaN(inputSeatName as any) &&
      isFinite(inputSeatName as any) &&
      inputSeatName.length > 0
    ) {
      baseNumber = parseInt(inputSeatName);
    }

    let seatNumber = baseNumber;

    for (let i = 0; i < seats.length; i++) {
      const seat = seats[i];

      seat.Name = seatNumber;

      if (seats[i + 1] != null && seat.Group != seats[i + 1].Group) {
        seatNumber = baseNumber;
      } else {
        seatNumber++;
      }
    }

    if (seats.length > 1) {
      setInputSeatName('*');
    } else if (seats.length == 1) {
      setInputSeatName(seats[0].Name);
    } else {
      setInputSeatName('');
    }

    changeMade();
  };

  const deleteCategory = function (seatCategory) {
    if (seatingPlan.SeatCategories.length == 1) {
      return;
    }

    const newCategories = seatingPlan.SeatCategories.filter((x) => x != seatCategory);

    event.AllocatedCategoryGroups.forEach((allocatedCategoryGroup) => {
      allocatedCategoryGroup.Categories = allocatedCategoryGroup.Categories.filter(
        (c) => c.SeatCategory.Guid !== seatCategory.Guid,
      );
    });

    seatingPlan.Seats.forEach((seat, index) => {
      if (seat.SeatCategory.Guid == seatCategory.Guid) {
        seat.SeatCategoryId = newCategories[0].Id;
        seat.SeatCategoryGuid = newCategories[0].Guid;
        seat.SeatCategory = newCategories[0];
      }
    });

    seatingPlan.SeatCategories = [...newCategories];
    changeMade();
    resetHistory();
  };

  const seatCategoryClicked = function (category: ISeatCategory) {
    getSelectedSeats().forEach((seat: ISeatingPlanSeat, index) => {
      seat.SeatCategory = category;
      seat.SeatCategoryId = category.Id;
      seat.SeatCategoryGuid = category.Guid;
      seat.Selected = false;
    });

    changeMade();
  };

  const propertiesColumnStyle = { width: '150px' };

  if (editMode != ViewMode.Category) {
  } else {
    propertiesColumnStyle.width = '250px';
  }

  const selectedDesignMode = designModes.filter((d) => d.designMode == designMode)[0];

  const editOptions = (
    <>
      <div className="option counter">
        <label>Columns</label>
        <div className="control">
          <button onClick={removeColumnClick}>
            <SVGMinus />
          </button>
          <input type="text" onChange={columnInputValueChange} value={inputColumns} />
          <button onClick={addColumnClick}>
            <SVGPlus />
          </button>
        </div>
      </div>
      <div className="option counter">
        <label>Rows</label>
        <div className="control">
          <button onClick={removeRowClick}>
            <SVGMinus />
          </button>
          <input type="text" onChange={rowInputValueChange} value={inputRows} />
          <button onClick={addRowClick}>
            <SVGPlus />
          </button>
        </div>
      </div>
      <div
        className={`option dropdown dropdown-modal${designModeOpen ? ' open' : ''}`}
        onClick={() => setDesignModeOpen(!designModeOpen)}
      >
        <label>{selectedDesignMode.Name}</label>

        {designModeOpen && <div className="click-off" onClick={() => setDesignModeOpen(false)}></div>}
        <span
          className="icon"
          style={
            selectedDesignMode.borderRadius && {
              borderRadius: selectedDesignMode.borderRadius,
              backgroundColor: selectedDesignMode.backgroundColor,
              border: selectedDesignMode.border,
            }
          }
        >
          {selectedDesignMode.icon && <img src={selectedDesignMode.icon} />}
        </span>

        <button className="notch"></button>

        <div className="dropdown-options right">
          {designModes.map((d) => (
            <button
              key={d.designMode}
              data-designmode={d.designMode}
              onClick={() => {
                setDesignMode(d.designMode);
                setDesignModeOpen(false);
              }}
              className={`${d.designMode == designMode ? 'selected' : ''}`}
            >
              <span
                className="dropdown-option-icon"
                style={
                  d.borderRadius && {
                    borderRadius: d.borderRadius,
                    backgroundColor: d.backgroundColor,
                    border: d.border,
                  }
                }
              >
                {d.icon && <img src={d.icon} />}
              </span>
              <span className="description">{d.Name}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );

  const selectedSeats = getSelectedSeats();

  const alterOffsetAmount = 0.05;

  const plusVerticalOffsetClick = () => {
    if (!selectedSeats || selectedSeats.length == 0) return;

    selectedSeats.forEach((seat) => {
      const offset = seat.OffsetY || 0.0;
      const newOffset = offset + alterOffsetAmount;

      seat.OffsetY = newOffset;
    });

    changeMade();
  };

  const minusSeatScaleClick = () => {
    seatingPlan.SeatScale = parseFloat((seatingPlan.SeatScale - 0.05).toFixed(2));
    changeMade();
  };

  const plusSeatScaleClick = () => {
    seatingPlan.SeatScale = parseFloat((seatingPlan.SeatScale + 0.05).toFixed(2));
    changeMade();
  };

  const minusVerticalOffsetClick = () => {
    if (!selectedSeats || selectedSeats.length == 0) return;

    selectedSeats.forEach((seat) => {
      const offset = seat.OffsetY || 0.0;
      const newOffset = offset - alterOffsetAmount;

      seat.OffsetY = newOffset;
    });

    changeMade();
  };

  const plusHorizontalOffsetClick = () => {
    if (!selectedSeats || selectedSeats.length == 0) return;

    selectedSeats.forEach((seat) => {
      const offset = seat.OffsetX || 0.0;
      const newOffset = offset + alterOffsetAmount;

      seat.OffsetX = newOffset;
    });

    changeMade();
  };

  const minusHorizontalOffsetClick = () => {
    if (!selectedSeats || selectedSeats.length == 0) return;

    selectedSeats.forEach((seat) => {
      const offset = seat.OffsetX || 0.0;
      const newOffset = offset - alterOffsetAmount;

      seat.OffsetX = newOffset;
    });

    changeMade();
  };

  const resetOffsets = () => {
    if (!selectedSeats || selectedSeats.length == 0) return;

    selectedSeats.forEach((seat) => {
      seat.OffsetX = 0.0;
      seat.OffsetY = 0.0;
    });

    changeMade();
  };

  const offsetOptions = (
    <>
      <div className="option counter">
        <label>Horizontal Offset X</label>
        <div className="control">
          <button onClick={minusHorizontalOffsetClick}>
            <SVGMinus />
          </button>
          <input
            readOnly
            type="text"
            onChange={() => {}}
            value={!selectedSeats || selectedSeats.length == 0 ? '-' : selectedSeats[0].OffsetX || '0'}
          />
          <button onClick={plusHorizontalOffsetClick}>
            <SVGPlus />
          </button>
        </div>
      </div>
      <div className="option counter">
        <label>Vertical Offset Y</label>
        <div className="control">
          <button onClick={minusVerticalOffsetClick}>
            <SVGMinus />
          </button>
          <input
            readOnly
            type="text"
            onChange={() => {}}
            value={!selectedSeats || selectedSeats.length == 0 ? '-' : selectedSeats[0].OffsetY || '0'}
          />
          <button onClick={plusVerticalOffsetClick}>
            <SVGPlus />
          </button>
        </div>
      </div>
      <div className="option counter">
        <label>Seat Scale</label>
        <div className="control">
          <button onClick={minusSeatScaleClick}>
            <SVGMinus />
          </button>
          <input readOnly type="text" onChange={() => {}} value={seatingPlan.SeatScale} />
          <button onClick={plusSeatScaleClick}>
            <SVGPlus />
          </button>
        </div>
      </div>
      <button className="option" onClick={resetOffsets}>
        <label>Reset Offset </label>
        <div className="icon">
          <img className="smaller" src={SVGHelper.get('Offset')} />
        </div>
      </button>
    </>
  );

  const backgroundChangeAmount = 1.0;

  const minusBackgroundXClick = () => {
    const value = seatingPlan.BackgroundX || 0.0;
    seatingPlan.BackgroundX = value - backgroundChangeAmount;
    changeMade();
  };
  const plusBackgroundXClick = () => {
    const value = seatingPlan.BackgroundX || 0.0;
    seatingPlan.BackgroundX = value + backgroundChangeAmount;
    changeMade();
  };

  const minusBackgroundYClick = () => {
    const value = seatingPlan.BackgroundY || 0.0;
    seatingPlan.BackgroundY = value - backgroundChangeAmount;
    changeMade();
  };
  const plusBackgroundYClick = () => {
    const value = seatingPlan.BackgroundY || 0.0;
    seatingPlan.BackgroundY = value + backgroundChangeAmount;
    changeMade();
  };

  const minusBackgroundWidthClick = () => {
    const value = seatingPlan.BackgroundWidth || 0.0;
    seatingPlan.BackgroundWidth = value - backgroundChangeAmount;
    changeMade();
  };

  const plusBackgroundWidthClick = () => {
    const value = seatingPlan.BackgroundWidth || 0.0;
    seatingPlan.BackgroundWidth = value + backgroundChangeAmount;

    changeMade();
  };

  const [imageRequests, setImageRequests] = useState<{ [key: string]: IImageUploadRequest }>({});

  const backgroundOptions = (
    <>
      {seatingPlan.BackgroundUrl && seatingPlan.BackgroundUrl.length > 0 ? (
        <>
          <button
            className="option"
            onClick={() => {
              seatingPlan.BackgroundUrl = null;
              changeMade();
            }}
          >
            <label>Clear</label>
            <div className="icon">
              <SVGMultiply />
            </div>
          </button>
          <div className="option counter">
            <label>X</label>
            <div className="control">
              <button onClick={minusBackgroundXClick}>
                <SVGMinus />
              </button>
              <input
                type="text"
                onChange={(t) => {
                  const value: number = NumberHelper.isNumeric(t.target.value)
                    ? parseInt(t.target.value)
                    : seatingPlan.BackgroundX;
                  seatingPlan.BackgroundX = value;
                  changeMade();
                }}
                value={seatingPlan.BackgroundX || '0'}
              />
              <button onClick={plusBackgroundXClick}>
                <SVGPlus />
              </button>
            </div>
          </div>
          <div className="option counter">
            <label>Y</label>
            <div className="control">
              <button onClick={minusBackgroundYClick}>
                <SVGMinus />
              </button>
              <input
                type="text"
                onChange={(t) => {
                  const value = NumberHelper.isNumeric(t.target.value)
                    ? parseInt(t.target.value)
                    : seatingPlan.BackgroundY;
                  seatingPlan.BackgroundY = value;
                  changeMade();
                }}
                value={seatingPlan.BackgroundY || '0'}
              />
              <button onClick={plusBackgroundYClick}>
                <SVGPlus />
              </button>
            </div>
          </div>
          <div className="option counter">
            <label>Width</label>
            <div className="control">
              <button onClick={minusBackgroundWidthClick}>
                <SVGMinus />
              </button>
              <input
                type="text"
                onChange={(t) => {
                  const value = NumberHelper.isNumeric(t.target.value)
                    ? parseInt(t.target.value)
                    : seatingPlan.BackgroundWidth;
                  seatingPlan.BackgroundWidth = value;
                  changeMade();
                }}
                value={seatingPlan.BackgroundWidth || '0'}
              />
              <button onClick={plusBackgroundWidthClick}>
                <SVGPlus />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {seatingPlan.Id && (
            <>
              <ImageUploader
                eventId={event.Id}
                url={
                  imageRequests[`${ImageRequestGroup.SeatingPlanBackground}_${seatingPlan.Id}`]
                    ? imageRequests[`${ImageRequestGroup.SeatingPlanBackground}_${seatingPlan.Id}`].url
                    : seatingPlan.BackgroundUrl
                }
                accept={'.jpeg, .jpg, .png, .svg'}
                onChange={(request) => {
                  props.onImageRequested(request);
                  seatingPlan.BackgroundUrl = request.url;
                  seatingPlan.BackgroundX = 0;
                  seatingPlan.BackgroundY = 0;
                  seatingPlan.BackgroundWidth = SeatingPlanHelper.elementWidth * seatingPlan.Columns;
                  changeMade();
                }}
                group={`${ImageRequestGroup.SeatingPlanBackground}_${seatingPlan.Id}` as any}
                className="option"
                type={ImageUploaderType.Button}
              >
                <label>Add</label>
                <div className="icon">
                  <SVGPlus />
                </div>
              </ImageUploader>
              <button
                className="option"
                onClick={() => {
                  (window as any).download();
                }}
              >
                <label>Download Layout</label>
                <div className="icon">
                  <SVGDown />
                </div>
              </button>
            </>
          )}
        </>
      )}
    </>
  );

  const [nameOptionMode, setNameOptionMode] = useState('NameAsc');
  const [nameOptionOpen, setNameOptionOpen] = useState(false);
  const [numberOptionMode, setNumberOptionMode] = useState('NumberAsc');
  const [numberOptionOpen, setNumberOptionOpen] = useState(false);

  const labelOptions = (
    <>
      <div className="option input">
        <label>Row</label>
        <div className="control">
          <input
            disabled={selectedSeats.length == 0}
            type="text"
            onChange={groupNameInputValueChange}
            value={inputGroupName}
          />
        </div>
      </div>
      <div className="option input">
        <label>Seat</label>
        <div className="control">
          <input
            disabled={selectedSeats.length == 0}
            type="text"
            onChange={seatNameInputValueChange}
            value={inputSeatName}
          />
        </div>
      </div>

      <div
        className={`option dropdown list${nameOptionOpen ? ' open' : ''}${selectedSeats.length <= 1 ? ' disabled' : ''}`}
      >
        <label>{nameOptionMode == 'NameAsc' ? 'Name Asc' : 'Name Desc'}</label>

        {nameOptionOpen && <div className="click-off" onClick={() => setNameOptionOpen(false)}></div>}
        <span className="icon">
          {nameOptionMode == 'NameAsc' ? (
            <img src={SVGHelper.get('Sort_AZ')} />
          ) : (
            <img src={SVGHelper.get('Sort_ZA')} />
          )}
        </span>

        <button
          disabled={selectedSeats.length <= 1}
          className="action"
          onClick={() => {
            if (nameOptionMode == 'NameAsc') handleAutoGroupAscending();
            if (nameOptionMode == 'NameDesc') handleAutoGroupDescending();
          }}
        />
        <button
          className="notch"
          onClick={() => selectedSeats.length > 1 && setNameOptionOpen(!nameOptionOpen)}
        ></button>

        <div className="dropdown-options">
          {nameOptionMode == 'NameAsc' ? (
            <button
              disabled={selectedSeats.length <= 1}
              className="option"
              onClick={() => {
                handleAutoGroupDescending();
                setNameOptionMode('NameDesc');
                setNameOptionOpen(false);
              }}
            >
              <div className="dropdown-option-icon">
                <img src={SVGHelper.get('Sort_ZA')} />
              </div>
              <span className="description">Name Desc</span>
              <p>
                Automatically name the selected seats row from their starting letter alphabetically in descending order.
              </p>
            </button>
          ) : (
            <button
              disabled={selectedSeats.length <= 1}
              className="option"
              onClick={() => {
                handleAutoGroupAscending();
                setNameOptionMode('NameAsc');
                setNameOptionOpen(false);
              }}
            >
              <div className="dropdown-option-icon">
                <img src={SVGHelper.get('Sort_AZ')} />
              </div>
              <span className="description">Name Asc</span>
              <p>Automatically name the selected seats row from their starting letter alphabetically.</p>
            </button>
          )}
        </div>
      </div>

      <div
        className={`option dropdown list${numberOptionOpen ? ' open' : ''}${selectedSeats.length <= 1 ? ' disabled' : ''}`}
      >
        <label>{numberOptionMode == 'NumberAsc' ? 'Number Asc' : 'Number Desc'}</label>

        {numberOptionOpen && <div className="click-off" onClick={() => setNumberOptionOpen(false)}></div>}
        <span className="icon">
          {numberOptionMode == 'NumberAsc' ? (
            <img src={SVGHelper.get('Sort_12')} />
          ) : (
            <img src={SVGHelper.get('Sort_21')} />
          )}
        </span>

        <button
          disabled={selectedSeats.length <= 1}
          className="action"
          onClick={() => {
            if (numberOptionMode == 'NumberAsc') handleAutoNumberAscending();
            if (numberOptionMode == 'NumberDesc') handleAutoNumberDescending();
          }}
        />
        <button
          className="notch"
          onClick={() => selectedSeats.length > 1 && setNumberOptionOpen(!numberOptionOpen)}
        ></button>

        <div className="dropdown-options">
          {numberOptionMode == 'NumberAsc' ? (
            <button
              disabled={selectedSeats.length <= 1}
              className="option"
              onClick={() => {
                handleAutoNumberDescending();
                setNumberOptionMode('NumberDesc');
                setNumberOptionOpen(false);
              }}
            >
              <div className="dropdown-option-icon">
                <img src={SVGHelper.get('Sort_21')} />
              </div>
              <span className="description">Number Desc</span>
              <p>Automatically number the selected seats from their starting number in descending order.</p>
            </button>
          ) : (
            <button
              disabled={selectedSeats.length <= 1}
              className="option"
              onClick={() => {
                handleAutoNumberAscending();
                setNumberOptionMode('NumberAsc');
                setNumberOptionOpen(false);
              }}
            >
              <div className="dropdown-option-icon">
                <img src={SVGHelper.get('Sort_12')} />
              </div>
              <span className="description">Number Asc</span>
              <p>Automatically number the selected seats from their starting number.</p>
            </button>
          )}
        </div>
      </div>
    </>
  );

  const [selectedSeatCategory, setSelectedSeatCategory] = useState<ISeatCategory>(seatingPlan.SeatCategories[0]);
  const [editingSeatCategory, setEditingSeatCategory] = useState(null);

  const categoryOptions = (
    <>
      {editingSeatCategory && (
        <SeatCategoryModal
          event={event}
          onClose={() => setEditingSeatCategory(null)}
          category={editingSeatCategory}
          onChange={(seatCategory: ISeatCategory) => {
            seatingPlan.SeatCategories = linq
              .from([...seatingPlan.SeatCategories.filter((s) => s.Guid != seatCategory.Guid), seatCategory])
              .orderBy((s) => s.Name)
              .toArray();

            event.AllocatedCategoryGroups.forEach((allocatedCategoryGroup) => {
              const ticketCategories = linq.from(allocatedCategoryGroup.Categories);
              const ticketCategoriesThatHaveThisSeatCategory = ticketCategories.where(
                (tc) => tc.SeatCategory && tc.SeatCategory.Guid == seatCategory.Guid,
              );

              if (ticketCategoriesThatHaveThisSeatCategory.count() > 0) {
                ticketCategoriesThatHaveThisSeatCategory.forEach((tc) => {
                  tc.SeatCategory = seatCategory;
                });
              } else {
                allocatedCategoryGroup.Categories.push({
                  Id: 0,
                  People: 1,
                  Description: '',
                  Name: '',
                  HasOrders: false,
                  PriceAsString: '',
                  Colour: ColourHelper.getRandomColor(),
                  Guid: GuidHelper.new(),
                  SeatCategory: seatCategory,
                  GeneralAdmission: false,
                  PriceAsInt: 0,
                });
              }
            });

            props.onEventUpdated({ ...event });

            setEditingSeatCategory(null);
            setSelectedSeatCategory(seatCategory);
            onSeatingPlanUpdated(seatingPlan);
            resetHistory();
          }}
        />
      )}
      <div className={`option dropdown list${categoryOpen ? ' open' : ''}`}>
        <label>{selectedSeatCategory.Name}</label>

        {categoryOpen && <div className="click-off" onClick={() => setCategoryOpen(false)}></div>}
        <span
          className="icon"
          style={{
            backgroundColor: selectedSeatCategory.Colour,
            borderRadius: '100%',
            border: '2px solid transparent',
          }}
        >
          {selectedSeatCategory.Icon == 'wheelchair' && (
            <img className="icon-icon" src={SVGHelper.get('Wheelchair_Light')} />
          )}
          {selectedSeatCategory.Icon == 'info' && <img className="icon-icon" src={SVGHelper.get('Info_Solo_Light')} />}
        </span>

        <button className="action" onClick={() => seatCategoryClicked(selectedSeatCategory)}></button>

        <button className="notch" onClick={() => setCategoryOpen(!categoryOpen)}></button>

        <div className="dropdown-options center">
          {linq
            .from(seatingPlan.SeatCategories)
            .orderBy((sc) => sc.Index)
            .toArray()
            .map((seatCategory) => (
              <div className="dropdown-option dropdown-option--upDown">
                <button
                  key={seatCategory.Guid}
                  onClick={() => {
                    seatCategoryClicked(seatCategory);
                    setSelectedSeatCategory(seatCategory);
                    setCategoryOpen(false);
                  }}
                  className={`${seatCategory == selectedSeatCategory ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <span
                      style={{
                        backgroundColor: seatCategory.Colour,
                        borderRadius: '100%',
                        border: '2px solid transparent',
                      }}
                    >
                      {seatCategory.Icon == 'wheelchair' && (
                        <img className="key-item-wheelchair" src={SVGHelper.get('Wheelchair_Light')} />
                      )}
                      {seatCategory.Icon == 'info' && <img src={SVGHelper.get('Info_Solo_Light')} />}
                    </span>
                  </span>
                  <span className="description">{seatCategory.Name}</span>
                  {seatCategory.Description && seatCategory.Description.length > 0 && <p>{seatCategory.Description}</p>}
                </button>
                <div className="dropdown-option__upDown flex_from_top" style={{ marginLeft: '5px' }}>
                  <button
                    disabled={seatCategory.Index == 0}
                    style={{ marginBottom: '4px' }}
                    className={`admin-button admin-button--small icon flip`}
                    onClick={() => {
                      seatCategory.Index = seatCategory.Index - 1.5;

                      const newList = linq
                        .from(seatingPlan.SeatCategories)
                        .orderBy((s) => s.Index)
                        .toArray();

                      newList.forEach((sp, index) => (sp.Index = index));
                      seatingPlan.ChangesMade = true;
                      props.onEventUpdated({ ...event });
                    }}
                  >
                    <SVGDown />
                  </button>
                  <button
                    disabled={seatCategory.Index == seatingPlan.SeatCategories.length - 1}
                    className={`admin-button admin-button--small icon`}
                    onClick={() => {
                      seatCategory.Index = seatCategory.Index + 1.5;

                      const newList = linq
                        .from(seatingPlan.SeatCategories)
                        .orderBy((s) => s.Index)
                        .toArray();

                      newList.forEach((sp, index) => (sp.Index = index));
                      seatingPlan.ChangesMade = true;
                      props.onEventUpdated({ ...event });
                    }}
                  >
                    <SVGDown />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <button className="option" onClick={() => setEditingSeatCategory(selectedSeatCategory)}>
        <label>Edit </label>
        <div className="icon">
          <SVGEditEvent />
        </div>
      </button>
      <button
        className="option"
        disabled={seatingPlan.SeatCategories.length <= 1}
        onClick={() => {
          const categoryToDelete = selectedSeatCategory;
          const ordersExistForCategory = linq
            .from(event.AllocatedCategoryGroups)
            .any((acg: ICategoryGroup) =>
              linq
                .from(acg.Categories)
                .any(
                  (c) =>
                    c.HasOrders &&
                    c.SeatCategory &&
                    (categoryToDelete.Id
                      ? c.SeatCategory.Id == categoryToDelete.Id
                      : c.SeatCategory.Guid == categoryToDelete.Guid),
                ),
            );

          if (ordersExistForCategory) {
            alert('Cannot delete a seat category that has orders placed against it.');
          } else {
            setSelectedSeatCategory(seatingPlan.SeatCategories.filter((s) => s != selectedSeatCategory)[0]);
            deleteCategory(categoryToDelete);
          }
        }}
      >
        <label>Delete </label>
        <div className="icon">
          <SVGMultiply />
        </div>
      </button>
      <button
        className="option"
        onClick={() =>
          setEditingSeatCategory({
            Id: 0,
            Guid: GuidHelper.new(),
            FocusOnRender: true,
            Name: '',
            Description: '',
            Colour: ColourHelper.getRandomColor(),
            Index: seatingPlan.SeatCategories.length,
          })
        }
      >
        <label>Add </label>
        <div className="icon">
          <SVGPlus />
        </div>
      </button>
    </>
  );

  const createNew = () => {
    enterDesignMode();

    const columns = event.SeatingPlans[0].Columns;
    const rows = event.SeatingPlans[0].Rows;

    const sp = { ...SeatingPlanHelper.getDefaultSeatingPlan(), Columns: columns, Rows: rows };
    event.SeatingPlans = [sp];

    resetHistory(sp);

    onSeatingPlanUpdated(sp);
    onEventUpdated({ ...event, AllocatedCategoryGroups: [] });
    setModeOpen(false);
  };

  const [selectedLabel, setSelectedLabel] = useState<ISeatingPlanObject>(null);
  const [labelText, setLabelText] = useState('');

  const isDisabled = labelText.length < 1 || /^[a-zA-Z0-9- ]*$/.test(labelText) == false;

  const submit = () => {
    if (isDisabled) return;
    seatingPlan.Objects.filter(
      (o) => o.designMode == DesignMode.Label && o.objectGroupId == selectedLabel.objectGroupId,
    ).map((o) => (o.text = labelText));

    setSelectedLabel(null);
    setLabelText('');

    changeMade();
  };

  return (
    <>
      {selectedLabel && (
        <Modal onCloseClick={() => setSelectedLabel(null)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              <SpacerTable>
                <h1>Set Label Name</h1>
                Enter the text for the label and click submit.
              </SpacerTable>

              <div className="spacer" />

              <table className="blocks">
                <tbody>
                  <Block>
                    <BlockTextBox
                      name="label"
                      onKeyPress={(e) => {
                        if (e.charCode == 13) submit();
                      }}
                      type="email"
                      autoFocus={true}
                      value={labelText}
                      placeholder={'Label name'}
                      onChange={(e) => setLabelText(e)}
                    />
                  </Block>
                </tbody>
              </table>

              <div className="spacer" />

              <SpacerTable>
                <Button className="confirm" onClick={submit} text="Submit" disabled={isDisabled} />
                <Button className="cancel" onClick={() => setSelectedLabel(null)} text="Cancel" />
              </SpacerTable>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}

      {findSeatingPlan && (
        <SeatingPlanSearchModal
          hasOrders={props.hasOrders}
          onClose={() => setFindSeatingPlan(false)}
          onSeatingPlanSelected={(sp) => {
            setFindSeatingPlan(false);
            setBusyMessage('Loading seating plan...');

            AdminApi.request('POST', '/api/SeatingPlan', sp)
              .then((seatingPlanResult) => {
                SeatingPlanHelper.copyPlan(seatingPlanResult, seatingPlan, props.hasOrders);

                setInputRows(seatingPlanResult.Rows.toString());
                setInputColumns(seatingPlanResult.Columns.toString());

                setSelectedSeatCategory(seatingPlan.SeatCategories[0]);

                onEventUpdated({ ...event });
                resetHistory(seatingPlan);

                window.setTimeout(() => (window as any).recenter(), 200);

                setBusyMessage(null);
              })
              .catch((message) => {
                alert(message);
                setBusyMessage(null);
              });
          }}
        />
      )}

      <div className={`seating-plan-editor animated fadeIn${fullScreen ? ' fullscreen' : ''}`} ref={containerRef}>
        <Toolbar>
          <div className="options">
            {props.globalOptions}

            <button disabled={history.length <= 1} className="option" onClick={undo}>
              <label>Undo</label>
              <div className="icon">
                <img className="smaller" src={SVGHelper.get('Undo')} />
              </div>
            </button>

            <div className={`option dropdown list${modeOpen ? ' open' : ''}`}>
              <label>
                {editMode == ViewMode.Design
                  ? 'Design'
                  : editMode == ViewMode.Label
                    ? 'Label'
                    : editMode == ViewMode.Offset
                      ? 'Offset'
                      : editMode == ViewMode.Attendee
                        ? 'Preview'
                        : editMode == ViewMode.Background
                          ? 'Background'
                          : 'Categories'}
              </label>

              {modeOpen && <div className="click-off" onClick={() => setModeOpen(false)}></div>}
              <span className="icon">
                {editMode == ViewMode.Design ? (
                  <img src={SVGHelper.get('Design')} />
                ) : editMode == ViewMode.Label ? (
                  <img src={SVGHelper.get('Label')} />
                ) : editMode == ViewMode.Offset ? (
                  <img src={SVGHelper.get('Offset')} />
                ) : editMode == ViewMode.Background ? (
                  <img src={SVGHelper.get('Image')} />
                ) : editMode == ViewMode.Attendee ? (
                  <img src={SVGHelper.get('Preview')} />
                ) : (
                  <img src={SVGHelper.get('SeatCategory')} />
                )}
              </span>

              <button
                className="action"
                onClick={() => {
                  if (editMode == ViewMode.Design) enterLabelMode();
                  if (editMode == ViewMode.Label) enterCategoryMode();
                  if (editMode == ViewMode.Category) enterOffsetMode();
                  if (editMode == ViewMode.Offset) enterBackgroundMode();
                  if (editMode == ViewMode.Background) enterPreviewMode();
                  if (editMode == ViewMode.Attendee) enterDesignMode();
                }}
              ></button>
              <button className="notch" onClick={() => setModeOpen(!modeOpen)}></button>

              <div className="dropdown-options">
                <button
                  onClick={() => {
                    enterDesignMode();
                    setModeOpen(false);
                  }}
                  className={`${editMode == ViewMode.Design ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('Design')} />
                  </span>
                  <span className="description">Design Mode</span>
                  <p>Change the layout and general look of the seating plan.</p>
                </button>
                <button
                  onClick={() => {
                    enterLabelMode();
                    setModeOpen(false);
                  }}
                  className={`${editMode == ViewMode.Label ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('Label')} />
                  </span>
                  <span className="description">Label Mode</span>
                  <p>Enter labels and give your seats an individual name.</p>
                </button>
                <button
                  onClick={() => {
                    enterCategoryMode();
                    setModeOpen(false);
                  }}
                  className={`${editMode == ViewMode.Category ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('SeatCategory')} />
                  </span>
                  <span className="description">Category Mode</span>
                  <p>Group your seats into seperate categories & colours.</p>
                </button>
                <button
                  onClick={() => {
                    enterOffsetMode();
                    setModeOpen(false);
                  }}
                  className={`${editMode == ViewMode.Offset ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('Offset')} />
                  </span>
                  <span className="description">Offset Mode</span>
                  <p>Change the offset of seats, use this to give seats a curved effect.</p>
                </button>
                <button
                  onClick={() => {
                    enterBackgroundMode();
                    setModeOpen(false);
                  }}
                  className={`${editMode == ViewMode.Background ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('Image')} />
                  </span>
                  <span className="description">Background Mode</span>
                  <p>Add an SVG background to your seatingplan.</p>
                </button>
                <button
                  onClick={() => {
                    enterPreviewMode();
                    setModeOpen(false);
                  }}
                  className={`${editMode == ViewMode.Attendee ? 'selected' : ''}`}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('Preview')} />
                  </span>
                  <span className="description">Preview Mode</span>
                  <p>View the seating plan as an attendee would.</p>
                </button>
                <button
                  onClick={() => {
                    setFindSeatingPlan(true);
                    enterCategoryMode();
                    setModeOpen(false);
                  }}
                >
                  <span className="dropdown-option-icon">
                    <img src={SVGHelper.get('SeatingPlanSearch')} />
                  </span>
                  <span className="description">Find Existing</span>
                  <p>Copy a seating plan from an existing event.</p>
                </button>
                {!props.hasOrders && (
                  <button onClick={createNew}>
                    <span className="dropdown-option-icon">
                      <img src={SVGHelper.get('SeatingPlanAdd')} />
                    </span>
                    <span className="description">Create New</span>
                    <p>Clear the seating plan and start again.</p>
                  </button>
                )}
              </div>
            </div>

            {editMode == ViewMode.Design && editOptions}
            {editMode == ViewMode.Label && labelOptions}
            {editMode == ViewMode.Offset && offsetOptions}
            {editMode == ViewMode.Background && backgroundOptions}
            {editMode == ViewMode.Category && categoryOptions}
          </div>
        </Toolbar>

        <div className="seating-plan-container" style={{ padding: '0', background: 'white' }}>
          <div className="canvas">
            <div
              style={{ textAlign: 'center' }}
              className={'seating-plan-svg-content seating-plan-editor mode-' + editMode}
            >
              {showSeatingPlan && (
                <SeatingPlan
                  onSeatDrag={(e) => {
                    let selectedSeats = getSelectedSeats();
                    if (!e.seat.Selected && selectedSeats.length == 0) {
                      e.seat.Selected = true;
                      selectedSeats.push(e.seat);
                    } else if (!e.seat.Selected && selectedSeats.length == 1) {
                      selectedSeats[0].Selected = false;
                      selectedSeats = [e.seat];
                      e.seat.Selected = true;
                    } else if (!e.seat.Selected) {
                      return;
                    }

                    // e.seat.OffsetX = e.dragOffsetX + e.dragEndX * alterOffsetAmount;
                    // e.seat.OffsetY = e.dragOffsetY + e.dragEndY * alterOffsetAmount;
                    const alterAmount = e.zoomLevel * 56.0;

                    selectedSeats.forEach((s) => {
                      s.OffsetX = parseFloat((s.OffsetX + e.movementX / alterAmount).toFixed(2));
                      s.OffsetY = parseFloat((s.OffsetY + e.movementY / alterAmount).toFixed(2));
                    });

                    // e.seat.OffsetX = parseFloat((e.seat.OffsetX + e.movementX / alterAmount).toFixed(2));
                    // e.seat.OffsetY = parseFloat((e.seat.OffsetY + e.movementY / alterAmount).toFixed(2));

                    // seatingPlan.Seats.forEach((s) => (s.Selected = false));
                    // e.seat.Selected = true;
                    changeMade();
                  }}
                  dragToSelectByDefault={true}
                  fullScreenClick={() => {
                    setFullScreen(!fullScreen);
                    setShowSeatingPlan(false);
                    window.setTimeout(() => {
                      setShowSeatingPlan(true);
                    }, 20);
                  }}
                  moveSeatsLeft={moveSeatsLeft}
                  moveSeatsRight={moveSeatsRight}
                  moveSeatsUp={moveSeatsUp}
                  moveSeatsDown={moveSeatsDown}
                  containerHeight={
                    containerRef && containerRef.current && (containerRef.current as HTMLDivElement).clientHeight - 130
                  }
                  containerWidth={
                    containerRef && containerRef.current && (containerRef.current as HTMLDivElement).clientWidth
                  }
                  handleObjectClick={onObjectClick}
                  onSeatClick={onSeatClick}
                  onPlaceholderClick={onPlaceholderClick}
                  onSelection={onSelection}
                  seatingPlan={seatingPlan}
                  viewMode={editMode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeatingPlanSection;

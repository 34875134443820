import './FAQ.scss';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import SideBar from './Sidebar';
import AdminApi from '../../api/AdminApi';
import Loader from '../../components/Loader';
import CacheHelper from '../../helpers/CacheHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import Switch from '../../components/Switch';

interface IEmailPreferences {
  OrganisationTag: string;
  OrganisationBlocked: boolean;
  SeatyBlocked: boolean;
  OrganisationMarketingBlocked: boolean;
  OrganisationSurveyBlocked: boolean;
  Email: string;
}

interface IProps {}

const queryParams = new URLSearchParams(window.location.search);
const key: string = queryParams.get('key');
const org: string = queryParams.get('org');

const EmailPreferencesPage: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IEmailPreferences>();
  const [organisation, setOrganisation] = useState<IOrganisation>();

  const getData = async () => {
    setLoading(true);
    AdminApi.request('GET', `/api/EmailPreferences?key=${key}&org=${org}`)
      .then((result: IEmailPreferences) => {
        setData(result);

        return CacheHelper.organisationByTag(result.OrganisationTag).then((org) => {
          setOrganisation(org);
          setLoading(false);
        });
      })
      .catch((message) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Seaty - Manage Preferences</title>
        <meta name="description" content={`Seaty.co.uk FAQ.`} />
      </Helmet>
      <div className="docs faq-page">
        <SideBar />

        <div className="content">
          <article>
            {loading ? (
              <Loader inline>Loading...</Loader>
            ) : (
              organisation && (
                <div>
                  <header>
                    <h1>Email Preferences</h1>
                  </header>
                  <h3>{organisation.Name}</h3>
                  {data?.Email}

                  <div style={{ margin: '20px 0' }}>
                    <p className="label" style={{ display: 'block', textAlign: 'left', marginBottom: '2px' }}>
                      Do you want to allow emails about updates/changes to events you have tickets to from this
                      organisation?
                    </p>
                    <Switch
                      trueLabel="Yes, allow updates / changes emails"
                      falseLabel="No, block updates / changes emails"
                      onChange={(value) => {
                        setLoading(true);
                        AdminApi.request('POST', `/api/EmailPreferences`, {
                          key: key,
                          org: org,
                          category: 1,
                          blocked: !value,
                        })
                          .then(() => {
                            getData();
                          })
                          .catch((message) => {
                            alert('There was an error in saving new state. Please refresh the page.');
                          });
                      }}
                      checked={!data.OrganisationBlocked}
                    />
                  </div>
                  <div style={{ margin: '20px 0' }}>
                    <p className="label">
                      Do you want to allow emails about news and marketing from this organisation?
                    </p>
                    <Switch
                      trueLabel="Yes, allow news / marketing emails"
                      falseLabel="No, block news / marketing emails"
                      onChange={(value) => {
                        setLoading(true);
                        AdminApi.request('POST', `/api/EmailPreferences`, {
                          key: key,
                          org: org,
                          category: 2,
                          blocked: !value,
                        })
                          .then(() => {
                            getData();
                          })
                          .catch((message) => {
                            alert('There was an error in saving new state. Please refresh the page.');
                          });
                      }}
                      checked={!data.OrganisationMarketingBlocked}
                    />
                  </div>
                  <div style={{ margin: '20px 0' }}>
                    <p className="label">
                      Do you want to allow emails asking you to take a survey from this organisation?
                    </p>
                    <Switch
                      trueLabel="Yes, allow survey emails"
                      falseLabel="No, block survey emails"
                      onChange={(value) => {
                        setLoading(true);
                        AdminApi.request('POST', `/api/EmailPreferences`, {
                          key: key,
                          org: org,
                          category: 3,
                          blocked: !value,
                        })
                          .then(() => {
                            getData();
                          })
                          .catch((message) => {
                            alert('There was an error in saving new state. Please refresh the page.');
                          });
                      }}
                      checked={!data.OrganisationSurveyBlocked}
                    />
                  </div>
                </div>
              )
            )}
          </article>
        </div>
      </div>
    </>
  );
};

export default EmailPreferencesPage;

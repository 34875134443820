import { FunctionComponent, useEffect, useState } from 'react';
import GeneralApi from '../api/GeneralApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import BlockTextBox from '../components/BlockTextBox';
import Button from '../components/Button';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import SolidLine from '../components/SolidLine';
import SpacerTable from '../components/SpacerTable';
import EmailHelper from '../helpers/EmailHelper';
import GAHelper from '../helpers/GAHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import UserHelper from '../helpers/UserHelper';
import Cookies from 'js-cookie';

export interface IProps {
  onClose: () => void;
}

const SignInModal = ((props) => {
  const [authCode, setAuthCode] = useState('');
  const [awaitingCode, setAwaitingCode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [busyMessage, setBusyMessage] = useState(null);
  const [signInComplete, setSignInComplete] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPasswordResetInfo, setShowPasswordResetInfo] = useState(false);

  const signInDisabled: boolean = busyMessage != null || !email || email.length < 4 || !password || password.length < 6;

  useEffect(() => {
    GAHelper.modal(`Sign In`);
  }, []);

  const submitAuthCode = () => {
    if (!authCode || authCode.length != 6) {
      setError('Auth code is invalid.');
      return;
    }

    if (signInDisabled) return;

    setError(null);
    setShowPasswordResetInfo(false);
    setBusyMessage('Signing you in...');

    UserHelper.signIn(email, password, authCode)
      .then((result) => {
        if (result.Valid) {
          setSignInComplete(true);
          setBusyMessage('Welcome back!');
          Cookies.remove('last-managed-organisation');
          window.location.reload();
        } else {
          setBusyMessage(null);
          setError(result.Message);
        }
      })
      .catch((message) => {
        setError(message);
        setBusyMessage(null);
      });
  };

  const requestAuthCode = () => {
    if (signInDisabled) return;

    setError(null);
    setShowPasswordResetInfo(false);
    setBusyMessage('Checking credentials...');

    UserHelper.requestSignInAuthCode(email, password)
      .then((result) => {
        setBusyMessage(null);

        if (result.Valid) {
          setAwaitingCode(true);
        } else {
          setPassword(null);
          setError(result.Message);
        }
      })
      .catch((message) => {
        setError(message);
        setBusyMessage(null);
      });
  };

  const recoverPassword = () => {
    setError(null);
    setShowPasswordResetInfo(false);
    setBusyMessage('Recovering password...');

    GeneralApi.request('GET', `/api/RecoverPassword?email=${email}`)
      .then((value) => {
        setBusyMessage(null);
        setShowPasswordResetInfo(true);
      })
      .catch((message) => {
        setError(message);
        setBusyMessage(null);
      });
  };

  return (
    <>
      <Modal onCloseClick={props.onClose}>
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            {busyMessage ? (
              <Loader inline={true}>{busyMessage}</Loader>
            ) : (
              <>
                <table className="blocks">
                  <tbody>
                    {signInComplete ? (
                      <BlockInfo type={InfoType.Info}> All done! Refreshing page...</BlockInfo>
                    ) : (
                      <>
                        {awaitingCode ? (
                          <>
                            <Block>
                              <BlockHeader>Security code</BlockHeader>
                              <BlockTextBox
                                name={'authcode'}
                                id="user-authcode"
                                onKeyPress={(e) => {
                                  if (e.charCode == 13) submitAuthCode();
                                }}
                                type="text"
                                autoFocus={true}
                                value={authCode}
                                onChange={(e) => setAuthCode(e.toUpperCase().trim())}
                              />
                            </Block>
                            <BlockInfo>
                              We have emailed you a security code. Please enter it below to confirm your email address
                              and sign in to Seaty.
                            </BlockInfo>
                          </>
                        ) : showPasswordResetInfo ? null : (
                          <Block>
                            <BlockHeader>Email</BlockHeader>
                            <BlockTextBox
                              name={'email'}
                              id="user-email"
                              onKeyPress={(e) => {
                                if (forgotPassword) {
                                  if (e.charCode == 13 && EmailHelper.validate(email)) recoverPassword();
                                } else {
                                  if (e.charCode == 13) (document.querySelector('#password_field') as any).select();
                                }
                              }}
                              type="email"
                              autoFocus
                              value={email}
                              onChange={(e) => setEmail(e)}
                            />

                            {!forgotPassword && (
                              <>
                                <div className="spacer" />
                                <BlockHeader>Password</BlockHeader>
                                <BlockTextBox
                                  name={'password'}
                                  id="password_field"
                                  onKeyPress={(e) => {
                                    if (e.charCode == 13) requestAuthCode();
                                  }}
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e)}
                                />
                              </>
                            )}
                          </Block>
                        )}

                        {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                        {showPasswordResetInfo && (
                          <BlockInfo type={InfoType.Info}>
                            If you have an account with Seaty a link to reset your password has been emailed to you.
                          </BlockInfo>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <div className="spacer-x2" />

                {awaitingCode ? (
                  <SpacerTable>
                    <Button
                      disabled={!authCode || authCode.length != 6}
                      className="confirm large"
                      onClick={submitAuthCode}
                      text={`Submit code`}
                    />
                    <Button
                      onClick={() => {
                        setAwaitingCode(false);
                        setAuthCode('');
                        setPassword('');
                      }}
                      text={`Cancel sign in`}
                    />
                  </SpacerTable>
                ) : signInComplete ? (
                  <SpacerTable>
                    <Button className="large" onClick={props.onClose} text={`Close`} />
                  </SpacerTable>
                ) : forgotPassword ? (
                  <SpacerTable>
                    {!showPasswordResetInfo && (
                      <Button
                        disabled={!EmailHelper.validate(email)}
                        className="confirm large"
                        onClick={recoverPassword}
                        text={`Recover password`}
                      />
                    )}
                    <Button
                      onClick={() => {
                        setForgotPassword(false);
                        setShowPasswordResetInfo(false);
                      }}
                      text={`Back to sign in`}
                    />
                  </SpacerTable>
                ) : (
                  <SpacerTable>
                    <Button
                      disabled={signInDisabled}
                      className="confirm large"
                      onClick={requestAuthCode}
                      text={`Sign in`}
                    />
                    <Button
                      onClick={() => {
                        setError(null);
                        setForgotPassword(true);
                        (document.getElementById('user-email') as HTMLInputElement).select();
                      }}
                      text={`Forgot password`}
                    />
                  </SpacerTable>
                )}
              </>
            )}
            <SolidLine />

            <SpacerTable className="small-font">
              All accounts are subject to the Seaty{' '}
              <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
              <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
}) as FunctionComponent<IProps>;

export { SignInModal };

import React, { useEffect, useRef, useState } from 'react';

import ReactQuill from 'react-quill';
import InfoLabel from './InfoLabel';
import EventHelper from '../helpers/EventHelper';

export interface IProps {
  onComplete: (prompt: string) => any;
  request: (prompt: string) => Promise<any>;
  onCancel: () => any;
  question: string;
  tooltip: string;
}

const AIPrompt: React.FC<React.PropsWithChildren<IProps>> = (props: IProps) => {
  const quillRef = useRef(null);

  useEffect(() => {
    // Ensure the editor is loaded
    if (quillRef.current) {
      // getEditor() returns the underlying Quill instance
      quillRef.current.getEditor().focus();
    }
  }, []);

  const [prompt, setPrompt] = useState('');
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      <div className="ai-prompt">
        <div className="ai-prompt__content">
          <InfoLabel text={props.question} tooltip={props.tooltip} />
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={prompt}
            onChange={(v, e, b) => {
              if (EventHelper.getRichTextCharLength(v) > 1000) return;

              setPrompt(v);
            }}
            modules={{
              toolbar: [[{ list: 'ordered' }, { list: 'bullet' }]],
            }}
          />
          <div className="ai-prompt__footer admin-buttons">
            <button
              disabled={generating}
              className="admin-button"
              onClick={() => {
                setGenerating(true);
                props
                  .request(prompt)
                  .then(() => {
                    setGenerating(false);
                    props.onComplete(prompt);
                  })
                  .catch(() => {
                    setError(
                      'There was a problem when generating the response. Please try again with a different prompt.',
                    );
                    setGenerating(false);
                  });
              }}
            >
              {generating ? `Asking your question...` : `🤖 Confirm`}
            </button>
            <button disabled={generating} className="admin-button" onClick={() => props.onCancel()}>
              Cancel
            </button>
          </div>

          {error && <div className="info error bad">{error}</div>}
        </div>
      </div>
    </>
  );
};

export default AIPrompt;

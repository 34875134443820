import React, { useEffect, useState } from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import GuidHelper from '../../../helpers/GuidHelper';
import SVGHelper from '../../../helpers/SVGHelper';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';
import './MapSection.scss';
import { IThreeWordAddress } from './MapSection';

enum ThreeWordAddressMode {
  Edit = 2,
  View = 1,
}

export interface IProps {
  threeWordAddress: IThreeWordAddress;
  onEnterViewMode: () => void;
  onEnterEditMode: (guid) => void;
  onChange: (threeWordAddress: IThreeWordAddress) => void;
  onDeleteClick: (threeWordAddress: IThreeWordAddress) => void;
}

const ThreeWordAddressListItem: React.FC<IProps> = (props) => {
  const [mode, setMode] = useState(props.threeWordAddress.New ? ThreeWordAddressMode.Edit : ThreeWordAddressMode.View);
  const [name, setName] = useState(props.threeWordAddress.Name);
  const [description, setDescription] = useState(props.threeWordAddress.Description);
  const [address, setAddress] = useState(props.threeWordAddress.Address);
  const [imageUrl, setImageUrl] = useState(
    props.threeWordAddress.IconUrl == null || props.threeWordAddress.IconUrl.length == 0
      ? 'MapPin.svg'
      : props.threeWordAddress.IconUrl,
  );
  const [error, setError] = useState(null);

  const handleCancelClick = function () {
    if (props.threeWordAddress.New) {
      if (props.onDeleteClick) {
        props.onDeleteClick(props.threeWordAddress);
      }
    } else {
      enterViewMode();
    }
  };

  const handleDeleteClick = function () {
    if (props.onDeleteClick) {
      props.onDeleteClick(props.threeWordAddress);
    }
  };

  const handleAddressChange = function (e) {
    setAddress(e);
  };

  const handleNameChange = function (e) {
    setName(e);
  };

  const handleDescriptionChange = function (e) {
    setDescription(e);
  };

  const handleToolbarButtonClick = function (e) {
    var element: HTMLElement = e.target;

    var designMode = element.dataset.designmode;

    while (designMode == null) {
      designMode = element.parentElement.dataset.designmode;
    }

    setImageUrl(designMode);
  };

  const enterViewMode = function () {
    setMode(ThreeWordAddressMode.View);

    if (props.onEnterViewMode) {
      props.onEnterViewMode();
    }
  };

  const enterEditMode = function () {
    setMode(ThreeWordAddressMode.Edit);

    if (props.onEnterEditMode) {
      props.onEnterEditMode(props.threeWordAddress.Guid);
    }
  };

  const handleConfirmClick = function () {
    setError('');

    fetch(`https://api.what3words.com/v3/convert-to-coordinates?words=${address.replace('///', '')}&key=NS0TGYKM`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {},
    })
      .then((response) => {
        return response.json();
      })
      .then((response: any) => {
        if (!response.words) {
          setError('This is not a valid three word address.');
        } else {
          props.threeWordAddress.Address = response.words; // e.g. "shark.flasks.shimmered"
          props.threeWordAddress.Name = name;
          props.threeWordAddress.Description = description;
          props.threeWordAddress.IconUrl = imageUrl;
          props.threeWordAddress.New = false;
          props.threeWordAddress.Lat = response.coordinates.lat;
          props.threeWordAddress.Lng = response.coordinates.lng;

          props.onChange(props.threeWordAddress);
          enterViewMode();
        }
      })
      .catch(() => setError('Error: This is not a valid three word address.'));
  };

  const onViewItemClick = function () {
    enterEditMode();
  };

  const renderViewMode = function () {
    console.log(props.threeWordAddress);
    return (
      <a className="field img" onClick={onViewItemClick} style={{ color: 'black' }}>
        <img src={SVGHelper.get(props.threeWordAddress.IconUrl)}></img>
        <div>
          <strong>{props.threeWordAddress.Name}</strong>
        </div>
        <div>{props.threeWordAddress.Description}</div>
        <div>{props.threeWordAddress.Address}</div>
      </a>
    );
  };

  const findAddress = function () {
    var win = window.open('https://map.what3words.com/', '_blank');
    win.focus();
  };

  const renderEditMode = function () {
    return (
      <div style={{ paddingTop: '4px' }}>
        <button onClick={findAddress} className="admin-button">
          Find a three word address
        </button>

        <div className="field element">
          <InfoInput maxLength={100} rows={1} labelText="What.3.Words" onChange={handleAddressChange} value={address} />
          <InfoInput maxLength={200} rows={1} labelText="Name" onChange={handleNameChange} value={name} />
          <InfoInput
            maxLength={200}
            rows={2}
            labelText="Description"
            onChange={handleDescriptionChange}
            value={description}
          />

          <div className="design-toolbar">
            <span
              data-designmode="MapPin.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-seats ' + (imageUrl == 'MapPin.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin')} className="icon" />
              <span className="description">Marker</span>
            </span>
            <span
              data-designmode="MapPin_Parking.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-stage ' + (imageUrl == 'MapPin_Parking.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Parking')} className="icon" />
              <span className="description">Parking</span>
            </span>
            <span
              data-designmode="MapPin_BoxOffice.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-stage ' + (imageUrl == 'MapPin_BoxOffice.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_BoxOffice')} className="icon" />
              <span className="description">Box Office</span>
            </span>
            <span
              data-designmode="MapPin_Entry.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-stage ' + (imageUrl == 'MapPin_Entry.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Entry')} className="icon" />
              <span className="description">Entrance</span>
            </span>
            <span
              data-designmode="MapPin_Exit.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Exit.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Exit')} className="icon" />
              <span className="description">Exit</span>
            </span>
            <span
              data-designmode="MapPin_Bar.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Bar.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Bar')} className="icon" />
              <span className="description">Bar</span>
            </span>
            <span
              data-designmode="MapPin_Food.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Food.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Food')} className="icon" />
              <span className="description">Food</span>
            </span>
            <span
              data-designmode="MapPin_IceCream.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_IceCream.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_IceCream')} className="icon" />
              <span className="description">Ice Cream</span>
            </span>
            <span
              data-designmode="MapPin_Info.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Info.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_Info')} className="icon" />
              <span className="description">Info</span>
            </span>
            <span
              data-designmode="MapPin_RedCross.svg"
              onClick={handleToolbarButtonClick}
              className={'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_RedCross.svg' ? 'selected' : '')}
            >
              <img src={SVGHelper.get('MapPin_RedCross')} className="icon" />
              <span className="description">Red Cross</span>
            </span>
            <span
              data-designmode="MapPin_Restaurant.svg"
              onClick={handleToolbarButtonClick}
              className={
                'toolbar-button designmode-tablearea ' + (imageUrl == 'MapPin_Restaurant.svg' ? 'selected' : '')
              }
            >
              <img src={SVGHelper.get('MapPin_Restaurant')} className="icon" />
              <span className="description">Restaurant</span>
            </span>
          </div>

          <div style={{ color: 'red', display: error ? 'block' : 'none' }}>{error}</div>
          <div className="row">
            <div className="col-sm-4">
              <button className="admin-button confirm" style={{ marginBottom: '0' }} onClick={handleConfirmClick}>
                Confirm
              </button>
            </div>
            <div className="col-sm-4">
              <button className="admin-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
            <div className="col-sm-4">
              <button
                style={{ marginBottom: '0', display: !props.threeWordAddress.New ? 'block' : 'none' }}
                className="admin-button bad"
                onClick={handleDeleteClick}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (mode == ThreeWordAddressMode.Edit) {
    return renderEditMode();
  } else {
    return renderViewMode();
  }
};

export default ThreeWordAddressListItem;

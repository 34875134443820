import React from 'react';
import { Link } from 'react-router-dom';

export interface IProps {
  onClick?(): void;
  className?: string;
  blockRowClassName?: string;
  background?: string;
  to?: string;
  id?: string;
}

const Block: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  let children: Array<React.ReactElement<any>> = typeof (props.children === 'object')
    ? (props.children as Array<React.ReactElement<any>>)
    : [];

  const content = (
    <table>
      <tbody className="block-body">
        <tr>
          <td className="spacer"></td>
          {children && children.length > 1 && children[0] != null && children[0].type === 'td' ? (
            props.children
          ) : (
            <td>{props.children}</td>
          )}
          <td className="spacer"></td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <tr className={`block-row${props.blockRowClassName ? ' ' + props.blockRowClassName : ''}`}>
      <td className="spacer"></td>
      <td
        id={props.id}
        style={{
          backgroundColor: props.background,
          cursor: props.onClick ? 'pointer' : null,
          textShadow: props.background ? '#fff 1px 1px 1px' : null,
        }}
        className={'block' + (props.className ? ' ' + props.className : '')}
        onClick={props.onClick}
      >
        {props.to ? <Link to={props.to}>{content}</Link> : content}
      </td>
      <td className="spacer"></td>
    </tr>
  );
};

export default Block;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import orderState from '../../atoms/orderState';
import Block from '../../components/Block';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import SeatingPlan from '../../components/seating_plan_svg/SeatingPlan';
import SpacerTable from '../../components/SpacerTable';
import { IEvent } from '../../interfaces/IEvent';
import { ISeatingPlanSeat } from '../../interfaces/ISeatingPlanSeat';
import './EventSeatingPlan.scss';
import { ISeatingPlan } from '../../interfaces/ISeatingPlan';
import { ViewMode } from '../Editor/event/EventEditorPage';
import { ITicketCategory } from '../../interfaces/ITicketCategory';

export interface IProps {
  event: IEvent;
  disabled?: boolean;
  onSelectedSeatsChange: (seats: Array<ISeatingPlanSeat>) => void;
  showOrders?: boolean;
  isAdmin: boolean;
  eventDateString: string;
  setShowPriceList: (value) => void;
  toolbarButtons?: any;
  seatingPlan: ISeatingPlan;
  onOrderModalClosed: () => void;
  prices?: ITicketCategory[][];
  verticalPadding?: number;
}

const EventSeatingPlan: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const [, setOrder] = useRecoilState(orderState);
  const [showPillarInfo, setShowPillarInfo] = useState(false);
  const navigate = useNavigate();
  const isAdmin = props.isAdmin;
  const seatingPlan = props.seatingPlan;

  return (
    <>
      <div className={`event-seating-plan seating-plan-svg-content${isAdmin ? ' is-admin' : ''}`}>
        {props.children}
        <SeatingPlan
          verticalPadding={props.verticalPadding}
          prices={props.prices}
          handleObjectClick={(o) => {
            if (o.designMode === 'pillar') {
              setShowPillarInfo(true);
            }
          }}
          toolbarButtons={props.toolbarButtons}
          onPricesClick={() => props.setShowPriceList(true)}
          onInfoClick={() => navigate('/' + props.event.EventTag)}
          onNothingSelected={() => {
            seatingPlan.Seats.forEach((seat) => (seat.Selected = false));
            props.onSelectedSeatsChange(seatingPlan.Seats.filter((s) => s.Selected));
          }}
          onSeatsSelected={
            !isAdmin
              ? null
              : (seats: ISeatingPlanSeat[]) => {
                  const availableSeats = seats.filter(
                    (seat) => !(seat.IsOrdered || seat.IsPurchased || seat.IsRequested),
                  );
                  if (availableSeats.filter((s) => !s.Selected).length > 0) {
                    availableSeats.forEach((seat) => (seat.Selected = true));
                  } else {
                    availableSeats.forEach((seat) => (seat.Selected = false));
                  }

                  props.onSelectedSeatsChange(seatingPlan.Seats.filter((s) => s.Selected));
                }
          }
          showOrders={props.showOrders}
          disabled={props.disabled}
          onSeatClick={(seat: ISeatingPlanSeat) => {
            if (props.showOrders && (seat.IsOrdered || seat.IsPurchased || seat.IsRequested)) {
              if (isAdmin && seat.IsOrdered) {
                setOrder({ orderId: seat.OrderId, event: props.event, onClose: props.onOrderModalClosed });
              }
            } else {
              seat.Selected = !seat.Selected;
              props.onSelectedSeatsChange(seatingPlan.Seats.filter((s) => s.Selected));
            }
          }}
          seatingPlan={seatingPlan}
          viewMode={ViewMode.Attendee}
        />
      </div>

      {showPillarInfo && (
        <Modal onCloseClick={() => setShowPillarInfo(false)}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              <SpacerTable>
                <h1>Obstructed View Due to Pillar</h1>
              </SpacerTable>

              <div className="spacer" />

              <table className="blocks">
                <tbody>
                  <Block>
                    This pillar may cause an obstructed view of the stage for surrounding seats. Please consider this
                    when booking your tickets.
                  </Block>
                </tbody>
              </table>

              <div className="spacer-x2" />

              <SpacerTable>
                <Button
                  className="confirm"
                  onClick={() => {
                    setShowPillarInfo(false);
                  }}
                  text="Close"
                />
              </SpacerTable>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      )}
    </>
  );
};

export default EventSeatingPlan;

import React from 'react';

export interface IProps {
  checked: boolean;
  onBoxClick?: () => void;
  onClick?: () => void;
  title?: string;
  className?: string;
  rightText?: string;
  fullClick?: boolean;
  id?: string;
}

const CheckBox: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  return (
    <div
      id={props.id}
      className={'checkbox' + (props.checked ? ' checked' : '') + (props.fullClick ? ' full-click' : '')}
    >
      <table>
        <tbody>
          <tr>
            <td
              className="check"
              onClick={(e) => {
                e.stopPropagation();
                if (props.onBoxClick) {
                  props.onBoxClick();
                }
              }}
            >
              <div
                className="border"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.charCode === 13 || e.charCode === 32) {
                    props.onBoxClick && props.onBoxClick();
                  }
                }}
              >
                <div className="checkbox-background">
                  <span className="tick"></span>
                </div>
              </div>
            </td>
            <td
              className="checkbox__content"
              onClick={(e) => {
                if (props.fullClick) {
                  e.stopPropagation();
                  if (props.onBoxClick) {
                    props.onBoxClick();
                  }
                }
              }}
            >
              {props.children}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CheckBox;

import { FunctionComponent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderUser from '../../components/HeaderUser';
import SVGSeaty from '../../svg/SVGSeaty';
import SVGSeatyFace from '../../svg/SVGSeatyFace';
import './AdminHeader.scss';
import AdminSections, { AdminSection } from './AdminSections';
import UserHelper from '../../helpers/UserHelper';
import StringHelper from '../../helpers/StringHelper';
import EventHelper from '../../helpers/EventHelper';
import { useRecoilState } from 'recoil';
import wizardOrganisationState from '../../atoms/wizardOrganisationState';

export interface IProps {
  section: AdminSection;
}

const UserHeader: FunctionComponent<IProps> = (props) => {
  const [open, setOpen] = useState(false);
  const user = UserHelper.currentUser;
  const [wizardOrganisation, setWizardOrganisation] = useRecoilState(wizardOrganisationState);
  const navigate = useNavigate();

  const buttons = (
    <>
      <div className="admin-button-menu">
        <div className="boundry">
          {AdminSections.getUserSections()
            .filter((e) => e.userCondition == null || (user && e.userCondition(user)))
            .map((s) => (
              <Link
                id={StringHelper.toCamelCase(s.section)}
                onClick={() => setOpen(false)}
                key={s.section}
                className={props.section == s.section ? 'selected' : ''}
                to={`/User/${s.section}`}
              >
                {s.section}
              </Link>
            ))}
          <button id="eventsButton" onClick={() => EventHelper.manageEvents(navigate)}>
            Events
          </button>
          <button
            name="createEventButton"
            className="menu-button hide-when-transparent"
            onClick={() => {
              setWizardOrganisation({ onClose: () => setWizardOrganisation(null) });
            }}
          >
            Create Event
          </button>
        </div>
      </div>
    </>
  );

  return (
    <div className={`admin-header donotprint${open ? ' open' : ''}`}>
      <div className="admin-header-navigator">
        <div className="boundry">
          <button className="admin-header-button burger" onClick={() => setOpen(!open)}>
            <div className={`hamburger hamburger--squeeze js-hamburger${open ? ' is-active' : ''}`}>
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </button>

          <Link to={'/'} className="admin-header-button seaty-icon">
            <SVGSeatyFace />
          </Link>
          <Link to={'/'} className="admin-header-button seaty-logo">
            <SVGSeaty />
          </Link>

          {open && (
            <div className={`admin-options-container`}>
              <div className="admin-options">{buttons}</div>
              <div className="click-zone" onClick={() => setOpen(false)}></div>
            </div>
          )}

          <div className="mount-right">
            <HeaderUser className="hide-on-small" />
          </div>
        </div>
      </div>
      {buttons}
    </div>
  );
};

export default UserHeader;

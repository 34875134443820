import Cookies from 'js-cookie';
import linq from 'linq';
import moment from 'moment';
import { FunctionComponent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import eventMenuState from '../../atoms/eventMenuState';
import organisationMenuState from '../../atoms/organisationMenuState';
import tourMenuState from '../../atoms/tourMenuState';
import wizardEventState from '../../atoms/wizardEventState';
import wizardOrganisationState from '../../atoms/wizardOrganisationState';
import wizardTourState from '../../atoms/wizardTourState';
import HeaderUser from '../../components/HeaderUser';
import NumberHelper from '../../helpers/NumberHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import { IOrganisation } from '../../interfaces/IOrganisation';
import { ITour } from '../../interfaces/ITour';
import SVGPlus from '../../svg/SVGPlus';
import SVGSeaty from '../../svg/SVGSeaty';
import SVGSeatyFace from '../../svg/SVGSeatyFace';
import './AdminHeader.scss';
import AdminMenuDropdown from './AdminMenuDropdown';
import AdminSections, { AdminSection } from './AdminSections';
import UserHelper from '../../helpers/UserHelper';
import StringHelper from '../../helpers/StringHelper';

export interface IProps {
  cachedOrganisation: IOrganisation;
  cachedTour: ITour;
  cachedEvent: IEvent;
  organisations: IOrganisation[];
  organisationTag: string;
  tourTag: string;
  eventTag: string;
  section: AdminSection;
  date: IEventDate;
  loadEventDate?: (event: IEvent, eventDateId: number, updateUrl: boolean) => void;
}

const AdminHeader: FunctionComponent<IProps> = (props) => {
  const navigate = useNavigate();
  const [, setEventWizardOptions] = useRecoilState(wizardEventState);
  const [, setOrganisationWizardOptions] = useRecoilState(wizardOrganisationState);
  const [, setTourWizardOptions] = useRecoilState(wizardTourState);
  const [, setTourMenu] = useRecoilState(tourMenuState);
  const [, setEventMenu] = useRecoilState(eventMenuState);
  const [, setOrganisationMenu] = useRecoilState(organisationMenuState);
  const [open, setOpen] = useState(false);
  const organisations = props.organisations;

  const { date, cachedEvent: event, cachedOrganisation, organisationTag, eventTag, tourTag, cachedTour } = props;

  const organisationListOrganisation =
    cachedOrganisation &&
    organisations &&
    organisations.length > 0 &&
    linq.from(organisations).firstOrDefault((o) => o.Id == cachedOrganisation.Id);

  const tours = organisationListOrganisation && organisationListOrganisation.Tours;

  const tourListTour =
    tours && cachedTour && tours.length > 0 && linq.from(tours).firstOrDefault((o) => o.Id == cachedTour.Id);

  const events = cachedTour ? cachedTour.Events : organisationListOrganisation && organisationListOrganisation.Events;

  const dates = event && event.Dates;

  const dateOptions =
    event != null &&
    dates != null &&
    linq
      .from(dates)
      .orderBy((d) => moment(d.Date))
      .select((d) => {
        return {
          id: d.Id,
          name: moment(d.Date).format('ddd DD MMM YYYY HH:mm') + (d.Suspended ? ' (Suspended)' : ''),
          searchTerms: [moment(d.Date).format('DD/MM/YYYY')],
        };
      });

  const currentEventDateIndex = event && date && dateOptions && dateOptions.indexOf((d) => d.id === date.Id);
  const navigateToEventDate = (d) => {
    const hasSection = AdminSections.getEventDateSections().filter((s) => s.section == props.section).length > 0;

    if (d && props.section == AdminSection.Tickets) {
      navigate(`/${event.EventTag}/tickets/${moment(d.Date).format('DDMMMYYYY/HHmm')}`);
      props.loadEventDate && props.loadEventDate(event, d.Id, false);
    } else if (date && d && hasSection) {
      navigate(
        `/Organisation/${cachedOrganisation.OrganisationTag}/Event/${event.EventTag}/Date/${moment(d.Date).format('DDMMMYYYY/HHmm')}/${props.section}`,
      );
    } else {
      navigate(`/${event.EventTag}/tickets/${moment(d.Date).format('DDMMMYYYY/HHmm')}`);
    }
  };

  const dropdowns = (
    <>
      <AdminMenuDropdown
        className="options-group organisation"
        title="Organisation"
        buttons={[
          {
            icon: <SVGPlus />,
            name: 'Create organisation',
            onClick: () => setOrganisationWizardOptions({ createNew: true, onClose: () => {} }),
          },
        ]}
        onValueChange={(v) => {
          const o = linq.from(organisations).firstOrDefault((o) => o.Id == v);

          Cookies.set('last-managed-organisation', o.OrganisationTag, { expires: 365, path: '/' });

          if (event || cachedTour || date) {
            navigate(`/Organisation/${o.OrganisationTag}/${AdminSection.Summary}`);
          } else {
            navigate(`/Organisation/${o.OrganisationTag}/${props.section}`);
          }
        }}
        value={cachedOrganisation && cachedOrganisation.Id}
        valueText={'#' + organisationTag}
        options={linq
          .from(organisations)
          .orderBy((o) =>
            NumberHelper.isNumeric(o.OrganisationTag.replace('#', '')) ? 'ZZZ' : o.OrganisationTag.toLowerCase(),
          )
          .select((o) => {
            return { id: o.Id, name: '#' + o.OrganisationTag };
          })
          .toArray()}
      />

      {tours && tours.length > 0 && (
        <AdminMenuDropdown
          className="options-group tour"
          title="Tour"
          placeholder="None selected"
          onValueChange={(v) => {
            const t = linq.from(tours).firstOrDefault((t) => t.Id == v);

            if (t) {
              if (!cachedTour) {
                navigate(
                  `/Organisation/${cachedOrganisation.OrganisationTag}/Tour/${t.TourTag}/${AdminSection.Summary}`,
                );
              } else {
                navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/Tour/${t.TourTag}/${props.section}`);
              }
            } else {
              navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/${AdminSection.Summary}`);
            }
          }}
          buttons={[
            {
              icon: <SVGPlus />,
              name: 'Create tour',
              onClick: () => setTourWizardOptions({ organisation: cachedOrganisation, onClose: () => {} }),
            },
          ]}
          value={cachedTour ? cachedTour.Id : 0}
          valueText={tourTag ? '#' + tourTag : ''}
          onClear={
            cachedTour &&
            (() => {
              navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/${AdminSection.Summary}`);
            })
          }
          options={linq
            .from(tours)
            .orderBy((t) => t.TourTag)
            .select((t) => {
              return { id: t.Id, name: '#' + t.TourTag };
            })
            .toArray()}
        />
      )}

      <AdminMenuDropdown
        className="options-group event"
        title="Event"
        placeholder="None selected"
        onValueChange={(v) => {
          const e = linq.from(events).firstOrDefault((e) => e.Id == v);
          const hasSection = AdminSections.getEventSections().filter((s) => s.section == props.section).length > 0;

          if (e && (!event || date)) {
            navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/Event/${e.EventTag}/${AdminSection.Summary}`);
          } else if (e && hasSection) {
            navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/Event/${e.EventTag}/${props.section}`);
          } else {
            navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/${AdminSection.Summary}`);
          }
        }}
        value={event ? event.Id : 0}
        valueText={eventTag ? '#' + eventTag : ''}
        onClear={
          event &&
          (() => {
            if (cachedTour) {
              navigate(
                `/Organisation/${cachedOrganisation.OrganisationTag}/Tour/${cachedTour.TourTag}/${AdminSection.Summary}`,
              );
            } else {
              navigate(`/Organisation/${cachedOrganisation.OrganisationTag}/${AdminSection.Summary}`);
            }
          })
        }
        buttons={[
          {
            icon: <SVGPlus />,
            name: 'Create event',
            onClick: () =>
              setEventWizardOptions({ organisation: cachedOrganisation, tour: cachedTour, onClose: () => {} }),
          },
        ]}
        options={linq
          .from(events)
          .orderByDescending((e) => moment(e.MinDate).unix())
          .select((e: IEvent) => {
            let dates = '';
            if (e.MinDate == e.MaxDate) {
              dates = `${moment.utc(e.MinDate).format('ddd Do MMM YYYY')} at ${moment.utc(e.MinDate).format('h:mma')}`;
              // } else if (moment(e.MinDate).day() === moment(e.MaxDate).day() && moment(e.MinDate).month() === moment(e.MaxDate).month() && moment(e.MinDate).year() === moment(e.MaxDate).year()) {
              //   if (!e.Dates) {
              //     dates = 'No dates';
              //   } else if (e.Dates.length == 2) {
              //     dates = `${moment.utc(e.MinDate).format('Do MMM YYYY')}, ${moment.utc(e.MinDate).format('h:mma')}, ${moment.utc(e.MaxDate).format('h:mma')}`;
              //   } else {
              //     dates = `${moment.utc(e.MinDate).format('Do MMM YYYY')}, (${e.Dates.length} times)`;
              //   }
            } else if (
              moment(e.MinDate).month() === moment(e.MaxDate).month() &&
              moment(e.MinDate).year() === moment(e.MaxDate).year()
            ) {
              dates = `${moment.utc(e.MinDate).format('ddd Do')} - ${moment.utc(e.MaxDate).format('ddd Do MMM YYYY')}`;
            } else {
              dates = `${moment.utc(e.MinDate).format('ddd Do MMM YY')} - ${moment.utc(e.MaxDate).format('ddd Do MMM YY')}`;
            }

            return {
              id: e.Id,
              name: '#' + e.EventTag,
              child: (
                <>
                  <div className="subtext">{dates}</div>
                </>
              ),
            };
          })
          .toArray()}
      />

      {event && (
        <AdminMenuDropdown
          onPreviousClick={
            date &&
            !(
              event &&
              date &&
              date.Id &&
              dateOptions &&
              dateOptions.count &&
              dateOptions.count() > 0 &&
              dateOptions.last().Id === date.Id
            ) &&
            (() => {
              if (currentEventDateIndex == 0) return;

              var previousEventDateId = dateOptions.toArray()[currentEventDateIndex - 1].id;
              const d = linq.from(dates).firstOrDefault((d) => d.Id == previousEventDateId);
              navigateToEventDate(d);
            })
          }
          onNextClick={
            date &&
            !(
              event &&
              date &&
              date.Id &&
              dateOptions &&
              dateOptions.count &&
              dateOptions.count() > 0 &&
              dateOptions.last().Id === date.Id
            ) &&
            (() => {
              if (dateOptions.count() == currentEventDateIndex + 1) return;

              var nextEventDateId = dateOptions.toArray()[currentEventDateIndex + 1].id;
              const d = linq.from(dates).firstOrDefault((d) => d.Id == nextEventDateId);
              navigateToEventDate(d);
            })
          }
          buttons={[
            {
              icon: <SVGPlus />,
              name: 'Edit dates',
              onClick: () =>
                navigate(
                  `/Organisation/${cachedOrganisation.OrganisationTag}/Event/${event.EventTag}/Edit?section=Dates`,
                ),
            },
          ]}
          className="options-group event-dates mono"
          title="Date"
          placeholder="None selected"
          onValueChange={(v) => {
            const d = linq.from(dates).firstOrDefault((d) => d.Id == v);

            navigateToEventDate(d);
          }}
          value={date ? date.Id : 0}
          valueText={date ? moment(date.Date).format('ddd DD MMM YYYY HH:mm') : ''}
          onClear={
            date &&
            (() => {
              navigate(
                `/Organisation/${cachedOrganisation.OrganisationTag}/Event/${event.EventTag}/${AdminSection.Summary}`,
              );
            })
          }
          options={dateOptions && dateOptions.toArray()}
        />
      )}
    </>
  );

  const buttons = (
    <>
      {date ? (
        <>
          <div className="admin-button-menu">
            <div className="boundry">
              {AdminSections.getEventDateSections()
                .filter((e) => e.eventCondition == null || (event && e.eventCondition(event)))
                .map((s) => (
                  <Link
                    id={StringHelper.toCamelCase(s.section)}
                    onClick={() => setOpen(false)}
                    key={s.section}
                    className={props.section == s.section ? 'selected' : ''}
                    to={
                      s.section == AdminSection.Tickets
                        ? `/${event.EventTag}/tickets/${moment(date.Date).format('DDMMMYYYY/HHmm')}`
                        : `/Organisation/${organisationTag}/Event/${eventTag}/Date/${moment(date.Date).format('DDMMMYYYY/HHmm')}/${s.section}`
                    }
                  >
                    {s.section}
                  </Link>
                ))}

              <button
                id="moreButton"
                onClick={
                  event &&
                  (() => {
                    setOpen(false);
                    setEventMenu({ eventTag: event.EventTag, eventDate: date });
                  })
                }
              >
                More
              </button>
            </div>
          </div>
        </>
      ) : eventTag && organisationTag ? (
        <div className="admin-button-menu">
          <div className="boundry">
            {AdminSections.getEventSections()
              .filter((e) => e.eventCondition == null || (event && e.eventCondition(event)))
              .map((s) => (
                <Link
                  id={StringHelper.toCamelCase(s.section)}
                  onClick={() => setOpen(false)}
                  key={s.section}
                  className={props.section == s.section ? 'selected' : ''}
                  to={
                    s.section == AdminSection.Landing
                      ? `/${eventTag}`
                      : `/Organisation/${organisationTag}/Event/${eventTag}/${s.section}`
                  }
                >
                  {s.section}
                </Link>
              ))}

            <button
              id="moreButton"
              onClick={
                event &&
                (() => {
                  setOpen(false);
                  setEventMenu({ eventTag: event.EventTag });
                })
              }
            >
              More
            </button>
          </div>
        </div>
      ) : tourTag && organisationTag ? (
        <div className="admin-button-menu">
          <div className="boundry">
            {AdminSections.getTourSections()
              .filter((e) => e.tourCondition == null || (cachedTour && e.tourCondition(cachedTour)))
              .map((s) => (
                <Link
                  id={StringHelper.toCamelCase(s.section)}
                  onClick={() => setOpen(false)}
                  key={s.section}
                  className={props.section == s.section ? 'selected' : ''}
                  to={
                    s.section == AdminSection.Landing
                      ? `/Tour/${tourTag}`
                      : `/Organisation/${organisationTag}/Tour/${tourTag}/${s.section}`
                  }
                >
                  {s.section}
                </Link>
              ))}

            <button
              onClick={
                cachedOrganisation &&
                (() => {
                  setOpen(false);
                  setTourMenu(cachedTour);
                })
              }
            >
              More
            </button>
          </div>
        </div>
      ) : organisationTag ? (
        <div className="admin-button-menu">
          <div className="boundry">
            {AdminSections.getOrganisationSections()
              .filter(
                (e) =>
                  e.organisationCondition == null ||
                  (cachedOrganisation && e.organisationCondition(cachedOrganisation)),
              )
              .map((s) => (
                <Link
                  id={StringHelper.toCamelCase(s.section)}
                  onClick={() => setOpen(false)}
                  key={s.section}
                  className={props.section == s.section ? 'selected' : ''}
                  to={
                    s.section == AdminSection.Landing
                      ? `/Organisation/${organisationTag}`
                      : `/Organisation/${organisationTag}/${s.section}`
                  }
                >
                  {s.section}
                </Link>
              ))}

            <button
              onClick={
                cachedOrganisation &&
                (() => {
                  setOrganisationMenu(cachedOrganisation);
                  setOpen(false);
                })
              }
            >
              More
            </button>
          </div>
        </div>
      ) : (
        <div className="admin-button-menu">
          <div className="boundry">
            <button>...</button>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className={`admin-header donotprint${open ? ' open' : ''}`}>
      <div className="admin-header-navigator">
        <div className="boundry">
          <button className="admin-header-button burger" onClick={() => setOpen(!open)}>
            <div className={`hamburger hamburger--squeeze js-hamburger${open ? ' is-active' : ''}`}>
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </button>

          <Link to={'/'} className="admin-header-button seaty-icon">
            <SVGSeatyFace />
          </Link>
          <Link to={'/'} className="admin-header-button seaty-logo">
            <SVGSeaty />
          </Link>

          {dropdowns}

          {open && (
            <div className={`admin-options-container`}>
              <div className="admin-options">
                {dropdowns}
                {buttons}
              </div>
              <div className="click-zone" onClick={() => setOpen(false)}></div>
            </div>
          )}

          <div className="mount-right">
            <HeaderUser className="hide-on-small" />
          </div>
        </div>
      </div>
      {buttons}
    </div>
  );
};

export default AdminHeader;

import { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M 12.5 4 C 10.019 4 8 6.019 8 8.5 L 8 39.5 C 8 41.981 10.019 44 12.5 44 L 33 44 L 33 43.949219 C 30.609 43.711219 28.467094 42.625 26.871094 41 L 12.5 41 C 11.673 41 11 40.327 11 39.5 L 11 8.5 C 11 7.673 11.673 7 12.5 7 L 24 7 L 24 15.5 C 24 17.981 26.019 20 28.5 20 L 37 20 L 37 24.460938 C 38.095 24.805937 39.103 25.343531 40 26.019531 L 40 18.5 C 40 18.0855 39.832047 17.710828 39.560547 17.439453 L 26.560547 4.4394531 C 26.289047 4.1680781 25.914 4 25.5 4 L 12.5 4 z M 27 9.1210938 L 34.878906 17 L 28.5 17 C 27.673 17 27 16.327 27 15.5 L 27 9.1210938 z M 34 26 C 29.589 26 26 29.589 26 34 C 26 38.411 29.589 42 34 42 C 35.665 42 37.210187 41.487281 38.492188 40.613281 L 43.439453 45.560547 C 43.732453 45.854547 44.116 46 44.5 46 C 44.884 46 45.267547 45.853547 45.560547 45.560547 C 46.145547 44.974547 46.145547 44.025453 45.560547 43.439453 L 40.615234 38.492188 C 41.488234 37.210187 42 35.665 42 34 C 42 29.589 38.411 26 34 26 z M 34 29 C 36.757 29 39 31.243 39 34 C 39 36.757 36.757 39 34 39 C 31.243 39 29 36.757 29 34 C 29 31.243 31.243 29 34 29 z" />
    </svg>
  );
}) as FunctionComponent<IProps>;

import linq from 'linq';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import ReactApexChart from 'react-apexcharts';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import SVGPrint from '../../../../svg/SVGPrint';
import SVGToggleOff from '../../../../svg/SVGToggleOff';
import SVGToggleOn from '../../../../svg/SVGToggleOn';
import { ILedgerRow } from './ILedgerRow';
import LedgerDailyTable from './LedgerDailyTable';
import './LedgerPage.scss';
import LedgerTotalsTable from './LedgerTotalsTable';
import SideMenu from '../../../../components/SideMenu';

interface IProps {
  organisation: IOrganisation;
}

const AdminLedger: React.FC<IProps> = (props) => {
  function getLast24Months(): moment.Moment[] {
    const months: moment.Moment[] = [];

    for (let i = 0; i < 24; i++) {
      const month = moment().subtract(i, 'months');
      months.push(month);
    }

    return months;
  }

  const [moments, setMoments] = useState<Array<moment.Moment>>(getLast24Months());
  const [expandedYears, setExpandedYears] = useState<number[]>([]);
  const [selectedMoment, setSelectedMoment] = useState(null);
  const [busyMessage, setBusyMessage] = useState('Loading Statement...');
  const [showVAT, setShowVAT] = useState<boolean>(true);
  const [showTranscations, setShowTransactions] = useState<boolean>(false);

  const [state, setState] = useState<{ purchases: Array<ILedgerRow>; refunds: Array<ILedgerRow> }>({
    purchases: [],
    refunds: [],
  });

  const organisation = props.organisation;

  useEffect(() => {
    const _moments = getLast24Months();

    setMoments(_moments);

    if (_moments.length > 0) {
      setSelectedMoment(_moments[0]);
      setExpandedYears([_moments[0].year()]);
    }
  }, []);

  useEffect(() => {
    if (!selectedMoment) return;
    const _period = selectedMoment.startOf('month').format('YYYY-MM-DD');

    setBusyMessage('Getting ledger...');
    AdminApi.request('GET', `/api/Ledger?date=${_period}`)
      .then((response: { Purchases: Array<ILedgerRow>; Refunds: Array<ILedgerRow> }) => {
        setState({ ...state, purchases: response.Purchases, refunds: response.Refunds });
        setBusyMessage(null);
      })
      .catch((message) => {
        alert(message);
        setBusyMessage('Sorry, we cannot get your statement.');
      });
  }, [selectedMoment]);

  if (busyMessage) return <Loader inline>{busyMessage}</Loader>;

  const ledgerPurchases = state.purchases;
  const ledgerRefunds = state.refunds;

  if (busyMessage || !ledgerPurchases || !ledgerRefunds || !organisation) return null;

  let eventGroups = linq
    .from(ledgerPurchases)
    .concat(ledgerRefunds)
    .groupBy((l) => l.EventId);

  eventGroups = eventGroups.orderByDescending((e) =>
    linq.from(e).sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.SeatyFeeGBPRefunded : o.SeatyFeeGBP)),
  );

  const jointLinq = linq.from(ledgerRefunds.concat(ledgerPurchases));

  const getData = (label, day: moment.Moment, upToCurrentHourOnly: boolean = false) => {
    const modifier = 1;

    const data = linq
      .from(ledgerPurchases)
      .where(
        (p) =>
          moment(p.DateCompleted).isAfter(day.startOf('day')) && moment(p.DateCompleted).isBefore(day.endOf('day')),
      )
      .groupBy((p) => moment(p.DateCompleted).add(modifier, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm'))
      .select((p) => ({
        x: p.key(),
        y: p.sum((pp) => pp.SeatyFee + (pp.EnhancedRefundFee ? pp.EnhancedRefundFee : 0)),
      }));

    const chartData = [];
    let startOfADay = day.clone().startOf('day');
    let total = 0;

    // Check if the specified `day` is today
    const currentHour = moment().hour();

    for (let i = 0; i < 25; i++) {
      // If today, only process up to the current hour + 1
      if (upToCurrentHourOnly && i > currentHour + 1) break;

      const hourData = data.firstOrDefault((d) => d.x == startOfADay.format('YYYY-MM-DD HH:mm'));

      if (hourData) {
        total += hourData.y;
      }

      chartData.push({
        i: i,
        x: startOfADay.format('ha'),
        y: total,
        l: label,
        c: '£',
      });

      startOfADay = startOfADay.add(1, 'hour');
    }

    return chartData;
  };

  const organisationSeries = jointLinq
    .groupBy((g) => g.OrganisationTag)
    .select((g) => {
      return {
        x: '#' + g.key(),
        y: g.sum((g) => (g.SeatyFee ? g.SeatyFee : 0) + (g.EnhancedRefundFee ? g.EnhancedRefundFee : 0)),
      };
    })
    .toArray();

  const dailyEventSeries = jointLinq
    .where((d) => moment(d.DateCompleted).unix() > moment().utc().startOf('day').unix())
    .groupBy((g) => g.EventTag)
    .select((g) => {
      return {
        x: '#' + g.key(),
        y: g.sum((g) => (g.SeatyFee ? g.SeatyFee : 0) + (g.EnhancedRefundFee ? g.EnhancedRefundFee : 0)),
      };
    })
    .toArray();

  const times = [];
  let time = moment().startOf('day');

  for (var i = 0; i < 25; i++) {
    times.push(time.format('ha'));
    time = time.add(1, 'hour');
  }

  const period = selectedMoment.startOf('month').format('YYYY-MM-DD');

  return (
    <div className="invoice-page">
      <Helmet>
        <title>
          #{organisation.OrganisationTag} {period} Statement
        </title>
        <meta name="description" content={`Statement for the month of ${period}.`} />
      </Helmet>

      <div className="row">
        <div className="col-sm-3 col-md-2 donotprint">
          <SideMenu>
            <div className="title">Statements</div>

            {linq
              .from(moments)
              .groupBy((m) => m.year())
              .select((group) => {
                return (
                  <div key={group.key().toString()}>
                    <button
                      className={`${expandedYears.includes(group.key()) ? 'expanded' : 'collapsed'}`}
                      onClick={() =>
                        expandedYears.includes(group.key())
                          ? setExpandedYears(expandedYears.filter((e) => e != group.key()))
                          : setExpandedYears([...expandedYears, group.key()])
                      }
                    >
                      {group.key()}
                    </button>

                    {expandedYears.includes(group.key()) && (
                      <div style={{ paddingLeft: '15px' }}>
                        {group
                          .select((m: moment.Moment) => (
                            <button
                              className={`${selectedMoment && selectedMoment.format('YYYY-MM') == m.format('YYYY-MM') ? 'selected' : ''}`}
                              onClick={() => setSelectedMoment(m)}
                              key={`Statement_${m.format('YYYY-MM')}`}
                            >
                              {m.format('MMMM YYYY')}
                            </button>
                          ))
                          .toArray()}
                      </div>
                    )}
                  </div>
                );
              })
              .toArray()}
          </SideMenu>
        </div>
        <div className="col-sm-9 col-md-10">
          <div className="toolbar donotprint">
            <div className="title">{selectedMoment && selectedMoment.format('MMMM YYYY')} Statement</div>
            <div className="buttons">
              <button onClick={() => setShowTransactions(!showTranscations)}>
                {!showTranscations ? <SVGToggleOff /> : <SVGToggleOn />}
                {showTranscations ? 'Hide' : 'Show'} transactions
              </button>
              <button onClick={() => (window as any).print()}>
                <SVGPrint />
                Print statement
              </button>
            </div>
          </div>

          <div className="container-outer" style={{ margin: '0' }}>
            <div className="container-inner">
              <div className="section-to-print">
                {ledgerPurchases &&
                  ledgerPurchases.length > 0 &&
                  moment().format('YYYYMM') == moment(ledgerPurchases[0].DateCompleted).format('YYYYMM') && (
                    <>
                      <div style={{ fontWeight: 900 }}>Seaty Fees Daily Comparison</div>

                      <ReactApexChart
                        options={{
                          chart: {
                            height: 350,
                            type: 'line',
                            zoom: {
                              enabled: false,
                            },
                          },
                          colors: ['#d8d8d8', '#625afa'], // Array of colours for each series
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: 2.5,
                          },
                          grid: {
                            row: {
                              colors: ['#f3f3f3', 'transparent'], // Repeated on rows
                              opacity: 0.25,
                            },
                          },
                          yaxis: {
                            labels: {
                              formatter: function (value) {
                                return CurrencyHelper.formatCurrency('£', value);
                              },
                            },
                          },
                          xaxis: {
                            categories: times,
                          },
                        }}
                        series={[
                          {
                            name: 'Yesterday',
                            data: getData('Yesterday at', moment().add(-1, 'day'), false).map((d) => d.y),
                          },
                          {
                            name: 'Today',
                            data: getData('Today at', moment(), false).map((d) => d.y),
                          },
                        ]}
                        type="line"
                        height={450}
                      />

                      <br />
                      <br />
                      <div className="pagebreak"></div>

                      <div style={{ fontWeight: 900 }}>Daily Event Comparison</div>

                      <ReactApexChart
                        options={{
                          legend: {
                            show: false,
                          },
                          chart: {
                            height: 350,
                            type: 'treemap',
                          },
                          yaxis: {
                            labels: {
                              formatter: function (value) {
                                return CurrencyHelper.formatCurrency('£', value);
                              },
                            },
                          },
                        }}
                        series={[{ data: dailyEventSeries }]}
                        type="treemap"
                        height={350}
                      />
                      <br />
                      <br />

                      <br />
                      <br />
                      <div className="pagebreak"></div>

                      <div style={{ fontWeight: 900 }}>Organisation Comparison</div>

                      <ReactApexChart
                        options={{
                          legend: {
                            show: false,
                          },
                          chart: {
                            height: 350,
                            type: 'treemap',
                          },
                          yaxis: {
                            labels: {
                              formatter: function (value) {
                                return CurrencyHelper.formatCurrency('£', value);
                              },
                            },
                          },
                        }}
                        series={[{ data: organisationSeries }]}
                        type="treemap"
                        height={350}
                      />
                      <br />
                      <br />
                      <br />
                    </>
                  )}

                <div className="summary">
                  <div style={{ fontWeight: 900 }}>Summary for {moment(period).format('MMMM YYYY')}</div>
                  <br />
                  {ledgerRefunds.length > 0 && (
                    <LedgerTotalsTable
                      invoicePeriod={period}
                      organisation={organisation}
                      ledgerPurchases={ledgerPurchases}
                      ledgerRefunds={ledgerRefunds}
                      showVAT={showVAT}
                    />
                  )}
                  <br />
                  <br />
                  <div className="pagebreak"></div>

                  {jointLinq.count() > 0 &&
                    jointLinq
                      .groupBy((j) => j.CurrencyName)
                      .select((g, i) => (
                        <React.Fragment key={`Row_${i}`}>
                          <LedgerDailyTable
                            period={period}
                            organisation={organisation}
                            ledgerPurchases={linq
                              .from(ledgerPurchases)
                              .where((p) => p.CurrencyName === g.first().CurrencyName)
                              .toArray()}
                            ledgerRefunds={linq
                              .from(ledgerRefunds)
                              .where((p) => p.CurrencyName === g.first().CurrencyName)
                              .toArray()}
                            showVAT={showVAT}
                          />

                          <br />
                        </React.Fragment>
                      ))}

                  <div className="pagebreak"></div>

                  {eventGroups.count() > 0 &&
                    eventGroups.select((eventData) => {
                      const anyConnect = eventData.any((e) => e.StripeConnect);

                      return (
                        <React.Fragment key={eventData.first().EventId}>
                          <div>
                            <div style={{ fontWeight: 600 }}>
                              {eventData.first().OrganisationName}
                              {eventData.first().StripeConnect && ' (Stripe Connect)'}
                            </div>
                            <div>{moment(period).format('MMMM YYYY')}</div>
                            <div style={{ fontWeight: 900 }}>{eventData.first().EventName}</div>
                            <Link to={`/${eventData.first().EventTag}`} style={{ fontWeight: 400 }}>
                              #{eventData.first().EventTag}
                            </Link>
                          </div>
                          <table style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <th style={{ width: '10%' }}>{showTranscations && `Order #`}</th>
                                <th style={{ width: '15%' }}>{showTranscations && `Date`}</th>
                                <th style={{ width: '10%' }}>{showTranscations && `Type`}</th>
                                <th style={{ width: '10%', textAlign: 'right' }}>Amount</th>
                                {anyConnect && <th style={{ width: '10%', textAlign: 'right' }}>Stripe fee</th>}
                                <th style={{ width: '10%', textAlign: 'right' }}>Service Fee</th>

                                {showVAT && <th style={{ width: '10%', textAlign: 'right' }}>VAT</th>}
                              </tr>

                              {showTranscations &&
                                eventData
                                  .orderBy((e) => e.DateCompleted)
                                  .toArray()
                                  .map((o) => {
                                    return (
                                      <tr key={o.OrderId}>
                                        <td>{o.OrderId}</td>
                                        <td>
                                          {moment(
                                            o.RefundAmount && o.RefundAmount > 0 ? o.RefundDate : o.DateCompleted,
                                          ).format('D MMM YYYY HH:mm')}
                                        </td>
                                        <td>
                                          {o.StripeConnect ? 'SC' : 'SP'}
                                          {o.AbsorbFee ? '-A' : '-P'}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          {o.RefundAmount && o.RefundAmount > 0
                                            ? `-${CurrencyHelper.formatCurrency(o.CurrencySymbol, o.RefundAmount - (!o.AbsorbFee ? o.GatewayFeeRefunded + o.SeatyFeeGBPRefunded : 0))}`
                                            : CurrencyHelper.formatCurrency(o.CurrencySymbol, o.TotalPrice)}
                                        </td>

                                        {o.StripeConnect ? (
                                          <td style={{ textAlign: 'right' }}>
                                            {o.RefundAmount && o.RefundAmount > 0
                                              ? `-${CurrencyHelper.formatCurrency(o.CurrencySymbol, o.GatewayFeeRefunded)}`
                                              : CurrencyHelper.formatCurrency(o.CurrencySymbol, o.GatewayFee)}
                                          </td>
                                        ) : anyConnect ? (
                                          <td></td>
                                        ) : null}

                                        <td style={{ textAlign: 'right' }}>
                                          {o.RefundAmount && o.RefundAmount > 0
                                            ? `-${CurrencyHelper.formatCurrency('£', o.SeatyFeeGBPRefunded)}`
                                            : CurrencyHelper.formatCurrency('£', o.SeatyFeeGBP)}
                                        </td>

                                        {showVAT && (
                                          <td style={{ textAlign: 'right' }}>
                                            {o.RefundAmount && o.RefundAmount > 0
                                              ? `-${CurrencyHelper.formatCurrency(o.CurrencySymbol, o.VATAdjustment).replace('-', '')}`
                                              : CurrencyHelper.formatCurrency(o.CurrencySymbol, o.VATAdjustment)}
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}

                              <tr>
                                <td colSpan={4} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                  {CurrencyHelper.formatCurrency(
                                    eventData.first().CurrencySymbol,
                                    linq
                                      .from(eventData)
                                      .sum((o) =>
                                        o.RefundAmount && o.RefundAmount > 0
                                          ? 0 -
                                            o.RefundAmount +
                                            (!o.AbsorbFee ? o.GatewayFeeRefunded + o.SeatyFeeGBPRefunded : 0)
                                          : o.TotalPrice,
                                      ),
                                  )}
                                </td>

                                {anyConnect && (
                                  <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {CurrencyHelper.formatCurrency(
                                      eventData.first().CurrencySymbol,
                                      linq
                                        .from(eventData)
                                        .sum((o) =>
                                          o.StripeConnect
                                            ? o.RefundAmount && o.RefundAmount > 0
                                              ? -o.GatewayFeeRefunded
                                              : o.GatewayFee
                                            : 0,
                                        ),
                                    )}
                                  </td>
                                )}

                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                  {CurrencyHelper.formatCurrency(
                                    '£',
                                    linq
                                      .from(eventData)
                                      .sum((o) =>
                                        o.RefundAmount && o.RefundAmount > 0
                                          ? 0 - o.SeatyFeeGBPRefunded
                                          : o.SeatyFeeGBP,
                                      ),
                                  )}
                                </td>

                                {showVAT && (
                                  <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {CurrencyHelper.formatCurrency(
                                      '£',
                                      linq
                                        .from(eventData)
                                        .sum((o) =>
                                          o.RefundAmount && o.RefundAmount > 0 ? o.VATAdjustment : o.VATAdjustment,
                                        ),
                                    )}
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>

                          <br />
                          <br />
                        </React.Fragment>
                      );
                    })}

                  <br />

                  <p style={{ fontSize: '12px' }}>
                    All purchases in this statement were placed in agreement with terms of service found at
                    https://Seaty.co.uk/Docs/TermsOfService.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLedger;

import { FunctionComponent } from 'react';
import SVGCheck from '../../svg/SVGCheck';
import { IEventDate } from '../../interfaces/IEventDate';
import DateHelper from '../../helpers/DateHelper';

export interface IProps {
  date: IEventDate;
  onCheckChange: () => void;
  checked: boolean;
}

const EventDateSelectionRow: FunctionComponent<IProps> = (props) => {
  return (
    <tr style={{ display: 'table-row' }}>
      <td className="check-cell">
        <button onClick={() => props.onCheckChange()} className={`${props.checked ? ' selected' : ''}`}>
          <SVGCheck />
        </button>
      </td>
      <td style={{ maxWidth: '400px' }}>{DateHelper.asDateAtTimeAmPm(props.date.DateAsString)}</td>
    </tr>
  );
};

export default EventDateSelectionRow;

import './Dropdown.scss';

import linq from 'linq';
import React, { CSSProperties, useState } from 'react';
import NumberHelper from '../helpers/NumberHelper';
import DropdownItem, { IDropDownItem } from './DropdownItem';
import SVGCheckMark from '../svg/SVGCheckmark';
import SVGCheckmarkUnchecked from '../svg/SVGCheckmarkUnchecked';
import SVGCheckmarkSomeChecked from '../svg/SVGCheckmarkSomeChecked';

export interface IProps {
  description: string;
  items: IDropDownItem[];
  nopadding?: boolean;
  value: any;
  onChange: (
    value: IDropDownItem | IDropDownItem[],
    item?: IDropDownItem,
    e?: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => void;
  multiple?: boolean;
  selectAllGroupClicked?: (items) => void;
  style?: CSSProperties;
}

const Dropdown: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false);

  const ignore = linq.from(props.items).sum((i) => (i.ignoreCount == true ? 1 : 0));

  const groupsEnabled = linq.from(props.items).any((i) => i.group);

  var selectedItems: IDropDownItem[] = linq
    .from(props.items)
    .where((i) => {
      if (props.multiple) {
        return (props.value as string[]).filter((s) => s === i.value).length > 0;
      } else {
        return i.value == props.value;
      }
    })
    .toArray();

  const selectedItem = selectedItems.length === 1 && selectedItems[0];

  var style: CSSProperties = {
    color: selectedItem == null || selectedItem.color == null ? null : selectedItem.color,
    backgroundColor: selectedItem == null || selectedItem.backgroundColor == null ? null : selectedItem.backgroundColor,
  };
  var labelStyle = style;

  if (selectedItem && selectedItem.backgroundColor != null) {
    labelStyle = {
      color: selectedItem == null || selectedItem.backgroundColor == null ? null : selectedItem.backgroundColor,
      backgroundColor: 'white',
    };
  }

  const getDropdownItem = (item) => {
    const selected = props.multiple && selectedItems.filter((i) => i.value === item.value).length > 0;

    return (
      <DropdownItem
        key={`Item_${item.value}`}
        selected={selected}
        clicked={(item, e) => {
          if (!props.multiple) {
            props.onChange(item, null, e);
            setOpen(false);
            return;
          }

          const itemsWithoutThisOne = selectedItems.filter((i) => i.value !== item.value);

          if (!selected) {
            props.onChange([...itemsWithoutThisOne, item], item, e);
            return;
          }

          props.onChange(itemsWithoutThisOne, item, e);
        }}
        item={item}
      />
    );
  };

  const selectedLinq = linq.from(selectedItems);

  return (
    <div
      className={'input-label-group' + (props.multiple ? ' multiple' : '')}
      style={{ ...props.style, padding: props.nopadding ? 0 : null }}
    >
      <div role="presentation" className={`dropdown${open ? ' open' : ''}`}>
        <button
          id={`dropdown${props.description ?? ''}`}
          onClick={() => setOpen(!open)}
          style={style}
          className="dropdown-toggle form-control"
        >
          {props.multiple
            ? selectedItems.length + ignore === props.items.length
              ? 'All'
              : `${selectedItems.length} selected of ${props.items.length - ignore} options`
            : selectedItem != null
              ? selectedItem.description
              : props.description}
          <span className="caret"></span>
        </button>
        {open && <div className="click-off" onClick={() => setOpen(false)}></div>}
        <ul className="dropdown-menu animated fadeIn">
          {groupsEnabled
            ? linq
                .from(props.items)
                .groupBy((i) => i.group)
                .select((g) => {
                  const selectedInGroup = g.where((i) => selectedLinq.any((selected) => selected.value == i.value));
                  const allSelected = selectedInGroup.count() == g.count();
                  const someSelected = selectedInGroup.count() > 0;

                  return (
                    <React.Fragment key={g.key()}>
                      {g.key() && (
                        <div className="dropdown-group-header">
                          {g.first().groupText}

                          {g.count() > 1 && (
                            <div className="dropdown-group-header-buttons">
                              <button
                                onClick={() => {
                                  props.onChange(
                                    allSelected
                                      ? selectedItems.filter((i) => !g.toArray().includes(i))
                                      : [...selectedItems.filter((i) => !g.toArray().includes(i)), ...g.toArray()],
                                    null,
                                    null,
                                  );

                                  props.selectAllGroupClicked && props.selectAllGroupClicked(g.toArray());
                                }}
                              >
                                {allSelected ? (
                                  <SVGCheckMark />
                                ) : someSelected ? (
                                  <SVGCheckmarkSomeChecked />
                                ) : (
                                  <SVGCheckmarkUnchecked />
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {g.toArray().map((item) => getDropdownItem(item))}
                    </React.Fragment>
                  );
                })
            : props.items.map((item, index) => getDropdownItem(item))}
        </ul>
      </div>
      {selectedItem == null || props.description == null || props.description.length == 0 ? null : (
        <label style={labelStyle} className="input-label animated fadeIn">
          {props.description}
        </label>
      )}
    </div>
  );
};

export default Dropdown;

import React, { FunctionComponent, useEffect, useState } from 'react';
import GeneralApi from '../api/GeneralApi';
import Block from '../components/Block';
import BlockHeader from '../components/BlockHeader';
import Button from '../components/Button';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import SolidLine from '../components/SolidLine';
import SpacerTable from '../components/SpacerTable';
import BlockTextBox from '../components/BlockTextBox';
import GAHelper from '../helpers/GAHelper';
import LocationHelper from '../helpers/LocationHelper';
import ThemeHelper from '../helpers/ThemeHelper';

export interface IProps {
  onClose: () => void;
}

const ResetPasswordModal = ((props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [busyMessage, setBusyMessage] = useState(null);
  const [resetComplete, setResetComplete] = useState(false);
  const [guid] = useState(LocationHelper.getQueryVariable('resetGuid'));

  const signInDisabled: boolean =
    busyMessage != null || !confirmPassword || confirmPassword.length < 6 || !password || password.length < 6;

  useEffect(() => {
    GAHelper.modal(`Reset Password`);
  }, []);

  const resetPassword = () => {
    setError(null);
    setBusyMessage('Updating password...');

    GeneralApi.request('POST', `/api/RecoverPassword`, {
      guid: guid,
      password: password,
      confirmPassword: confirmPassword,
    })
      .then((value) => {
        setBusyMessage(null);
        if (value) {
          setResetComplete(true);
        } else {
          setError('Failed to reset password... something went wrong.');
        }
      })
      .catch((message) => {
        setError(message);
        setBusyMessage(null);
      });
  };

  return (
    <>
      <Modal theme={ThemeHelper.getDefaultTheme()} onCloseClick={props.onClose}>
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            {busyMessage ? (
              <Loader inline={true}>{busyMessage}</Loader>
            ) : (
              <>
                <table className="blocks">
                  <tbody>
                    {resetComplete ? (
                      <BlockInfo type={InfoType.Info}>
                        {' '}
                        All done, you can now close this dialog and sign in using your new password.
                      </BlockInfo>
                    ) : (
                      <>
                        <Block>
                          <BlockHeader>New password</BlockHeader>
                          <BlockTextBox
                            name={'password'}
                            id="password_field"
                            onKeyPress={(e) => {
                              if (e.charCode == 13) (document.querySelector('#password_field2') as any).select();
                            }}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e)}
                          />

                          <div className="spacer" />

                          <BlockHeader>Confirm password</BlockHeader>
                          <BlockTextBox
                            name={'confirmPassword'}
                            id="password_field2"
                            onKeyPress={(e) => {
                              if (e.charCode == 13) resetPassword();
                            }}
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e)}
                          />
                        </Block>

                        {error && <BlockInfo type={InfoType.Error}>{error}</BlockInfo>}
                      </>
                    )}
                  </tbody>
                </table>

                <div className="spacer-x2" />

                {resetComplete ? (
                  <SpacerTable>
                    <Button className="large" onClick={props.onClose} text={`Close`} />
                  </SpacerTable>
                ) : (
                  <SpacerTable>
                    <Button
                      disabled={signInDisabled}
                      className="confirm large"
                      onClick={resetPassword}
                      text={`Submit`}
                    />
                    <Button className="large" onClick={props.onClose} text={`Cancel`} />
                  </SpacerTable>
                )}
              </>
            )}
            <SolidLine />

            <SpacerTable className="small-font">
              All accounts are subject to the Seaty{' '}
              <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
              <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
}) as FunctionComponent<IProps>;

export { ResetPasswordModal };

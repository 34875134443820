import React from 'react';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import DateHelper from '../../../helpers/DateHelper';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';

export interface IProps {
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
}

const ReferralsSection: React.FC<IProps> = (props) => {
  const { overview, setSection } = props;

  return (
    <>
      <SpacerTable>
        <h1>Referrals</h1>
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          {overview.Referrals.filter((t) => t.Seats && t.Seats.length > 0).map((referral, index) => (
            <Block>
              <BlockHeader>
                <td>Order #{referral.OrderNumber}</td>
                <td className="right">{referral.PriceAsString}</td>
              </BlockHeader>
              {referral.Seats.length} tickets for {DateHelper.asDateAtTimeAmPm(referral.EventDate)} referred to you on{' '}
              {DateHelper.asDateAtTimeAmPm(referral.DateBooked)}
            </Block>
          ))}
        </tbody>
      </table>

      <div className="spacer" />
      <SpacerTable>
        <Button onClick={() => setSection(Section.Dashboard)} text={'Back to overview'} />
      </SpacerTable>
    </>
  );
};

export default ReferralsSection;

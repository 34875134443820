import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../Loader';
import { IEvent } from '../../interfaces/IEvent';
import SVGMultiply from '../../svg/SVGMultiply';
import EventDateSelectionRow from './EventDateSelectionRow';
import SVGCheck from '../../svg/SVGCheck';
import SVGTick from '../../svg/SVGTick';
import StringHelper from '../../helpers/StringHelper';
import SVGGoBackArrow from '../../svg/SVGGoBackArrow';
import { IEventDate } from '../../interfaces/IEventDate';
import DateHelper from '../../helpers/DateHelper';

export interface IProps {
  event: IEvent;
  confirm: (emails: string[]) => void;
  onBack: () => void;
  initialValue: string[];
  backMessage: string;
}

const EventDateSelection: FunctionComponent<IProps> = (props) => {
  const { event } = props;

  const [filter, setFilter] = useState('');
  const [selectedDates, setSelectedDates] = useState({});

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const initialDates = props.initialValue.reduce((acc, date) => {
      acc[date] = true;
      return acc;
    }, {});
    setSelectedDates(initialDates);
  }, []);

  if (!event) return null;

  const onFilterKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      setFilter(query);
    }
  };

  const clearFilterClicked = () => {
    setFilter('');
    setQuery('');
  };

  const handleSearchChanged = (e) => {
    setQuery(e.target.value);
  };

  var rows = [];
  var sortedData = event.Dates;

  const filteredData = [];

  sortedData.forEach((eventDate: IEventDate, index) => {
    var include = true;
    if (filter != null && filter.length > 0) {
      include = false;

      if (DateHelper.asDateAtTimeAmPm(eventDate.DateAsString).toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        include = true;
      }
    }

    if (include) {
      filteredData.push(eventDate);

      var rowId = 'row_' + eventDate.DateAsString;
      rowId += '_' + index;

      rows.push(
        <EventDateSelectionRow
          checked={selectedDates[eventDate.DateAsString]}
          onCheckChange={() => {
            if (selectedDates[eventDate.DateAsString]) {
              delete selectedDates[eventDate.DateAsString];
            } else {
              selectedDates[eventDate.DateAsString] = true;
            }

            setSelectedDates({ ...selectedDates });
          }}
          key={rowId}
          date={eventDate}
        />,
      );
    }
  });

  if (loading) return <Loader inline>Loading dates...</Loader>;
  const allFilteredDatesChecked = filteredData.every((rowData) => selectedDates[rowData.DateAsString]);

  return (
    <>
      <div className="admin-buttons" style={{ marginTop: 0, padding: 0 }}>
        <button
          className="admin-button icon-inline"
          onClick={() => {
            props.onBack();
          }}
        >
          <SVGGoBackArrow />
          {props.backMessage}
        </button>
        <button
          disabled={Object.keys(selectedDates).length == 0}
          className="admin-button icon-inline confirm"
          onClick={() => props.confirm(Object.keys(selectedDates))}
        >
          <SVGTick />
          {Object.keys(selectedDates).length == 0
            ? 'No event dates selected'
            : `Confirm selection of ${Object.keys(selectedDates).length} ${StringHelper.AddSWhenMany(Object.keys(selectedDates).length, 'event date')}`}
        </button>

        <button
          disabled={Object.keys(selectedDates).length == 0}
          className="admin-button icon-inline"
          onClick={() => {
            setSelectedDates({});
          }}
        >
          <SVGMultiply />
          Clear
        </button>
      </div>

      <div className="orderoverview" style={{ paddingBottom: '100px', maxWidth: '900px' }}>
        <div className="input donotprint">
          <input
            id="searchinput"
            onChange={handleSearchChanged}
            type="text"
            placeholder="Search..."
            value={query}
            onKeyPress={onFilterKeyPress.bind(this)}
            style={{ margin: '0' }}
          />

          <button onClick={clearFilterClicked}>
            <SVGMultiply />
          </button>
        </div>
        <div className="container-outer" style={{ minHeight: '300px' }}>
          <div className="container-inner" style={{ backgroundColor: 'white' }}>
            <table className="table grid-table">
              <thead>
                <tr>
                  <th style={{ width: '100px' }} className="grid-header check-cell">
                    <button
                      className={`${allFilteredDatesChecked ? ' selected' : ''}`}
                      onClick={() => {
                        if (allFilteredDatesChecked) {
                          filteredData.forEach((rowData) => {
                            delete selectedDates[rowData.DateAsString];
                          });
                        } else {
                          filteredData.forEach((rowData) => {
                            selectedDates[rowData.DateAsString] = true;
                          });
                        }

                        setSelectedDates({ ...selectedDates });
                      }}
                    >
                      <SVGCheck />
                    </button>
                  </th>
                  <th className="grid-header">Date</th>
                </tr>
              </thead>

              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDateSelection;

import './FAQ.scss';

import React from 'react';
import { Helmet } from 'react-helmet-async';

import SideBar from './Sidebar';

interface IProps {}

const CreatingAnEvent: React.FC<IProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Seaty - Videos</title>
        <meta name="description" content={`Seaty.co.uk FAQ.`} />
      </Helmet>
      <div className="docs faq-page">
        <SideBar />

        <div className="content">
          <article>
            <header>
              <h1>Creating a New Event</h1>
            </header>

            <div>
              <h3>Seated Event</h3>

              <p>
                In this video we outline how to set up a quick and simple Seaty event as a new user with a seating plan
                and seated tickets.
              </p>

              <div className="video">
                <iframe
                  title="Creating A Seated Event"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '411px' }}
                  src="https://www.loom.com/embed/e8967dc838b74a8b96f17cbae0f40e83?sid=07a663db-e116-42c1-896b-456a1e3a78c8"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div>
              <h3>General Admission Event</h3>

              <p>
                In this video we outline how to set up a quick and simple Seaty event as a new user with general
                admission tickets.
              </p>

              <div className="video">
                <iframe
                  title="Creating A General Admission Event"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '411px' }}
                  src="https://www.loom.com/embed/252f0cf6b3ea41cab81af4129b26d8d9?sid=2a235a70-f552-4fa7-bef1-15cffdffef8f"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default CreatingAnEvent;

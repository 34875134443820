import React, { FunctionComponent, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import GAHelper from '../../../helpers/GAHelper';
import Modal from '../../../components/Modal';
import ThemeHelper from '../../../helpers/ThemeHelper';
import SpacerTable from '../../../components/SpacerTable';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import BlockTextBox from '../../../components/BlockTextBox';
import Button from '../../../components/Button';
import SolidLine from '../../../components/SolidLine';
import { ISeatCategory } from '../../../interfaces/ISeatCategory';
import { IEvent } from '../../../interfaces/IEvent';
import { ISeatingPlan } from '../../../interfaces/ISeatingPlan';

export interface IProps {
  onClose: () => void;
  seatingPlan: ISeatingPlan;
  event: IEvent;
  onChange: (category: ISeatingPlan) => void;
  onDelete: () => void;
}

const SeatingPlanSectionModal = ((props) => {
  const { seatingPlan, event, onChange, onClose } = props;

  const [name, setName] = useState(seatingPlan.Name);

  useEffect(() => {
    GAHelper.modal(`Seat Category`);
  }, []);

  const submit = () => {
    seatingPlan.Name = name;
    onChange(seatingPlan);
  };

  return (
    <>
      <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
        <div className="content">
          <div className="ticket-rip" />

          <div className="body">
            <SpacerTable>
              <h1>Edit Seat Category</h1>
            </SpacerTable>

            <div className="spacer" />

            <table className="blocks">
              <tbody>
                <Block>
                  <BlockHeader>Name</BlockHeader>
                  <BlockTextBox
                    name="name"
                    onKeyPress={(e) => {
                      if (e.charCode == 13) submit();
                    }}
                    type="text"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e)}
                  />

                  <div className="spacer" />
                </Block>
              </tbody>
            </table>
            <div className="spacer-x2" />

            <SpacerTable>
              <Button className="confirm large" onClick={submit} text={`Submit`} />
            </SpacerTable>

            {props.onDelete && (
              <>
                <div className="spacer" />
                <SpacerTable>
                  <Button className="large bad" onClick={props.onDelete} text={`Delete`} />
                </SpacerTable>
              </>
            )}
            <SolidLine />

            <SpacerTable className="small-font">
              All changes are subject to the Seaty{' '}
              <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
              <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
            </SpacerTable>
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    </>
  );
}) as FunctionComponent<IProps>;

export { SeatingPlanSectionModal };

import React, { FunctionComponent, useState } from 'react';
import GeneralApi from '../../../api/GeneralApi';
import Block from '../../../components/Block';
import BlockHeader from '../../../components/BlockHeader';
import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import BlockTextBox from '../../../components/BlockTextBox';

interface IProps {
  onSaved: (value: string) => void;
  notes: string;
  seatName?: string;
  groupName?: string;
  seatCategoryId?: number;
  orderNumber: number;
}

const UserNotesInput: FunctionComponent<IProps> = (props) => {
  const [notes, setNotes] = useState<string>(props.notes);

  const save = () => {
    GeneralApi.request('POST', '/api/UserNote', {
      notes: notes,
      OrderNumber: props.orderNumber,
      Name: props.seatName,
      Group: props.groupName,
      SeatCategoryId: props.seatCategoryId,
    })
      .then(() => {
        props.onSaved(notes);
      })
      .catch((message) => alert(message));
  };

  return (
    <>
      <SpacerTable>
        <h1>
          {props.seatName ? (
            <BlockHeader>
              Ticket {props.groupName}
              {props.seatName} Notes
            </BlockHeader>
          ) : (
            <BlockHeader>Order #{props.orderNumber} Notes</BlockHeader>
          )}
        </h1>
      </SpacerTable>
      <div className="spacer" />

      <table className="blocks">
        <tbody>
          <Block>
            <BlockHeader>Notes</BlockHeader>
            <BlockTextBox
              name="notes"
              autoFocus={true}
              onKeyPress={(e) => {
                var ENTER = 13;
                if (e.charCode == ENTER) {
                  e.preventDefault();
                  save();
                }
              }}
              value={notes}
              onChange={(e) => {
                setNotes(e);
              }}
              rows={2}
            />
          </Block>
        </tbody>
      </table>
      <div className="spacer" />
      <SpacerTable>
        <Button className={'confirm'} onClick={save} text={'Save note'} />
      </SpacerTable>
    </>
  );
};

export default UserNotesInput;

import linq from 'linq';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminApi from '../../../../api/AdminApi';
import Loader from '../../../../components/Loader';
import { PaymentType } from '../../../../components/stripe/PaymentControl';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IEvent } from '../../../../interfaces/IEvent';
import { ITicketCategory } from '../../../../interfaces/ITicketCategory';
import './AdminSummary.scss';
import EventSummary_TicketSales, { EventSummaryMode, IEventDateSale } from './EventSummary_TicketSales';
import EventSummary_Report from './EventSummary_Report';
import StringHelper from '../../../../helpers/StringHelper';

export interface IProps {
  event: IEvent;
}

interface IEventSummary {
  EventDateMerchandiseSales: any;
  GeneralAdmissionOccupiedTicketCount: number;
  Categories: any;
  GeneralAdmissionCategories: any;
  EventDateSales: IEventDateSale[];
  OrderDiscounts: any[];
  GroupedPayments: any[];
  TotalPaidAsString: string;
  TotalOwedAsString: string;
}

const AdminEventSummary: FunctionComponent<IProps> = ({ event }) => {
  const [summary, setSummary] = useState<IEventSummary>(null);
  const [busyMessage, setBusyMessage] = useState('Loading summary...');
  const [showReport, setShowReport] = useState(false);
  const [mode, setMode] = useState(EventSummaryMode.ByDateTime);

  const load = () => {
    setBusyMessage('Loading summary...');

    AdminApi.request('GET', `/Api/EventSummary?eventTag=${event.EventTag}&currencySymbol=${event.CurrencySymbol}`)
      .then((_summary) => {
        setSummary(_summary);
        setBusyMessage(null);
      })
      .catch((message) => alert(message));
  };
  useEffect(load, []);

  if (busyMessage) return <Loader inline>{busyMessage}</Loader>;

  const totalSales = linq.from(summary.EventDateSales).sum((eds) => eds.SoldTickets);
  const totalSalesPrice = linq.from(summary.EventDateSales).sum((eds) => eds.PriceAsInt);
  const averageSalesPrice = totalSales == 0 ? 0 : totalSalesPrice / totalSales;

  const renderField = (name, value) => {
    return (
      <>
        <label className="inline">{name}</label>
        <label className="right" id={`${StringHelper.toCamelCase(name)}Amount`}>
          {CurrencyHelper.formatCurrency(event.CurrencySymbol, value)}
        </label>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>#{event.EventTag} Summary</title>
        <meta name="description" content={`Summary for #${event.EventTag}`} />
      </Helmet>

      {summary && event && (
        <>
          <div className="toolbar" style={{ marginBottom: 0 }}>
            <div className="title">Summary</div>
            <div className="buttons"></div>
          </div>

          <div className="row donotprint" style={{ marginBottom: '15px' }}>
            <div className="col-sm-8">
              <div className="admin-button-menu inline">
                <button className={!showReport ? 'selected' : ''} onClick={() => setShowReport(false)}>
                  Table view
                </button>
                {!showReport && (
                  <>
                    <button
                      className={mode == EventSummaryMode.ByDateTime ? 'selected' : ''}
                      onClick={() => setMode(EventSummaryMode.ByDateTime)}
                    >
                      By Date & Time
                    </button>
                    <button
                      className={mode == EventSummaryMode.ByDay ? 'selected' : ''}
                      onClick={() => setMode(EventSummaryMode.ByDay)}
                    >
                      By Day
                    </button>
                    <button
                      className={mode == EventSummaryMode.ByTicketType ? 'selected' : ''}
                      onClick={() => setMode(EventSummaryMode.ByTicketType)}
                    >
                      By Type
                    </button>
                  </>
                )}
                <button className={showReport ? 'selected' : ''} onClick={() => setShowReport(true)}>
                  Report view
                </button>
              </div>
            </div>
          </div>
          {/* 
          <div className="row donotprint" style={{ marginBottom: '15px' }}>
            <div className="col-sm-8">
              <div className="admin-button-menu inline">
                <button
                  className={mode == EventSummaryMode.ByDateTime ? 'selected' : ''}
                  onClick={() => setMode(EventSummaryMode.ByDateTime)}
                >
                  Date & Time
                </button>
                <button
                  className={mode == EventSummaryMode.ByDay ? 'selected' : ''}
                  onClick={() => setMode(EventSummaryMode.ByDay)}
                >
                  Day
                </button>
                <button
                  className={mode == EventSummaryMode.ByTicketType ? 'selected' : ''}
                  onClick={() => setMode(EventSummaryMode.ByTicketType)}
                >
                  Type
                </button>
              </div>
            </div>
          </div> */}

          {showReport ? (
            <EventSummary_Report
              event={event}
              eventDateSales={summary.EventDateSales}
              currency={event.CurrencySymbol}
            />
          ) : (
            <>
              <div className="row section">
                <div className="col-sm-7">
                  {summary.EventDateSales != null && summary.EventDateSales.length > 0 && (
                    <>
                      <div className="fields">
                        <h2>Ticket Sales</h2>
                        <EventSummary_TicketSales
                          mode={mode}
                          merchandise={false}
                          event={event}
                          eventDateSales={summary.EventDateSales}
                          currency={event.CurrencySymbol}
                        />
                      </div>
                    </>
                  )}
                  {summary.EventDateMerchandiseSales != null &&
                    linq.from(summary.EventDateMerchandiseSales).sum((t) => t.SoldTickets) > 0 && (
                      <>
                        <div className="fields" style={{ marginTop: '20px' }}>
                          <h2>Merchandise Sales</h2>
                          <EventSummary_TicketSales
                            mode={mode}
                            merchandise={true}
                            event={event}
                            eventDateSales={summary.EventDateMerchandiseSales}
                            currency={event.CurrencySymbol}
                          />
                        </div>
                      </>
                    )}
                </div>

                <div className="col-sm-1"></div>
                <div className="col-sm-4">
                  <div className="info">
                    Here are your total sales summaries. You can click each row to filter down by event dates.
                  </div>
                </div>
              </div>

              {summary.OrderDiscounts != null && summary.OrderDiscounts.length > 0 && (
                <>
                  <div className="row section">
                    <div className="col-sm-7">
                      <div className="fields">
                        <h2>Discounts</h2>
                        {summary.OrderDiscounts.map((discount, index) => (
                          <div key={`Discount_${index}`} className="field">
                            <label className="inline">
                              {discount.Quantity} {discount.Name}
                            </label>
                            <label className="right">
                              {CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}
                            </label>
                          </div>
                        ))}
                        <div className="field">
                          <label className="inline">
                            {linq.from(summary.OrderDiscounts).sum((d) => parseInt((d as any).Quantity))} discounts
                            issued in total
                          </label>
                          <label className="right">
                            <strong>
                              {CurrencyHelper.formatCurrency(
                                event.CurrencySymbol,
                                linq.from(summary.OrderDiscounts).sum((d) => (d as any).Amount),
                              )}
                            </strong>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="spacer"></div>
                </>
              )}

              <div className="row section">
                <div className="col-sm-7">
                  <div className="fields">
                    {summary.GroupedPayments.filter(
                      (p) => p.TypeId == PaymentType.CardSeaty || p.TypeId == PaymentType.RefundedToCardWithSeaty,
                    ).length > 0 && (
                      <>
                        <h2>Online Payments</h2>
                        {summary.GroupedPayments.map((p, i) => (
                          <div className="field" key={i}>
                            {p.TypeId == PaymentType.CardSeaty &&
                              renderField('Card Online with Seaty', p.AmountPaidAsInt)}
                            {p.TypeId == PaymentType.RefundedToCardWithSeaty &&
                              renderField('Refunded to Card with Seaty', p.AmountPaidAsInt)}
                          </div>
                        ))}
                        <div className="spacer"></div>
                      </>
                    )}

                    {summary.GroupedPayments.filter(
                      (p) =>
                        p.TypeId == PaymentType.CardOutSideSeaty ||
                        p.TypeId == PaymentType.Cash ||
                        p.TypeId == PaymentType.BankTransfer ||
                        p.TypeId == PaymentType.Cheque ||
                        p.TypeId == PaymentType.RefundByAdmin,
                    ).length > 0 && (
                      <>
                        <h2>Other Payments</h2>
                        {summary.GroupedPayments.map((p, i) => (
                          <div className="field" key={i}>
                            {p.TypeId == PaymentType.CardOutSideSeaty &&
                              renderField('Card Outside of Seaty', p.AmountPaidAsInt)}
                            {p.TypeId == PaymentType.Cash && renderField('Cash', p.AmountPaidAsInt)}
                            {p.TypeId == PaymentType.BankTransfer && renderField('Bank Transfer', p.AmountPaidAsInt)}
                            {p.TypeId == PaymentType.Cheque && renderField('Cheque', p.AmountPaidAsInt)}
                            {p.TypeId == PaymentType.RefundByAdmin &&
                              renderField('Refunded Outside of Seaty', p.AmountPaidAsInt)}
                          </div>
                        ))}
                        <div className="spacer"></div>
                      </>
                    )}

                    {summary.GroupedPayments.filter(
                      (p) => p.TypeId == PaymentType.HandlingFee || p.TypeId == PaymentType.HandlingFeeRefunds,
                    ).length > 0 && (
                      <>
                        <h2>Handling Fees</h2>
                        {summary.GroupedPayments.map((p, i) => (
                          <div className="field" key={i}>
                            {p.TypeId == PaymentType.HandlingFee && renderField('Handling Fees', p.AmountPaidAsInt)}
                            {p.TypeId == PaymentType.HandlingFeeRefunds &&
                              renderField('Handling Fees Refunded', p.AmountPaidAsInt)}
                          </div>
                        ))}
                        <div className="spacer"></div>
                      </>
                    )}

                    <div className="field bold">
                      <div className="inline">Total</div>
                      <div className="right">{summary.TotalPaidAsString}</div>
                    </div>
                    <div className="field">
                      <label className="inline">Owed from box office sales</label>
                      <label className="right">{summary.TotalOwedAsString}</label>
                    </div>
                  </div>
                </div>
              </div>

              {averageSalesPrice > 0 && (
                <div className="row section">
                  <div className="col-sm-7">
                    <div className="fields">
                      <h2>Averages</h2>
                      <div className="field">
                        <label className="inline">Average ticket sale price</label>
                        <label className="right">
                          {CurrencyHelper.formatCurrency(event.CurrencySymbol, averageSalesPrice)}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-1"></div>
                  <div className="col-sm-4"></div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminEventSummary;

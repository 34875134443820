import React from 'react';

import Button from '../../../components/Button';
import SpacerTable from '../../../components/SpacerTable';
import { IOrder } from '../../../interfaces/IOrder';
import { ITicket } from '../../../interfaces/ITicket';
import IUserOrderOverview from '../../../interfaces/IUserOrderOverview';
import { Section } from '../../../modals/UserEventOverview';
import UserNotesInput from './UserNotesInput';

export interface IProps {
  overview: IUserOrderOverview;
  setSection: (section: Section) => void;
  setOverview: (overview: IUserOrderOverview) => void;
  selectedOrder: IOrder;
  selectedTicket: ITicket;
}

const NotesSection: React.FC<IProps> = (props) => {
  const { setSection, overview, selectedTicket, selectedOrder, setOverview } = props;

  return (
    <>
      <UserNotesInput
        onSaved={(notes) => {
          if (selectedTicket) {
            selectedTicket.UserNotes = notes;
          } else {
            selectedOrder.UserNotes = notes;
          }

          setOverview({ ...overview });
          setSection(Section.Order);
        }}
        groupName={selectedTicket ? selectedTicket.Group : null}
        seatName={selectedTicket ? selectedTicket.Name : null}
        seatCategoryId={selectedTicket ? selectedTicket.SeatCategoryId : null}
        orderNumber={selectedOrder.Id}
        notes={selectedTicket ? selectedTicket.UserNotes || '' : selectedOrder.UserNotes || ''}
        key={'notesEditor_' + selectedOrder.Id}
      />
      <div className="spacer" />
      <SpacerTable>
        <Button onClick={() => setSection(Section.Order)} text={'Back to order'} />
      </SpacerTable>
    </>
  );
};

export default NotesSection;

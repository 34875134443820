import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SVGHelper from '../helpers/SVGHelper';
import UserHelper from '../helpers/UserHelper';
import './Footer.scss';
import EventHelper from '../helpers/EventHelper';

const Footer: React.FC<{ spacer?: boolean; transparent?: boolean; admin?: boolean }> = ({
  spacer,
  transparent,
  admin,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`seaty-footer${spacer ? ' has-spacer' : ''}${admin ? ' admin' : ''}${transparent ? ' transparent' : ''} donotprint`}
    >
      {/* <img alt="Seaty branding dhadow" className="shadow-icon" src={SVGHelper.get('Seaty Icon')} /> */}

      <div className="desktop-center">
        <div className="blocks links">
          <div className="block with-love">
            <Link to="/">
              <img
                className="icon"
                alt="Seaty made with love in Britain"
                src="https://seatylive.blob.core.windows.net/images/WithLove.png"
              />
              <img className="icon dark" alt="Seaty made with love in Britain" src={SVGHelper.get('WithLoveBlack')} />
            </Link>
          </div>
          <div className="block">
            <h1>Seaty</h1>
            <Link to="/Home/MoreInfo">Find out more</Link>
            <Link to="/Home/Fees">Fees</Link>
            <Link to="/Docs/TermsOfService/">Terms of service</Link>
          </div>
          {UserHelper.currentUser && (
            <div className="block">
              <h1>Events</h1>
              <>
                <a href="/Event/Create/">Create an event</a>
                <Link to="/User/Tickets/">Your tickets</Link>
                <a href="#" onClick={() => EventHelper.manageEvents(navigate)}>
                  Your events
                </a>
              </>
            </div>
          )}
          <div className="block">
            <h1>Support</h1>
            <Link to={'/Docs/FAQ'}>Frequently asked questions</Link>
            <Link to={'/Docs/Events'}>Video Tutorials</Link>
            <a href="mailto:support@seaty.co.uk">Email us</a>
          </div>
        </div>
      </div>

      <div className="brand">
        <div className="blocks desktop-center">
          <div className="block">
            <a href="mailto:support@seaty.co.uk">Support@Seaty.co.uk</a>
          </div>
          <div className="block">
            <Link to="/">
              <img
                className="light"
                alt="Seaty.co.uk"
                src="https://seatylive.blob.core.windows.net/images/Seaty_Light.png"
              />
              <img
                className="dark"
                alt="Seaty.co.uk"
                src="https://seatylive.blob.core.windows.net/images/Seaty_Dark.png"
              />
            </Link>
          </div>
          <div className="block">© {moment().format('YYYY')} All rights reserved.</div>
        </div>
      </div>

      <div className="trademark">
        Seaty is a registered trademark in the United Kingdom. <Link to="/Docs/Privacy/">Privacy</Link> &{' '}
        <Link to="/Docs/Cookies/">Cookies</Link>
      </div>
    </div>
  );
};

export default Footer;

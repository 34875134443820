import { FunctionComponent, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import loaderState from '../atoms/loaderState';
import Modal from '../components/Modal';
import OrderDashboard from '../components/order_dashboard/OrderDashboard';
import SpacerTable from '../components/SpacerTable';
import CacheHelper from '../helpers/CacheHelper';
import ThemeHelper from '../helpers/ThemeHelper';
import { IEvent } from '../interfaces/IEvent';

export interface IProps {
  event?: IEvent;
  eventTag?: string;
  onClose?: () => void;
  orderId: number;
}

const AdminOrderModal = ((props) => {
  const { eventTag } = props;

  const [event, setEvent] = useState(props.event);
  const [loaders, setLoaders] = useRecoilState(loaderState);

  useEffect(() => {
    if (!event && eventTag) {
      CacheHelper.eventWithMessage(setLoaders, loaders, eventTag).then((e) => setEvent(e));
    }
  }, []);

  if (!event) return <></>;

  return (
    <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
      <div className="content">
        <div className="ticket-rip" />

        <div className="body">
          <SpacerTable>
            <h1>{event.Name}</h1>
            <div>
              {event.Organisation.Name}{' '}
              <a href={'/Organisation/' + event.Organisation.OrganisationTag}>#{event.Organisation.OrganisationTag}</a>
            </div>

            {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
              <div>
                {event.Venue.Name}
                {event.Venue.Postcode && event.Venue.Postcode.length > 0 ? ', ' + event.Venue.Postcode : ''}
              </div>
            )}
          </SpacerTable>

          <div className="spacer" />

          <OrderDashboard event={event} orderId={props.orderId} />
        </div>

        <div className="ticket-rip bottom" />
      </div>
    </Modal>
  );
}) as FunctionComponent<IProps>;

export { AdminOrderModal as OrderDashboardModal };

import { FunctionComponent } from 'react';
import { IAdminEmail } from '../../../../interfaces/IAdminMail';
import DateHelper from '../../../../helpers/DateHelper';
import { MailSendToDescriptions } from './MailSendTo';
import moment from 'moment';
import SVGList from '../../../../svg/SVGList';
import SVGPencil from '../../../../svg/SVGPencil';
import SVGViewDocument from '../../../../svg/SVGViewDocument';

export interface IProps {
  adminEmail: IAdminEmail;
  onViewClick: (adminEmail: IAdminEmail) => void;
  onEditClick: (adminEmail: IAdminEmail) => void;
}

const AdminMailHistoryRow: FunctionComponent<IProps> = (props) => {
  const { adminEmail } = props;
  return (
    <tr style={{ display: 'table-row' }}>
      {adminEmail.IsDraft ? (
        <td className="donotprint" style={{ textAlign: 'left' }}>
          <button className="table-action-button" onClick={() => props.onEditClick(adminEmail)}>
            <SVGPencil />
          </button>
        </td>
      ) : (
        <td className="donotprint" style={{ textAlign: 'left' }}>
          <button className="table-action-button" onClick={() => props.onViewClick(adminEmail)}>
            <SVGViewDocument />
          </button>
        </td>
      )}
      <td style={{}}>
        {adminEmail.SentByName}
        <br />
        <div style={{ opacity: 0.5 }}>{adminEmail.SentByEmail}</div>
      </td>
      <td style={{}}>
        {MailSendToDescriptions[adminEmail.SendTo]} <br />
        <div style={{ opacity: 0.5 }}>{adminEmail.EmailCount} emails sent</div>
      </td>
      <td style={{}}>
        {adminEmail.IsDraft && (
          <>
            <strong>Draft:</strong>{' '}
          </>
        )}
        {adminEmail.Subject}
      </td>
      {adminEmail.IsDraft ? (
        <td>Not sent</td>
      ) : (
        <td style={{}}>
          {moment(adminEmail.DateSent).format('dddd Do MMMM YYYY')}
          <br />
          <strong>{moment(adminEmail.DateSent).format('h:mma')}</strong>
        </td>
      )}
    </tr>
  );
};

export default AdminMailHistoryRow;

import React, { useEffect, useState } from 'react';
import InfoInput from '../../../components/InfoInput';
import InfoLabel from '../../../components/InfoLabel';
import Switch from '../../../components/Switch';
import GuidHelper from '../../../helpers/GuidHelper';
import SVGHelper from '../../../helpers/SVGHelper';
import { IEvent } from '../../../interfaces/IEvent';
import Toolbar from '../toolbar/Toolbar';
import './MapSection.scss';
import ThreeWordAddressListItem from './ThreeWordAddressListItem';

export interface IThreeWordAddress {
  Guid: string;
  Lng: number;
  Lat: number;
  New: boolean;
  Name: string;
  Description: string;
  Address: string;
  IconUrl: string;
}

export interface IProps {
  event: IEvent;
  onEventUpdated: (e: IEvent) => void;
  globalOptions: JSX.Element;
}

const MapSection: React.FC<IProps> = (props) => {
  const { event, onEventUpdated } = props;

  const [editingThreeWordAddress, setEditingThreeWordAddress] = useState<string>(null);
  const [map, setMap] = useState<google.maps.Map<HTMLElement>>(null);
  const [markers, setMarkers] = useState([]);

  const updateMapMarkers = () => {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }

    const _markers = [];

    if (map && event.UseThreeWordAddress) {
      var threeWordAddresses = event.ThreeWordAddresses;
      for (var i = 0; i < threeWordAddresses.length; i++) {
        var threeWordAddress = threeWordAddresses[i];

        if (threeWordAddress.IconUrl == null || threeWordAddress.IconUrl.length == 0) {
          threeWordAddress.IconUrl = 'MapPin.svg';
        }

        threeWordAddress.Lat = Number(threeWordAddress.Lat);
        threeWordAddress.Lng = Number(threeWordAddress.Lng);

        var infowindow = new google.maps.InfoWindow({
          content: `<strong>${threeWordAddress.Name}</strong><div>${threeWordAddress.Description}</div>`,
        });

        var marker = new google.maps.Marker({
          position: {
            lat: threeWordAddress.Lat,
            lng: threeWordAddress.Lng,
          },
          map: map,
          title: threeWordAddress.Name,
          icon: {
            url: SVGHelper.get(threeWordAddress.IconUrl),
            scaledSize: new google.maps.Size(60, 60),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(30, 60),
          },
        });

        google.maps.event.addListener(
          marker,
          'click',
          (function (marker, infowindow) {
            return function () {
              infowindow.open(map, marker);
            };
          })(marker, infowindow),
        );

        _markers.push(marker);
      }
    }

    setMarkers(_markers);
  };

  const onZoomChanged = () => {
    event.MapZoom = map.getZoom().toString();
    onEventUpdated({ ...event });
  };

  const onCenterChanged = () => {
    var loc = map.getCenter();

    event.Lat = loc.lat().toString();
    event.Lng = loc.lng().toString();
    onEventUpdated({ ...event });
  };

  useEffect(() => {
    const listener1 = map ? map.addListener('zoom_changed', onZoomChanged) : null;
    const listener2 = map ? map.addListener('center_changed', onCenterChanged) : null;

    if (map) {
      updateMapMarkers();
    }

    return () => {
      listener1 && google.maps.event.removeListener(listener1);
      listener2 && google.maps.event.removeListener(listener2);
    };
  }, [map, event]);

  useEffect(() => {
    var lat = event.Lat != null && event.Lat.length > 0 ? parseFloat(event.Lat) : 55.3781;
    var lng = event.Lng != null && event.Lng.length > 0 ? parseFloat(event.Lng) : 3.436;
    var mapZoom = event.MapZoom != null && event.MapZoom.length > 0 ? parseInt(event.MapZoom) : 6;

    var uluru = { lat: lat, lng: lng };

    var google_map = new google.maps.Map(document.getElementById('googleMap'), {
      zoom: mapZoom,
      center: uluru,
      disableDefaultUI: true, // a way to quickly hide all controls
      scaleControl: true,
      zoomControl: true,
      streetViewControl: false,
      mapTypeControl: false,
    });

    setMap(google_map);
  }, []);

  return (
    <>
      <Toolbar>
        <div className="options">{props.globalOptions}</div>
      </Toolbar>

      <div className="maps animated fadeIn">
        <div className="row">
          <div className="col-sm-8">
            <div className="fields">
              <h2>Map options</h2>
              <div className="section">
                <InfoLabel
                  text="Show a map on your event?"
                  tooltip="Showing a map is a great way to help people find your event. Just enable the map and then set the pointer over your event location."
                />

                <Switch
                  trueLabel="Yes, show a map"
                  falseLabel="No, don't show a map"
                  onChange={(value) => onEventUpdated({ ...event, ShowMap: value })}
                  checked={event.ShowMap}
                />
              </div>
            </div>

            <div className="fields">
              <h2>Three Word Address</h2>
              <div
                className="section"
                style={{
                  display: editingThreeWordAddress ? 'none' : 'block',
                }}
              >
                <InfoLabel
                  text="Point out specific locations using three word addresses?"
                  tooltip="Three word addresses work by allowing you to pin point locations on a map. You can use them to help people find the exact entrance to your venue or to help point out the car park etc. You can have more than one three word address to help point out multiple locations."
                />

                <Switch
                  trueLabel="Yes, use them"
                  falseLabel="No, thanks"
                  onChange={(value) => onEventUpdated({ ...event, UseThreeWordAddress: value })}
                  checked={event.UseThreeWordAddress}
                />
              </div>

              <div
                className="smooth-details"
                style={{
                  display: event.UseThreeWordAddress ? 'block' : 'none',
                }}
              >
                {event.ThreeWordAddresses.map((threeWordAddress) => {
                  if (!threeWordAddress.Guid) {
                    threeWordAddress.Guid = GuidHelper.new();
                  }

                  return (
                    <ThreeWordAddressListItem
                      onEnterViewMode={() => setEditingThreeWordAddress(null)}
                      onEnterEditMode={(guid: string) => setEditingThreeWordAddress(guid)}
                      onChange={() => {
                        updateMapMarkers();
                        if (map) {
                          map.setCenter(new google.maps.LatLng(threeWordAddress.Lat, threeWordAddress.Lng));
                        }
                        onEventUpdated({ ...event });
                      }}
                      onDeleteClick={(address) => {
                        event.ThreeWordAddresses = event.ThreeWordAddresses.filter((e) => e.Guid != address.Guid);
                        onEventUpdated({ ...event });
                        setEditingThreeWordAddress(null);
                      }}
                      key={'event_threewordaddress_list_item_' + threeWordAddress.Guid}
                      threeWordAddress={threeWordAddress}
                    />
                  );
                })}
                <button
                  className="admin-button"
                  style={{
                    display: editingThreeWordAddress ? 'none' : 'block',
                  }}
                  onClick={() => {
                    var newAddress = {
                      Id: 0,
                      Address: '',
                      Description: '',
                      Guid: GuidHelper.new(),
                      New: true,
                      IconUrl: 'MapPin.svg',
                    };

                    event.ThreeWordAddresses.push(newAddress);
                    setEditingThreeWordAddress(newAddress.Guid);
                    onEventUpdated({ ...event });
                  }}
                >
                  Add a three word address
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ opacity: event.ShowMap ? '1' : '0.25' }} className="container">
          <input
            style={{
              display: event.UseThreeWordAddress ? 'none' : 'block',
            }}
            id="pac-input"
            className="form-control mapSearch"
            type="text"
            placeholder="Search for a location..."
          />
          <img
            style={{
              display: event.UseThreeWordAddress ? 'none' : 'block',
            }}
            className="pointer"
            src={SVGHelper.get('MapPin')}
          ></img>
          <div id="googleMap" className="map"></div>
        </div>
      </div>
    </>
  );
};

export default MapSection;

import moment from 'moment';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

export interface IProps {
  date: moment.Moment;
  description?: string;
  disabled?: boolean;
  onDateChange: (value: moment.Moment) => void;
  disableTime?: boolean;
}

const DatePicker: React.FC<IProps> = (props) => {
  var date = props.date == null ? moment() : props.date;
  const [state, setState] = useState({
    validTime: true,
    date: date,
    description: props.description,
    timeValue: date.format('HH:mm'),
    dateValue: date.format('ddd Do MMM YYYY'),
  });

  const handleDateChange = (date: Date) => {
    var momentValue = moment(date);

    momentValue.set({
      hour: state.date.hours(),
      minute: state.date.minutes(),
      second: 0,
    });

    setState({
      ...state,
      date: momentValue,
      timeValue: momentValue.format('HH:mm'),
      dateValue: momentValue.format('ddd Do MMM YYYY'),
    });

    if (props.onDateChange != null) {
      props.onDateChange(momentValue);
    }
  };

  const handleTimeChange = function (e) {
    if (props.disabled) return;

    var value = e.target.value;
    value = value.replace('.', ':');

    var t = value.split(':');
    var validTime =
      /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value) &&
      t[0] >= 0 &&
      t[0] < 25 &&
      t[1] >= 0 &&
      t[1] < 60;

    var date = state.date;
    if (validTime) {
      date.set({
        hour: parseInt(t[0]),
        minute: parseInt(t[1]),
        second: 0,
      });

      if (props.onDateChange != null) {
        props.onDateChange(date);
      }
    }

    setState({ ...state, date: date, timeValue: value, validTime: validTime });
  };

  return (
    <div className="row datepicker-row">
      <div className={props.disableTime ? 'col col-md-12' : 'col col-md-7'}>
        <div className={'input-label-group' + (props.disabled ? ' disabled' : '')}>
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            disabled={props.disabled}
            placeholderText={state.description == null ? 'Date' : state.description}
            className={'form-control datepicker-input'}
            selected={props.date.toDate()}
            onChange={handleDateChange}
          />
          {state.date == null ? null : (
            <label className="input-label animated fadeIn">
              {state.description == null ? 'Date' : state.description}
            </label>
          )}
        </div>
      </div>
      {props.disableTime ? null : (
        <div className="col col-md-5">
          <div className={'input-label-group' + (props.disabled ? ' disabled' : '')}>
            <input
              placeholder="Time"
              value={state.timeValue}
              onChange={handleTimeChange}
              className={'form-control timepicker-input'}
              type="text"
            />
            {state.timeValue == null || state.timeValue.length == 0 ? null : (
              <label className="input-label animated fadeIn">{state.validTime ? 'Time' : 'Time *'}</label>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;

export enum EmailEventRecordType {
  Open = 'Open',
  Delivery = 'Delivery',
}

export interface IEmailEvent {
  Date: string;
  RecordType: EmailEventRecordType;
  Recipient: string;
}

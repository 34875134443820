import linq from 'linq';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import showSignInState from '../../atoms/showSignInState';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import UserHelper from '../../helpers/UserHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IOrganisation } from '../../interfaces/IOrganisation';
import './Admin.scss';
import AdminSections, { AdminSection } from './AdminSections';
import MyTicketsPage from '../MyTickets/MyTicketsPage';
import AdminHeader from './AdminHeader';
import UserHeader from './UserHeader';
import AdminEvents from './Sections/Events/AdminEvents';
import UserAccountPage from './UserAccountPage';

export interface IProps {
  hideFooter?: boolean;
}

const UserPage: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const [section, setSection] = useState<AdminSection>(null);
  const [busyMessage, setBusyMessage] = useState(null);
  const [, setShowSignIn] = useRecoilState(showSignInState);

  const location = useLocation();
  const user = UserHelper.currentUser;

  useEffect(() => {
    if (!UserHelper.currentUser || UserHelper.currentUser.Guest) {
      setShowSignIn(true);
      return;
    }
  }, []);

  useEffect(() => {
    const fragments = location.pathname.split('/');
    const sectionName = fragments[fragments.length - 1].toLowerCase();

    AdminSections.getSections().forEach((s) => {
      if (sectionName == s.toString().toLowerCase()) {
        setSection(s);
      }
    });
  }, [location]);

  if (!UserHelper.currentUser || UserHelper.currentUser.Guest) {
    return (
      <>
        <Header />
      </>
    );
  }

  return (
    <>
      <div className="admin-dashboard">
        <UserHeader section={section} />

        <div className={`admin-dashboard-section ${section}`}>
          {busyMessage ? (
            <Loader inline>{busyMessage}</Loader>
          ) : (
            <>
              {user && <>{section == AdminSection.Tickets && <MyTicketsPage />}</>}
              {user && <>{section == AdminSection.Account && <UserAccountPage />}</>}

              {props.children}
            </>
          )}
        </div>
        {props.hideFooter ? null : <Footer admin={true} />}
      </div>
    </>
  );
};

export default UserPage;
